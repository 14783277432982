import { render, staticRenderFns } from "./ac-move-up-down.vue?vue&type=template&id=e2a7c724&scoped=true&"
import script from "./ac-move-up-down.vue?vue&type=script&lang=js&"
export * from "./ac-move-up-down.vue?vue&type=script&lang=js&"
import style0 from "./ac-move-up-down.vue?vue&type=style&index=0&id=e2a7c724&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2a7c724",
  null
  
)

export default component.exports