<template>
  <div>
    <b-form-input
      v-model="defaultValue"
      placeholder="Input default value"
      :list="`${id}-default-input-helper`"
      autocomplete="off"
      :class="{ 'no-pointer-events': !id }"
      style="font-size: 1em;"
      @input="onInputDefaultValue"/>
    <datalist :id="`${id}-default-input-helper`">
      <option v-for="el in constants.DEFAULT_VALUES" :key="el">{{el}}</option>
    </datalist>
  </div>
</template>
<script>
import constants from '../constants'
import store from '../store'

export default {
  name: 'default-value-input',
  props: {
    value: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      defaultValue: this.value,
      constants,
    }
  },
  methods: {
    onInputDefaultValue(value) {
      const constraints = {
        default: '',
        auto_increment: '',
      }
      if (value === 'AUTO_INCREMENT') constraints.auto_increment = value
      else constraints.default = value
      store.actions.updateCurrentTableColumn(this.id, constraints)
    },
  },
}
</script>
<style scoped>
.no-pointer-events input{
  background: #e6e5e5;
}
</style>
