<template>
  <b-button
    class="m-2 d-flex justify-content-center align-items-center"
    size="lg"
    variant="outline-dark"
    @click="show"
  >
    <i class="fab fa-google-drive fa-2x"></i>
  </b-button>
</template>
<script>
import uploaderMixin from "../mixins/ac-uploader";
export default {
  mixins: [uploaderMixin],

  props: {
    developer_key: {
      type: String,
      required: true
    },

    client_id: {
      type: String,
      required: true
    },

    actual_api: {
      type: String,
      required: true
    }
  },

  name: "ac-google-picker",

  created() {
    this.script_el.setAttribute("type", "text/javascript");
    this.script_el.setAttribute("src", this.actual_api);
    document.head.appendChild(this.script_el);
  },

  data() {
    return {
      scope: "https://www.googleapis.com/auth/drive.readonly",
      picker_api_loaded: false,
      oauth_token: null,
      auth_loading: false
    };
  },

  methods: {
    async show() {
      this.auth_loading = true;
      await gapi.load("auth2", this.open);
      gapi.load("picker", this.on_picker_api_load);
    },

    async open() {
      try {
        const google_auth = await gapi.auth2.init({
          client_id: this.client_id
        });
        const result = await google_auth.signIn({
          scope: this.scope
        });
        this.handle_auth_result(result.getAuthResponse());
      } catch (e) {
        console.error(e);
      }
    },

    handle_auth_result(auth_result) {
      if (auth_result && !auth_result.error) {
        this.oauth_token = auth_result.access_token;
        this.auth_loading = false;
        this.create_picker();
      }
    },

    on_picker_api_load() {
      this.picker_api_loaded = true;
      this.create_picker();
    },

    create_picker() {
      if (this.picker_api_loaded && this.oauth_token && !this.auth_loading) {
        const picker = new google.picker.PickerBuilder()
          .addView(google.picker.ViewId.DOCS)
          .setOAuthToken(this.oauth_token)
          .setDeveloperKey(this.developer_key)
          .setCallback(this.picker_callback)
          .build();

        this.picker = picker;
        picker.setVisible(true);
      }
    },

    picker_callback(data) {
      let url = "nothing";
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        const doc = data[google.picker.Response.DOCUMENTS][0];
        url = doc[google.picker.Document.URL];
        
        this.$emit(
          "choose",
          data.docs &&
            data.docs.map(item => ({
              ...item,
              lastModified: new Date(item.lastEditedUtc),
              link: `https://drive.google.com/uc?export=view&id=${item.id}`,
              iconURL: item.iconUrl,
              title: item.name,
              size: item.sizeBytes,
              type: item.mimeType
            }))
        );
        
        this.picker.setVisible(false);
      }
    }
  }
};
</script>
<style>
/* Google picker over bootstrap modal content */
body .picker-dialog-bg {
  z-index: 2000;
}
body .picker-dialog {
  z-index: 2001;
}
</style>