<template>
  <div v-if="ready">
    <component :is="component_name" v-bind="component_props" v-on="$listeners"></component>
  </div>
</template>

<script>
import Vue from "vue";
import loadjs from "loadjs";
/**
 * Ac Dynamic component which will load components by URL
 */
export default {
  name: "ac-dynamic",

  data() {
    return {
      ready: false,
      component_props: {}
    };
  },

  props: {
    /**
     * URLs for js and css files of the component
     * @param {array} url
     * Required
     * @label URL
     * @category_name 1_General
     */
    url: {
      type: Array,
      required: true
    },

    /**
     * Name of the imported component
     * @param {array} component_name
     * Required
     * @label Component Name
     * @category_name 1_General
     */
    component_name: {
      type: String,
      required: true
    },

    /**
     * Props for loaded component
     * @param {object} component_props
     * @label Properties
     * @category_name 1_General
     */
    props: {
      type: Object
    },

    /**
     * Props mapper for loaded component
     * @param {object} props_mapper
     * @label Properties Mapper
     * @category_name 1_General
     */
    props_mapper: {
      type: Object
    }
  },

  watch: {
    props: function() {
      this.component_props = {
        ...this.component_props,
        ...this.convert_props()
      };
    }
  },

  mounted() {
    this.load(this.url, this.loaded);
  },

  methods: {
    load(url, callback) {
      loadjs(url, "component", { returnPromise: true }).then(() => callback());
    },

    loaded() {
      Vue.component(this.component_name, window[this.component_name].default);
      const props = Vue.options.components[this.component_name].options.props;
      const converted_props = this.convert_props();

      Object.keys(props).forEach(key => {
        if (props[key].default) {
          const defaultType = typeof props[key].default;
          const propType = typeof props[key].type;
          if (propType === "function") {
            if (typeof props[key].type() === defaultType) {
              this.component_props[key] = props[key].default;
            }
          }
        }
      });
      this.component_props = {
        ...this.component_props,
        ...converted_props,
        ...this.props
      };
      this.ready = true;
    },

    convert_props() {
      return Object.keys(this.props).reduce((obj, prop) => {
        if (Object.keys(this.props_mapper).includes(prop)) {
          obj[this.props_mapper[prop]] = this.props[prop];
          return obj;
        }
      }, {});
    }
  }
};
</script>
