import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

// Add examples
import examplesRoutes from './example-routes';
import ExampleLayout from '../examples/example-layout.vue';

export default new Router({
  routes: [
    {
      path: '/examples',
      name: 'examples',
      component: ExampleLayout,
      children: examplesRoutes
    }
  ]
})
