<template>
  <div>
    <b-form-input
      v-model="dataType"
      placeholder="Input data type"
      :list="`${id}-input-helper`"
      autocomplete="off"
      :class="{ 'no-pointer-events': !id }"
      style="font-size: 1em;"
      :state="state"
      @input="onInputDataType"/>
    <datalist :id="`${id}-input-helper`">
        <option v-for="el in constants.DATA_TYPES" :key="el">{{el}}</option>
    </datalist>
  </div>
</template>
<script>
import constants from '../constants'
import store from '../store'

export default {
  name: 'data-type-input',
  props: {
    state: {
      type: Boolean,
    },
    value: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      dataType: this.value,
      constants,
    }
  },
  methods: {
    onInputDataType(value) {
      store.actions.updateCurrentTableColumn(this.id, { data_type: value })
    },
  },
}
</script>
<style scoped>
.no-pointer-events input{
  background: #e6e5e5;
}
</style>
