<script>
/**
 * View component for ac-collections
 * @requires VueBootstrap
 */

export default {
  name: 'ac-view',

  props: {
    /**
     * @param {array} options - Options for top buttons to change view
     * @properties {"icon": {"type": "String"}, "ref": {"type": "String"}, "selected": {"type": "Boolean"}}
     */
    options: {
      type: Array,
      required: true,
    },

    /**
     * @param {string} header_label - Text for header
     */
    header_label: {
      type: String,
      required: true,
    },

    /**
     * @param {array} header_classes - Array of classes for header
     */
    header_classes: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      h: null,
      selected_ref: null,
      views: {},
    }
  },

  created() {
    this.h = this.$createElement

    this.options.forEach((item) => {
      if (item.selected) {
        this.selected_ref = item.ref
      }
    })

    this.$on('collection_added', (data) => {
      this.views[data.name] = data.component
    })
  },

  mounted() {
    // Get data for selected view
    this.views[this.selected_ref].set_ajax()
  },

  render(h) {
    const header = this.render_header()
    const body = this.h('div', { class: { 'ac-view__body': true } }, [this.render_body()])

    return h('div', {
      class: {
        'ac-view': true,
        rounded: true,
        border: true,
      },
    }, [
      header,
      body,
    ])
  },

  methods: {
    /**
     * @function change_view - Copies data from old view to new.
     */
    change_view(new_ref) {
      this.copy_data(this.selected_ref, new_ref)

      // Update selected view reference
      this.selected_ref = new_ref
    },

    /**
     * @function copy_data - Copies data from old view to new.
     * @param {string} old_ref - ref to copy value from
     * @param {string} new_ref - ref to copy value to
     * Updates selected values if present
     */
    copy_data(old_ref, new_ref) {
      const data = [...this.views[old_ref].data]
      this.views[new_ref].set_data(data)

      if (this.views[old_ref].get_selected) {
        const checked = this.views[old_ref].get_selected()
        this.views[new_ref].set_selected(checked)
      }
    },

    /**
     * @function render_header - renders header.
     */
    render_header() {
      const header_classes = this.header_classes.reduce((acc, item) => {
        acc[item] = true
        return acc
      }, {})

      return this.h('header', {
        class: {
          'd-flex': true,
          'p-3': true,
          'border-bottom': true,
          'ac-view-header': true,
          ...header_classes,
        },
      },
      [
        this.h('h3', this.header_label),
        this.render_header_buttons(),
      ])
    },

    /**
     * @function render_header_buttons - renders header buttons.
     */
    render_header_buttons() {
      const buttons = this.options.map(item => this.h('b-button', {
        props: {
          variant: 'outline-secondary',
        },
        class: {
          'mr-2': true,
        },
        on: {
          click: () => {
            this.change_view(item.ref)
          },
        },
      }, [
        this.h('i', { class: { [item.icon]: true, 'mr-2': true } }),
        item.label,
      ]))

      return this.h('div', {
        class: {
          'ml-auto': true,
        },
      }, [...buttons])
    },

    /**
     * @function render_body - Render methods for body.
     */
    render_body() {
      const slots = this.options.map(item => this.h('div', {
        class: {
          'd-none': item.ref !== this.selected_ref,
        },
      },
      [
        this.$slots[item.ref],
      ]))

      return this.h('div', {
        class: {
          'p-3': true,
        },
      },
      [
        slots,
      ])
    },
  },
}
</script>
