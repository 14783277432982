<template>
  <div class="ac-sql-builder-ui"></div>
</template>
<script>
import cytoscape from 'cytoscape'
import dblclick from 'cytoscape-dblclick'
import uuidv4 from 'uuid/v4'
import store from './store'
import stylesheet from './stylesheet'
import utils from './utils'
import confirm from './mixins/confirm'

cytoscape.use(dblclick)

/**
 * acSqlBuilderUi - board contains tables and linkers
 * @requires cytoscape
 * @requires VueBootstrap
 * @requires dblclick
 */
export default {
  name: 'ac-sql-builder-ui',
  mixins: [confirm],
  cytoscapeInstance: null,
  computed: {
    cytoscapeElements() {
      return store.getters.cytoscapeElements()
    },
    sqlJson() {
      return store.getters.sqlJson()
    },
    currentElements() {
      return store.getters.currentElements()
    },
    changedElements() {
      return store.getters.changedElements()
    },
    isRedrawNeeded() {
      return store.getters.isRedrawNeeded()
    },
    isAddingTable() {
      return store.getters.isAddingTable()
    },
    isAddingEdge() {
      return store.getters.isAddingEdge()
    },
    isAddingComment() {
      return store.getters.isAddingComment()
    },
    zoomMode() {
      return store.getters.zoomMode()
    },
    isMultiselectEnabled() {
      return store.getters.isMultiselectEnabled()
    },
  },
  watch: {
    isRedrawNeeded(value) {
      if (!value) return
      this.drawBoard()
    },
    isAddingEdge(value) {
      const edgeHandles = this.cytoscapeInstance.nodes('.eh-handle')
      if (value) return
      const currentHandle = edgeHandles.filter(node => node.data('visibility') === 'visible')
      currentHandle.data('visibility', 'hidden')
    },
    changedElements() {
      this.redrawChangedElements()
    },
    zoomMode(value) {
      if (value === 'fit') {
        this.cytoscapeInstance.fit(this.cytoscapeInstance.nodes(), 50)
      } else if (value === 'max') {
        this.cytoscapeInstance.zoom(1.0).center()
      }
    },
    isMultiselectEnabled(value) {
      this.cytoscapeInstance.userPanningEnabled(!value)
    },
  },
  created() {
    window.addEventListener('keyup', this.handleKeyUp)
  },
  mounted() {
    this.drawBoard()
  },
  destroyed() {
    if (this.cytoscapeInstance) this.cytoscapeInstance.destroy()
    window.removeEventListener('keyup', this.handleKeyUp)
  },
  methods: {
    drawBoard() {
      if (this.isRedrawNeeded) store.actions.setIsRedrawNeededState(false)
      if (this.cytoscapeInstance) this.cytoscapeInstance.destroy()
      this.initCytoscapeInstance()
    },
    initCytoscapeInstance() {
      this.cytoscapeInstance = cytoscape({
        container: this.$el,
        elements: this.cytoscapeElements,
        style: stylesheet,

        layout: {
          name: 'null',
        },
        maxZoom: 1e1,
        minZoom: 1e-1,
        wheelSensitivity: 0.2,
      })
      this.cytoscapeInstance.dblclick()
      this.addMouseDownListener()
      this.cytoscapeInstance.on('boxselect', (e) => {
        if (!e.target.hasClass('eh-handle')) return
        e.target.unselect()
      })
      this.cytoscapeInstance.ready(() => {
        this.positionNodes()
        this.getTableNodesUI(this.cytoscapeInstance.nodes('.table'))
        this.cytoscapeInstance.edges().on('tapselect', this.onEdgeTapSelect)
        if (!this.sqlJson.comments || !this.sqlJson.comments.length) return
        this.sqlJson.comments.forEach(c => this.addCommentNode(c.id, c.position, c.text))
      })
    },
    redrawChangedElements() {
      if (this.changedElements.length) {
        this.changedElements.forEach((el, index) => {
          if (!el.id) return
          let changedNode = this.cytoscapeInstance.getElementById(el.id)
          const zIndex = changedNode.data('zIndex') || (this.sqlJson.tables.length - 1) * 3 - index + 1
          this.cytoscapeInstance.remove(changedNode)
          if (el.columns && el.columns.length) {
            const newNode = utils.getTableNodes({ tables: [el] }, zIndex)
            this.cytoscapeInstance.add({ nodes: newNode })
            changedNode = this.cytoscapeInstance.getElementById(el.id)
            const { positions } = utils.getColumnFixedPositions(this.sqlJson, changedNode, el)
            changedNode.descendants().positions(desc => positions[desc.id()])
            this.getTableNodesUI(changedNode)
            return
          }
          if (el.text && el.text.length) {
            this.addCommentNode(el.id, el.position, el.text)
          }
        })
      }
      if (!this.sqlJson.tables) return
      const newEdges = utils.getEdges(this.sqlJson)
      this.cytoscapeInstance.remove(this.cytoscapeInstance.edges())
      this.cytoscapeInstance.add({ edges: newEdges })
      this.cytoscapeInstance.edges().on('tapselect', this.onEdgeTapSelect)
    },
    addMouseDownListener() {
      this.cytoscapeInstance.on('mousedown', async (e) => {
        if (e.target.data('id')) return
        const position = { x: e.position.x + 10, y: e.position.y + 10 }
        if (this.isAddingTable) {
          const newTable = {
            table_name: '',
            columns: [],
            keys: [],
            position,
          }
          await store.actions.addNewTable(newTable)
          await store.actions.setAddingTableMode(false)
        }
        if (this.isAddingComment) {
          const commentId = uuidv4()
          const defaultText = 'Double click to edit comment text'
          await store.actions.addComment(defaultText, commentId, position)
          await store.actions.setAddingCommentMode(false)
          this.addCommentNode(commentId, position, defaultText)
        }
      })
    },
    positionNodes() {
      const { positions, definedPositions } = utils.getColumnFixedPositions(this.sqlJson, this.cytoscapeInstance.nodes('.table'))
      if (this.sqlJson.tables && !this.sqlJson.tables[0].positions) {
        store.actions.setTablesPositions(definedPositions)
      }
      const params = { fit: true }
      if (this.sqlJson.viewport) {
        params.zoom = this.sqlJson.viewport.zoom
        params.pan = this.sqlJson.viewport.pan
        params.fit = false
      }
      const columnsLayout = this.cytoscapeInstance.nodes().layout({
        name: 'preset',
        ...params,
        positions: node => positions[node.id()],
      })
      columnsLayout.run()
    },
    addDblClickListener(tableNodes) {
      tableNodes.on('dblclick', async (e) => {
        const tableId = e.target.id()
        const table = this.sqlJson.tables.filter(el => el.id === tableId)[0]
        await store.actions.setCurrentElements([table])
        await store.actions.showEditorModal()
        await store.actions.setViewport(this.cytoscapeInstance.zoom(), this.cytoscapeInstance.pan())
      })
    },
    addSelectionListeners(nodes) {
      nodes.on('select', (e) => {
        const tableId = e.target.id()
        let container = 'tables'
        if (e.target.hasClass('comment')) container = 'comments'
        const currentElement = this.sqlJson[container].filter(el => el.id === tableId)[0]
        let newCurrent = [currentElement]
        if (this.isMultiselectEnabled) {
          newCurrent = [...this.currentElements, currentElement]
        } else {
          this.addDeleteButton(e.target)
        }
        store.actions.setCurrentElements(newCurrent)
      })
      nodes.on('unselect', (e) => {
        let newCurrent = []
        if (this.isMultiselectEnabled) {
          const tableId = e.target.id()
          newCurrent = this.currentElements.filter(el => el.id !== tableId)
        }
        const deleteButton = this.cytoscapeInstance.getElementById(`${e.target.id()}-delete`)
        this.cytoscapeInstance.remove(deleteButton)
        store.actions.setCurrentElements(newCurrent)
      })
    },
    addDragFreeListener() {
      const nodes = this.cytoscapeInstance.nodes('.table, .comment')
      nodes.removeListener('dragfree')
      nodes.once('dragfree', async (e) => {
        let container = 'comments'
        let pos = e.target.position()
        if (e.target.hasClass('table')) {
          container = 'tables'
          const bounds = e.target.children()[0].boundingBox()
          pos = { x: bounds.x1 + 10, y: bounds.y1 + 10 }
        }
        await store.actions.setViewport(this.cytoscapeInstance.zoom(), this.cytoscapeInstance.pan())
        await store.actions.setElementPosition(container, e.target.id(), pos)
        nodes.removeListener('dragfree')
        this.addDragFreeListener(nodes)
      })
    },
    addMouseMoveListener(tableNodes) {
      tableNodes.on('mousemove', (e) => {
        if (!this.isAddingEdge) return
        const currentHandle = this.cytoscapeInstance.nodes(node => node.data('visibility') === 'visible')
        const children = e.target.children()
        const bounds = children[0].boundingBox()
        const pos = e.position.y - bounds.y1
        const childNum = Math.floor(pos / bounds.h)
        if (!children[childNum] || !currentHandle) return
        const child = children[childNum]
        const {
          x1, x2, y1, y2,
        } = child.boundingBox()
        let x = x1 - 20
        if (e.position.x - x1 > x2 - e.position.x) x = x2 + 20
        if (currentHandle.id() !== this.getEdgeHandleId(children[childNum].id(), 'edge')) {
          const handle = this.cytoscapeInstance.getElementById(this.getEdgeHandleId(child.id(), 'edge'))
          this.cytoscapeInstance.batch(() => {
            currentHandle.data('visibility', 'hidden')
            handle.data('visibility', 'visible')
            handle.position({ x, y: (y2 + y1) / 2 })
          })
        } else if (currentHandle.position.x !== x) {
          currentHandle.position({ x, y: (y2 + y1) / 2 })
        }
      })
    },
    addEdgeHandles(columnNodes) {
      columnNodes.forEach((column) => {
        const handle = this.cytoscapeInstance.add({
          nodes: [
            {
              data: {
                id: this.getEdgeHandleId(column.id(), 'edge'),
                label: '',
                visibility: 'hidden',
              },
              classes: ['eh-handle'],
            },
          ],
        })
        handle.on('grab', () => {
          const outgoers = this.getOutgoers(column)
          const edge = outgoers.length && outgoers[0]
          const { tableId, columnName } = this.getKeyParamsByColumnId(column.id())
          this.getEdgeSourceTableKey(tableId, columnName, edge)
          if (edge) this.cytoscapeInstance.remove(edge)
          this.cytoscapeInstance.add({
            nodes: [
              {
                data: {
                  id: this.getEdgeHandleId(column.id(), 'start'),
                  label: '',
                  visibility: 'visible',
                },
                selectable: false,
                grabbable: false,
                position: { ...handle.position() },
                classes: ['eh-handle'],
              },
            ],
            edges: [
              {
                data: {
                  target: this.getEdgeHandleId(column.id(), 'edge'),
                  source: this.getEdgeHandleId(column.id(), 'start'),
                },
              },
            ],
          })
        })
        handle.on('free', async () => {
          const changed = this.getTargetChangeResult(handle, column)
          if (!changed) {
            await store.actions.setCurrentElements([])
            await store.actions.setChangedElements([])
          }
          const prevMoveHandle = this.cytoscapeInstance.getElementById(this.getEdgeHandleId(column.id(), 'start'))
          if (prevMoveHandle) this.cytoscapeInstance.remove(prevMoveHandle)
          handle.data('visibility', 'hidden')
        })
      })
    },
    getTableNodesUI(tableNodes) {
      this.addSelectionListeners(tableNodes)
      utils.addTableStyles(tableNodes)
      this.addDblClickListener(tableNodes)
      this.addDragFreeListener()
      this.addEdgeHandles(tableNodes.children())
      this.addMouseMoveListener(tableNodes)
      this.addGrabListeners(tableNodes)
      tableNodes.on('grab', () => this.cytoscapeInstance.edges().unselect())
    },
    addCommentNode(commentId, position, defaultText = 'Double click to edit comment text') {
      const comment = this.cytoscapeInstance.add({
        nodes: [
          {
            data: {
              id: commentId,
              label: defaultText,
            },
            position,
            classes: 'comment',
          },
        ],
      })
      const width = comment.boundingBox().w
      const height = comment.boundingBox().h
      const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" width="${width}" height="${height}"><g>
      <g><path d="M30 0L0 30L30 30L30 0Z" fill="#68696d"/></g>
      <g><path d="M30 0L${width} 0L${width} 60L30 60L30 0Z M0 30L30 30L30 60L0 60L0 30Z M0 60 L${width} 60 L${width} ${height} L0 ${height} Z" fill="#57565c"/></g>
      <g><path d="M0 60L30 30L0 30L0 60Z" fill="#4f4e54"/></g>
      </g></svg>`
      utils.setNodeBackgroundFromSVG(svg, comment)
      this.addSelectionListeners(comment)
      comment.on('dblclick', async () => {
        const currentComment = this.sqlJson.comments.filter(el => el.id === commentId)[0]
        await store.actions.setCurrentElements([currentComment])
        await store.actions.showCommentModal()
      })
      this.addDragFreeListener()
      this.addGrabListeners(comment)
    },
    onEdgeTapSelect(e) {
      if (this.currentElements.length) return
      const edge = e.target
      const sourcePosition = { ...edge.sourceEndpoint() }
      const targetPosition = { ...edge.targetEndpoint() }
      const id = edge.id()
      const sourceId = `${id}-edge-editor-source`
      const targetId = `${id}-edge-editor-target`
      const column = edge.source()
      const columnTarget = edge.target()
      const { tableId, columnName } = this.getKeyParamsByColumnId(column.id())
      const editors = this.cytoscapeInstance.add({
        nodes: [
          {
            data: {
              id: sourceId,
              label: '',
              visibility: 'visible',
            },
            classes: ['eh-handle'],
            position: sourcePosition,
          },
          {
            data: {
              id: targetId,
              label: '',
              visibility: 'visible',
            },
            classes: ['eh-handle'],
            position: targetPosition,
          },
        ],
      })
      this.cytoscapeInstance.remove(edge)
      editors.on('dragfree', async (evt) => {
        const key = this.getEdgeSourceTableKey(tableId, columnName, edge)
        let changed = false
        if (evt.target.id() === sourceId) {
          changed = this.getSourceChangeResult(editors[0], key)
        } else if (evt.target.id() === targetId) {
          changed = this.getTargetChangeResult(editors[1], column)
        }
        this.cytoscapeInstance.remove(editors)
        if (changed) return
        await store.actions.addCurrentTableKey(key.type, key.columns, { columnRef: key.columnRef, tableRef: key.tableRef })
        await store.actions.setChangedElements(this.currentElements[0])
        await store.actions.setCurrentElements([])
      })
      const newEdge = this.cytoscapeInstance.add({
        edges: [
          {
            data: {
              id,
              source: sourceId,
              target: targetId,
            },
            pannable: false,
            selectable: true,
            classes: ['editable-edge'],
          },
        ],
      })
      newEdge.select()
      store.actions.setCurrentElements([{
        id: newEdge.id(), edge: true, source: columnName, table: tableId,
      }])
      this.addDeleteButton(newEdge)
      newEdge.on('unselect', () => {
        const deleteButton = this.cytoscapeInstance.getElementById(`${newEdge.id()}-delete`)
        this.cytoscapeInstance.remove(deleteButton.union(editors).union(newEdge))
        const restored = this.cytoscapeInstance.add({
          edges: [{ data: { source: column.id(), target: columnTarget.id() } }],
        })
        restored.on('tapselect', this.onEdgeTapSelect)
        store.actions.setCurrentElements([])
      })
      newEdge.on('remove', () => this.cytoscapeInstance.remove(editors))
    },
    getEdgeSourceTableKey(tableId, columnName, edge) {
      const sourceTable = this.sqlJson.tables
        .filter(table => table.id === tableId)[0]
      store.actions.setCurrentElements([sourceTable])
      if (!edge) return null
      const sameKey = this.currentElements[0].keys
        .filter(key => key.type === 'foreign' && key.columns[0] === columnName)
      if (sameKey.length) store.actions.removeCurrentTableKey(sameKey[0])
      return sameKey[0]
    },
    getSourceChangeResult(handle, key) {
      if (!this.currentElements.length) return false
      const { x, y } = handle.position()
      let changed = false
      this.cytoscapeInstance.nodes(async (node) => {
        if (!node.hasClass('column')) return
        if (!this.getIsNodeInBounds(node, x, y)) return
        const outgoers = this.getOutgoers(node)
        if (outgoers.length) return
        const { tableId, columnName } = this.getKeyParamsByColumnId(node.id())
        let source = {}
        let target = {}
        this.sqlJson.tables.forEach((table) => {
          if (table.id === tableId) source = { ...table }
          else if (table.table_name === key.tableRef) target = { ...table }
        })
        const tableRef = utils.getPossibleTableRef(this.sqlJson, source, target.id)
        if (!tableRef) return
        changed = true
        const isSameTable = this.currentElements[0].id === source.id
        if (!isSameTable) {
          await store.actions.saveCurrentTableToSqlJson()
          await store.actions.setCurrentElements([source])
          await store.actions.removeCurrentTableKey(key)
        }
        await store.actions.addCurrentTableKey('foreign', [columnName], { tableRef, columnRef: key.columnRef })
        await store.actions.saveCurrentTableToSqlJson(!isSameTable)
      })
      return changed
    },
    getTargetChangeResult(handle, sourceColumn) {
      if (!this.currentElements.length) return false
      const { x, y } = handle.position()
      let changed = false
      this.cytoscapeInstance.nodes(async (node) => {
        if (changed) return
        if (!node.hasClass('column')) return
        if (!this.getIsNodeInBounds(node, x, y)) return
        const { tableId: refTableId, columnName: columnRef } = this.getKeyParamsByColumnId(node.id())
        const tableRef = utils.getPossibleTableRef(this.sqlJson, this.currentElements[0], refTableId)
        if (!tableRef) return
        changed = true
        const sourceId = sourceColumn.id()
        await store.actions.addCurrentTableKey('foreign',
          [sourceId.substr(sourceId.lastIndexOf('-') + 1)], { tableRef, columnRef })
        await store.actions.saveCurrentTableToSqlJson()
      })
      return changed
    },
    getOutgoers(column) {
      return this.cytoscapeInstance.edges().filter(edge => edge.source().id() === column.id())
    },
    handleKeyUp(e) {
      if (e.code === 'Delete') {
        this.handleDelete()
      }
    },
    handleDelete() {
      this.confirm('Are you sure that you want to delete an element?', () => store.actions.removeCurrentElements())
    },
    addDeleteButton(nodes) {
      nodes.forEach((node) => {
        let bounds = { x: 0, y: 0 }
        if (node.isNode()) {
          const pos = node.boundingBox()
          bounds.x = pos.x2
          bounds.y = pos.y1
        } else bounds = { ...node.midpoint() }
        const paddings = { x: 0, y: 0 }
        const classes = ['delete']
        if (node.hasClass('table')) {
          paddings.y = 15
          classes.push('table-delete')
        }
        if (node.isEdge()) {
          paddings.y = 15
          paddings.x = 15
        }
        const button = this.cytoscapeInstance.add({
          nodes: [
            {
              data: {
                id: `${node.id()}-delete`,
                label: '',
              },
              selectable: false,
              grabbable: false,
              classes,
              position: { x: bounds.x + paddings.x, y: bounds.y + paddings.y },
            },
          ],
        })
        button.on('mousedown', () => this.handleDelete())
        nodes.on('remove', () => this.cytoscapeInstance.remove(button))
      })
    },
    addGrabListeners(nodes) {
      nodes.on('grabon', (e) => {
        const deleteButton = this.cytoscapeInstance.getElementById(`${e.target.id()}-delete`)
        this.cytoscapeInstance.remove(deleteButton)
      })
      nodes.on('freeon', (e) => {
        if (this.isMultiselectEnabled
        || !this.currentElements.some(t => t.id === e.target.id())) return
        this.addDeleteButton(e.target)
      })
    },
    getKeyParamsByColumnId(columnId) {
      const lastDash = columnId.lastIndexOf('-')
      const tableId = columnId.substr(0, lastDash)
      const columnName = columnId.substr(lastDash + 1)
      return {
        tableId,
        columnName,
      }
    },
    getEdgeHandleId(id, type) {
      return `${id}-${type}-handle`
    },
    getIsNodeInBounds(node, x, y) {
      const nodeBounds = node.boundingBox()
      return nodeBounds.x1 < x && x < nodeBounds.x2
          && nodeBounds.y1 < y && y < nodeBounds.y2
    },
  },
}
</script>
<style scoped>
.ac-sql-builder-ui {
  height: calc(100% - 3.25rem - 2px);
  min-height: 600px;
  width: 100%;
  background: #44444c;
}
</style>
