<template>
    <div role="tablist">
        <b-card no-body class="mb-4 mb-8" v-for="(step, stepIndex) in steps" :key="`ac-automate-step_${stepIndex}`">
            <b-card-header header-tag="header" class="p-3" role="tab">
                <div class="pl-3" v-b-toggle="`ac-automate_accordion_${stepIndex}`" >
                    <h5 class="mb-0">{{ step.title }}</h5>
                </div>
            </b-card-header>
            <b-collapse :id="`ac-automate_accordion_${stepIndex}`" visible role="tabpanel">
                <b-card-body>
                    <b-card-text v-if="step.description">{{ step.description }}</b-card-text>
                    <component
                        :is="step.component.name"
                        v-bind="step.component.props"
                        ref="steps"
                    ></component>
                    <b-card-text class="text-center">
                        <b-button v-if="step.show_code" variant="link" @click="show_code(stepIndex)">Show me code</b-button>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-modal
            ref="code_modal"
            hide-footer
            title="Preview"
        >
            <b-form class="json-modal-form">
                <b-form-textarea
                    id="textarea"
                    :value="code_modal"
                    placeholder="Enter JSON..."
                    rows="20"
                    disabled
                ></b-form-textarea>
            </b-form>
        </b-modal>
        <div class="text-right">
            <b-button class="mb-2 px-5" variant="primary" @click="save">Save</b-button>
        </div>
    </div>
</template>
<script>
import acAutomationConditionList from './ac-automation-condition-list'
import acAutomationSwitch from './ac-automation-switch'
import acAutomationActionsList from './ac-automation-actions-list'
import acAutomationWorkflow from './ac-automation-workflow'
/**
 * acSimpleAutomation - seems query builder
 * @requires VueBootstrap
 * @requires ac-automation-condition-list.vue
 * @requires ac-automation-switch.vue
 * @requires ac-automation-actions-list.vue
 * @requires ac-automation-workflow.vue
 */
export default {
    name: 'ac-automation',
    components: {
        acAutomationConditionList,
        acAutomationSwitch,
        acAutomationActionsList,
        acAutomationWorkflow
    },
    props: {
        /**
         * List of each step information
         * @param {array} steps
         * @properties {"title": {"type": "String"}, "description": {"type": "String"}, "show_code": {"type": "Boolean"}, "component": { "name": {"type": "String"}, "props": {"type": "Object"} } }
         * @requires
         */
        steps: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
             /**
             * Converted query from json
             */
            code_modal: null
        }
    },
    methods: {
        /**
         * To display modal with query
         * @param stepIndex
         */
        async show_code(stepIndex) {
            this.code_modal = await this.get_query_string(stepIndex)
            this.show_modal()
        },

        /**
         * Get query json
         * @param stepIndex
         * @returns {object}
         */
        async get_query_json(stepIndex) {
            const steps = typeof stepIndex === 'number' ?
                          this.$refs['steps'].slice(stepIndex, stepIndex + 1) :
                          this.$refs['steps']

            let result_json = {}

            for (let step of steps) {
              result_json = { ...result_json, ...(await step.get_query()) }
            }

            return result_json
        },

        /**
         * Convert query json to string
         * @param stepIndex
         * @returns {string}
         */
        async get_query_string(stepIndex) {
             const json = await this.get_query_json(stepIndex);
             console.log({json})
            const first_key = Object.keys(json)[0]
            const conver_operator = (val) => val === 'EQ' ? '==' :
                                             val === 'NE' ? '!=' :
                                             val === 'INBTW' ? '<>' : '';
            const convert_condition = (val) => val === 'And' ? '&&' :
                                               val === 'Or' ? '||' : '';
            if (first_key === 'actions') {
                let result = json[first_key].reduce((str, { selectedNameKey, selectedNameVal, selectedVal }, aIndex) => {
                    return str + `Action ${aIndex + 1} : "${selectedNameKey || ''}" = "${selectedNameVal || selectedVal}" \n`
                }, '')

                return result
            }
            let result = json[first_key].reduce && json[first_key].reduce((str, item, itemIndex) => {

                let str_rules = item.rules.reduce((val, {selectedNameKey, selectedNameVal, selectedVal, selectedOperator, condition}, ruleIndex) => {
                    const isLastRule = ruleIndex + 1 === item.rules.length
                    let opt = `"${selectedNameKey || ''}" ${conver_operator(selectedOperator)} "${selectedNameVal || selectedVal}"`
                    if (selectedOperator === 'INBTW') {
                        opt = `"${selectedNameKey || ''}" >= "${selectedVal.min || ''}" && "${selectedNameKey || ''}" <= "${selectedVal.max || ''}"`
                    }
                    if (item.rules.length > 1) {
                        opt = `(${opt})`
                        opt = isLastRule ? opt : `${opt} ${convert_condition(condition)} `
                    }
                    return val + opt
                }, '')

                const isLastCondition = itemIndex + 1 === json[first_key].length
                if (json[first_key].length > 1) {
                    str_rules = `(${str_rules})`
                    str_rules = isLastCondition ? str_rules : `${str_rules} ${convert_condition(item.condition)} `
                }
                return str + str_rules
            }, '')

            return `IF(${result})`
        },
        async save() {
            this.$emit('save', await this.get_query_json())
        },
        show_modal() {
            this.$refs['code_modal'].show()
        },
        hide_modal() {
            this.$refs['code_modal'].hide()
        },
    }
}
</script>
<style scoped>
.json-modal-form textarea {
    font-family: monospace;
    font-size: 1.25rem;
}
</style>
