import Vue from 'vue'
import AcCollection from './ac-collection.vue'
import acCollectionGrouped from './ac-collection-grouped.vue'
import acCollectionTable from './ac-collection-table.vue'
import acCollectionTableOne from './ac-collection-tableone.vue'
import acCollectionGroupedTable from './ac-collection-grouped-table.vue'
import acCollectionCard from './ac-collection-card.vue'
import acCardCollection from './ac-card-collection.vue'
import acView from './ac-view.vue'
import acSimpleCard from './ac-simple-card.vue'
import acTab from './ac-tab.vue'
import acAccordion from './ac-accordion.vue'
import acForm from './ac-form.vue'
import acFilterForm from './ac-filter-form.vue'
import acFilterFormAdvanced from './ac-filter-form-advanced.vue'
import acGravatar from './ac-gravatar.vue'
import acMaterialDropdown from './ac-material-dropdown.vue'
import acImageUpload from './ac-image-upload.vue'
import acGrid from './ac-grid.vue'
import acInput from './ac-input.vue'
import acSelect from './ac-select.vue'
import acMultiselect from './ac-multiselect.vue'
import acTextArea from './ac-text-area.vue'
import acRadio from './ac-radio.vue'
import acCheckbox from './ac-checkbox.vue'
import acFile from './ac-file.vue'
import acKanban from './ac-kanban.vue'
import acCollectionGroupedAccordion from './ac-collection-grouped-accordion.vue'
import acSwitchButton from './ac-switch-button.vue'
import acButtonGroup from './ac-button-group.vue'
import acTagsInline from './ac-tags-inline.vue'
import acAccordionTable from './ac-accordion-table.vue'
import acListGroup from './ac-list-group.vue'
import acSimpleSearch from './ac-simple-search.vue'
import acCodemirror from './ac-codemirror.vue'

import acFilterSearchAdvanced from './ac-filter-search-advanced.vue'
import acDynamic from './ac-dynamic.vue'
import acDynamicView from './ac-dynamic-view.vue'
import acHeader from './ac-header.vue'
import acChart from './ac-chart.vue'
import acCard from './ac-card.vue'

import acInlineEdit from './ac-inline-edit.vue'
import acSlideout from './ac-slideout.vue'
import acSlideoutPanel from './ac-slideout-panel.vue'
import acSlideoutLayout from './ac-slideout-layout.vue'
import acInlineFormEdit from './ac-inline-form-edit.vue'
import acCsvMappingscreen from './ac-csv-mappingscreen.vue'
import acRuleBuilder from './ac-rule-builder'
import acFilterQueryBuilder from './ac-filter-query-builder.vue'
import acWizardBuilder from './ac-wizard-builder.vue'
import acDynamicKanban from './ac-dynamic-kanban.vue'
import acCustomHeader from './ac-custom-header.vue'
import acNewsection from './ac-newsection.vue'
import acNestedDropdown from './ac-nested-dropdown.vue'
import acDynamicChart from './ac-dynamic-chart.vue'
import acSimpleAutomation from './ac-simple-automation.vue'

import acDraggable from 'vuedraggable'
acDraggable.name = 'ac-draggable'
import acMoveUpDown from './ac-move-up-down.vue'
import acEmailBuilder from './ac-email-builder.vue'
import acSqlBuilder from './ac-sql-builder'

// v2 components
import acBulkActions from './v2/ac-bulk-actions.vue'
import acMentions from './v2/ac-mentions.vue'
import acComposeMessage from './v2/ac-compose-message'
import acCollectionGroupedCard from './v2/ac-collection-grouped-card.vue'
import acHtml from './v2/ac-html.vue'
import acSort from './v2/ac-sort.vue'
import acMultiSearch from './v2/ac-multi-search.vue'
import acGlobalSearch from './v2/ac-global-search.vue'
import acImage from './v2/ac-image.vue'
import acNotifications from './v2/ac-notifications.vue'
import acTimeago from './v2/ac-timeago.vue'
import acDualList from './v2/ac-dual-list.vue'
import acWizard from './v2/ac-wizard.vue'
import acSimpleWizard from './v2/ac-simple-wizard.vue'
import acImagePicker from './v2/ac-image-picker.vue'
import acModal from './v2/ac-modal.vue'
import acTimeline from './v2/ac-timeline/ac-timeline.vue'
import acCalendar from './ac-calendar.vue'
import acVerticalNavigation from './ac-vertical-navigation.vue'
import acGanttChart from './ac-gantt-chart.vue'
import acSwatches from './ac-swatches.vue'
import acGroupedDropdown from './ac-grouped-dropdown.vue'
import acDropdown from './ac-dropdown.vue'
import acActionNavbar from './ac-action-navbar.vue'
import acStar from './ac-star.vue'
import acFormPreview from './ac-form-preview.vue'
import acSimpleGrid from './ac-simple-grid'
import acOnboardingMeter from './ac-onboarding-meter.vue'
import acAutomation from './ac-automation'
import acResource from './ac-resource'

import acTracklytable from './ac-traclytable.vue'
import acNavbar from './cssHelpers/ac-navbar.vue'
import acMySimpleCard from './cssHelpers/ac-my-simple-card.vue'
import acMylist from './cssHelpers/ac-mylist.vue'
import acGroupcard from './cssHelpers/ac-groupcard.vue'
// v3 components
import acDateRangePicker from './v3/ac-date-range-picker.vue'
import acSignaturePad from './v3/ac-signature-pad.vue'
import acVerticalBar from './ac-vertical-bar'
import acMessage from './ac-message'
import acSimpleLabel from './ac-simple-label.vue'
import { from } from 'rxjs';
import acCanvas from './ac-canvas.vue'
import AppupHttp from './appuphttp'
import AcRatingBar from './ac-rating-bar.vue'
import AcMenubar from './ac-menubar.vue'
Vue.component("AppupHttp", AppupHttp);

export {
  AcCollection,
  acCollectionGrouped,
  acCollectionTable,
  acCollectionGroupedTable,
  acSimpleCard,
  acCollectionGroupedCard,
  acWizard,
  acTimeline,
  acModal,
  acImagePicker,
  acTab,
  acImage,
  acAccordion,
  acNotifications,
  acDualList,
  acHtml,
  acForm,
  acSort,
  acFilterForm,
  acTimeago,
  acFilterFormAdvanced,
  acMultiSearch,
  acGlobalSearch,
  acMaterialDropdown,
  acCollectionCard,
  acBulkActions,
  acView,
  acGravatar,
  acComposeMessage,
  acImageUpload,
  acGrid,
  acInput,
  acSelect,
  acMultiselect,
  acTextArea,
  acRadio,
  acCheckbox,
  acFile,
  acKanban,
  acFilterSearchAdvanced,
  acDynamic,
  acMentions,
  acDynamicView,
  acHeader,
  acChart,
  acCard,
  acCardCollection,
  acInlineEdit,
  acSlideout,
  acSlideoutPanel,
  acSlideoutLayout,
  acInlineFormEdit,
  acCalendar,
  acVerticalNavigation,
  acCollectionGroupedAccordion,
  acGanttChart,
  acDateRangePicker,
  acRuleBuilder,
  acCsvMappingscreen,
  acSwatches,
  acFilterQueryBuilder,
  acWizardBuilder,
  acDropdown,
  acGroupedDropdown,
  acSwitchButton,
  acTracklytable,
  acNavbar,
  acCollectionTableOne,
  acMySimpleCard,
  acMylist,
  acGroupcard,
  acDynamicKanban,
  acButtonGroup,
  acTagsInline,
  acDraggable,
  acMoveUpDown,
  acEmailBuilder,
  acAccordionTable,
  acCustomHeader,
  acListGroup,
  acSimpleSearch,
  acNewsection,
  acVerticalBar,
  acNestedDropdown,
  acActionNavbar,
  acSimpleWizard,
  acCodemirror,
  acStar,
  acDynamicChart,
  acFormPreview,
  acSimpleGrid,
  acOnboardingMeter,
  acSimpleAutomation,
  acAutomation,
  acMessage,
  acResource,
  acCanvas,
  acSignaturePad,
  acSimpleLabel,
  AppupHttp,
  AcRatingBar,
  AcMenubar,
  acSqlBuilder,
}
