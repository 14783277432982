<template>
  <div class="ac-multi-search">
    <!-- search box and search buttons -->
    <b-input-group>
      <b-input v-model="input_value" :placeholder="placeholder" @keyup="keyup_event" />
      <b-input-group-append is-text v-if="search_symbol" @click="on_input" class="search-symbol">
        <i class="fal fa-search"></i>
      </b-input-group-append>
      <b-input-group-append @click="on_input" v-else>
        <b-button variant="outline-secondary">search</b-button>
      </b-input-group-append>
    </b-input-group>
    <div v-if="!response_data.length && input_value.length > 1">
      <!-- Use this slot for Empty State -->
      <slot name="empty_state"></slot>
    </div>

    <div v-else>
      <slot name="result_body" :data="response_data" v-if="input_value.length >= 1"></slot>
    </div>
    <div class="options">
      <!-- types checkboxes when types options are given-->
      <b-form-checkbox-group v-if="!$slots.types" v-model="selected" name="flavour-1">
        <b-form-checkbox
          v-for="item in types"
          :key="item.value"
          :value="item.value"
        >{{ item.label }}</b-form-checkbox>
      </b-form-checkbox-group>

      <!-- use this types slot for types checkboxes -->
      <slot name="types" :types="types"></slot>
    </div>
  </div>
</template>

<script>
/**
 * ac global search component
 * @requires VueBootstrap
 */

export default {
  name: "ac-global-search",

  props: {
    /**
     * Url to get data from
     * @param {string} url
     * Required
     * @lable URL
     * @category_name 1_General
     */
    url: {
      type: String,
      required: true
    },

    /**
     * Array of types
     * @param {array} types
     * Required
     * @label Types
     * @category_name 1_General
     */
    types: {
      type: Array,
      required: true,
      default: () => []
    },

    /**
     * default symbol
     * @param {Boolean} search_symbol
     * @label Search Symbol
     * @category_name 4_Style
     */
    search_symbol: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Placeholder for search input
     * @param {string} placeholder
     * @label Search Placeholder
     * @category_name 1_General
     */
    placeholder: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      input_value: "",
      selected: [],
      response_data: []
    };
  },

  methods: {
    /**
     * @function keyup_event -  triggers  on every keyup
     */
    keyup_event() {
      this.$emit("keyup", {
        Input: this.input_value,
        Types: this.selected
      });
    },
    /**
     * @function on_input - input handler
     */
    on_input() {
      this.$emit("button_click", {
        Input: this.input_value,
        Types: this.selected
      });
    }
  }
};
</script>

<style scoped>
.search-symbol .input-group-text {
  background-color: transparent !important;
  cursor: pointer;
}
</style>
