<template>
  <b-form-group v-bind="$attrs">
    <b-form-select v-bind="$attrs" v-on="$listeners" />
  </b-form-group>
</template>
<script>

/**
 * ac Select component
 * @requires VueBootstrap
*/

export default {
  name: 'ac-select',
}
</script>
