export default {
  methods: {
    async confirm(message, action) {
      const ok = await this.$bvModal.msgBoxConfirm(message, {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      if (ok) action()
    },
  },
}
