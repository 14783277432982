<template>
  <div v-if="show_onboard_meter" class="on-board-shuttle">
    <b-dropdown
      size="lg"
      toggle-class="text-decoration-none"
      :dropup="bottom_position"
      :right="!bottom_position"
      variant="btn-transparent"
      no-caret
    >
      <!-- Icon  -->
      <template slot="button-content">
        <div class="position-relative">
          <i v-bind:class="icon"></i>
          <span :class="{ circle: apply_repel_effect}"></span>
          <span
            class="badge badge-primary meter-badge"
            :class="{activityBadge:apply_repel_effect}"
            v-if="show_badge"
          >{{items.length-items_completed}}</span>
        </div>
      </template>
      <!-- List view -->
      <b-dropdown-text :class="{'onboard-topright':bottom_position}">
        <!-- Component Body -->
        <div class="card text-center meter-360 dropdown-meter">
          <!-- Header-->
          <div class="card-header bg-transparent p-3">
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="text-left ma-inhead" v-if="$slots.header">
                <slot name="header"></slot>
              </h4>
              <div v-if="show_progress">
                <h6 :style="{color:percentage_color}">{{percent}}%</h6>
              </div>
            </div>
            <b-progress v-if="show_progress" :value="percent" :variant="progress_variants"></b-progress>
          </div>
          <!-- Container -->
          <div class="card-body scrollmeter p-0">
            <div
              class="media p-3 border-bottom hover-meter"
              v-for="(item, index) in items"
              :key="index"
              @click="event => validate_item_help(event, item)"
            >
              <div
                class="py-2 px-2 meter-40 rounded mr-3 text-white"
                :class="'bg-'+icon_variants[Math.floor(Math.random() * (5 - 0 + 1)) + 0]"
              >
                <i class="pt-1 fa-lg" :class="item.icon"></i>
              </div>
              <div class="media-body text-left mr-3">
                <h6 class="mb-1">{{item.name}}</h6>
                <p class="small text-muted mb-0">{{item.description}}</p>
              </div>
              <div class="ml-auto mt-3" v-if="item.completed">
                <i class="fal fa-check text-success fa-lg"></i>
              </div>
            </div>
          </div>
          <!-- Footer -->
          <div class="card-footer bg-white p-4" v-if="$slots.footer">
            <h5 class="mb-0">
              <slot name="footer"></slot>
            </h5>
          </div>
        </div>
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "ac-onboarding-meter",

  props: {
    /**
     * Array of items to be present in onboarding meter
     * @param {object} items
     * @properties {"name": {"type": "String"}, "description": {"type": "String"}, "url": {"type": "String"}, "help": {"type": "String"}, "icon": {"type": "String"}}
     * @label Onboarding items
     * @category_name 1_General
     */
    items: {
      type: Array,
      required: true
    },

    /**
     * Icon class for onboarding meter
     * @param {string} icon
     * @label Onboarding icon
     * @category_name 1_General
     */
    icon: {
      type: String,
      default: "fal fa-tachometer-alt-average fa-lg"
    },

    /**
     * Conditional prop to show badge.
     * @param {boolean} show_badge
     * @label Show badge?
     * @category_name 4_Style
     */
    show_badge: {
      type: Boolean,
      default: true
    },

    /**
     * Conditional prop to show progress.
     * @param {boolean} show_progress
     * @label Show progress?
     * @category_name 4_Style
     */
    show_progress: {
      type: Boolean,
      default: true
    },

    /**
     * Conditional prop to show onboarding meter if all items are completed.
     * @param {boolean} hide_if_complete
     * @label hide onboarding meter if complete ?
     * @category_name 4_Style
     */
    hide_if_complete: {
      type: Boolean,
      default: false
    },

    /**
     * Headers can be defined here for api
     * @param {object} headers
     * @label headers
     * @category_name 3_Advanced
     */
    headers: {
      type: Object,
      default: () => {}
    },

    /**
     * Position of onboarding meter top/bottom. Default top
     * @param {string} position
     * @label position
     * @category_name 4_Style
     */
    position: {
      type: String,
      default: "top"
    }
  },

  data() {
    return {
      show_onboard_meter: true,
      items_completed: 0,
      percent: 0,
      apply_repel_effect: true,
      icon_variants: [
        "success",
        "info",
        "warning",
        "danger",
        "primary",
        "secondary"
      ],
      bottom_position: false
    };
  },

  computed: {
    // Add computed property for variants in progress bar based on percentage
    progress_variants() {
      if (this.percent <= 33) {
        return "danger";
      } else if (this.percent <= 66) {
        return "primary";
      } else {
        return "success";
      }
    },

    // Add percent text color based on variant
    percentage_color() {
      if (this.progress_variants == "danger") {
        return "red";
      } else if (this.progress_variants == "primary") {
        return "blue";
      } else {
        return "green";
      }
    }
  },

  created() {
    // Check position prop if bottom, apply custom css
    if (this.position == "bottom") {
      this.bottom_position = true;
    }
  },

  beforeMount() {
    // for each item request to end point and receive status of item
    for (let i = 0; i < this.items.length; i++) {
      fetch(this.items[i].url, {
        // append headers
        headers: this.headers
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          // If result is success mark it as done and increase count
          if (data.result === "success") {
            this.items[i].completed = true;

            if (this.items[i].completed) {
              this.items_completed++;

              //re-calc percent
              this.percent = this.items_completed / (this.items.length * 0.01);

              // Round off percentage
              this.percent = this.percent.toFixed(0);

              // Since toFixed() returns String convert again to INT
              this.percent = parseInt(this.percent);
              // show when all items is checked

              if (i == this.items.length - 1) {
                // check hide-if-complete attribute if true and completed all items, then hide component
                this.show_onboard_meter = !(
                  this.hide_if_complete && this.percent == 100
                );

                // If all items completed hide
                if (this.show_onboard_meter) {
                  this.show_onboard_meter = false;
                }
              }
            }
          }
        })
        // handle fetch error
        .catch(() => {
          this.$emit("error-fetch-item");
        });
    }
  },

  mounted() {
    // Apply repel effect for 6 seconds
    let _this = this;
    setTimeout(function() {
      _this.apply_repel_effect = false;
    }, 6000);
  },

  methods: {
    /**
     * This method checks for the "help" key json, if starts from http or https --> opens in new tab / else redirect in current page
     * @return void
     * @param event
     * @param item item from json
     */
    validate_item_help(event, item) {
      // emit item_click
      this.$emit("item_click", item);

      event.preventDefault();
      // validating help key in item from json
      if (typeof item.help !== "undefined") {
        if (/http:[\w\W]+|https:[\w\W]+/.test(item.help)) {
          window.open(item.help, "_blank");
        } else {
          this.$router.push(item.help);
        }
      }
    }
  }
};
</script>

<style>
/* bottom position */
.onboard-topright {
  top: -423px !important;
  right: -319px;
}
.defaultTransition {
  transition: visibility 0s, opacity 0.5s linear;
}
.hover-meter:hover {
  background: #f9f9f9;
  cursor: pointer;
}

.meter-360 {
  width: 360px;
}
.meter-40 {
  width: 40px;
  height: 40px;
}
.dropdown-meter {
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  z-index: 99;
}

.on-board-shuttle ul.dropdown-menu.show {
  margin: 0;
  padding: 0;
  border: 0 solid #fff;
  background: none;
  box-shadow: none;
}

.activity-badge {
  position: absolute !important;
  top: 0px !important;
  left: 14px;
  font-size: 10px;
}
.meter-badge {
  position: absolute !important;
  font-size: 10px;
}

.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute !important;
  -webkit-transition: height 0.3s ease, width 0.3s ease;
  transition: height 0.3s ease, width 0.3s ease;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-30%, 4%);
  left: 11px;
  top: 5px;
}

.circle:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #a2ceffed;
  box-shadow: 0px 0px 10px #a2ceffed;
}

.circle:before {
  -webkit-animation: 2s linear infinite;
  animation: ripple 2s linear infinite;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1.25);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.75);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.75);
    opacity: 0;
  }
}
</style>
