<template>
  <b-button
    class="m-2 d-flex justify-content-center align-items-center"
    size="lg"
    variant="outline-dark"
    @click="open"
  >
    <i class="fab fa-dropbox fa-2x"></i>
  </b-button>
</template>
<script>
import uploaderMixin from "../mixins/ac-uploader";
export default {
  mixins: [uploaderMixin],

  props: {
    id: {
      type: String,
      default: "dropboxjs"
    },
    data_app_key: {
      type: String,
      required: true
    },
    actual_api: {
      type: String,
      required: true
    }
  },

  name: "ac-dropbox-picker",

  created() {
    this.script_el.setAttribute("type", "text/javascript");
    this.script_el.setAttribute("src", this.actual_api);
    this.script_el.setAttribute("id", this.id);
    this.script_el.setAttribute("data-app-key", this.data_app_key);
    document.head.appendChild(this.script_el);
  },

  methods: {
    open() {
      let options = {
        success: async files => {
          let attachments = [];
          for (let i = 0; i < files.length; i++) {
            let attachment = {};
            attachment._id = files[i].id;
            attachment.title = files[i].name;
            attachment.size = files[i].bytes;
            attachment.iconURL = files[i].icon;
            attachment.link = files[i].link;
            attachment.extension = `. ${files[i].name.split(".")[1]}`;
            attachments.push(attachment);
          }
          this.$emit("choose", attachments);
        },
        cancel: function() {},
        linkType: "direct",
        multiselect: true,
        // extensions: [".pdf", ".doc", ".docx", ],
        folderselect: false,
        sizeLimit: 102400000
      };
      Dropbox.choose(options);
    }
  }
};
</script>