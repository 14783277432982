
const getTableColumnId = (tableId, columnName) => `${tableId}-${columnName}`

const getKeysLabel = (keys, column) => {
  let label = ''
  keys[column.column_name].forEach((key, ind) => {
    if (ind > 0) label += ' '
    switch (key) {
      case 'primary':
        label += 'PK'
        break
      case 'unique':
        label += 'UK'
        break
      case 'fulltext':
        label += 'FT'
        break
      case 'foreign':
        label += 'FK'
        break
      default:
        break
    }
  })
  return label
}

const getColumnNodes = (table, column, keys, zIndex) => {
  const nodes = []
  const columnId = getTableColumnId(table.id, column.column_name)
  const fontColor = keys[column.column_name] && keys[column.column_name].includes('foreign') ? '#439ac1' : '#ccc'

  const columnNode = {
    data: {
      id: columnId,
      label: '',
      parent: table.id,
      zIndex,
    },
    classes: ['column'],
  }

  const columnNameNode = {
    data: {
      id: `${columnId}-name`,
      label: column.column_name,
      parent: columnId,
      fontColor,
      zIndex: zIndex + 1,
    },
    selectable: false,
    classes: ['column-name'],
  }

  const columnTypeNode = {
    data: {
      id: `${columnId}-type`,
      label: `${column.data_type}`,
      parent: columnId,
      zIndex: zIndex + 1,
    },
    selectable: false,
    classes: ['column-type'],
  }

  nodes.push(columnNode)
  nodes.push(columnNameNode)
  nodes.push(columnTypeNode)

  if (column.not_null || column.auto_increment || column.default) {
    const columnConstraintNode = {
      data: {
        id: `${columnId}-constraint`,
        label: `${column.not_null ? ' NOT NULL' : ''}${column.auto_increment ? ' AI' : ''}${column.default ? ` DEFAULT ${column.default}` : ''}`,
        parent: columnId,
        zIndex: zIndex + 1,
      },
      selectable: false,
      classes: ['column-constraint'],
    }
    nodes.push(columnConstraintNode)
  }

  if (keys[column.column_name]) {
    const label = getKeysLabel(keys, column)
    const columnKeyNode = {
      data: {
        id: `${columnId}-key`,
        label,
        parent: columnId,
        fontColor,
        zIndex: zIndex + 1,
      },
      selectable: false,
      classes: ['column-key'],
    }
    nodes.push(columnKeyNode)
  }
  return nodes
}

const getTableNodes = (sqlJson, zIndex) => {
  const nodes = []
  sqlJson.tables.forEach((table) => {
    const tableNode = {
      data: {
        id: table.id,
        label: table.table_name,
        svg: 'data:image/svg+xml;utf8',
        zIndex,
      },
      position: { ...table.position },
      classes: 'table',
    }

    const keys = {}
    table.keys.forEach((key) => {
      key.columns.forEach((column) => {
        if (keys[column]) {
          keys[column].push(key.type)
        } else keys[column] = [key.type]
      })
    })
    const primaryColumns = table.columns
      .filter(column => keys[column.column_name] && keys[column.column_name].includes('primary'))
    const regularColumns = table.columns
      .filter(column => !keys[column.column_name] || !keys[column.column_name].includes('primary'))

    if (primaryColumns.length > 0) {
      primaryColumns.forEach(column => nodes.push(...getColumnNodes(table, column, keys, zIndex + 1)))
    }
    regularColumns.forEach(column => nodes.push(...getColumnNodes(table, column, keys, zIndex + 1)))

    nodes.push(tableNode)

    zIndex += 3
  })

  return nodes
}

const getEdges = (sqlJson) => {
  const edges = []
  const tables = {}
  sqlJson.tables.forEach((table) => {
    tables[table.table_name] = table.id
  })

  sqlJson.tables.forEach((table) => {
    table.keys.filter(key => key.type === 'foreign').forEach((key) => {
      const {
        tableRef,
        columnRef,
        columns: [column_name],
      } = key
      const edge = {
        data: {
          source: getTableColumnId(table.id, column_name),
          target: getTableColumnId(tables[tableRef], columnRef),
        },
        pannable: false,
        selectable: true,
      }
      edges.push(edge)
    })
  })
  return edges
}

const getPossibleTableRef = (sqlJson, tableToCheck, refTableId) => {
  let tableRef = null
  const toCheck = {}

  const addToCheck = (name) => {
    if (toCheck[name]) return
    toCheck[name] = {
      in: 0,
      keys: null,
    }
  }

  sqlJson.tables.forEach((table) => {
    addToCheck(table.table_name)
    toCheck[table.table_name].keys = [...table.keys.filter(key => key.type === 'foreign')]
    toCheck[table.table_name].keys.forEach((key) => {
      addToCheck(key.tableRef)
      toCheck[key.tableRef].in += 1
    })
    if (table.id === refTableId) {
      tableRef = table.table_name
    }
  })
  toCheck[tableToCheck.table_name].keys.push({ type: 'foreign', tableRef })
  toCheck[tableRef].in += 1

  const queue = []
  sqlJson.tables.forEach((table) => {
    if (!toCheck[table.table_name].in) queue.push(table.table_name)
  })

  let count = 0

  while (queue.length > 0) {
    const current = queue.shift()
    count += 1
    toCheck[current].keys.forEach((key) => {
      toCheck[key.tableRef].in -= 1
      if (!toCheck[key.tableRef].in) queue.push(key.tableRef)
    })
  }

  if (count !== sqlJson.tables.length) return null
  return tableRef
}

const getColumnFixedPositions = (sqlJson, tables, changedElement = {}) => {
  const padding = 50
  const cols = 5
  const positions = {}
  const definedPositions = {}
  let x = 0
  let y = 0
  let maxHeight = 0
  if (sqlJson.tables) {
    sqlJson.tables.forEach((table) => {
      if (table.position) definedPositions[table.id] = table.position
    })
  }
  if (changedElement.position) {
    definedPositions[changedElement.id] = changedElement.position
  }
  tables.forEach((table, index) => {
    if (index % cols === 0) {
      y = maxHeight + padding
      x = 0
    }
    if (definedPositions[table.id()]) {
      ({ x, y } = definedPositions[table.id()])
    } else definedPositions[table.id()] = { x, y }
    const columns = table.children('.column')
    const names = columns.children('.column-name')
    const types = columns.children('.column-type')
    const constraints = columns.children('.column-constraint')
    const keys = columns.children('.column-key')
    const maxNameWidth = names.max(el => el.boundingBox().w)
    const maxTypeWidth = types.max(el => el.boundingBox().w)
    const maxConsWidth = constraints.max(el => el.boundingBox().w)
    const maxKeyWidth = keys.max(el => el.boundingBox().w)
    const rowPositions = {}
    const rowHeight = names[0].boundingBox().h
    let rowY = y
    names.forEach((name) => {
      positions[name.id()] = { x, y: rowY }
      rowPositions[name.parent().id()] = rowY
      rowY += rowHeight
    })
    maxHeight = Math.max(maxHeight, rowY)
    types.forEach((type) => {
      positions[type.id()] = { x: x + maxNameWidth.value, y: rowPositions[type.parent().id()] }
    })
    constraints.forEach((con) => {
      positions[con.id()] = { x: x + maxNameWidth.value + maxTypeWidth.value, y: rowPositions[con.parent().id()] }
    })
    keys.forEach((key) => {
      positions[key.id()] = { x: x + maxNameWidth.value + maxTypeWidth.value + (maxConsWidth.value > 0 ? maxConsWidth.value : 0), y: rowPositions[key.parent().id()] }
    })
    x += maxNameWidth.value + maxTypeWidth.value + (maxKeyWidth.value > 0 ? maxKeyWidth.value : 0) + (maxConsWidth.value > 0 ? maxConsWidth.value : 0) + padding
  })
  return { positions, definedPositions }
}

const setNodeBackgroundFromSVG = (svg, node) => {
  const resultImage = `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`
  node.style('background-image', resultImage)
}

const addTableStyles = (tables) => {
  tables.forEach((table) => {
    const tableBounds = table.boundingBox({ includeMainLabels: false })
    let color = '#cccccc'
    const rows = table.children()
    const maxRowsSize = rows.max(el => el.width())
    rows.style('min-width', `${maxRowsSize.value}px`)
    const width = tableBounds.w
    const height = tableBounds.h - 3
    let svg = ''
    const borderSize = 1
    const nodesPositions = [null, null, null, null]
    rows.forEach((row) => {
      const children = row.children()
      children.forEach((child, index) => {
        if (index <= 1) {
          nodesPositions[index] = child
          return
        }
        if (child.hasClass('column-constraint')) nodesPositions[2] = child
        else if (child.hasClass('column-key')) nodesPositions[3] = child
      })
    })
    nodesPositions.forEach((el, index) => {
      if (!index || !el) return
      const bb = el.boundingBox({ includeMainLabels: false })
      svg = `${svg}<rect x="${bb.x1 - tableBounds.x1 - borderSize}" y="0" width="${borderSize}" height="${height}" fill="${color}"></rect>`
    })
    let primaryKey = rows[0].children('.column-key').some(key => key.data('label').indexOf('PK') !== -1)
    rows.forEach((el, index) => {
      if (!index) return
      const bb = el.boundingBox({ includeMainLabels: false })
      const currentKey = el.children('.column-key').some(key => key.data('label').indexOf('PK') !== -1)
      const isDivider = primaryKey !== currentKey
      svg = `${svg}<rect x="0" y="${bb.y1 - tableBounds.y1 - borderSize - isDivider * 2}" width="${width}" height="${borderSize + isDivider * 2}" fill="${!isDivider ? color : '#fcba03'}"></rect>`
      primaryKey = currentKey
    })
    color = '#fcba03'
    svg = `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg"><g><rect x="0" y="0" width="${width}" height="${height}" fill="rgb(55, 55, 63)"></rect></g><g>${svg}</g><g><rect x="0" y="0" width="8" height="${height}" fill="${color}"></rect></g></svg>`
    setNodeBackgroundFromSVG(svg, table)
  })
}

export default {
  getTableNodes,
  getEdges,
  getPossibleTableRef,
  getColumnFixedPositions,
  setNodeBackgroundFromSVG,
  addTableStyles,
}
