<template>
  <div class="ac-wizard" v-bind:class="[align_vertical ? 'row' : '' ]">
    <!--If html is provided in header slot -->
    <header v-if="$scopedSlots.header" class="ac-wizard__header">
      <slot name="header" :steps="ac_steps" />
    </header>
    <!--steps JSON has given -->
    <header
      v-if="!$scopedSlots.header"
      v-bind:class="[align_vertical ? 'ac-wizard__header' : 'ac-wizard__header--default']"
    >
      <div
        v-for="(step, index) in steps"
        :key="index"
        :class="{'active': step.is_current }"
        class="item"
      >
        <div v-html="step.html" />
        <h5>{{ step.title }}</h5>
        <h6>{{ step.description }}</h6>
      </div>
    </header>

    <div v-bind:class="[align_vertical ? 'col-9' : 'col-12']">
      <main class="ac-wizard__body">
        <!--Appends current component -->
        <component :is="current_helper"></component>

        <!--Previous, Next and Finish buttons -->
        <div class="ac-wizard__footer d-flex justify-content-between mt-2">
          <b-button
            ref="previous_btn"
            :disabled="current_step === 0"
            :class="previous_btn_class"
            @click="on_back_click"
          >{{ previous_btn_label }}</b-button>

          <b-button
            ref="next_btn"
            v-show="current_step !== ac_steps.length - 1"
            :class="next_btn_class"
            @click="on_next_click"
          >{{ next_btn_label }}</b-button>

          <b-button
            ref="finish_btn"
            v-show="current_step === ac_steps.length - 1"
            :class="finish_btn_class"
            @click="on_finish_click"
          >{{ finish_btn_label }}</b-button>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "ac-simple-wizard",

  props: {
    /**
     * @param {array} steps
     * @properties {"html": {"type": "String"}, "title": {"type": "String"},
     * "description": {"type": "String"}, "helper": {"type": "String"},"helper_reference": {"type": "String"},
     *  "active": {"type": "Boolean"},"completed": {"type": "Boolean"}}
     * @label Steps
     * @category_name 2_Data
     */
    steps: {
      type: Array,
      required: true
    },
    /**
     * @param {boolean} align_vertical
     * @label Align Vertical
     * @category_name 4_Style
     */
    align_vertical: {
      type: Boolean,
      default: false
    },
    /**
     * @param {string} previous_btn_label
     * @label Previous Button Label
     * @category_name 1_General
     */
    previous_btn_label: {
      type: String,
      required: false,
      default: "Previous"
    },
    /**
     * @param {string} previous_btn_class
     * @label  Class Name Of Previous Button
     * @category_name 4_Style
     */
    previous_btn_class: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * @param {string} next_btn_label
     * @label Next Button Label
     * @category_name 1_General
     */
    next_btn_label: {
      type: String,
      required: false,
      default: "Next"
    },
    /**
     * @param {string} next_btn_class
     * @label Class Name Of Next Button
     * @category_name 4_Style
     */
    next_btn_class: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * @param {string} finish_btn_label
     * @label Finish Button Label
     * @category_name 1_General
     */
    finish_btn_label: {
      type: String,
      required: false,
      default: "Finish"
    },
    /**
     * @param {string} finish_btn_class
     * @label Class Name Of Finish Button
     * @category_name 4_Style
     */
    finish_btn_class: {
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      current_step: 0,
      ac_steps: [],
      current_slot: null,
      current_helper: null
    };
  },

  created() {
    this.current_helper = this.steps[0].helper;
    this.ac_steps = this.steps.map((el, index) => {
      const is_current = index === this.current_step;
      if (!el.id) {
        el.id = new Date().getTime() * Math.random();
      }
      el.is_current = is_current;
      if (is_current) {
        this.current_slot = el.block;
      }
      return el;
    });
  },
  updated: function() {
    if (this.previous_btn_class)
      this.$refs.previous_btn.className = this.previous_btn_class;
  },
  mounted() {
    if (this.previous_btn_class)
      this.$refs.previous_btn.className = `${this.previous_btn_class}`;
    if (this.next_btn_class)
      this.$refs.next_btn.className = `${this.next_btn_class}`;
    if (this.finish_btn_class)
      this.$refs.finish_btn.className = `${this.finish_btn_class}`;
  },

  methods: {
    /**
     * @function on_next_click - Triggers when clicked on next button
     */
    on_next_click() {
      //To Fix: if it is a form we are able to read ref if we use another helper we don't get any ref
      let index = null;
      if (Object.keys(this.$children[0].$refs).length) {
        var obj = this.$children[0].$refs;
        index = 0;
      } else {
        var obj = this.$children[1].$refs;
        index = 1;
      }
      if (Object.keys(obj).length) {
        for (var key in obj) {
          if (this.$children[index].$refs[key].formFields) {
            this.$children[index].$refs[key].$validator
              .validateAll()
              .then(valid => {
                if (valid) {
                  this.next_step();
                }
              });
          } else {
            this.next_step();
          }
        }
      } else {
        this.next_step();
      }
    },

    /**
     * @function next_step - Assigns current step and current helper to variable on clicking on next button
     * @fires completed_step event
     */
    next_step() {
      this.current_step += 1;
      this.current_helper = this.steps[this.current_step].helper;
      this.change_current_step();
      this.$emit("completed_step", this.ac_steps[this.current_step]);
    },

    /**
     * @function on_back_click - Assigns current step and current helper to variable on clicking on previous button
     * @fires clicked_back event
     */
    on_back_click() {
      this.current_step -= 1;
      this.current_helper = this.steps[this.current_step].helper;
      this.change_current_step();
      this.$emit("clicked_back");
    },

    /**
     * @function on_finish_click - Validates the form on clicking on Finished button click
     */
    on_finish_click() {
      var obj = this.$children[0].$refs;
      if (Object.keys(obj).length) {
        for (var key in obj) {
          if (this.$children[0].$refs[key].formFields) {
            this.$children[0].$refs[key].$validator
              .validateAll()
              .then(valid => {
                if (valid) {
                  this.emit_finish();
                }
              });
          } else {
            this.emit_finish();
          }
        }
      } else {
        this.emit_finish();
      }
    },
    /**
     * @function emit_finish - emits finish step event
     * @fires finished_step event
     */
    emit_finish() {
      this.$emit("finished_step");
    },

    /**
     * @function get_all_refs - returns an object consisting all the references
     * @returns {Object} referenceObject -with all refs
     */

    get_all_refs() {
      var referenceObject = {};
      var referencesArray = [];
      var current_ref = "";
      this.steps.forEach(item => {
        if (item.helper == this.current_helper) {
          current_ref = item.helper_reference;
        }
        referencesArray.push(item.helper_reference);
      });
      referenceObject.references = referencesArray;
      referenceObject.current_reference = current_ref;
      return referenceObject;
    },

    /**
     * @function change_current_step - Assigns current helper to current slot
     */
    change_current_step() {
      this.ac_steps = this.ac_steps.map((el, index) => {
        const is_current = index === this.current_step;
        el.is_current = is_current;
        if (is_current) {
          this.current_slot = el.helper;
        }
        return el;
      });
    }
  }
};
</script>

<style scoped>
.ac-wizard.is-vertical {
  display: flex;
}

.ac-wizard__body {
  flex: auto;
}

.ac-wizard__header--default {
  display: flex;
  justify-content: space-around;
}

.item {
  opacity: 0.6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px;
}

.item.active {
  opacity: 1;
}
</style>