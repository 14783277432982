<template>
  <div>
    <header class="ac__header">
      <!-- @slot Use this slot for header -->
      <slot name="header"></slot>
    </header>
    <ac-input v-model="query" @input="handleChange"></ac-input>
    <ac-filter-form-advanced
      :data="filter_data"
      v-bind="$props"
    >
    <slot />
    </ac-filter-form-advanced>
    <footer class="ac__footer">
      <!-- @slot Use this slot for footer -->
      <slot name="footer"></slot>
    </footer>
  </div>
</template>
<script>
import AcFilterForm from './ac-filter-form.vue'
import AcFilterFormAdvanced from './ac-filter-form-advanced'
/**
 * Ac Filter Search component
 * @requires VueBootstrap
 * @requires ac-filter-form.vue
 */
export default {
  name: 'ac-filter-search-advanced',

  extends: AcFilterFormAdvanced,

  components: {
    AcFilterFormAdvanced
  },

  data() {
    return {
      query: '',
      filter_data: this.data
    }
  },

  methods: {
    handleChange(value) {
      this.filter_data = this.data.filter(item => item['filter-name'].indexOf(this.query.toLowerCase())+1)
      this.$emit('onChange', this.filter_data)
    }
  },
}
</script>