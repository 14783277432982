<template>
  <div>
    <ul class="list-group bg-white">
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
        v-for="(lists,index) in listgroups"
        :key="index"
      >
        <p class="mb-0 text-muted small">{{lists.listname}}</p>
        <span class="text-dark small">{{lists.listvalue}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ac-mylist",
  props: {
    /**
     * @param {array} listgroups - Array of objects. each object redenring to listgroup
     *@properties {"listname": {"type": "String"}, "listvalue": {"type": "String"}}
     */
    listgroups: {
      type: Array
    }
  }
};
</script>

<style>
</style>
