<template>
  <div>
    <b-button variant="outline-primary" @click="$refs.imageFile.click()">Import Csv</b-button>
    <input
      ref="imageFile"
      type="file"
      accept=".csv"
      v-show="false"
      @change="on_upload_change($event)"
    />
    <b-modal
      ref="importScreenRef"
      :title="title"
      hide-footer
      title-hide
      no-close-on-backdrop
      scrollable
    >
      <table class="table table-responsive d-md-table border">
        <thead class="bg-light">
          <tr>
            <th scope="col">Headers</th>
            <th scope="col">Fields to map</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in csvHeaders" :key="index">
            <td nowrap scope="col">{{item}}</td>
            <td>
              <select id="select" class="form-control" v-model="mappingFields[item]">
                <option disabled value>Select the field</option>
                <option :value="cl" v-for="(cl,clIndx) in dbColumnsList" :key="clIndx">{{cl}}</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        type="button"
        class="btn btn-primary float-right mt-3"
        @click="afterMapping"
      >Import From CSV</button>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ac-csv-mappingscreen",
  props: {
    /**
     * @param {string} title - title for Mapping popup
     * @label Title
     */
    title: {
      type: String,
      required: true
    },
    /**
     * @param {array} dbColumnsList - Array of elements of column names of a DB
     * @label Database Column List
     */
    dbColumnsList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      csvHeaders: [],
      mappingFields: {},
      csvData: ""
    };
  },
  methods: {
    on_upload_change: function(event) {
      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length
      ) {
        const fileType = event.target.files[0].type;

        if (fileType !== "text/csv") {
          alert("Its not csv file");
          return;
        }
        var reader = new FileReader();
        reader.onload = () => {
          try {
            var blob = reader.result;
            var allTextLines = blob.split(/\r\n|\n/);
            this.csvData = allTextLines;
            var headers = allTextLines[0].split(",");
            var lines = [];
            for (var i = 0; i < allTextLines.length; i++) {
              var dataLines = allTextLines[i].split(",");
              if (dataLines.length == headers.length) {
                var tarr = [];
                for (var j = 0; j < headers.length; j++) {
                  tarr.push(headers[j]);
                }
                lines.push(tarr);
              }
            }
            this.csvHeaders = lines[0];
            this.$refs.importScreenRef.show();
            this.mappingFields = {};
            this.$refs.imageFile.value = "";
          } catch (e) {
            alert("Erorr in parsing file");
            console.log(e);
          }
        };
        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsBinaryString(event.target.files[0]);
      }
    },
    afterMapping: function() {
      if (Object.keys(this.mappingFields).length == this.csvHeaders.length) {
        var mappedValues = Object.values(this.mappingFields);
        this.isDuplicateMap(mappedValues)
          ? alert("Mapping fields shouldn't be same")
          : this.formInsertableObjects(this.mappingFields);
      } else alert("Please map all the fields");
    },
    isDuplicateMap: function(mappedList) {
      return new Set(mappedList).size !== mappedList.length;
    },
    formInsertableObjects: function(fields) {
      this.$refs["importScreenRef"].hide();
      var keysArray = [];
      debugger;
      for (var key in fields) {
        keysArray.push(fields[key]);
      }
      var csvArray = [];
      for (var i = 1; i < this.csvData.length - 1; i++) {
        var csvObject = {};
        var values = this.csvData[i].split(",");
        for (var j = 0; j < values.length; j++) {
          csvObject[keysArray[j]] = values[j];
        }
        csvArray.push(csvObject);
      }
      this.$emit("mappedData", csvArray);
    }
  },
  mounted() {}
};
</script>