<script>
import acForm from './ac-form.vue'
/**
 * ac Filter Form component which will interact with a card or a table acting as a filter
 * @requires VueBootstrap
 * @requires ac-form.vue
 * @inherits ac-form
 */
export default {
  name: 'ac-filter-form',

  extends: acForm,

  props: {
    /**
     * @param {string} url - Base URL for filtering
     * Required
     * @label URL
     */
    url: {
      type: String,
      required: true,
    },

    /**
     * @param {object} reference - Reference of the collection component
     * Required
     * @label Reference
     */
    reference: {
      type: Object,
      required: true,
    },

    /**
     * @param {object} apply_type - When filter form should be submited
     * Required
     * @label Apply Type
     */
    apply_type: {
      type: String,
      default: 'onChange',
    },
  },

  render(createElement) {
    const apply_type = this.apply_type
    const filter_submit = this.filter_submit
    const children = this.form_data.map(item => createElement(item.tag, {
        attrs: { ...item },
        on: { input: (value) => {
          item.value = value

          if (apply_type == 'onChange') {
            filter_submit()
          }
        }},
      })
    )

    return createElement('b-form',
      {
        attrs: { ...this.props, novalidate: !this.validate },
        on: { submit: (e) => {
          e.preventDefault()
          if (apply_type == 'onSave') {
            filter_submit()
          }
        }},
      },
      [...children, ...(this.$slots.default || [])])
  },

  methods: {
    filter_submit() {
      for (const item of this.data) {
        this.reference.ac_cursor[item.name] = item.value
      }

      this.reference.set_ajax()
    },
  }
}
</script>
