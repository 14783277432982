import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import uuid from 'uuid'
import mutations from './mutations'
import state from './state'
import utils from './utils'

const pushChangeToStack = (changedElements, joinStack = false) => {
  let stack = cloneDeep(state.stack)
  let { stackIndex } = state

  if (stack.length - 1 > stackIndex) {
    stack = stack.slice(0, stackIndex + 1)
  }
  if (stack.length && joinStack) stack[stack.length - 1].push(...changedElements)
  else stack.push(cloneDeep(changedElements))

  if (stack.length > 100) {
    stack = stack.slice(-100)
  }
  stackIndex = stack.length - 1

  mutations.SET_STACK(stack)
  mutations.SET_STACK_INDEX(stackIndex)
}

const setNewSqlJson = (sqlJson) => {
  const stack = []
  if (state.sqlJson.tables) stack.push(...cloneDeep(state.sqlJson.tables))
  if (state.sqlJson.comments) stack.push(...cloneDeep(state.sqlJson.comments))

  const pushNewElements = (container, mainField) => {
    container.forEach((ele) => {
      if (stack.some(s => s.id === ele.id)) return
      stack.push({ id: ele.id, [mainField]: [] })
    })
  }

  if (sqlJson.tables) pushNewElements(sqlJson.tables, 'columns')
  if (sqlJson.comments) pushNewElements(sqlJson.comments, 'text')

  pushChangeToStack(stack)
  mutations.SET_SQL_JSON(cloneDeep(sqlJson))
}

const handleStackIndexChange = (stack, stackIndex) => {
  const prevElements = cloneDeep(stack[stackIndex])

  if (!prevElements) return

  const currentElement = []
  const newJson = cloneDeep(state.sqlJson)
  prevElements.forEach((prevElement) => {
    const container = prevElement.columns ? 'tables' : 'comments'

    let isRemoving = true
    if ((prevElement.columns && prevElement.columns.length)
      || (prevElement.text && prevElement.text.length)) {
      isRemoving = false
    }
    let isAdding = true
    const checker = (el) => {
      if (el.id === prevElement.id) {
        isAdding = false
        currentElement.push(cloneDeep(el))
        return !isRemoving ? prevElement : false
      }
      return el
    }

    let newElements = []
    if (!isRemoving) newElements = (newJson[container] && newJson[container].map(checker)) || []
    else newElements = (newJson[container] && newJson[container].filter(checker)) || []

    if (isAdding) {
      newElements.push(cloneDeep(prevElement))
      currentElement.push({ id: prevElement.id, [prevElement.columns ? 'columns' : 'text']: [] })
    }

    newJson[container] = newElements
  })

  stack[stackIndex] = currentElement
  mutations.SET_CHANGED_ELEMENTS(cloneDeep(prevElements))
  mutations.SET_SQL_JSON(newJson)
  mutations.SET_STACK(cloneDeep(stack))
}

const checkKeys = (prevColumns, currentTable) => {
  const newKeys = []
  currentTable.keys.forEach((key) => {
    const newKeyColumns = []
    key.columns.forEach((column) => {
      if (prevColumns[column] === undefined) newKeyColumns.push(column)
      else if (prevColumns[column] !== '') newKeyColumns.push(prevColumns[column])
    })
    if (newKeyColumns.length) newKeys.push({ ...key, columns: newKeyColumns })
  })
  return newKeys
}

const actions = {
  setSqlJsonFromObject: json => new Promise((res) => {
    mutations.SET_SQL_JSON(cloneDeep(json))
    res()
  }),
  setSqlJsonFromJsonString: importString => new Promise((res, rej) => {
    let json = {}
    try {
      json = JSON.parse(importString)
    } catch (e) {
      rej(new Error('Unable to parse JSON'))
      return
    }

    setNewSqlJson(json)
    res()
  }),
  setSqlJsonFromSqlString: importString => new Promise((res, rej) => {
    let json = {}
    try {
      json = utils.parseSQL(importString)
    } catch (e) {
      rej(e)
      return
    }

    setNewSqlJson(json)
    res()
  }),
  showImportModal: () => new Promise((res) => {
    mutations.SET_IMPORT_MODAL_MODE(true)
    res()
  }),
  hideImportModal: () => new Promise((res) => {
    mutations.SET_IMPORT_MODAL_MODE(false)
    res()
  }),
  showEditorModal: () => new Promise((res) => {
    mutations.SET_EDITOR_MODAL_MODE(true)
    res()
  }),
  hideEditorModal: () => new Promise((res) => {
    mutations.SET_EDITOR_MODAL_MODE(false)
    res()
  }),
  updateDatabaseName: name => new Promise((res) => {
    const newJson = cloneDeep(state.sqlJson)
    newJson.database_name = name
    mutations.SET_SQL_JSON(newJson)
    res()
  }),
  setCurrentElements: el => new Promise((res) => {
    mutations.SET_CURRENT_ELEMENTS(cloneDeep(el))
    res()
  }),
  addCurrentTableColumn: column => new Promise((res) => {
    if (state.currentElements.length !== 1) return
    const [currentTable] = cloneDeep(state.currentElements)
    currentTable.columns.push(cloneDeep(column))
    mutations.SET_CURRENT_ELEMENTS([currentTable])
    res()
  }),
  removeCurrentTableColumn: columnId => new Promise((res) => {
    if (state.currentElements.length !== 1) return
    const [currentTable] = cloneDeep(state.currentElements)
    const newColumns = currentTable.columns.filter(column => column.id !== columnId)
    currentTable.columns = newColumns
    mutations.SET_CURRENT_ELEMENTS([currentTable])
    res()
  }),
  updateCurrentTableName: tableName => new Promise((res) => {
    if (state.currentElements.length !== 1) return
    const [currentTable] = cloneDeep(state.currentElements)
    currentTable.table_name = tableName
    mutations.SET_CURRENT_ELEMENTS([currentTable])
    res()
  }),
  updateCurrentTableColumn: (columnId, constraints) => new Promise((res) => {
    if (state.currentElements.length !== 1) return
    const [currentTable] = cloneDeep(state.currentElements)
    let prevColumnName = ''
    const newColumns = currentTable.columns.map((column) => {
      if (column.id !== columnId) return column
      if (constraints.column_name) prevColumnName = column.column_name
      return { ...column, ...constraints }
    })

    if (prevColumnName) {
      currentTable.keys = checkKeys({ [prevColumnName]: constraints.column_name }, currentTable)
    }

    currentTable.columns = newColumns
    mutations.SET_CURRENT_ELEMENTS([currentTable])
    res()
  }),
  addCurrentTableKey: (type, columns, params) => new Promise((res) => {
    if (state.currentElements.length !== 1) return
    const [currentTable] = cloneDeep(state.currentElements)
    currentTable.keys.push({
      type, name: '', columns, ...params,
    })
    mutations.SET_CURRENT_ELEMENTS([currentTable])
    res()
  }),
  removeCurrentTableKey: key => new Promise((res) => {
    if (state.currentElements.length !== 1) return
    const [currentTable] = cloneDeep(state.currentElements)
    const newKeys = currentTable.keys.filter(el => !isEqual(el, key))
    currentTable.keys = newKeys
    mutations.SET_CURRENT_ELEMENTS([currentTable])
    res()
  }),
  saveCurrentTableToSqlJson: (joinStack = false) => new Promise((res) => {
    if (state.currentElements.length !== 1) return
    const newJson = cloneDeep(state.sqlJson)
    const [currentTable] = cloneDeep(state.currentElements)
    let prevTable = {}
    const newJsonTables = (newJson.tables && newJson.tables
      .filter((table) => {
        if (table.id === currentTable.id) prevTable = table
        return table.id !== currentTable.id
      })) || []

    const stack = [{ id: currentTable.id, columns: [], ...prevTable }]

    if (prevTable.id) {
      const newColumns = {}
      prevTable.columns.forEach((column) => {
        const newColumn = currentTable.columns.filter(el => el.id === column.id)
        if (newColumn.length && newColumn[0].column_name !== column.column_name) {
          newColumns[column.column_name] = newColumn[0].column_name
        } else if (!newColumn.length) {
          newColumns[column.column_name] = ''
        }
      })
      if (currentTable.table_name !== prevTable.table_name
        || Object.keys(newColumns).length) {
        newJsonTables.forEach((table) => {
          const newKeys = []
          table.keys.forEach((key) => {
            if (key.type !== 'foreign' || key.tableRef !== prevTable.table_name
            || (currentTable.table_name === prevTable.table_name && newColumns[key.columnRef] === undefined)) {
              newKeys.push(key)
              return
            }
            if (!stack.find(e => e.id === table.id)) stack.push(cloneDeep(table))

            const tableRef = currentTable.table_name
            const columnRef = newColumns[key.columnRef]

            if (tableRef && columnRef) {
              newKeys.push({ ...key, tableRef, columnRef })
            }
          })
          table.keys = newKeys
        })
        currentTable.keys = checkKeys(newColumns, currentTable)
      }
    }

    newJsonTables.push(cloneDeep(currentTable))
    newJson.tables = newJsonTables

    const changedElements = newJsonTables.filter(table => stack.find(el => el.id === table.id))

    pushChangeToStack([...stack], joinStack)

    mutations.SET_SQL_JSON(newJson)
    mutations.SET_CHANGED_ELEMENTS(changedElements)
    mutations.SET_CURRENT_ELEMENTS([])
    res()
  }),
  setTablesPositions: positions => new Promise((res) => {
    const newJson = cloneDeep(state.sqlJson)
    const newTables = newJson.tables
      .map(table => ({ ...table, position: { ...positions[table.id] } }))
    newJson.tables = newTables
    mutations.SET_SQL_JSON(newJson)
    res()
  }),
  setElementPosition: (container, tableId, position) => new Promise((res) => {
    const newJson = cloneDeep(state.sqlJson)
    let newCurrents = state.currentElements
    const newElement = newJson[container].find(table => table.id === tableId)
    const delta = {
      x: newElement.position.x - position.x,
      y: newElement.position.y - position.y,
    }
    if (!newElement) {
      res()
      return
    }
    const stack = []
    if (newCurrents.length > 1 && newCurrents.find(e => e.id === tableId)) {
      newCurrents = newCurrents.map((current) => {
        let currentContainer = 'tables'
        if (current.text) currentContainer = 'comments'
        const changed = newJson[currentContainer].find(e => e.id === current.id)
        stack.push(cloneDeep(changed))
        const result = {
          ...changed,
          position: {
            x: changed.position.x - delta.x,
            y: changed.position.y - delta.y,
          },
        }
        changed.position = result.position
        return result
      })
    }
    if (!stack.length) {
      stack.push(cloneDeep(newElement))
      newElement.position = { ...position }
    }
    pushChangeToStack(stack)
    mutations.SET_CURRENT_ELEMENTS(newCurrents)
    mutations.SET_SQL_JSON(newJson)
    res()
  }),
  setViewport: (zoom, pan) => new Promise((res) => {
    const newJson = cloneDeep(state.sqlJson)
    newJson.viewport = {
      zoom,
      pan,
    }
    mutations.SET_SQL_JSON(newJson)
    res()
  }),
  setChangedElements: el => new Promise((res) => {
    mutations.SET_CHANGED_ELEMENTS(cloneDeep(el))
    res()
  }),
  setIsRedrawNeededState: isRedrawNeeded => new Promise((res) => {
    mutations.SET_IS_REDRAW_NEEDED_STATE(isRedrawNeeded)
    res()
  }),
  setAddingTableMode: isEditing => new Promise((res) => {
    mutations.SET_ADDING_TABLE_MODE(isEditing)
    res()
  }),
  addNewTable: table => new Promise((res) => {
    table.id = uuid()
    mutations.SET_CURRENT_ELEMENTS([table])
    mutations.SET_EDITOR_MODAL_MODE(true)
    res()
  }),
  setAddingEdgeMode: isEditing => new Promise((res) => {
    mutations.SET_ADDING_EDGE_MODE(isEditing)
    res()
  }),
  setAddingCommentMode: isAdding => new Promise((res) => {
    mutations.SET_ADDING_COMMENT_MODE(isAdding)
    res()
  }),
  addComment: (commentText, commentId, position) => new Promise((res) => {
    const newJson = cloneDeep(state.sqlJson)
    if (!newJson.comments) newJson.comments = []
    newJson.comments.push({
      id: commentId,
      text: commentText,
      position: { ...position },
    })
    pushChangeToStack([{ id: commentId, text: '' }])
    mutations.SET_SQL_JSON(newJson)
    res()
  }),
  updateCurrentComment: (newText, position) => new Promise((res) => {
    if (state.currentElements.length !== 1) return
    const [currentComment] = cloneDeep(state.currentElements)
    currentComment.text = newText
    currentComment.position = position
    mutations.SET_CURRENT_ELEMENTS([currentComment])
    res()
  }),
  saveCurrentComment: () => new Promise((res) => {
    if (state.currentElements.length !== 1) return
    const newJson = cloneDeep(state.sqlJson)
    const [currentComment] = cloneDeep(state.currentElements)
    const foundComment = newJson.comments.find(comment => comment.id === currentComment.id)
    if (foundComment) {
      pushChangeToStack([cloneDeep(foundComment)])
      foundComment.text = currentComment.text
      foundComment.position = { ...currentComment.position }
    }
    mutations.SET_SQL_JSON(newJson)
    mutations.SET_CURRENT_ELEMENTS([])
    res()
  }),
  showCommentModal: () => new Promise((res) => {
    mutations.SET_COMMENT_MODAL_MODE(true)
    res()
  }),
  hideCommentModal: () => new Promise((res) => {
    mutations.SET_COMMENT_MODAL_MODE(false)
    res()
  }),
  undo: () => new Promise((res) => {
    let { stackIndex } = state
    const { stack } = state
    if (stackIndex === -1) {
      res()
      return
    }
    handleStackIndexChange(stack, stackIndex)
    stackIndex -= 1

    mutations.SET_STACK_INDEX(stackIndex)
    mutations.SET_CURRENT_ELEMENTS([])
    res()
  }),
  redo: () => new Promise((res) => {
    let { stackIndex } = state
    const { stack } = state
    if (stackIndex === stack.length - 1) {
      res()
      return
    }
    stackIndex += 1
    handleStackIndexChange(stack, stackIndex)

    mutations.SET_STACK_INDEX(stackIndex)
    mutations.SET_CURRENT_ELEMENTS([])
    res()
  }),
  setZoomModeFit: () => new Promise((res) => {
    mutations.SET_ZOOM_MODE('fit')
    res()
  }),
  setZoomModeMax: () => new Promise((res) => {
    mutations.SET_ZOOM_MODE('max')
    res()
  }),
  setZoomModeFree: () => new Promise((res) => {
    mutations.SET_ZOOM_MODE('')
    res()
  }),
  enableMultiselect: () => new Promise((res) => {
    mutations.SET_MULTISELECT_MODE(true)
    res()
  }),
  disableMultiselect: () => new Promise((res) => {
    mutations.SET_MULTISELECT_MODE(false)
    res()
  }),
  showExportModal: () => new Promise((res) => {
    mutations.SET_EXPORT_MODAL_MODE(true)
    res()
  }),
  hideExportModal: () => new Promise((res) => {
    mutations.SET_EXPORT_MODAL_MODE(false)
    res()
  }),
  removeCurrentElements: () => new Promise((res) => {
    const newJson = cloneDeep(state.sqlJson)
    const { currentElements } = state
    const changedElements = []
    const stack = []
    currentElements.forEach((el) => {
      changedElements.push({ id: el.id, [el.columns ? 'columns' : 'text']: null })
      if (currentElements.length === 1 && el.edge) {
        const sourceTable = newJson.tables.find(table => table.id === el.table)
        currentElements[0] = cloneDeep(sourceTable)
        sourceTable.keys = sourceTable.keys.filter(key => key.type !== 'foreign' || key.columns[0] !== el.source)
        changedElements.push(sourceTable)
      } else if (el.text) {
        newJson.comments = newJson.comments.filter(comment => comment.id !== el.id)
      } else if (el.columns) {
        newJson.tables = newJson.tables.filter(table => table.id !== el.id)
        newJson.tables.forEach((table, index) => {
          const newKeys = table.keys
            .filter(key => key.type !== 'foreign' || key.tableRef !== el.table_name)
          if (newKeys.length !== table.keys.length) {
            if (!stack.some(e => e.id === table.id) && !currentElements.some(e => e.id === table.id)) {
              stack.push(cloneDeep(table))
              changedElements.push(table)
            }
            newJson.tables[index].keys = newKeys
          }
        })
      }
    })

    pushChangeToStack([...stack, ...currentElements])
    mutations.SET_CHANGED_ELEMENTS(changedElements)
    mutations.SET_SQL_JSON(newJson)
    mutations.SET_CURRENT_ELEMENTS([])
    res()
  }),
}

export default actions
