<template>
  <div class='navbar ac-sql-builder-navbar'>
    <div>
      <b-form-input
        class="db-name-input"
        placeholder="Enter database name"
        v-model="dbName" />
      <b-form-invalid-feedback tooltip :state="sqlJson.tables && dbName.length === 0 ? false : null">
        Database name must contain at least one character
      </b-form-invalid-feedback>
    </div>
    <div class='d-flex edit-buttons-container'>
      <div class="controls-group">
        <div class="mr-3" @click="onClickUndo">
          <i class="fas fa-undo"></i>
        </div>
        <div class="mr-3" @click="onClickRedo">
          <i class="fas fa-redo"></i>
        </div>
      </div>
      <div class="controls-group">
        <div :class="{ selected: !isMultiselectEnabled, 'mr-3': true, }" @click="onClickPointer">
          <i class="fas fa-mouse-pointer"></i>
        </div>
        <div :class="{ selected: isMultiselectEnabled, 'mr-3': true, }" @click="onClickMultiselect">
          <i class="fas fa-vector-square"></i>
        </div>
      </div>
      <div class="controls-group">
        <div class="mr-3" @click="onClickZoomIn">
          <i class="fas fa-compress"></i>
        </div>
        <div class="mr-3" @click="onClickZoomOut">
          <i class="fas fa-expand"></i>
        </div>
      </div>
      <div class="d-flex pr-2 pl-2">
        <div :class="{ selected: isAddingTable, 'mr-3': true, }" @click="onClickTableButton">
          <i class="fas fa-table"></i>
        </div>
        <div :class="{ selected: isAddingEdge, 'mr-3': true, }" @click="onClickEdgeButton">
          <i class="fas fa-project-diagram"></i>
        </div>
        <div :class="{ selected: isAddingComment }" @click="onClickCommentButton">
          <i class="far fa-sticky-note"></i>
        </div>
      </div>
    </div>
    <div>
      <b-button @click="onClickImportButton">Import</b-button>
      <b-button class="ml-2" @click="onClickExportButton">Export</b-button>
    </div>
  </div>
</template>
<script>
import store from './store'
/**
 * acSqlBuilderNavbar - helps to manage board elements
 * @requires VueBootstrap
 */
export default {
  name: 'ac-sql-builder-navbar',
  computed: {
    dbName: {
      get() {
        const json = store.getters.sqlJson()
        return (json && json.database_name) || ''
      },
      set(value) {
        store.actions.updateDatabaseName(value)
      },
    },
    sqlJson() {
      return store.getters.sqlJson()
    },
    isAddingTable() {
      return store.getters.isAddingTable()
    },
    isAddingEdge() {
      return store.getters.isAddingEdge()
    },
    isAddingComment() {
      return store.getters.isAddingComment()
    },
    isMultiselectEnabled() {
      return store.getters.isMultiselectEnabled()
    },
  },
  methods: {
    onClickImportButton() {
      store.actions.showImportModal()
    },
    onClickExportButton() {
      store.actions.showExportModal()
    },
    onClickTableButton() {
      if (!this.isAddingTable) this.resetButtons()
      store.actions.setAddingTableMode(!this.isAddingTable)
    },
    onClickEdgeButton() {
      if (!this.isAddingEdge) this.resetButtons()
      store.actions.setAddingEdgeMode(!this.isAddingEdge)
    },
    onClickCommentButton() {
      if (!this.isAddingComment) this.resetButtons()
      store.actions.setAddingCommentMode(!this.isAddingComment)
    },
    resetButtons() {
      if (this.isAddingTable) store.actions.setAddingTableMode(false)
      else if (this.isAddingComment) store.actions.setAddingCommentMode(false)
      else if (this.isAddingEdge) store.actions.setAddingEdgeMode(false)
    },
    onClickUndo() {
      store.actions.undo()
    },
    onClickRedo() {
      store.actions.redo()
    },
    async onClickZoomIn() {
      await store.actions.setZoomModeMax()
      await store.actions.setZoomModeFree()
    },
    async onClickZoomOut() {
      await store.actions.setZoomModeFit()
      await store.actions.setZoomModeFree()
    },
    onClickMultiselect() {
      store.actions.enableMultiselect()
    },
    onClickPointer() {
      store.actions.disableMultiselect()
    },
  },
}
</script>
<style scoped>
.ac-sql-builder-navbar {
  background: #303037
}
.selected {
  color: #2589b1;
}
.edit-buttons-container {
  font-size: 1.5em;
  color: #ccc;
}
.edit-buttons-container div {
  cursor: pointer;
}
.controls-group {
  display: flex;
  padding-left: 0.5rem;
  margin-right: 0.5rem;
  padding-right: 0.5rem;
  border-right: 2px solid #212121;
}
.db-name-input {
  background-color: #44444c;
  border-color: #303037;
  color: #ccc;
}
</style>
