<template>
  <div class="ac-multi-search">
    <b-input-group>
      <b-input v-model="input_val" :placeholder="placeholder" @keyup="emit_keyup_event" />
      <!-- <b-input-group-append v-if="search_symbol">
      <i class="fal fa-search" ></i>
      </b-input-group-append>-->
      <b-input-group-append is-text v-if="search_symbol" class="search-symbol">
        <i class="fal fa-search" @click="on_input"></i>
      </b-input-group-append>
      <b-input-group-append v-else>
        <b-button variant="outline-secondary" @click="on_input">search</b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="results-wrapper">
      <ac-collection-grouped
        ref="collection"
        sort_key
        :ac_cursor="cursor"
        :group_key="group_key"
        :url="this.url"
        :loading_image="loading_image"
        method="GET"
        class="results"
        @success="on_success_load"
      >
        <template #group_header="{ title }">
          <!--Use this result_title slot for each title in query result -->
          <slot name="result_title" :title="title"></slot>
        </template>

        <template #body="{ data }" class="item" v-if="input_val.length > 1">
          <div v-for="(value, key) in data" :key="key">
            <!-- Use this result_body slot for each body in query result -->
            <slot name="result_body" :item="value"></slot>
          </div>
        </template>

        <template #footer>
          <div v-show="is_result_data">
            <div class="text-center">
              <!-- <b-link v-if="!!$slots.more_btn" :href="more_btn_link">More...</b-link> -->

              <!-- Use this more_btn slot for for more button -->
              <slot name="more_btn"></slot>
            </div>
          </div>
        </template>
      </ac-collection-grouped>
    </div>

    <div class="options">
      <b-form-checkbox-group v-if="!$slots.types" v-model="selected" name="flavour-1">
        <b-form-checkbox
          v-for="item in types"
          :key="item.value"
          :value="item.value"
        >{{ item.label }}</b-form-checkbox>
      </b-form-checkbox-group>

      <!-- use this types slot for types checkboxes -->
      <slot name="types" :types="types"></slot>
    </div>
  </div>
</template>

<script>
import acCollectionGrouped from "../ac-collection-grouped.vue";
import axios from "axios";

/**
 * ac multi search component
 * @requires VueBootstrap
 * @requires ac-collection-grouped.vue
 */

let timeout = null;

export default {
  name: "ac-multi-search",

  components: {
    acCollectionGrouped
  },

  props: {
    /**
     * Url to get data from
     * @param {string} url
     * Required
     * @category_name 1_General
     */
    url: {
      type: String,
      required: true
    },

    /**
     * Array of types
     * @param {array} types
     * Required
     * @category_name 1_General
     */
    types: {
      type: Array,
      required: true,
      default: () => []
    },

    /**
     * Image to show while loading data
     * @param {string} loading_image
     * @label Loading Image
     * @category_name 4_Style
     */
    loading_image: {
      type: String,
      required: true
    },

    /**
     * Data from every request will be grouped with this key
     * @param {string} group_key
     * @label Group Key
     * @category_name 1_General
     * Required
     */
    group_key: {
      type: String,
      required: false
    },

    /**
     * parameter in request for input value
     * @param {string} search_param
     * @default 'q'
     * @label Search Param
     * @category_name 3_Advanced
     */
    search_param: {
      type: String,
      required: false,
      default: "q"
    },

    /**
     * @param {string} more_btn_link - default more button link href
     * @label More button Link
     * @category_name 3_Advanced
     */
    more_btn_link: {
      type: String,
      required: false,
      default: "#"
    },
    /**
     * default symbol
     * @param {Boolean} search_symbol
     * @category_name 4_Style
     */
    search_symbol: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Placeholder for search input
     * @param {string} placeholder
     * @category_name 1_General
     */
    placeholder: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      input_val: "",
      selected: [],
      cursor: {},
      is_result_data: false
    };
  },

  created() {
    this.set_default_checked();
  },

  methods: {
    /**
     * @function build_cursor - get cursor with current parameters
     * @returns {object} cursor
     */
    build_cursor() {
      const obj = {
        [this.search_param]: this.input_val,
        types: ""
      };

      this.selected.forEach(el => {
        obj.types += `${el},`;
      });

      return obj;
    },
    /**
     * @function emit_key_up_event - emits event if length is more than 2 charecters
     * @fires Keyup
     */
    emit_keyup_event: function() {
      if (this.input_val.length > 0) {
        this.$emit("keyup",this.input_val);
      }
    },
    /**
     * @function set_default_checked - for each checked type add value to selected
     */
    set_default_checked() {
      this.types.forEach(el => {
        if (el.checked) {
          this.selected.push(el.value);
        }
      });
    },

    /**
     * @function get_selected - get current selected types
     * @returns {array} selected
     */
    get_selected() {
      return [...this.selected];
    },

    /**
     * @function set_selected - set current selected types
     * @param {array} new_selected - array with selected values
     * e.g. ['all', 'contacts']
     */
    set_selected(new_selected) {
      this.selected = [...new_selected];
    },

    /**
     * @function send_request - send ajax to url if input is not empty
     */
    send_request() {
      this.cursor = this.build_cursor();

      if (!this.input_val) {
        this.$refs.collection.set_data([]);
        this.is_result_data = false;
        return;
      }

      setTimeout(() => {
        this.$refs.collection.set_ajax();
      }, 50);
    },

    /**
     * @function on_success_load - load data handler
     */
    on_success_load(data) {
      this.is_result_data = Object.keys(data).length > 0;
    },

    /**
     * @function on_input - input handler
     */
    on_input() {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        this.send_request();
      }, 350);
    }
  }
};
</script>

<style scoped>
.results-wrapper {
  position: relative;
}

.results {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
}
.search-symbol .input-group-text {
  background-color: transparent !important;
  cursor: pointer;
}
</style>
