<template>
  <div class="ac-timeline">
    <ul class="timeline">
      <li
        v-for="(item, index) in items"
        :key="index"
        is="timeline-item"
        :item="item"
      />
    </ul>
  </div>
</template>

<script>
import timelineItem from './ac-timeline-item.vue'

/**
 * ac-timeline component
 * @requires timeline-item.vue
 */

export default {
  name: 'ac-timeline',

  components: {
    timelineItem,
  },

  props: {
    /**
     * @param {Array} items - Array of timeline objects.
     */
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style>
#timeline-header {
  font-size: 26px;
}
.cancelled {
  text-decoration: line-through;
}
.timeline-panel.today {
  height: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px;
  margin-bottom: 10px;
  background: #000;
}
.timeline-panel.today:before {
  visibility: hidden !important;
  display: none !important;
}
.timeline-panel.today:after {
  visibility: hidden !important;
  display: none !important;
}
.timeline-badge.warning {
  top: -20px !important;
}
.timeline-panel.past {
  background: #eee;
}
.timeline-panel.past:after {
  border-right: 14px solid #eee !important;
}
.timeline-panel.main_element {
  font-weight: bolder;
  color: #fff !important;
  background: #0196a3;
  border-color: #0196a3 !important;
}
.timeline-panel.main_element:after {
  border-right: 14px solid #0196a3 !important;
}
.timeline-panel.selected_past {
  font-weight: bolder;
  color: #fff !important;
  background: #333;
  border-color: #333 !important;
}
.timeline-panel.selected_past:after {
  border-right: 14px solid #333 !important;
}
.timeline {
  list-style: none;
  padding: 10px 0 10px;
  position: relative;
  width: 420px;
}
.timeline:before {
  background-color: #eee;
  bottom: 0;
  content: " ";
  left: 50px;
  margin-left: -1.5px;
  position: absolute;
  top: 0;
  width: 3px;
}
.timeline > li {
  margin-bottom: 10px;
  position: relative;
}
.timeline > li:before, .timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-panel {
  border-radius: 2px;
  border: 1px solid #d4d4d4;
  box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
  margin-left: 100px;
  padding: 20px;
  position: relative;
}
.timeline > li > .timeline-panel .timeline-heading .timeline-panel-controls {
  position: absolute;
  right: 8px;
  top: 5px;
}
.timeline > li > .timeline-panel .timeline-heading .timeline-panel-controls .timestamp {
  display: inline-block;
}
.timeline > li > .timeline-panel .timeline-heading .timeline-panel-controls .controls {
  display: inline-block;
  padding-right: 5px;
  border-right: 1px solid #aaa;
}
.timeline > li > .timeline-panel .timeline-heading .timeline-panel-controls .controls a {
  color: #999;
  font-size: 11px;
  padding: 0 5px;
}
.timeline > li > .timeline-panel .timeline-heading .timeline-panel-controls .controls a:hover {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}
.timeline > li > .timeline-panel .timeline-heading .timeline-panel-controls .controls + .timestamp {
  padding-left: 5px;
}
.timeline > li .timeline-badge {
  background-color: #999;
  border-radius: 50%;
  color: #fff;
  font-size: 1.4em;
  height: 50px;
  left: 50px;
  line-height: 52px;
  margin-left: -25px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 16px;
  width: 50px;
  z-index: 100;
}
.timeline > li .timeline-badge + .timeline-panel:before {
  border-bottom: 15px solid transparent;
  border-left: 0 solid #ccc;
  border-right: 15px solid #ccc;
  border-top: 15px solid transparent;
  content: " ";
  display: inline-block;
  position: absolute;
  left: -15px;
  right: auto;
  top: 26px;
}
.timeline > li .timeline-badge + .timeline-panel:after {
  border-bottom: 14px solid transparent;
  border-left: 0 solid #fff;
  border-right: 14px solid #fff;
  border-top: 14px solid transparent;
  content: " ";
  display: inline-block;
  position: absolute;
  left: -14px;
  right: auto;
  top: 27px;
}
.timeline-badge.primary {
  background-color: #2e6da4 !important;
}
.timeline-badge.success {
  background-color: #0196a3 !important;
}
.timeline-badge.warning {
  background-color: #000 !important;
}
.timeline-badge.danger {
  background-color: #d9534f !important;
}
.timeline-badge.info {
  background-color: #5bc0de !important;
}
.timeline-badge.planning {
  background-color: #00629c !important;
}
.timeline-title {
  margin-top: 0;
  color: inherit;
}
.timeline-body > p, .timeline-body > ul {
  margin-bottom: 0;
}
.timeline-body > p + p {
  margin-top: 5px;
}
.copy {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #aaa;
  font-size: 11px;
}
.copy > * {
  color: #444;
}
/*============================
 ANIMATIONS
 =============================*/
.pulse_wrap {
  animation: pulse 1.5s ease-in-out alternate infinite;
}
@keyframes pulse {
  0% {
    opacity: 0.8;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
