<template>
  <div>
    <header class="container">
      <nav
        class="navbar navbar-expand-sm navbar-light px-0 fit-content-navbar"
        :class="nav_bgcolor"
      >
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav max-auto">
            <li class="nav-item" :class="active_class(nav)" v-for="(nav,indexing) in left_controls" :key="indexing">
              <a class="nav-link" :href="nav.link_to">
                <template v-if="nav.icon">
                  <i :class="nav.icon" class="p-2 fa-lg"></i>
                </template>
                {{nav.label}}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>
/**
 * Appup Header with capabilty to render controls either left or right or both
 *
 * TODOs:
 *
 * Actions for header controls
 *
 * Icons are not aligned properly
 */
export default {
  name: "ac-menubar",

  props: {
    /**
     * It shows left side NAVITEMS
     * @param {object} left_controls
     * @properties {"activeWhen": {"type": "Array"}, "icon": {"type": "String"}, "label": {"type": "String"}, "opens":{"type": "String"}, "type": {"type": "String"}, "icon": {"type": "String"}, "link_to": {"type": "String"}, "items": {"type": "String"},  "handler": {"type": "String"}, "handler_params": {"type": "String"}}
     * @label Left Controls
     * @category_name 2_Data
     */
    left_controls: {
      type: Array,
      required: false
    },
    /**
     * Style varient of the navbar Background color
     * @label Background Color
     * `bg-primary, bg-seconday, bg-success, bg-warning, bg-danger`
     * @category_name 4_Style
     */
    nav_bgcolor: {
      type: String,
      default: "bg-light"
    }
  },

  data: function() {
    return {};
  },

  methods: {
        /**
     * To determine active class for nav items
     * @function active_class
     * @public
     * @param {object} nav - item in navbar
     */
    active_class(nav) {
      if (nav.activeWhen) {
          console.log("this.$route.meta.url...",this.$route.meta.url);
        for (var i = 0; i < nav.activeWhen.length; i++) {
          // For testing use $route.path
          if (this.$route.meta.url === nav.activeWhen[i]) {
            return "active";
          }
        }
      }
    }
  }
};
</script>
<style scoped>
.fit-content-navbar {
  width: fit-content;
}
</style>