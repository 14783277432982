import state from './state'

const mutations = {
  SET_SQL_JSON: (sqlJson) => {
    state.sqlJson = sqlJson
  },
  SET_IMPORT_MODAL_MODE: (isShown) => {
    state.isShowImportModal = isShown
  },
  SET_EDITOR_MODAL_MODE: (isShown) => {
    state.isShowEditorModal = isShown
  },
  SET_CURRENT_ELEMENTS: (el) => {
    state.currentElements = el
  },
  SET_CHANGED_ELEMENTS: (el) => {
    state.changedElements = el
  },
  SET_IS_REDRAW_NEEDED_STATE: (isNeeded) => {
    state.isRedrawNeeded = isNeeded
  },
  SET_ADDING_TABLE_MODE: (isAdding) => {
    state.isAddingTable = isAdding
  },
  SET_ADDING_EDGE_MODE: (isAdding) => {
    state.isAddingEdge = isAdding
  },
  SET_ADDING_COMMENT_MODE: (isAdding) => {
    state.isAddingComment = isAdding
  },
  SET_COMMENT_MODAL_MODE: (isShown) => {
    state.isShowCommentModal = isShown
  },
  SET_STACK_INDEX: (index) => {
    state.stackIndex = index
  },
  SET_STACK: (stack) => {
    state.stack = stack
  },
  SET_ZOOM_MODE: (mode) => {
    state.zoomMode = mode
  },
  SET_MULTISELECT_MODE: (isEnabled) => {
    state.isMultiselectEnabled = isEnabled
  },
  SET_EXPORT_MODAL_MODE: (isShown) => {
    state.isShowExportModal = isShown
  },
}

export default mutations
