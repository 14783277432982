<script>
/**
 * ac Section component
 * @requires VueBootstrap
 */


export default {
  name: 'ac-section',

  props: {
    /**
     * @param {boolean} multi - Indicates if section has multiple inputs
     * @label Multiple Inputs
     */
    multi: {
      type: Boolean,
      required: true,
    },

    /**
     * @param {number,string} max - Maximal available number of inputs
     * @label Maximum Inputs
     */
    max: {
      type: [Number, String],
      required: true,
      default: 1,
    },

    /**
     * @param {Object} item - Item to render
     * @label Item
     */
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      new_input: '',
      h: this.$createElement,
    }
  },

  render(h) {
    const add_item = this.multi ? this.render_add_item() : null
    const input = this.multi ? this.render_multi_inputs() : this.render_input(this.item)

    const wrapper = h('div', {
      class: {
        section: true,
        'mb-2': true,
      },
    },
    [
      input,
      add_item,
    ])

    return wrapper
  },

  methods: {
    /**
     * @function on_add - Adds new input to current section.
     */
    on_add() {
      const max = parseInt(this.max, 10)
      const children_amount = Object.keys(this.item.children).length

      if (children_amount < max) {
        if (this.item.ac_type === 'Array') {
          this.item.children.push({
            tag: this.item.tag,
            label: this.new_input,
            value: '',
          })
        } else if (this.item.ac_type === 'Object') {
          this.$set(this.item.children, this.new_input, {
            tag: this.item.tag,
            label: this.new_input,
            value: '',
          })
        }
      }
    },

    /**
     * @function render_input - Renders single element.
     * Fires if multi is false
     */
    render_input(item) {
      return this.h(item.tag,
        {
          attrs: { ...item },
          on: { input: (value) => { item.value = value } },
        },
        item.inner_text)
    },

    /**
     * @function render_multi_inputs - Renders multiple elements.
     * Works with Object and Array properties
     * Fires if multi is true
     */
    render_multi_inputs() {
      let children = null
      if (this.item.ac_type === 'Array') {
        children = this.item.children.map(input => this.h('div', { class: { 'mb-3': true } }, [
          this.h(input.tag, {
            attrs: {
              ...input,
            },
            on: {
              input: (e) => { input.value = e },
            },
          }),
          this.h('b-button', {
            props: {
              variant: 'outline-danger',
              block: true,
            },
            on: {
              click: () => {
                this.item.children.splice(this.item.children.indexOf(input), 1)
              },
            },
          }, '-'),
        ]))
      } else if (this.item.ac_type === 'Object') {
        const obj_keys = Object.keys(this.item.children)

        children = obj_keys.map(obj_key => this.h('div', { class: { 'mb-3': true } }, [
          this.h(this.item.children[obj_key].tag, {
            attrs: {
              ...this.item.children[obj_key],
            },
            on: {
              input: (e) => { this.item.children[obj_key].value = e },
            },
          }),
          this.h('b-button', {
            props: {
              variant: 'outline-danger',
              block: true,
            },
            on: {
              click: () => {
                const new_children = { ...this.item.children }
                delete new_children[obj_key]
                this.item.children = new_children
              },
            },
          }, '-'),
        ]))
      }

      // Add col-form-label class for draggable
      return this.h('div', {}, [
        this.h('div', { class: { 'col-form-label': true } }, this.item.label),
        children,
      ])
    },

    /**
     * @function render_add_item - renders input and + button
     */
    render_add_item() {
      const input = this.h('b-form-input', {
        props: {
          value: this.new_input,
        },
        on: {
          input: (e) => { this.new_input = e },
        },
      })

      const button = this.h('b-input-group-append', {}, [
        this.h('b-button', {
          props: {
            variant: 'outline-primary',
          },
          on: {
            click: this.on_add,
          },
        }, '+'),
      ])

      const group = this.h('b-input-group', {
        class: {
          'mt-2': true,
        },
      },
      [
        input,
        button,
      ])

      return group
    },
  },
}
</script>
