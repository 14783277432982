<template>
  <div
    ref="collection_wrapper"
    :style="{maxHeight: max_height}"
    class="app-collection card d-flex flex-column"
  >
    <div
      v-show="is_loading"
      class="loader row"
    >
      <img
        :src="loading_image"
        class="loader__image"
        alt="loader m-auto"
      >
    </div>

    <header class="ac__header">
      <!-- Use this slot for header -->
      <slot name="header"></slot>
    </header>

    <main class="ac__body">
    
    <!-- Event will be fired whenever the item is dragged-->
      <ac-draggable v-model="data" @change="$emit('item_moved',$event)">
          <div v-for="(item, index) in data" :key="index">
            <!-- Use this slot for each item -->
              <slot name="item" :item="item"></slot>
          </div>
      </ac-draggable>

      <div v-if="data && !data.length">
        <!-- Use this slot represent empty state, this slot will be shown whenever the data is empty. -->
         <slot name="empty_state"></slot>     
      </div>

      <div
        v-show="load_more_type === 'loadMoreButton'"
        ref="loader_div"
        class="load-more text-center"
      >
        <!--Use this Slot for load more button, shown only if load_more_type is 'loadMoreButton'
          and there is more data to fetch -->
        <slot name="load_more_button"></slot>
      </div>
    </main>

    <footer class="ac__footer">
      <!-- Use this slot for footer -->
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
import AppData from './mixins/ac-data'

/**
 * ac Colection component which can be used to display data from API
 * @requires VueBootstrap
 * @requires ac-data.js
 * @inherits ac-data
 */
export default {
  name: 'ac-move-up-down',

  mixins: [AppData],

  props: {
    /**
     * Image to show while loading data
     * @param {string} loading_image
     * @label Loading Image
     * @category_name 1_General
     */
    loading_image: {
      type: String,
      default: 'https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif',
    },

    /**
     * Max height of component
     * @param {string} max_height
     * @label Maximum Height
     * @category_name 1_General
     */
    max_height: {
      type: String,
      required: false,
      default: '100%',
    },

    /**
     * Type of loading more information, ex:'loadMoreButton, infiniScroll'
     * @param {string} load_more_type
     * @default loadMoreButton
     * 'loadMoreButton, infiniScroll'
     * @label Load More Type
     * @category_name 1_General
     */
    load_more_type: {
      type: String,
      default: 'loadMoreButton',
      validator: value => ['loadMoreButton', 'infiniScroll'].indexOf(value) !== -1,
    },
     preload: {
      type: String,
      required: false
    },
    /**
     * Provide params to preload handler.
     * @param {string} handler_params 
     * @label Handler Params
     * @category_name 1_General
     */
    handler_params:{
      type: String,
      required: false
    }

  },

  created() {
    this.$parent.$emit('collection_added', { name: this.$options.name, component: this })
  },
  

  mounted() {
    
    this.set_ajax();
    
    /**
    * If prop load_more_type is infiniScroll then add listener for scroll
    * when scroll reaches bottom of page then load more data
    */
    if (this.load_more_type === 'infiniScroll') {
      const wrapper = this.$refs.collection_wrapper
      wrapper.onscroll = () => {
        if (wrapper.scrollTop === (wrapper.scrollHeight - wrapper.offsetHeight)) {
          if (!this.is_loading) {
            this.has_more()
            this.set_ajax()
          }
        }
      }
    }
    if (this.preload) {
      this.start(this.preload,{
          helper: {
            component: this,
            custom: this.handler_params ? JSON.parse(this.handler_params) : {}
          }
        });
    }     
  },
  methods:{
    dragged(){

    }
  },
  beforeDestroy() {
    /**
    * Remove scroll listener when component about to destroy
    */
    window.onscroll = () => {}

    this.$parent.$emit('collection_removed', { name: this.$options.name, component: this })
  },
}
</script>

<style scoped>
  .ac__item {
    display: flex;
    flex-wrap: wrap;
  }

  .app-collection {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ac__body {
    flex: auto;
  }

  .loader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(255, 255, 255, .65);
  }
</style>
