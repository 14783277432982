<template>
  <div class="dropdown">
    <button
      @click="show_dropdown()"
      class="btn btn-default dropdown-toggle btn-dropdown"
      type="button"
    >
      <slot name="btn_slot"></slot>
    </button>
    <ul :class="`dropdown-menu parent-ddm${$attrs.refs}`">
      <template v-for="(dropdown,dInx) in nested_dropdown_list">
        <li
          class="pl-2 cursor-pointer"
          v-show="!dropdown.isSubMenu"
          :key="`${dropdown[key_value]}${dInx}`"
          @click="emit_nested_data(dropdown)"
        >
          <p v-if="dropdown.text">{{dropdown.text}}</p>
          <!-- use this slot for main dropdown item -->
          <slot name="step" :item="dropdown"></slot>
        </li>
        <li
          class="dropdown-submenu"
          v-show="dropdown.isSubMenu"
          :key="`${dropdown[key_value]}-submenu`"
        >
          <p class="test pl-2 cursor-pointer">
            {{dropdown.text}}
            <span class="caret"></span>
          </p>
          <ul class="dropdown-menu">
            <template v-for="(subdd1,d1Inx) in dropdown.subMenuList">
              <li
                class="pl-2 cursor-pointer"
                v-show="!subdd1.isSubMenu"
                :key="`${subdd1[key_value]}${d1Inx}`"
                @click="emit_nested_data(dropdown,subdd1)"
              >
                <p v-if="subdd1.text">{{subdd1.text}}</p>
                <!-- use this slot for first sub dropdown item -->
                <slot name="step1" :item="subdd1"></slot>
              </li>
              <li
                class="dropdown-submenu"
                v-show="subdd1.isSubMenu"
                :key="`${subdd1[key_value]}-submenu`"
              >
                <p class="test pl-2 cursor-pointer">
                  {{subdd1.text}}
                  <span class="caret"></span>
                </p>
                <ul class="dropdown-menu">
                  <template v-for="(subdd2,d2Inx) in subdd1.subMenuList">
                    <li
                      class="pl-2"
                      v-show="!subdd2.isSubMenu"
                      :key="`${subdd2[key_value]}${d2Inx}`"
                      @click="emit_nested_data(dropdown,subdd1,subdd2)"
                    >
                      <p class="cursor-pointer" v-if="subdd2.text">{{subdd2.text}}</p>
                      <!-- use this slot for sub dropdown item -->
                      <slot name="step2" :item="subdd2"></slot>
                    </li>
                    <li
                      class="dropdown-submenu"
                      v-show="subdd2.isSubMenu"
                      :key="`${subdd2[key_value]}-submenu`"
                    >
                      <p class="test pl-2 cursor-pointer">
                        {{subdd2.text}}
                        <span class="caret"></span>
                      </p>
                    </li>
                  </template>
                </ul>
              </li>
            </template>
          </ul>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ac-nested-dropdown",
  props: {
    /**
     * JSON List of options for dropdown
     * @param {Array} -nested_dropdown_list
     * @category_name 1_General
     */
    nested_dropdown_list: {
      type: Array,
      required: true
    },
    /**
     * Dynamic label to pick value from JSON data
     * @param {String} -key_value
     * @category_name 1_General
     */
    key_value: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.toggle_dropdown();
  },
  methods: {
    /**
     * Toggle dropdown
     * Add event listners tothe dropdown items
     */
    toggle_dropdown() {
      let nested_dropdown_list = document.querySelectorAll(
        ".dropdown-submenu p.test"
      );

      for (var i = 0; i < nested_dropdown_list.length; i++) {
        if (nested_dropdown_list[i])
          nested_dropdown_list[i].addEventListener("mouseover", function(e) {
            if (!e.target.nextElementSibling) return;
            e.target.nextElementSibling.style.display = "block";
            e.target.nextElementSibling.addEventListener(
              "mouseover",
              function() {
                e.target.nextElementSibling.style.display = "block";
              }
            );
            e.stopPropagation();
            e.preventDefault();
          });
        nested_dropdown_list[i].addEventListener("mouseout", function(e) {
          if (!e.target.nextElementSibling) return;
          e.target.nextElementSibling.style.display = "none";
          e.target.nextElementSibling.addEventListener("mouseout", function() {
            e.target.nextElementSibling.style.display = "block";
          });
          e.stopPropagation();
          e.preventDefault();
        });
      }
    },

    /**
     * Emit event on click of dropdown item
     * @param {Object} step - Data of main dropdown
     * @param {Object} step1 -Data of level 1 sub dropdown dropdown
     * @param {Object} step2 -Data of level 2 sub dropdown dropdown
     */
    emit_nested_data(step, step1, step2) {
      let emittedSteps = {};
      if (step) emittedSteps["step"] = step[this.key_value];
      if (step1) emittedSteps["step1"] = step1[this.key_value];
      if (step2) emittedSteps["step2"] = step2[this.key_value];
      this.$emit("nested_menu_emit", emittedSteps);
    },

    /**
     * Triggers on click of and display the items
     */
    show_dropdown() {
      if (
        document.querySelector(".parent-ddm" + this.$attrs.refs).style
          .display == "" ||
        document.querySelector(".parent-ddm" + this.$attrs.refs).style
          .display == "none"
      )
        document.querySelector(".parent-ddm" + this.$attrs.refs).style.display =
          "block";
      else {
        document.querySelector(".parent-ddm" + this.$attrs.refs).style.display =
          "none";
      }
    }
  }
};
</script>

<style scoped>
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
.dropdown-toggle::after {
  display: none;
}
</style>
