<template>
  <b-modal
    title="Input comment text"
    v-model="isShowCommentModal"
    @ok="onSubmitCommentText">
    <b-form-textarea
        id="commentTextarea"
        placeholder="Enter comment..."
        v-model="commentText" />
  </b-modal>
</template>
<script>
import store from './store'

export default {
  name: 'ac-sql-builder-comment-modal',
  data() {
    return {
      commentText: '',
    }
  },
  computed: {
    currentComment() {
      const comments = store.getters.currentElements()
      return (comments.length === 1 && comments[0]) || {}
    },
    isShowCommentModal: {
      get() {
        return store.getters.isShowCommentModal()
      },
      set(value) {
        if (!value) {
          store.actions.hideCommentModal()
        }
      },
    },
  },
  watch: {
    isShowCommentModal(value) {
      if (value) {
        this.commentText = this.currentComment.text
      }
    },
  },
  methods: {
    async onSubmitCommentText() {
      await store.actions.updateCurrentComment(this.commentText, this.currentComment.position)
      await store.actions.setChangedElements([this.currentComment])
      await store.actions.saveCurrentComment()
    },
  },
}
</script>
