<template>
  <!-- popup view -->
  <b-modal
    v-if="is_popup"
    ref="filesModal"
    title="Files"
    size="lg"
    hide-footer
    @hidden="popup_hidden"
  >
    <ac-tab :data="tab_data" :align="align" :tabs_attrs="{ card: true, pills: true }">
      <template #title="{ data }">{{ data.label }}</template>
      <template #gallery_tab="{ data }">
        <component
          v-if="data.tab_content.type"
          :is="data.tab_content.type"
          v-bind="data.tab_content.props"
          @input="emit_input_event($event)"
          @choose="emit_choose_event($event)"
          class="w-100"
        ></component>
        <b-row v-else>
          <b-col v-for="(item, index) in data.tab_content" :key="index" md="2" class="mb-2">
            <b-card class="text-center cursor-pointer p-1" no-body @click="grid_click(item)">
              <div class="img-container">
                <b-card-img :src="item.thumbnail" :alt="item.label" />
              </div>
              <b-card-text>{{item.label}}</b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <template v-if="!data.tab_content.hide_button">
          <hr />
          <b-row class="justify-content-end mx-0">
            <b-button @click="add_click">Add</b-button>
          </b-row>
        </template>
      </template>
    </ac-tab>
  </b-modal>
  <!-- default view -->
  <div v-else>
    <h4>Files</h4>
    <ac-tab :data="tab_data" :align="align" :tabs_attrs="{ card: true, pills: true }">
      <template #title="{ data }">{{ data.label }}</template>
      <template #gallery_tab="{ data }">
        <component
          v-if="data.tab_content.type"
          :is="data.tab_content.type"
          v-bind="data.tab_content.props"
          @input="emit_input_event($event)"
          @choose="emit_choose_event($event)"
          class="w-100"
        ></component>
        <b-row v-else>
          <b-col v-for="(item, index) in data.tab_content" :key="index" md="2" class="mb-2">
            <b-card class="text-center cursor-pointer p-1" no-body @click="grid_click(item)">
              <div class="img-container">
                <b-card-img :src="item.thumbnail" :alt="item.label" />
              </div>
              <b-card-text>{{item.label}}</b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <template v-if="!data.tab_content.hide_button">
          <hr />
          <b-row class="justify-content-end mx-0">
            <b-button @click="add_click">Add</b-button>
          </b-row>
        </template>
      </template>
    </ac-tab>
  </div>
</template>

<script>
/**
 * ac-resource component
 * @requires VueBootstrap
 * @requires axios
 */
import axios from "axios";
// To perform group actions in array
let group_by = require("lodash.groupby");
import acResourceUpload from "./ac-resource-upload";

export default {
  name: "ac-resource",
  components: {
    acResourceUpload
  },
  props: {
    /**
     * Array with gallery data
     * @param {array} data
     * @label Data
     * @category_name 2_Data
     */
    data: {
      type: Array,
      required: false,
      default: null
    },

    /**
     * Object with upload configuration for third party integration
     * @param {object} upload_config
     * @label Upload Config
     * @category_name 2_Data
     */
    upload_config: {
      type: Object,
      required: false,
      default: null
    },

    /**
     * Fetch data with url
     * @label Src
     * @category_name 1_General
     */
    src: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * Key for grouping the data
     * @label Group Key
     * @category_name 1_General
     */
    group_key: {
      type: String,
      required: true
    },

    /**
     * Determines which type of view should be rendered
     * @param {string} type - type of view.
     * popup, default. Can be changed in real time
     * @label Type
     * @category_name 3_Advanced
     */
    type: {
      type: String,
      required: false,
      default: "default",
      validator: value => ["default", "popup"].indexOf(value) !== -1
    }
  },
  data() {
    return {
      init_data: {}
    };
  },
  computed: {
    is_popup() {
      return this.type === "popup";
    },
    align() {
      return this.type === "default" ? "vertical" : "horizontal";
    },
    tab_data() {
      return Object.keys(this.init_data).map(key => ({
        label: key,
        ref: "gallery_tab",
        tab_content: this.init_data[key]
      }));
    }
  },
  created() {
    if (this.src) {
      this.fetch_data();
    } else {
      this.init_data = group_by(this.data, this.group_key);
      if (this.upload_config) {
        this.append_upload_config();
      }
    }
  },
  methods: {
    /**
     * Append default config for upload component
     */
    append_upload_config() {
      let default_upload_config = {
        type: "ac-resource-upload",
        hide_button: true
      };
      this.init_data[this.upload_config[this.group_key]] = {
        ...this.upload_config,
        ...default_upload_config
      };
      console.log({data: this.init_data})

    },

    /**
     * Get data from src
     */
    fetch_data() {
      this.$appupajax.get2(this.src, {}, {}, {withCredentials: true, credentials: "include" }).
    //  this.$appupajax.get2(this.src, {}, { withCredentials: true, credentials: "include" }).
                    then(this.$appupajax.handleResponse).then(response => {
          this.init_data = group_by(response.data, this.group_key);
          if (this.upload_config) {
            this.append_upload_config();
          }
        })
        .catch(error => {
          // handle error
          console.log(error);
        })
        .finally(() => {
          // always executed
        });
    },
    grid_click(e) {
      this.$emit("onGridClick", e);
    },
    add_click() {
      this.$emit("onAddClick");
    },
    popup_hidden() {
      this.$emit("onPopupHidden");
    },
    show_popup() {
      if (this.$refs.filesModal) {
        this.$refs.filesModal.show();
      }
    },
    hide_popup() {
      if (this.$refs.filesModal) {
        this.$refs.filesModal.show();
        }
      },

    // Vue doc parser doen't recognise if-else block so concatinating all emits
    emit_input_event($event) {
      this.$emit("input", $event);
    },
    
    // Vue doc parser doen't recognise if-else block so concatinating all emits
    emit_choose_event($event) {
      this.$emit("choose", $event);
    }
    }
};
</script>
<style scoped>
.img-container {
  height: 100px;
  display: flex;
  align-items: center;
}

.img-container img {
  border-radius: 0;
  max-height: 100%;
}
</style>
