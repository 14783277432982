<script>
/**
 * ac Gravatar component for making avatars
 * @requires vue-avatar https://github.com/eliep/vue-avatar
 */

import Avatar from "vue-avatar";
import VGravatar from "vue-gravatar";
import AppPreload from "./mixins/ac-preload";

/**
 * @requires ac-preload.js
 * @inherits ac-preload
 */

export default {
  name: "ac-gravatar",
  mixins: [AppPreload],
  components: {
    Avatar,
    VGravatar
  },

  props: {
    /**
     * Give the user email that will be used to compute default avatar image
     * @param {string} email - The user email that will be used to compute gravatar.
     * @label Email
     * @category_name 1_General
     */
    email: {
      type: String,
      required: false
    },

    /**
     * Give the user name that will be used to compute avatar with name
     * when email is not given
     * @param {string} name - The user name that will be used to compute user initial,
     * if no email given.
     * @label Name
     * @category_name 1_General
     */
    name: {
      type: String,
      required: false
    },

    /**
     * Give properties of the avatar like size,rounded as object
     * Eg.{ "size": 100, "rounded": true }
     * @param {object} avatar_props - Properties for vue-avatar https://github.com/eliep/vue-avatar
     * @label Avatar Properties
     * @category_name 1_General
     */
    avatar_props: {
      type: Object,
      required: true
    }
    // /**
    //  * Onload handler call
    //  */
    // preload: {
    //   type: String,
    //   required: false
    // },
    // /**
    //  * Give params to be send to handler
    //  * @category_name 1_General
    //  */
    // handler_params: {
    //   type: String,
    //   required: false
    // }
  },

  mounted() {
    /**
     * Execute preload handler
     */
    // if (this.preload) {
    //   this.start(this.preload, {
    //     helper: {
    //       component: this,
    //       custom: this.handler_params ? JSON.parse(this.handler_params) : {}
    //     }
    //   });
    // }
  },

  /**
   * Renders the gravtar component based on props
   */
  render(h) {
    return h(
      "div",
      {
        class: {
          "ac-gravatar": true
        }
      },
      // Depending on email v-gravatar or avatar component is rendered
      [
        h(this.email ? "v-gravatar" : "avatar", {
          props: {
            username: this.name,
            email: this.email,
            ...this.avatar_props
          },
          on: {
            "avatar-initials": val => {
              this.$emit("avatar-initials", val);
            }
          },
          class: {
            "rounded-circle": this.avatar_props.rounded,
            rounded: !this.avatar_props.rounded
          }
        })
      ]
    );
  }
};
</script>
