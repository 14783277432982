<template >
  <div>
    <div v-if="data_json.length>0" class="row m-0 mb-2">
      <template v-for="(item,index) in data_json">
        <div :key="index" class="del-icon d-flex">
          <!-- slot given by user -->
          <slot name="body" class="flex-grow-1" :item="item"></slot>
          <!-- delete icon -->
          <i
            v-b-tooltip.hover
            title="delete"
            style="display:none"
            class="fa fa-times text-danger delete-icon m-1 cursor-pointer"
            @click="delete_item(item,index)"
            aria-hidden="true"
          ></i>
        </div>
      </template>
      <!-- if data exist this add icon is to display in same line -->
      <i
        v-b-tooltip.hover
        :title="add_title"
        :class="add_class"
        class="cursor-pointer"
        aria-hidden="true"
        @click="check_search_type($event)"
      ></i>
    </div>
    <!-- if data does not exist this add icon is to display in next line -->
    <i
      v-else
      v-b-tooltip.hover
      :title="add_title"
      :class="add_class"
      class="cursor-pointer"
      aria-hidden="true"
      @click="check_search_type($event)"
    ></i>

    <!-- typeahead -->
    <div class v-if="search_data">
      <span class="d-flex">
        <input
          type="text"
          class="form-control"
          :placeholder="search_placeholder"
          v-model="search_param"
          @keyup="search"
        />
        <i
          class="fa fa-times text-danger pt-1 m-1 cursor-pointer"
          v-b-tooltip.hover
          title="cancel"
          @click="hide_search"
          aria-hidden="true"
        ></i>
      </span>
      <div>
        <ul class="list-group mt-1" v-if="search_result.length>0">
          <template v-for="(seach_item, search_index) in search_result">
            <li
              :key="search_index"
              @click="add(seach_item)"
              class="list-group-item text-m border-muted px-2 py-2 text-dark-70"
              :value="`${seach_item[key_value]}`"
              :id="`${seach_item[key_name]}`"
              name="user"
            >
              <h6 class="d-inline-block font-weight-normal h6 mb-0" v-html="seach_item[key_name]"></h6>
            </li>
          </template>
        </ul>
        <ul v-else-if="search_param" class="list-group mt-1">
          <li class="list-group-item text-m border-muted px-2 py-2 text-dark-70" name="user">
            <h6 class="d-inline-block font-weight-normal h6 mb-0">No search results</h6>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ac-tags-inline",
  components: {},

  props: {
    /**
     * url to fetch the data
     * @param { String } url
     * @label URL
     * @category_name 1_General
     */
    url: {
      type: String,
      required: true
    },
    /**
     * @param {string} preload
     * @label Preload
     */
    preload: {
      type: String,
      required: false
    },
    /**
     * url for search data
     * @param { String } search_url
     * @label Search URL
     * @category_name 1_General
     */
    search_url: {
      type: String,
      required: true
    },
    /**
     * params for url
     * @param { String } search_param
     * @label Search Param
     * @category_name 1_General
     */
    search_key: {
      type: String,
      required: true
    },
    /**
     * to specify search type
     * @param { string } type
     * @label Search Type
     * @category_name 1_General
     */
    type: {
      type: String,
      default: "typeahead"
    },
    /**
     * allows duplicate values to insert if set true
     * @param {boolean} allow_duplicate
     * @label Allow Duplicate
     * @category_name 3_Advanced
     */
    allow_duplicate: {
      type: Boolean,
      default: false
    },
    /**
     * what field you want to display in tags
     * @param {string} key_name
     * @label Key Name
     * @category_name 3_Advanced
     */
    key_name: {
      type: String,
      default: "name",
      required: true
    },
    /**
     * @param {string} key_value
     * @label Key Value
     * @category_name 3_Advanced
     */
    key_value: {
      type: String,
      required: true
    },
    
    /**
     * Class for add icon
     * @param { string } add_class
     * @label Add Class
     * @category_name 3_Advanced
     */
    add_class: {
      type: String,
      required: false,
      default: "fa fa-plus-circle my-2"
    },
    /**
     * Placeholder for search bar
     * @param {String} -search_placeholder
     * @label Search Placeholder
     * @category_name 1_General
     */
    search_placeholder: {
      type: String,
      required: false,
      default: "Search"
    },
    /**
     * handler params for preload handler
     * @param {string} handler_params
     * @label Preload Handler Params
     * @category_name 1_General
     */
    handler_params: {
      type: String,
      required: false
    },
    /**
     * Tooltip for add
     * @param {string} add_title
     * @label Add Tooltip
     * @category_name 1_General
     */
    add_title: {
      type: String,
      required: false,
      default: "Add"
    }
  },
  data() {
    return {
      data_json: [],
      search_param: "",
      search_result: "",
      search_data: 0
    };
  },
  created() {
    this.get_data();
  },

  //If preload handler exists
  mounted() {
    if (this.preload) {
      this.start(this.preload, {
        helper: {
          component: this,
          custom: this.handler_params ? JSON.parse(this.handler_params) : {}
        }
      });
    }
  },
  methods: {
    /**
     * @function search -  Search based on url
     */
    search: function() {
      var _this = this;
      if (this.search_param) {
        if (this.search_url) {
          var search_url =
            this.search_url +
            (this.search_key
              ? `?${this.search_key} '${this.search_param}%25'`
              : null);
          this.$appupajax.get2(search_url, {}, {}, {withCredentials: true, credentials: "include" }).
          // this.$appupajax.get2(search_url, {}, { withCredentials: true, credentials: "include" }).
                    then(this.$appupajax.handleResponse).then(response => {
              if (!this.allow_duplicate) {
                _this.search_result = this.filter_duplicate_values(
                  response.data
                );
              } else {
                _this.search_result = response.data;
              }
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          this.set_result();
        }
      } else {
        _this.search_result = [];
      }
    },

    /**
     * @function set_result - emits event when type is typeahead and search_url is empty
     * @fires set_search_result event
     */
    set_result() {
      this.$emit("set_search_result", this.search_param);
    },
    /**
     * @function filter_duplicate_values - filter the search results data
     * @fires set_search_result event
     */
    filter_duplicate_values(filter_data) {
      var tagsData = this.data_json;
      for (var i = 0; i < filter_data.length; i++) {
        for (var j = 0; j < tagsData.length; j++) {
          if (filter_data[i][this.key_name] == tagsData[j][this.key_name]) {
            filter_data.splice(i, 1);
          }
        }
      }
      return filter_data;
    },

    /**
     * @function add - Add's item in frontend and emit add event
     * @fires add event
     */
    add: function(data) {
      this.$emit("add", data);
      this.data_json.push(data);
      this.hide_search();
    },
    /**
     * @function check_search_type - Check the type
     * @fires type event
     */
    check_search_type: function(event) {
      if (!(this.type == "typeahead")) {
        this.$emit("type", event);
      } else {
        this.search_data = 1;
      }
    },
    /**
     * @function hide_search - Hide typeahead and clear data
     */
    hide_search: function() {
      this.search_data = 0;
      this.search_param = "";
      this.search_result = [];
    },
    /**
     * @function delete_item - Remove item in frontend and emit removed event
     */
    delete_item: function(item, index) {
      this.$emit("removed", item);
    },
    /**
     * @function get_data - fetches data from given url
     */
    get_data: function() {
      var _this = this;
      if (_this.url) {
        axios
          .get(_this.url, { withCredentials: true, credentials: "include" })
          .then(response => {
            // Assign JSON data from url to component
            if (response.data) {
              _this.data_json = response.data;
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
      return _this.data_json;
    }
  }
};
</script>
<style>
.del-icon:hover .delete-icon {
  display: inline-block !important;
}
</style>
