<script>
import acFilterForm from './ac-filter-form.vue'
/**
 * ac Filter Form component which will interact with a card or a table acting as a filter
 * @requires VueBootstrap
 * @requires ac-form.vue
 */
export default {
  name: 'ac-filter-form-advanced',

  extends: acFilterForm,

  props: {
    /**
     * @param {string} save_url - URL for saving output JSON
     * Required
     * @label Search URL
     */
    save_url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      filters: [],
      conditions: [],
      filter_options: [],
      values: [],

      conditions_list: {
        'tags': [
          {
            value: 'NOT_CONTAINS',
            text: 'Doesn\'t Contains',
          }, {
            value: 'ALL_OF',
            text: 'Has all of',
          }, {
            value: 'ANY_OF',
            text: 'Has any of',
          },
        ],

        'number': [
          {
            value: 'EQ',
            text: 'Equals',
          }, {
            value: 'NOT_EQ',
            text: 'Not Equals',
          }, {
            value: 'GREATER_THAN',
            text: 'Greater Than',
          }, {
            value: 'LESS_THAN',
            text: 'Less Than',
          }, {
            value: 'GREATER_THAN_EQ',
            text: 'Greater Than or Equals',
          }, {
            value: 'LESS_THAN_EQ',
            text: 'Less Than or Equals',
          }, {
            value: 'BETWEEN',
            text: 'In Between',
          },
        ],

        'date': [
          {
            value: 'EQ',
            text: 'Equals',
          }, {
            value: 'NOT_EQ',
            text: 'Not Equals',
          }, {
            value: 'GREATER_THAN',
            text: 'Greater Than',
          }, {
            value: 'LESS_THAN',
            text: 'Less Than',
          }, {
            value: 'GREATER_THAN_EQ',
            text: 'Greater Than or Equals',
          }, {
            value: 'LESS_THAN_EQ',
            text: 'Less Than or Equals',
          }, {
            value: 'BETWEEN',
            text: 'In Between',
          },
        ],

        'string': [
          {
            value: 'EQ',
            text: 'Equals',
          }, {
            value: 'NOT_EQ',
            text: 'Not Equals',
          }, {
            value: 'CONTAINS',
            text: 'Contains',
          }, {
            value: 'NOT_CONTAINS',
            text: 'Not Contains',
          }, {
            value: 'STARTS_WITH',
            text: 'Starts with',
          }, {
            value: 'ENDS_WITH',
            text: 'Ends with',
          }, {
            value: 'REGEX',
            text: 'Regular Expression',
          },
        ],

        'boolean': [
          {
            value: 'EQ',
            text: 'Is',
          }, {
            value: 'NOT_EQ',
            text: 'Isn\'t',
          },
        ],
      },

      components: {
        multiselect: {
          tag: 'ac-multiselect',
          type: null,
        },

        number: {
          tag: 'ac-input',
          type: 'number',
        },

        date: {
          tag: 'ac-input',
          type: 'date',
        },

        text: {
          tag: 'ac-input',
          type: 'text',
        },

        dropdown: {
          tag: 'ac-select',
          type: null,
        },
      }
    }
  },

  render(createElement) {
    const filter_save = this.filter_save
    const children = this.form_data.map(item => {
      if ( ! this.filters[item['filter-name']]) {
        this.filters[item['filter-name']] = false
      }

      const checkbox = createElement('ac-checkbox', {
        attrs: {
          name: item['filter-name'],
          checked: this.filters[item['filter-name']],
        },

        on: {
          change: (e) => this.filters = { ...this.filters, [item['filter-name']]: e }
        },
      }, [item['filter-label']])

      var content = [checkbox]
      if (this.filters[item['filter-name']]) {
        let options = item['filter-type'] != 'custom' ? this.conditions_list[item['filter-type']] : []
        if (item['filter-type'] == 'custom' && item['custom-field-json'].type == 'dropdown') {
          for (let option of item['custom-field-json'].option) {
            options.push({
              value: option.value,
              text: option.label,
            })
          }
        }

        const conditions = createElement('ac-select', {
          attrs: {
            value: this.conditions[item['filter-name']],
            label: 'Condition',
            options,
          },

          on: {
            input: (e) => this.conditions = { ...this.conditions, [item['filter-name']]: e }
          },
        }, [item['filter-label']])

        let field = null
        if (item['field-json'].url && ! this.filter_options[item['filter-name']]) {
          const data = this.get_options(item['field-json'].url)
          let temp = []
          data.then(result => {
            for (let line of result) {
              temp.push({
                value: line[item['field-json'].key_value],
                text: line[item['field-json'].key_label],
              })
            }

            this.filter_options = { ...this.filter_options, [item['filter-name']]: temp }
          })
        }

        field = createElement(this.components[item['field-json'].type].tag, {
          attrs: {
            type: this.components[item['field-json'].type].type,
            name: item['field-json'].name,
            label: item['field-json'].label,
            value: this.values[item['filter-name']],
            options: this.filter_options[item['filter-name']] || [],
          },

          on: {
            input: (e) => this.values = { ...this.values, [item['filter-name']]: e }
          },
        })

        var container = createElement('div', {}, [
          conditions,
          field,
        ])

        content.push(container)
      }

      return createElement('div', {
        class: 'form-group',
      }, content)
    })

    return createElement('b-form', {
      attrs: { ...this.props, novalidate: !this.validate },
      on: { submit: (e) => {
        e.preventDefault()
        if (this.apply_type == 'onSave') {
          filter_save()
        }
      }},
    },
    [...children, ...(this.$slots.default || [])])
  },

  methods: {
    filter_save() {
      let result = {}
      for (const item of this.data) {
        if (this.values[item['filter-name']] || this.conditions[item['filter-name']]) {
          let value = null
          if (typeof this.values[item['filter-name']] == 'object') {
            value = []
            for (let i of this.values[item['filter-name']]) {
              value.push(i.value)
            }
          } else {
            value = this.values[item['filter-name']]
          }

          result[item['filter-name']] = {
            condition: this.conditions[item['filter-name']],
            value,
          }
        }
      }

      fetch(this.save_url, {method: 'POST', withCredentials: true, credentials: 'include', body: JSON.stringify(result) })
    },

    async get_options(url) {
      let response = await fetch(url, {method: 'GET', withCredentials: true, credentials: 'include' })
      if (response.ok) {
        response = await response.json()
        return response
      }
    }
  }
}
</script>
