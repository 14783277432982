<template>
  <div>
    <div class="row m-0">
      <div class="col-sm-12 p-0">
        <div class="card">
          <div class="card-header bg-transparent d-flex align-items-center justify-content-between">
            <h5 class="mb-0">
              {{headersdata.headername}}
              <span title class="ml-2 cursor-pointer">
                <i :class="headersdata.icons + ' ' + 'text-muted'"></i>
              </span>
            </h5>
            <div>
              <b-dropdown
                size="lg"
                variant="transparent border-0"
                right
                toggle-class="text-decoration-none"
                no-caret
              >
                <template slot="button-content">
                  <i :class="headersdata.rcicon + ' '+ + 'fa-lg'"></i>
                </template>
                <b-dropdown-item
                  href="#"
                  v-for="(drodowns,index) in  dropdowns"
                  :key="index"
                >{{drodowns.dname}}</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div
                class="col-lg-3 col-md-6 col-sm-12 px-custom-card mb-3"
                v-for="(datas,index) in carddata"
                :key="index"
              >
                <div class="card">
                  <div class="card-body pb-3">
                    <div class="media pb-3">
                      <img :src="datas.profile" height="35" width="35" class="rounded-circle mr-2" />
                      <div class="media-body">
                        <h5 class="mb-1 ml-1 text-capitalize">{{datas.name}}</h5>
                        <p class="mb-0 ml-1 sub-title text-muted small">{{datas.time}}</p>
                      </div>
                    </div>
                    <div class="card-hover">
                      <div class="card rounded p-0">
                        <a :href="datas.link" target="new">
                          <img :src="datas.url" alt="screenshot" class="custom-img-height rounded" />
                        </a>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="progress cursor-pointer" :style="{height:datas.prog1}">
                        <div
                          role="progressbar"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                           :class="datas.color + ' '+ 'progress-bar' "
                          :style="{width: datas.width}"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-outline-primary px-3 py-2 d-flex mx-auto my-3">
            View
            All
            Activities
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ac-groupcard",
  props: {
    /**
     * @param {Object} headersdata - Array of objects. Each object will be rendered as title
     *@properties {"headername": {"type": "String"}, "icons": {"type": "String"}, "rcicon": {"type": "String"}}
     */
    headersdata: {
      type: Object
    },

    /**
     * @param {array} carddata - Array of objects. Each object will be rendered as title
     *@properties {"profile": {"type": "String"}, "name": {"type": "String"}, "time": {"type": "String"}, "link": {"type": "String"}, "url": {"type": "String"}, "prog1": {"type": "String"}, "color": {"type": "String"}, "width": {"type": "String"}}
     */
    carddata: {
      type: Array
    },
    /**
     * @param {array} dropdowns - Array of objects. Each object will be rendered as title
     *@properties {"dname": {"type": "String"}}
     */
    dropdowns: {
      type: Array
    }
  }
};
</script>

<style>
.custom-img-height {
  max-width: 100%;
  min-height: 170px;
}
.px-custom-card {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>
