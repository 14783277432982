<template>
  <div
    :class="{'slide': slide}"
    class="ac-image"
  >
    <vue-picture-swipe
      v-if="fancybox"
      :items="items"
      :options="fancybox_config"
      @imageLoadComplete="on_load"
      @close="on_close"
    />

    <img
      v-else
      :src="thumb_src || src"
      itemprop="thumbnail"
    />
  </div>
</template>

<script>
import VuePictureSwipe from 'vue-picture-swipe'

/**
 * ac image component
 * @requires vue-picture-swipe
 */

export default {
  name: 'ac-image',

  components: {
    VuePictureSwipe,
  },

  props: {
    /**
     * @param {string} src - src of image
     */
    src: {
      type: String,
      required: true,
    },

    /**
     * @param {string} thumb_src - src of thumbnail
     */
    thumb_src: {
      type: String,
      required: false,
    },

    /**
     * @param {boolean} slide - is slide on hover enabled
     */
    slide: {
      type: Boolean,
      required: true,
    },

    /**
     * @param {boolean} fancybox - is popup on click enabled
     */
    fancybox: {
      type: Boolean,
      required: true,
    },

    /**
     * @param {object} fancybox_config - config of photoswipe
     * List of all available options: https://photoswipe.com/documentation/options.html
     */
    fancybox_config: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    /**
     * @param {string|number} width - width of image
     */
    width: {
      type: [String, Number],
      required: true,
    },

    /**
     * @param {string|number} height - height of image
     */
    height: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      items: [],
    }
  },

  /**
   * prepare data for photoswipe
   */
  created() {
    const obj = {
      src: this.src,
      thumbnail: this.thumb_src || this.src,
      w: this.width,
      h: this.height,
    }

    this.items = [obj]
  },

  methods: {
    on_load() {
      console.log('load')
    },

    on_close() {
      console.log('close')
    },
  },
}
</script>

<style>
  .ac-image {
    overflow: hidden;
    display: inline-block;
  }

  .ac-image img {
    max-width: 100%;
  }

  .slide img[itemprop="thumbnail"] {
    transition: transform 2s ease-in-out;
  }

  .slide img[itemprop="thumbnail"]:hover {
    transform: translateY(-65%);
  }
</style>
