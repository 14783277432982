<template>
  <star-rating
    :increment="increment"
    :rating="rating"
    :maxRating="maxRating"
    :starPoints="starPoints"
    :readOnly="readOnly"
    :showRating="showRating"
    :fixedPoints="fixedPoints"
    :rtl="rtl"
    :roundStartRating="roundStartRating"
    :starSize="starSize"
    :inactiveColor="inactiveColor"
    :activeColor="activeColor"
    :borderColor="borderColor"
    :borderWidth="borderWidth"
    :padding="padding"
    :roundedCorners="roundedCorners"
    :inline="inline"
    :glow="glow"
    :glowColor="glowColor"
    :textClass="textClass"
    @rating-selected="ratingSelected($event)"
    @current-rating="currentRating($event)"
  ></star-rating>
</template>

<script>
/**
 * ac star component
 * @requires vue-star-rating - Forked from https://github.com/craigh411/vue-star-rating
 */
import StarRating from "vue-star-rating";
export default {
  name: "ac-star",
  components: {
    StarRating
  },
  methods: {
    /**
     * Emits the rating when clicked
     * @function currentRating
     * @param rating
     */
    ratingSelected(rating) {
      this.$emit("ratingSelected", rating);
    },
    /**
     * Emits the rating when hovered
     * @function currentRating
     * @param rating
     */
    currentRating(rating) {
      this.$emit("currentRating", rating);
    }
  },
  props: {
    /**
     * The rating increment, for example pass 0.5 for half stars or 0.01 for fluid stars. Expects a number between 0.01 - 1.
     * Default is 1
     * @param {number} increment
     * @label Increment
     */
    increment: {
      type: Number,
      default: 1
    },
    /**
     * The initial rating, this will automatically round to the closest increment, so for the most accurate rating pass 0.01 as increment or set the round-start-rating prop to false
     * Default is 0
     * @param {number} rating
     * @label Rating
     */
    rating: {
      type: Number,
      default: 0
    },
    /**
     * The maximum rating, this lets vue-star-rating know how many stars to display.
     * Default is 5
     * @param {number} maxRating
     * @label Max Rating
     */
    maxRating: {
      type: Number,
      default: 5
    },
    /**
     * The points defining a custom star shape. If no points are passed the default star shape is used.
     * Default is empty array []
     * @param {array} starPoints
     * @label Star Points
     */
    starPoints: {
      type: Array,
      default: () => []
    },
    /**
     * When set to true, the rating cannot be edited. Use in conjuction with increment to define rounding precision.
     * Default is false
     * @param {boolean} readOnly
     * @label ReadOnly
     */
    readOnly: {
      type: Boolean,
      default: false
    },
    /**
     * Whether or not to show the rating next to the stars.
     * Default is true
     * @param {boolean} showRating
     * @label ShowRating
     */
    showRating: {
      type: Boolean,
      default: true
    },
    /**
     * Specify a fixed number of digits after the decimal point.
     * Default is null
     * @param {number} fixedPoints
     * @label FixedPoints
     */
    fixedPoints: {
      type: Number,
      default: null
    },
    /**
     * Pass true to display star rating using rtl (right-to-left)
     * Default is false
     * @param {boolean} rtl
     * @label RTL
     */
    rtl: {
      type: Boolean,
      default: false
    },
    /**
     * Pass false if you don't want the start rating value to round to the closest increment. The user will still only be able to select based on the given increment.
     * Default is true
     * @param {boolean} roundStartingRating
     * @label RoundStartingRating
     */
    roundStartRating: {
      type: Boolean,
      default: true
    },
    // Style Props
    /**
     * The size of each star, this gets passed to the SVG width attribute, so larger numbers are larger stars
     * Default is 50
     * @param {number} starSize
     * @label StarSize
     */
    starSize: {
      type: Number,
      default: 50
    },
    /**
     * The color of the non-highlighted portion of a star.
     * Default is #d8d8d8
     * @param {string} inactiveColor
     * @label InactiveColor
     */
    inactiveColor: {
      type: String,
      default: "#d8d8d8"
    },
    /**
     * The color of the highlighted portion of a star.
     * Default is #ffd055
     * @param {string} activeColor
     * @label ActiveColor
     */
    activeColor: {
      type: String,
      default: "#ffd055"
    },
    /**
     * Sets the colour of the border for each star
     * Default is #999
     * @param {string} borderColor
     * @label BorderColor
     */
    borderColor: {
      type: String,
      default: "#999"
    },
    /**
     * Sets the width of the border for each star
     * Default is 0
     * @param {number} borderWidth
     * @label borderWidth
     */
    borderWidth: {
      type: Number,
      default: 0
    },
    /**
     * Pads the right of each star so distance between stars can be altered
     * Default is 0
     * @param {number} padding
     * @label Padding
     */
    padding: {
      type: Number,
      default: 0
    },
    /**
     * Whether or not to round the star's corners
     * Default is false
     * @param {boolean} roundedCorners
     * @label RoundedCorners
     */
    roundedCorners: {
      type: Boolean,
      default: false
    },
    /**
     * Sets the star rating to display inline.
     * Default is false
     * @param {boolean} inline
     * @label Inline
     */
    inline: {
      type: Boolean,
      default: false
    },
    /**
     * Adds a subtle glow around each active star, this should be a number to spread the glow.
     * Default is 0
     * @param {number} glow
     * @label Glow
     */
    glow: {
      type: Number,
      default: 0
    },
    /**
     * Sets the color for the glow (note, this effect can be very subtle).
     * Default is #000
     * @param {string} glowColor
     * @label GlowColor
     */
    glowColor: {
      type: String,
      default: "#000"
    },
    /**
     * A css class name to style the rating text for a specific star rating component.
     * Default is empty string
     * @param {string} textClass
     * @label TextClass
     */
    textClass: {
      type: String,
      default: ""
    }
  }
};
</script>

<style>
</style>