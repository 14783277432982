<template>
  <div class="ac-table">
    <header v-if="hasHeaderslot" class="ac-table__header my-3">
      <!-- Use this slot for table header -->
      <slot name="header"></slot>
    </header>
    <div v-if="Object.keys(data).length">
      <div class="table-responsive">
        <table class="table border">
          <thead :class="header_class">
            <tr>
              <!-- Check for bluk prop -->
              <th scope="col" :class="checkbox_class" v-if="bulk">
                <b-form-checkbox v-model="select_all" @change="bulk_actions($event)" />
              </th>
              <template v-for="(table_column, index) in table_columns">
                <th
                  scope="col"
                  :class="rclass + table_column.class"
                  :key="index"
                >{{ table_column.label }}</th>
              </template>
            </tr>
          </thead>

          <tbody v-for="(value, key) in actual_data" :key="key">
            <tr>
              <td :colspan="col_span" class="align-middle">
                <!-- Use this slot group_header for each item in response -->
                <slot name="group_header" :group-item="value[0]"></slot>
              </td>
            </tr>

            <ac-draggable v-model="actual_data[key]" class="stretched-table" :options="{disabled: get_enable_drag}" @change="on_change($event,actual_data[key])">
            <tr v-for="(innerObject, key) in value" :key="key">
              <td scope="col" class="align-middle" v-if="bulk">
                <b-form-checkbox
                  v-model="selected"
                  :value="innerObject.id"
                  @change.native="checkbox_click(innerObject.id)"
                />
              </td>
              <template v-for="(column, index) in columns">
                <td
                  scope="col"
                  class="align-middle"
                  @click="$emit('row_clicked', $event,innerObject)"
                  @dblclick="$emit('row_dblclicked', $event,innerObject)"
                  :key="index"
                >
                  <div v-if="column.__slot__">
                    <slot :name="column.key" :item="innerObject"></slot>
                  </div>
                  <div v-else>{{innerObject[column.key]}}</div>
                </td>
              </template>
              <td scope="col" class="align-middle">
                <ac-material-dropdown
                  :options="dropdown_options_handler"
                  :button_options="dropdown_button_options"
                  :right="dropdown_button_options.right"
                  :show_icon="show_dropdown_icons"
                  @item_click="on_dropdown_item_click($event, innerObject)"
                />
              </td>
              <!-- <slot name="body" :data="value1"></slot> -->
            </tr>
          </ac-draggable>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="!Object.keys(actual_data).length">
      <!-- Use this slot for Empty State -->
      <slot name="empty_state"></slot>
    </div>

    <!-- loader image -->
    <div v-show="is_loading" class="loader row">
      <img :src="loading_image" class="loader__image m-auto" alt="loader" />
    </div>

    <!-- <div
      v-show="load_more_type === 'loadMoreButton'"
      ref="loader_div"
      class="load-more text-center mt-2"
    >

      <slot name="load_more_button"></slot>
    </div>-->
    <div
      v-show="load_more_type === 'loadMoreButton'"
      v-if="pagination_type === 'loadMore'"
      ref="loader_div"
      class="load-more text-center"
    >
      <!--
          Use this slot for load more button, shown only if load_more_type is 'loadMoreButton'
          and there is more data to fetch
      -->
      <slot name="load_more_button">
        <button
          type="button"
          class="btn btn-link"
          :class="page_number >= load_option ?'d-none':''"
          @click="loadmore_click_fun()"
        >Load More...</button>
      </slot>
    </div>

    <footer class="ac__footer">
      <!-- Use this slot for footer -->
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
import acCollection from "./ac-collection.vue";
import acMaterialDropdown from "./ac-material-dropdown.vue";
/**
 * ac Table component which can be used to display data from API in table view
 * @requires VueBootstrap
 * @requires ac-collection.vue
 * @requires ac-material-dropdown.vue
 *
 * Fires event on row click and row double click
 * @event row_clicked
 * @event row_dblclicked
 * @type {object} row data
 *
 * @event checkbox_clicked
 * @type {object} row data
 *
 * @inherits ac-collection,ac-data,ac-preload
 *
 * @author Dharma teja TV
 */

export default {
  name: "ac-collection-grouped-table",

  components: {
    acMaterialDropdown
  },

  extends: acCollection,

  props: {
    /**
     * List of table columns
     * @param {array} columns
     * @properties {"label": {"type": "String","description":"Give label name of table header"}, "key": {"type": "String","description":"Give key name from the data"}, "__slot__": {"type": "String","description":"write HTML code and data-scope:item"}}
     * @label Columns
     * @category_name 2_Data
     */
    columns: {
      type: Array,
      required: true
    },

    /**
     * Whenever true show checkbox in the first column
     * @param {boolean} bulk
     * @label Bulk
     * @category_name 1_General
     */
    bulk: {
      type: Boolean,
      default: false
    },

    /**
     * Whenever true enables the drag and drop functionality
     * @param {boolean} enable_drag
     * @label Enable Drag
     * @category_name 1_General
     */
    enable_drag: {
      type: Boolean,
      default: false
    },

    /**
     * Whenever true show dropdown in the last column
     * @param {boolean} dropdown
     * @label Dropdown
     * @category_name 1_General
     */
    dropdown: {
      type: Boolean,
      default: false
    },

    /**
     * Options for dropdown menu for each row
     * @param {object} dropdown_options
     * @properties {"label": {"type": "String","description":"Give label name of option"}, "type": {"type": "String", "description":"Type of the option Eg:edit,submit..etc"}, "icon": {"type": "String", "description":"Give icon class for option"}, "handler": {"type": "String","description":"Select handler to perform action"}, "handler_params": {"type": "String","description":"Give params to be send to handler"}}
     * @label Dropdown Options
     * @category_name 1_General
     */
    dropdown_options: {
      type: Array,
      required: false
    },

    /**
     * If true - display dropdown options icon
     * @param {boolean} show_dropdown_icons
     * @label Show Dropdown Icons
     * @category_name 1_General
     */
    show_dropdown_icons: {
      type: Boolean,
      default: false
    },

    /**
     * Dropdown Button Options is a object with keys icon, variant, size, right. It shows three dots based upon icon in each row
     * @param {object} dropdown_button_options
     * @properties
        {
          "icon": {"type": "String", "default": "fas fa-ellipsis-v","description":"Give button icon class"},
          "variant": {"type": "String", "default": "outline-secondary","description":"Give variant class for button", "values": ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark", "outline-primary", "outline-secondary", "outline-success", "outline-danger", "outline-warning", "outline-info", "outline-light", "outline-dark"]},
          "size": {"type": "String", "default": "sm", "description":"Give size of the button Eg:sm,lg,md", "values": ["sm", "md", "lg"]},
          "right": {"type": "Boolean", "default": "true" , "description":"To display dropdown options right or left"}
        }
     * @label Dropdown Button Options
     * @category_name 1_General
     */
    dropdown_button_options: {
      type: Object,
      required: false
    },

    /**
     * Data from every request will be grouped with this key
     * @param {string} group_key
     * Required
     * @label Group Key
     * @category_name 1_General
     */

    group_key: {
      type: String,
      required: false
    },
    /**
     * Header class
     * @param {string} header_class
     * Required
     * @label Header Class
     * @category_name 4_Style
     */
    header_class: {
      type: String,
      required: false,
      default: "bg-light"
    }
  },

  data() {
    return {
      selected: [],
      select_all: false,
      rclass: "border-bottom-0 text-nowrap ",
      checkbox_class: "border-bottom-0 text-nowrap ",
      dragged_ids:{}
    };
  },

  computed: {
    /**
     * To get enable drag option
     * @return {Boolean}
     */
    get_enable_drag(){
       return (this.enable_drag)?false:true;
    },
    /**
     * colspan for td
     * @return {Integer}
     */
    col_span() {
      let tdata = this.columns.length + 1;
      if (this.bulk) {
        tdata += 1;
      }
      return tdata;
    },
    /**
     * Adds column depending on bulk and dropdown props
     * @private
     */
    table_columns() {
      var cols = [...this.columns];
      var option = { label: "options", key: "options" };
      //Identifies checkbox or options type
      var checkbox = cols.find(c => c.key === "checkbox");
      var optionsClass = cols.find(c => c.key === "options");
      if (checkbox || optionsClass) {
        for (var i = 0; i < cols.length; i++) {
          if (cols[i].key === "checkbox") {
            this.checkbox_class = this.checkbox_class + checkbox.class;
            cols.splice(i, 1);
          }
          if (cols[i].key === "options") {
            option.class = optionsClass.class;
            cols.splice(i, 1);
          }
        }
      }
      if (this.dropdown) {
        cols.push(option);
      }

      return cols;
    },

    /**
     * Not used
     * Depends on dropdown_options prop
     * If dropdown prop is true - checks for dropdown_options presense
     * @private
     */
    dropdown_options_handler() {
      if (!this.dropdown) return [];

      if (this.dropdown && !this.dropdown_options) {
        console.error(
          "dropdown_options is required when dropdown prop is true"
        );
        return [];
      }

      return [...this.dropdown_options];
    },
    hasHeaderslot() {
      return !!this.$slots.header;
    }
  },

  watch: {
    actual_data() {
      if (this.select_all) this.bulk_actions(true)
    }
  },

  methods: {
    /**
     * Executes on change of drag and drop
     * Gets ids for dragged items
     * @param {object} event - event on drag and drop
     * @param {array} change_data - grouped data
     */
    on_change(event,change_data){

       let index=event.moved.newIndex;

       if(index !== 0 && index+1 !== Object.keys(change_data).length){
             this.dragged_ids.from_id = change_data[index-1].id;
             this.dragged_ids.to_id = change_data[index+1].id
       } else if(index == 0){
             this.dragged_ids.to_id = 0;
             this.dragged_ids.from_id = change_data[index+1].id;
       } else if (index+1 === Object.keys(change_data).length){
             this.dragged_ids.to_id = change_data[index-1].id;
             this.dragged_ids.from_id = 0;
       }
       this.dragged_ids.target_id = event.moved.element.id;
       this.$emit('on-drag',event,this.dragged_ids);
    },
    /**
     * Executes on click of checkbox to select all from table header
     * when checkbox is selected saves data in a selected array
     * @public
     */
    bulk_actions(target) {
      this.selected = [];
      if (!this.select_all || target) {
        // Since data is object here, so need to get key
        for (let key in this.actual_data) {
          if (this.actual_data.hasOwnProperty(key)) {
            this.actual_data[key].forEach(object => {
              this.selected.push(object.id);
            });
          }
        }
      }
      // It fires array of checked rows
      this.$emit("bulkaction", this.selected);
    },

    /**
     * Not used
     * Get the data of checkbox selected from table row
     * @public
     * @returns {array} id's array of checked rows
     */
    get_selected() {
      return [...this.selected];
    },

    /**
     * Not used
     * Sets checked rows to the selected array
     * @public
     * @param {array} data - array of row ids
     */
    set_selected(actual_data) {
      this.selected = [...actual_data];
       },
    /**f
     * It fires when click on each row checkbox
     * @param  {number} id - id of selected row's data
     */
    checkbox_click(id) {
      // if (this.selected.length === this.actual_data.length)
      //   this.select_all = true;
      // else this.select_all = false;

      //It fires when click on row checkbox
      this.$emit("checkbox_clicked", this.selected);

    },
    /**
     * Executes onclick of dropdown option
     * @public
     * @param {string} type - type of click e.g. 'edit'
     * @param {object} item - row info related to clicked dropdown
     */
    on_dropdown_item_click(option, item) {
      // Execute appup handler workflow
      if (option.handler && this.start) {
        let custom = {};
        if (option.handler_params) {
          try {
            custom = JSON.parse(option.handler_params);
          } catch (error) {
            if (typeof option.handler_params === "object") {
              custom = option.handler_params;
            }
          }
        }
        this.start(option.handler, {
          helper: {
            component: this,
            item: item,
            custom: custom
          }
        });
      }
      // It fires when Dropdown Click
      this.$emit("dropdown_click", {
        type: option.type,
        item
      });
    }
  }
};
</script>

<style>
.stretched-table{
  display: contents;
}
</style>
