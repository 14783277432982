<template>
  <div class="ac-resource-upload">
    <b-card :class="['ac-resource-upload_uploaders', { 'mb-2': has_files }]">
      <b-row>
        <div class="ac-resource-upload_placeholder m-auto p-4 text-black-50 text-center w-50">
          <i class="fas fa-cloud-upload-alt fa-3x"></i>
          <br />Drag and Drop Files here
          <br />(Or)
          <br />Select option below
        </div>
      </b-row>
      <div class="d-flex w-50 m-auto justify-content-center ac-resource-upload_buttons">
        <component
          v-for="uploader in uploaders"
          :key="uploader.name"
          class="mr-3"
          v-bind="uploader.props"
          :is="get_uploader_component(uploader)"
          @choose="on_choose_files(uploader, $event)"
        ></component>
      </div>
    </b-card>

    <transition-group v-show="has_files" name="files-fade" tag="b-card" class="files-list">
      <b-card
        v-for="(ch_file, ch_index) in local_files"
        :key="ch_file.title + ch_index"
        class="mb-2"
        :bg-variant="ch_file.error ? 'danger' : 'dark'"
        text-variant="white"
      >
        <template #header>
          <div class="d-flex justify-content-between align-content-center">
            <h5 class="mb-0">
              <span v-if="ch_file.error">Error in uploading:</span>
              {{ch_file.title}}
            </h5>
            <i
              v-if="ch_file.error || ch_file.from_cloud"
              class="fas fa-times"
              @click="delete_file(ch_file)"
            ></i>
            <span v-else>{{Math.round(ch_file.loaded) || 0}}%</span>
          </div>
        </template>
        <b-card-text>
          <b-textarea v-if="ch_file.error" :value="ch_file.error" disabled></b-textarea>
          <div v-else class="d-flex">
            <b-progress :value="ch_file.loaded" :max="100" class="w-100"></b-progress>
            <i v-if="is_canceled" class="fas fa-times ml-2" @click="cancel_upload(ch_file)"></i>
          </div>
        </b-card-text>
        <b-button v-if="!ch_file.error" disabled href="#" variant="primary">
          <span>{{ch_file.loaded * ch_file.size / 100 | to_mb}} /</span>
          {{ch_file.size | to_mb}}
        </b-button>
      </b-card>
    </transition-group>
  </div>
</template>
<script>
import axios from "axios";
import * as UPLOADERS from "./uploaders";
import acDropboxPicker from "./ac-dropbox-picker";
import acGooglePicker from "./ac-google-picker";
import acLocalUpload from "./ac-local-upload";
import acOnedrivePicker from "./ac-onedrive-picker";
import acS3UploaderMixin from "../mixins/ac-s3-uploader";

/**
 * ac-resource-upload
 * @inherits acS3UploaderMixin
 */
export default {
  components: {
    acDropboxPicker,
    acGooglePicker,
    acLocalUpload,
    acOnedrivePicker
  },

  mixins: [acS3UploaderMixin],

  computed: {
    local_files() {
      return this.choosen_files.filter(file => !file.from_cloud)
    },

    has_files() {
      return this.choosen_files.length > 0;
    },

    is_canceled() {
      if (!this.current_upload) return true;
      const presigned_url = Object.keys(this.presigned_urls).find(
        key => this.presigned_urls[key] === this.current_upload.file
      );
      return presigned_url && this.cancel_tokens[presigned_url];
    }
  },

  filters: {
    to_mb(val) {
      const number = Number(val) || 0;
      const value = ((number / Math.pow(1024, 2)).toFixed(2) * 100) / 100;
      return isNaN(value) ? 0 : value + " MB";
    }
  },

  props: {
    uploaders: {
      type: Array,
      validator: function(value) {
        const uploadersNames = Object.keys(UPLOADERS).map(
          key => UPLOADERS[key].name
        );
        return !value.some(item => !uploadersNames.includes(item.name));
      }
    }
  },

  name: "ac-resource-upload",

  data() {
    return {
      choosen_files: [],
      current_upload: {}
    };
  },

  methods: {
    get_uploader_component({ name }) {
      const uploader = Object.values(UPLOADERS).find(
        item => item.name === name
      );
      return uploader && uploader.component;
    },

    on_choose_files({ name }, files = []) {
      this.choosen_files.push(
        ...files
      );

      this.upload_files(files, name);
    },

    cancel_upload(fileInfo) {
      const presigned_url = Object.keys(this.presigned_urls).find(
        key => this.presigned_urls[key] === fileInfo
      );
      const cancelFn = this.cancel_tokens[presigned_url];
      this.cancel_tokens[presigned_url] = null;
      typeof cancelFn === "function" && cancelFn();
      this.delete_file(fileInfo);
      this.current_upload = null;
    },

    update_file_status(file, changes) {
      if (!file) return;
      const index = this.choosen_files.findIndex(_file => _file === file);

      if (index > -1) {
        this.choosen_files.splice(index, 1, {
          ...file,
          ...changes
        });
        this.current_upload = this.choosen_files[index];
      }
    },

    delete_file(file) {
      const fileIndex = this.choosen_files.findIndex(_file => _file === file);
      this.choosen_files.splice(fileIndex, 1);
    },

    upload_files(files = [], serviceName) {
      files.forEach(async file => {
        let result = null;
        this.current_upload = file

        try {
          if (file.link) {
              file.file = (await axios.get(file.link, { responseType: 'blob' })).data
              file.file.name = file.title
          } 

          result = await this.upload_to_s3(file.file);
          this.emit_input(result);
        } catch (e) {
          this.update_file_status(this.current_upload, {
            error: e.message || e
          });
        }
      });
    },

    emit_input(value) {
      this.$emit("input", value);
    }
  },
  watch: {
    current_upload_status(status) {
      this.update_file_status(this.current_upload, status);
    }
  }
};
</script>
<style>
.ac-resource-upload_buttons {
  position: static;
}
.ac-resource-upload_buttons button {
  width: 65px;
  height: 65px;
  position: relative;
  z-index: 2;
}
.ac-resource-upload_buttons button i {
  font-size: 2rem;
}
.ac-resource-upload_placeholder {
  line-height: 2.5rem;
  font-size: 1.5rem;
  opacity: 0.5;
}

.ac-resource-upload_uploaders {
  position: relative;
}

.ac-local-upload_input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.ac-local-upload_input > input[type="file"] {
  width: inherit;
  height: inherit;
}
.ac-local-upload_input label {
  display: none;
}

.files-list .fa-times {
  cursor: pointer;
}

.files-fade-enter-active,
.files-fade-leave-active {
  transition: opacity 0.5s;
}
.files-fade-enter, .files-fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>