export default {
  data() {
      return {
          script_el: document.createElement("script")
      }
  },

  created() {
      //  Create script and append to header here
  },

  destroyed() {
      this.script_el && document.head.removeChild(this.script_el)
  },
}