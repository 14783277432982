<template>
  <div role="tablist">
    <b-card no-body class="mb-1" v-for="(item,index) in stages" :key="index">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button
          block
          href="#"
          v-b-toggle="`accordion${index}`"
          :variant="toggle_variant"
          @click="show_collapse_class(index)"
        >
        <span class="when-opened float-right text-muted">
                  <i class="fas fa-chevron-up"></i>
                </span>
                <span class="when-closed float-right text-muted">
                  <i class="fas fa-chevron-down"></i>
                </span>
          <slot name="accordion_header" :item="item">{{item.name}} ({{item.count}})</slot>
        </b-button>
      </b-card-header>
      <b-collapse :id="`accordion${index}`" visible accordion="my-accordion" role="tabpanel">
        <b-card-body :class="append_class(index) ? 'active-accordion' : ''">
          <b-card-text>
            <ac-collection-table
              :url="item_url"
              :total_count="item.count"
              v-bind="table_props"
              :ac_cursor="item.items"
              :ref="`accordion${item.id}`"
              @dropdown_click="dropdown_clicked"
              @checkbox_clicked="checkbox_clicked"
              @row_clicked="row_clicked"
              @ctbulkaction="collection_table_bulk_action"
            ></ac-collection-table>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- Empty state slot -->
    <div v-if="stages && !stages.length">
      <slot name="empty_state">
        <p>No records found.</p>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "ac-accordion-table",
  props: {
    /**
     * Give url to get stages of accordion
     * @param { String } stages_url - to fetch data
     * @label Stage Url
     * @category_name 1_General
     */
    stages_url: {
      type: String,
      required: true
    },
    /**
     * Give key name from stage url data to append to item url
     * @param { String } append_key - dynamic key
     * @label Append Key
     * @category_name 1_General
     */
    append_key: {
      type: String,
      required: false
    },
    /**
     * Give item url to get the data for stages
     * @param { String } item_url - url for table
     * @label Item Url
     * @category_name 1_General
     */
    item_url: {
      type: String,
      required: true
    },
    /**
     * Toggle label variant Eg.primary, seconday, success, warning, danger,info
     * @param { String } toggle_variant - variant for toggle
     * @label Toggle Variant
     * @category_name 4_Style
     */
    toggle_variant: {
      type: String,
      default: "info"
    },
    /**
     * Properties of table to be display
     * @param { String } table_props - props for table
     * @label Table Props
     * @category_name 1_General
     */
    table_props: {
      type: Object,
      required: true
    }
  },

  data: function() {
    return {
      stages: [],
      arr: [],
      show_collapse: 0
    };
  },

  watch: {
    stages_url(newval, oldval) {
      this.get_data();
    },
    item_url(newval, oldval) {
      // this.get_data();
    },
    table_props: {
      handler(val) {
        this.get_data();
      },
      deep: true
    }
  },

  created: function(id) {
    this.get_data();
  },

  methods: {
    /**
     * Compile handlebars
     *
     */
    get_blocks_from_stages() {
      for (var i = 0; i < this.stages.length; i++) {
        var url = this.item_url;
        var obj = { ...this.table_props.ac_cursor };
        /* var a = "where=${id}";
        console.log("a value is", `${a}`);
        console.log(
          "new a value is",
          obj.where.replace(/\$.*}/, this.stages[i][this.append_key])
        );

        obj = JSON.stringify(obj);
        let whr = handelbars.compile(obj);
        obj = whr(this.stages[i]);*/
        obj.where = obj.where.replace(
          /\${.*}/,
          this.stages[i][this.append_key]
        );
        if (this.stages[i][this.append_key] === null) {
          obj.where = obj.where.replace(/=%27null%27/, "is null");
        }
        this.stages[i].items = obj;
        this.stages = [...this.stages];
      }
    },
    /**
     * Fetch main stage data from stage_url
     */
    get_data() {
      if (this.stages_url) {
        fetch(this.stages_url, {
          withCredentials: true,
          credentials: "include"
        })
          .then(res => res.json())
          .then(res => {
            // if (res.length) {
            this.stages = res;
            this.get_blocks_from_stages();
            // }
          })
          .catch(err => {});
      }
    },
    /**
     * Emit row-click-event on click of row
     */
    row_clicked(data) {
      this.$emit("row-clicked", data);
    },
    /**
     * Emit dropdown-click-event on click of dropdown option
     */
    dropdown_clicked(data) {
      this.$emit("dropdown-clicked", data);
    },
    /**
     * Emit checkbox-click-event
     */
    checkbox_clicked(data) {
      this.$emit("checkbox-clicked", data);
    },
    /**
     * Not used
     * get id's of all selected rows
     */
    get_selected_all(id) {
      if (id) {
        return this.$refs[`accordion${id}`][0].get_selected();
      } else {
        this.arr = [];
        this.stages.forEach(item => {
          this.arr = this.arr.concat(
            this.$refs[`accordion${item.id}`][0].get_selected()
          );
        });
        return this.arr;
      }
    },
    /**
     * fires bulk action checkbox data
     */
    collection_table_bulk_action(checkbox_data) {
      this.$emit("accordian_bulkaction", checkbox_data);
    },
    /**
     * get collapsed label reference key
     * @param {Integer} key_ref - Reference index of collapsed label
     */
    show_collapse_class(key_ref) {
      this.show_collapse = key_ref;
    },
    /**
     * append dynamic class for collapsed label
     * @param {Integer} key - Reference index of collapsed label
     * @returns Boolean - True or False
     */
    append_class(key) {
      if (this.show_collapse == key) return true;
      else return false;
    }
  }
};
</script>
