<template>
  <div>
    <div class="form__field">
      <div class="form__label">
        <strong>
          <!-- Use this slot for giving title -->
          <slot name="title"></slot>
        </strong>
        <swatches :colors="colors" inline v-model="value" @input="$emit('input',$event)"></swatches>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ac swatches component
 * @requires vue-swatches - Forked from https://github.com/saintplay/vue-swatches
 */
import Swatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.min.css";

export default {
  name: "ac-swatches",
  components: {
    Swatches
  },
  props: {
    /**
     * Give custom colors to override default colors Eg.["#F64272", "#F6648B", "#F493A7", "#F891A6", "#FFCCD5"]
     * You should always use 6-digits hex colors.
     * @param {string, object, array} colors
     * @label Colors
     * @category_name 1_General
     */
    colors: {
      type: [String, Object, Array],
      default: "basic"
    },
  },
  data() {
    return {
      value: "",
    }
  }
};
</script>