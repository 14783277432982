<template>
  <div class="container-fluid p-0 d-flex overflow-auto">
    <!-- <div class="d-flex flex-row-reverse py-3 icon bg-white border-muted" v-if="limitedStages && limitedStages.length">
    <a :class="{'disabled-arrows':right_length == stages.length}"><i class="material-icons rounded-circle bg-primary text-white d-flex justify-content-center align-items-center mr-3"  :class="{'bg-secondary':right_length == stages.length}" style="font-size: 20px; cursor: pointer;" @click="arrow_right">keyboard_arrow_right</i> </a>
    <a :class="{'disabled-arrows':right_length <= 4}"><i class="material-icons rounded-circle bg-primary d-flex text-white justify-content-center align-items-center mr-3" :class="{'bg-secondary':right_length <= 4}"  style="font-size: 20px; cursor: pointer;" @click="arrow_left">keyboard_arrow_left</i></a>
    </div>-->
    <div :class="main_class">
      <div
        class="d-flex flex-column align-items-stretch w-340 p-1 custom-ticket-bg"
        v-for="(stg,stgIndex) in limitedStages"
        :key="stgIndex"
      >
        <div class="custom-odd-color">
          <div class>
            <div class="d-flex align-items-center kanban-header">
              <template v-if="bulk_action">
                <b-form-checkbox
                  :class="header_class"
                  :id="`checkboxs${stg[stage_append_key]}${stgIndex}`"
                  @change="bulk_chkbox_chng($event,stg,'S',stgIndex)"
                ></b-form-checkbox>
              </template>
              <!-- slot for header -->
              <slot name="header" :headItem="stg"></slot>
            </div>
            <div class="scroll-area-css p-0">
              <ac-collection
                ref="acCollections"
                :key="stgIndex + '_' + render_count"
                :total_count="stg.count"
                :pagination_type="pagination_type"
                :load_more_type="load_more_type"
                :data_count_url="stg.data_count_url"
                :count_key="count_key"
                :limit="limit"
                :limit_key="limit_key"
                :offset_key="offset_key"
                :url="stg.itemUrl"
                :ac_cursor="{}"
                loading_image="https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif"
                sort_key="name"
                v-if="stg.itemUrl"
                class="my-2"
              >
                <template #body="{ data }">
                  <div
                    class="items border-light"
                    @dragover.stop="on_drag_over"
                    @drop.stop="on_drop($event,stgIndex)"
                  >
                    <div
                      class="card draggable cursor-pointer mb-2"
                      id="cd1"
                      v-for="(item,blkIndex) in data"
                      :key="blkIndex"
                      draggable="true"
                      @dragstart.stop="on_drag_start($event,blkIndex,stgIndex,item)"
                    >
                      <template v-if="bulk_action">
                        <b-form-checkbox
                          :id="`checkboxb${stg[stage_append_key]}${blkIndex}`"
                          @change="bulk_chkbox_chng($event,item,'B',stgIndex)"
                          :class="checkbox_class"
                        ></b-form-checkbox>
                      </template>
                      <slot name="body" :item="item" :list="data"></slot>
                    </div>
                    <div class="card draggable shadow-sm cursor-pointer" v-if="!data.length">
                      <div v-if="show_stg_empty_slot">
                        <!-- Empty stage slot -->
                        <slot name="stg_empty_state"></slot>
                      </div>
                      <div class="card-body p-3" v-else>Drag Here</div>
                    </div>
                  </div>
                </template>
              </ac-collection>
            </div>
          </div>
        </div>
      </div>

      <!-- Empty state slot -->
      <div class="empty_state_class w-100" v-if="limitedStages && !limitedStages.length">
        <slot name="empty_state"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ac-dynamic-kanban",
  props: {
    /**
     * Url to fetch stage data
     * @param {String} -stagesUrl
     * @category_name 1_General
     * @label Stage url
     */
    stage_url: {
      type: String
    },
    /**
     * Url to fetch collections data
     * @param {String} -item_url
     * @category_name 1_General
     *  @label Item url
     */
    item_url: {
      type: String
    },
    /**
     * Keyvalue to append dynamically to stagesUrl
     * @param {String} -stage_append_key
     * @category_name 1_General
     *  @label Stage append key
     */
    stage_append_key: {
      type: String
    },
    /**
     * Keyvalue to append dynamically to itemUrl
     * @param {String} -item_append_key
     * @category_name 1_General
     * @label Item append key
     */
    item_append_key: {
      type: String
    },
    /**
     * To enable bulkoptions
     * @param {Boolean} -bulk_action
     * @category_name 3_Advanced
     * @label Bulk action
     */
    bulk_action: {
      type: Boolean,
      default: false
    },
    /**
     * Drag and drop
     * @param {Booolean} -noDrop
     * @category_name 1_General
     * @label No drop
     */
    no_drop: {
      type: Boolean,
      default: false
    },
    /**
     * To enable empty slot
     * @param {Boolean} -show_stg_empty_slot
     * @category_name 3_Advanced
     * @label Show stage empty slot
     */
    show_stg_empty_slot: {
      type: Boolean,
      default: false
    },
    /**
     * Type for loadmore
     * @param {String} -load_more_type
     * @category_name 3_Advanced
     * @label Load more type
     */
    load_more_type: {
      type: String,
      required: false,
      default: "loadMoreButton"
    },
    /**
     * Enable loadmore
     * @param {Boolean} -show_loadmore
     * @category_name 3_Advanced
     * @label Show load more
     */
    show_loadmore: {
      type: Boolean,
      default: false
    },
    /**
     * Fetch data count with given url
     * @param {string} data_count_url
     * @label Data Count Url
     * @category_name 3_Advanced
     */
    data_count_url: {
      type: String,
      required: false
    },
     /**
     * This key will be used to get count from data_count_url
     * @param {string} count_key
     * @label Count Key
     * @category_name 3_Advanced
     */
    count_key: {
      type: String,
      required: false
    },
    /**
     * Limit for loadmore
     * @param {Number} -limit
     * @category_name 3_Advanced
     * @label Limit
     */
    limit: {
      type: Number,
      required: false
    },
    /**
     * Key for limit
     * @param {String} -limit_key
     * @category_name 3_Advanced
     * @label Limit key
     */
    limit_key: {
      type: String,
      required: false
    },
    /**
     * Key for offset
     * @param {String} -offset_key
     * @category_name 3_Advanced
     * @label Offset key
     */
    offset_key: {
      type: String,
      required: false
    },
    /**
     * Css class for header
     * @param {String} -header_class
     * @category_name 4_Style
     * @label Header class
     */
    header_class: {
      type: String,
      required: false
    },
    /**
     * Class for checkbox
     * @param {String} -checkbox_class
     * @category_name 4_Style
     * @label Check box class
     */
    checkbox_class: {
      type: String,
      required: false
    },
    /**
     * Class for empty state
     * @param {String} -empty_state_class
     * @category_name 4_Style
     * @label Empty state class
     */
    empty_state_class: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      stages: [],
      limitedStages: [],
      right_length: null,
      dragging_obj: {},
      from_stg_object: {},
      to_stg_object: {},
      item_Index_befor_drop: null,
      stage_Index_befor_drop: null,
      stage_Index_after_drop: null,
      bulkIds: {},
      render_count: 1,
      main_class: "d-flex m-0 custom-kb-view"
    };
  },
  computed: {
    pagination_type(){
      if(this.show_loadmore){
        return 'loadMore';
      }else{
        return 'deselect'
      }
    }
  },
  watch: {
    stage_url: function(oldValue, newValue) {
      this.getdata_byStage_url();
      this.bulkIds={};

    }
  },
  created() {
    this.getdata_byStage_url();
  },
  mounted() {},
  methods: {
    /**
     * Append dynamic append key to item_url
     */
    async get_blocks_from_stages() {
      for (var i = 0; i < this.stages.length; i++) {
        var compiledUrl = this.stage_url;

        if (this.stages[i][this.stage_append_key])
        {
          var compiledUrl = this.item_url.replace(
            /\${.*}/,
            this.stages[i][this.stage_append_key]
          );
          var compiledUrl2 = (this.data_count_url)?this.data_count_url.replace(
            /\${.*}/,
            this.stages[i][this.stage_append_key]
          ):'';
        }
        else
        {
          var compiledUrl = this.item_url.replace(
            /=%27\${(.*)}%27/,
            " is null "
          );
          var compiledUrl2 = (this.data_count_url)?this.data_count_url.replace(
            /=%27\${(.*)}%27/,
            " is null "
          ):'';
        }
        
        this.stages[i].itemUrl = compiledUrl;
        this.stages[i].data_count_url = compiledUrl2;
        this.stages = [...this.stages];

        // Change key to force rerender
        this.render_count++;

        console.log("get_blocks_from_stages ", this.stages);
      }
    },

    /**
     * Triggers when a user starts dragging an element or text
     */
    on_drag_start: function(
      event,
      itemIndexBfrDrop,
      stageIndexBfrDrop,
      itemObj
    ) {
      event.dataTransfer.dropEffect = "move";
      this.item_Index_befor_drop = itemIndexBfrDrop;
      this.stage_Index_befor_drop = stageIndexBfrDrop;
      this.dragging_obj = itemObj;
      this.from_stg_object = Object.assign({}, this.stages[stageIndexBfrDrop]);
    },

    /**
     *  Triggers on being dragged over a valid drop target
     */
    on_drag_over: function(event) {
      event.preventDefault();
    },

    /**
     *  Triggers after drag and drop
     * @param {Object} event -Event
     * @param {Number} stageIndexAftrDrop -Index of new Stage
     */
    on_drop: function(event, stageIndexAftrDrop) {
      this.stage_Index_after_drop = stageIndexAftrDrop;

      if (!this.no_drop) this.on_confirm_drop();

      this.to_stg_object = Object.assign({}, this.stages[stageIndexAftrDrop]);

      var emitObj = {
        fromStageObj: Object.assign({}, this.from_stg_object),
        toStageObj: Object.assign({}, this.to_stg_object),
        dragObj: Object.assign({}, this.dragging_obj)
      };

      console.log(emitObj);
      this.$emit("emitDrop", emitObj);
    },

    /**
     * After Drop is confirmed
     */
    on_confirm_drop: function() {
      this.$refs.acCollections[this.stage_Index_after_drop].actual_data.unshift(
        Object.assign({}, this.dragging_obj)
      );
      this.$refs.acCollections[this.stage_Index_befor_drop].actual_data.splice(
        this.item_Index_befor_drop,
        1
      );

      this.stages = [...this.stages];
    },

    /**
     * Checkbox checked
     * @param {Object} evnt -event
     * @param {Object} data - Data
     * @param {String} stgOrBlock - Specifes whather stage on item
     * @param {Number} stgIndex - Stage index of clicled item
     */

    bulk_chkbox_chng: function(evnt, data, stgOrBlock, stgIndex) {
      if (
        stgIndex != -1 &&
        !Array.isArray(
          this.bulkIds[this.limitedStages[stgIndex][this.stage_append_key]]
        )
      )
        this.bulkIds[this.limitedStages[stgIndex][this.stage_append_key]] = [];

      if (stgIndex != -1 && stgOrBlock == "B") {
        if (evnt)
          this.bulkIds[
            this.limitedStages[stgIndex][this.stage_append_key]
          ].push(data[this.item_append_key]);
        else
          this.bulkIds[
            this.limitedStages[stgIndex][this.stage_append_key]
          ].splice(
            this.bulkIds[
              this.limitedStages[stgIndex][this.stage_append_key]
            ].findIndex(item => item == data[this.stage_append_key]),
            1
          );
        console.log(
          this.bulkIds[this.limitedStages[stgIndex][this.stage_append_key]]
            .length,
          this.$refs.acCollections[stgIndex].actual_data.length
        );
        if (
          this.bulkIds[this.limitedStages[stgIndex][this.stage_append_key]]
            .length == this.$refs.acCollections[stgIndex].actual_data.length
        )
          document.querySelector(
            `#checkboxs${
              this.limitedStages[stgIndex][this.stage_append_key]
            }${stgIndex}`
          ).checked = true;
        else
          document.querySelector(
            `#checkboxs${
              this.limitedStages[stgIndex][this.stage_append_key]
            }${stgIndex}`
          ).checked = false;
      } else if (stgIndex != -1 && stgOrBlock == "S") {
        let blcksData = this.$refs.acCollections[stgIndex].actual_data || [];
        if (blcksData.length) {
          blcksData.forEach((item, index) => {
            if (evnt) {
              document.querySelector(
                `#checkboxb${
                  this.limitedStages[stgIndex][this.stage_append_key]
                }${index}`
              ).checked = true;
              this.bulkIds[
                this.limitedStages[stgIndex][this.stage_append_key]
              ].push(item[this.item_append_key]);
              console.log(this.bulkIds);
            } else {
              document.querySelector(
                `#checkboxb${
                  this.limitedStages[stgIndex][this.stage_append_key]
                }${index}`
              ).checked = false;
              this.bulkIds[
                this.limitedStages[stgIndex][this.stage_append_key]
              ].splice(
                this.bulkIds[
                  this.limitedStages[stgIndex][this.stage_append_key]
                ].findIndex(e => e == item[this.item_append_key]),
                1
              );
            }
          });
        }
      }

      this.bulkIds[this.limitedStages[stgIndex][this.stage_append_key]] = [
        ...new Set(
          this.bulkIds[this.limitedStages[stgIndex][this.stage_append_key]]
        )
      ];

      this.$emit("bulkChkboxChange", data);
    },

    /**
     * @return {Number} -Index of stage
     * @param {Number} id -Value of stage
     */
    get_stgIndex_byId: function(id) {
      return this.stages.findIndex(item => item[this.stage_append_key] == id);
    },

    /**
     * Get Stage data from stage_url
     */
    getdata_byStage_url: function() {
      this.bulkIds={};
      if (this.stage_url) {
        fetch(this.stage_url, { })
          .then(res => res.json())
          .then(res => {
            // if (res.length) {
            this.stages = res;
            console.log("get from stage url ", this.stages);
            this.get_blocks_from_stages();
            this.display_view_portStgs();
            // }
          })
          .catch(err => {});
      }
    },

    /**
     * Set number of stages to display
     */
    display_view_portStgs: function() {
      this.leftlength = 0;
      this.right_length = 4;
      this.limitedStages = [];
      // this.limitedStages = this.stages.slice(this.leftlength, this.right_length);
      this.limitedStages = this.stages;
      this.has_empty_state_slot(this.stages);
    },

    /**
     * Triggers on click of right arrow
     * Displays new stage to right if exists
     */
    arrow_right() {
      this.leftlength = this.leftlength + 1;
      this.right_length = this.right_length + 1;
      this.limitedStages = this.stages.slice(this.leftlength, this.right_length);
    },

    /**
     * Triggers on click of left arraow
     * Displays new stage to left if exists
     */
    arrow_left() {
      this.leftlength = this.leftlength - 1;
      this.right_length = this.right_length - 1;
      this.limitedStages = this.stages.slice(this.leftlength, this.right_length);
    },

    /**
     * Append dynamic class based on data
     * @param {Array} data -Stages data
     */
    has_empty_state_slot(data) {
      if (!data.length) {
        this.main_class = "d-flex m-0 custom-kb-view w-100";
        return !!this.$slots.empty_state;
      } else {
        this.main_class = "d-flex m-0 custom-kb-view";
      }
      console.log("main calss is -- ", this.main_class);
    }
  }
};
</script>

<style scoped>
.disabled-arrows {
  pointer-events: none;
  color: "#fff";
}
</style>

