<template>
  <div class="position-relative">
    <button class="btn pr-3" :title="title">
      <a class="btn text-secondary p-0" @click="showModal">
        <i :class="icon"></i>
      </a>
    </button>
    <template>
      <span class="badge bg-warning text-white notification-count position-absolute">{{ac_data.length}}</span>
    </template>
    <b-modal ref="modalRef" :title="title" hide-footer>
      <div v-show="ac_data.length" class="ac-notifications">
        <ac-collection
          ref="ac_collection"
          :url="url"
          :ac_cursor="{}"
          :params="{}"
          method="GET"
          loading_image
          sort_key
          @success="on_success_load"
        >
          <template #header>
            <div class="ac-notifications__header p-2 d-flex">
              <!-- <h6>{{ title }}</h6> -->
              <a
                v-show="show_mark_all_btn"
                href="javascript:;"
                class="ml-auto"
                @click="mark_all_as_read"
              >{{ mark_all_btn_label }}</a>
            </div>
          </template>

          <template #body="{ data }">
            <div class="ac-notifications__body">
              <slot name="custom" :data="data">
                <b-card
                  v-for="(item, index) in data"
                  :key="index"
                  :class="{'bg-light': !item[read_key]}"
                  class="ac-notifications__item"
                  @click="on_item_click(index)"
                >
                  <!-- @slot body - slot for each notification body -->
                  <slot name="body" :item="item"></slot>
                </b-card>
              </slot>
            </div>
          </template>

          <template #footer>
            <footer v-show="show_see_all_btn" class="text-center p-2 mt-2">
              <a :href="see_all_btn_link">{{ see_all_btn_label }}</a>
            </footer>
          </template>
        </ac-collection>
      </div>
      <div v-if="!ac_data.length">
        <p>No Notifications</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import acCollection from "../ac-collection.vue";

/**
 * ac notifications component
 * @requires VueBootstrap
 * @requires ac-collection.vue
 */

export default {
  name: "ac-notifications",

  components: {
    acCollection
  },

  props: {
    /**
     * @param {string} title - notifications title.
     * @default 'Notifications'
     */
    title: {
      type: String,
      required: false,
      default: "Notifications"
    },

    /**
     * @param {array} data - list of notifications.
     */
    data: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @param {string} url - Endpoint to get data from.
     */
    url: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Call handler when preload page
     * @param {string} preload
     * @label Preload
     */
    preload: {
      type: String,
      required: false
    },
    /**
     * Give params to be send to handler
     * @param {string} handler_params
     * @label Handler Params
     */
    handler_params: {
      type: String,
      required: false
    },
    /**
     * @param {boolean} show_mark_all_btn - indicates when mark all button is shown.
     * @default false
     */
    show_mark_all_btn: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @param {String} mark_all_btn_label - mark all button text.
     * @default 'Mark all as Read'
     */
    mark_all_btn_label: {
      type: String,
      required: false,
      default: "Mark all as Read"
    },

    /**
     * @param {String} mark_all_btn_url - Endpoint for sending mark all request.
     */
    mark_all_btn_url: {
      type: String,
      required: false
    },

    /**
     * @param {boolean} show_see_all_btn - indicates when see all link is shown.
     * @default false
     */
    show_see_all_btn: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @param {String} see_all_btn_label - see all link text.
     * @default 'See All'
     */
    see_all_btn_label: {
      type: String,
      required: false,
      default: "See All"
    },

    /**
     * @param {String} see_all_btn_link - see all link href.
     * @default '#'
     */
    see_all_btn_link: {
      type: String,
      required: false,
      default: "#"
    },

    /**
     * @param {string} read_key - key inside each item to indicate if item is already read.
     */
    read_key: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: "fal fa-bell align-middle fa-lg ripplebell"
    }
  },

  mounted() {
    if (this.data.length) {
      this.ac_data = this.data;
      this.$refs.ac_collection.set_data(this.data);
    } else if (this.url) {
      this.$refs.ac_collection.set_ajax();
    } else if (this.preload) {
      //Calling handler
      this.start(this.preload, {
        helper: {
          component: this,
          custom: this.handler_params ? JSON.parse(this.handler_params) : {}
        }
      });
    }
  },

  data() {
    return {
      ac_data: []
    };
  },

  methods: {
    /**
     * @function get_data - get list of current notifications
     * @returns {array} current notifications
     */
    get_data() {
      return [...this.ac_data];
    },

    /**
     * @function set_data - set list of current notifications
     * @param {array} new_data - new notifications
     */
    set_data(new_data) {
      this.ac_data = new_data;
      this.$refs.ac_collection.set_data(new_data);
    },

    /**
     * @function mark_all_as_read - marks all notifications as read
     * Also sends request to mark_all_btn_url endpoint
     */
    mark_all_as_read() {
      if (this.mark_all_btn_url) {
        fetch(this.mark_all_btn_url, {
          method: "POST",
          withCredentials: true,
          credentials: "include"
        }).then(response => {
          if (response.ok) {
            this.ac_data.forEach(el => {
              el[this.read_key] = true;
            });
            this.$refs.ac_collection.set_data(this.ac_data);
          }
        });
      }
    },

    /**
     * @function on_item_click - Single notification click handler
     * @prop {number} index - index of clicked notification
     */
    on_item_click(index) {
      this.ac_data[index][this.read_key] = true;
      this.$refs.ac_collection.set_data(this.ac_data);
      this.$emit("clicked", this.ac_data[index].id);
    },

    /**
     * @function on_success_load - load data handler
     */
    on_success_load(data) {
      this.ac_data = [...data];
    },
    showModal() {
      this.$refs.modalRef.show();
    }
  }
};
</script>
<style scoped>
.notification-count {
  bottom: 22px;
  left: 26px;
}
</style>