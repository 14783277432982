<template>
  <div>
    <div class="form-group col-md-12" v-for="(rule,rinx) in rules" :key="rinx">
      <ac-newsection-rule :rule="rule" ref="rules" :rule_index="rinx"></ac-newsection-rule>
    </div>
        <button class="btn btn-success float-right" @click="adding_rule">Add Rule</button>
  </div>
</template>
<script>
import acNewsectionRule from "./ac-newsection/ac-newsection-rule";
export default {
  components: {
    acNewsectionRule
  },
  name: "ac-newsection",
  props: {
    /**
     * Json Data for sections
     * @param {object} rule_json
     * @label Rule Json
     * @category_name 1_Data
     */
    rule_json: {
      type: Object
    },
    /**
     * Data to prefilled
     * @param {array} prefill_data
     * @label Prefill Data
     * @category_name 1_Data
     */
    prefill_data: {
      type: Array
    }
  },

  data() {
    return {
      rules: []
    };
  },

  created() {
    var groups = []; //Not used
    if (this.prefill_data.length) {
      var avail_data = JSON.parse(JSON.stringify(this.prefill_data));
      this.rules = [...avail_data];
    } else {
      this.adding_rule();
    }
  },

  mounted() {},

  methods: {
    /**
     * Invoke on click of add rule button
     * @function add_rule - To add new section
     */
    adding_rule() {
      console.log("this.rule_json"+this.rule_json);
      
      var rule_json = Object.assign({}, this.rule_json);
      var rules = JSON.parse(JSON.stringify(this.rules));
      this.rules = [...rules, rule_json];
    },
    /**
     * Invoke on click of close button
     * @function delete_rule - To delete the added section
     */
    delete_rule({ rule_index }) {
      this.rules.splice(rule_index, 1);
    },
    /**
     * To Get the selected controls
     * @function get_selected_data
     */
    get_selected_data() {
      
      let rules = this.$refs.rules || [];
      let section_outputs_list = [];
      rules.map((rule_item, rule_index) => {
        let new_obj = {};
        let controls = rule_item.rule.controls;
        if (controls && controls.length) {
          controls.map(item => {
            new_obj[item.name] = item.value;
          });
          section_outputs_list.push(new_obj);
        }
        return rule_item;
      });
      return section_outputs_list;
    }
  },
  
};
</script>
<style>
.header-circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: darkblue;
  color: white;
}
</style>