export default [
  {
    selector: 'node',
    style: {
      label: 'data(label)',
      padding: 0,
      shape: 'rectangle',
      'text-valign': 'center',
      'text-halign': 'right',
      'background-opacity': 0,
      'overlay-opacity': 0,
      'border-width': 0,
      color: '#ccc',
      'font-family': 'Consolas',
      'z-compound-depth': 'top',
      'z-index-compare': 'manual',
      events: 'no',
      'z-index': 'data(zIndex)',
    },
  },

  {
    selector: '.table',
    style: {
      'text-valign': 'top',
      'text-halign': 'center',
      'background-color': 'rgb(55, 55, 63)',
      'border-width': 3,
      'border-opacity': 0.2,
      'background-opacity': 1,
      events: 'yes',
    },
  },

  {
    selector: '.comment',
    style: {
      padding: '30px',
      height: 'label',
      width: '300px',
      'text-wrap': 'wrap',
      'text-max-width': '300px',
      'text-margin-x': '-320px',
      'text-justification': 'left',
      'z-index': '999999',
      events: 'yes',
    },
  },

  {
    selector: '.table:selected, .comment:selected',
    style: {
      'border-width': '5px',
      'border-color': '#fcba03',
      'border-opacity': 1,
    },
  },

  {
    selector: '.column',
    style: {
      'min-width-bias-right': '100%',
    },
  },

  {
    selector: '.column-name',
    style: {
      'text-wrap': 'wrap',
      'text-max-width': '200px',
      'text-overflow-wrap': 'anywhere',
      'text-justification': 'left',
      'text-background-padding': '15px',
      padding: '5px',
      width: '1px',
    },
  },

  {
    selector: '.column-type',
    style: {
      'text-background-padding': '15px',
      'text-transform': 'uppercase',
      padding: '5px',
      width: '1px',
      color: '#fcba03',
    },
  },

  {
    selector: '.column-constraint',
    style: {
      'text-background-padding': '15px',
      padding: '5px',
      width: '1px',
    },
  },

  {
    selector: '.column-key',
    style: {
      'text-background-padding': '15px',
      padding: '5px',
      width: '1px',
    },
  },

  {
    selector: 'edge',
    style: {
      'target-distance-from-node': '3px',
      'source-distance-from-node': '3px',
      'curve-style': 'taxi',
      'taxi-direction': 'horizontal',
      'taxi-turn': 20,
      'taxi-turn-min-distance': 5,
      'line-color': '#ccc',
      'target-arrow-color': '#ccc',
      'target-arrow-shape': 'triangle',
      'z-compound-depth': 'top',
      'z-index-compare': 'manual',
      'z-index': '0',
      width: 3,
    },
  },

  {
    selector: 'edge:selected',
    style: {
      'target-arrow-color': '#fcba03',
      'line-color': '#fcba03',
      'z-index': '999999',
      events: 'no',
      width: 3,
    },
  },

  {
    selector: 'edge.editable-edge',
    style: {
      'target-distance-from-node': '0px',
      'source-distance-from-node': '0px',
    },
  },

  {
    selector: '.eh-handle',
    style: {
      'background-opacity': 1,
      'background-color': '#41d1cc',
      width: 15,
      height: 15,
      shape: 'ellipse',
      'overlay-opacity': 0,
      'border-width': 15,
      'border-opacity': 0,
      visibility: 'data(visibility)',
      'z-compound-depth': 'top',
      'z-index': 100,
      events: 'yes',
    },
  },

  {
    selector: '.delete',
    style: {
      width: '20px',
      height: '20px',
      'background-opacity': 1,
      'background-image': 'data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20enable-background%3D%22new%200%200%20256%20256%22%20height%3D%2215px%22%20id%3D%22Layer_1%22%20version%3D%221.1%22%20viewBox%3D%220%200%20256%20256%22%20width%3D%2215px%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20d%3D%22M137.051%2C128l75.475-75.475c2.5-2.5%2C2.5-6.551%2C0-9.051s-6.551-2.5-9.051%2C0L128%2C118.949L52.525%2C43.475%20%20c-2.5-2.5-6.551-2.5-9.051%2C0s-2.5%2C6.551%2C0%2C9.051L118.949%2C128l-75.475%2C75.475c-2.5%2C2.5-2.5%2C6.551%2C0%2C9.051%20%20c1.25%2C1.25%2C2.888%2C1.875%2C4.525%2C1.875s3.275-0.625%2C4.525-1.875L128%2C137.051l75.475%2C75.475c1.25%2C1.25%2C2.888%2C1.875%2C4.525%2C1.875%20%20s3.275-0.625%2C4.525-1.875c2.5-2.5%2C2.5-6.551%2C0-9.051L137.051%2C128z%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E',
      shape: 'ellipse',
      'border-width': 20,
      'border-opacity': 0,
      'z-index': '999999',
      events: 'yes',
    },
  },

  {
    selector: '.table-delete',
    style: {
      'z-index': '999998',
    },
  },

  {
    selector: '[fontColor]',
    style: {
      color: 'data(fontColor)',
    },
  },
]
