<template>
  <gantt-elastic :tasks="tasks" :options="options">
    <gantt-elastic-header slot="header" v-if="show_toolbar"></gantt-elastic-header>
  </gantt-elastic>
</template>

<script>
/**
 * ac gantt chart component
 * @requires gantt-elastic - Forked from https://github.com/neuronetio/gantt-elastic
 */
import ganttElastic from "gantt-elastic";
import ganttElasticHeader from "gantt-elastic-header";

export default {
  name: "ac-gantt-chart",

  components: {
    ganttElasticHeader,
    ganttElastic
  },

  props: {
    /**
     * Array of tasks.
     * @param {array} tasks 
     * @properties {
     * "id": {"type": "Number"},
     * "label": {"type": "String"},
     * "user": {"type": "String"},
     * "parentId": {"type": "Number"},
     * "start": {"type": "String"},
     * "duration": {"type": "Number"},
     * "progress": {"type": "Number"},
     * "type": {"type": "String"},
     * "collapsed": {"type": "Boolean"},
     * "style": {"type": "Object"}
     * }
     * @label Tasks
     * @category_name 1_General
     */

    tasks: {
      type: Array,
      required: true
    },

    /**
     * Objects containing configuration for the tasks.
     * @param {object} options 
     * @properties {
     * "maxRows" : {"type": "Number"},
     * "maxHeight" : {"type": "Number"},
     * "row" : {"type": "Object"},
     * "calendar" : {"type": "Object"},
     * "chart" : {"type": "Object"},
     * "taskList" : {"type": "Object"}
     * }
     * @label Options
     * @category_name 1_General
     */

    options: {
      type: Object,
      required: true
    },

    /**
     * Make it true to show toolbar for gantt chart
     * @param {boolean} show_toolbar 
     * @label show_toolbar
     * @category_name 3_Advanced
     */
    show_toolbar: {
      type: Boolean
    },

    /**
     * Custom title in toolbar for gantt chart 
     * @param {string} title 
     * @label title
     * @category_name 3_Advanced
     */
    toolbar_title: {
      type: String
    }
  },
  mounted() {
    // over-write custom title from wrapper
    this.$el.getElementsByClassName(
      "gantt-elastic__header-title--text"
    )[0].innerText = this.toolbar_title ? this.toolbar_title : " ";
  }
};
</script>
