<template>
  <div
    :class="{ 'is-vertical': $scopedSlots.header }"
    class="ac-wizard"
  >
    <header
      v-if="$scopedSlots.header"
      class="ac-wizard__header"
    >
      <slot
        name="header"
        :steps="ac_steps"
      />
    </header>

    <header
      v-if="!$scopedSlots.header"
      class="ac-wizard__header--default"
    >
      <div
        v-for="(step, index) in steps"
        :key="index"
        :class="{'active': step.is_current }"
        class="item"
      >
        <div v-html="step.html" />
        <h5>{{ step.title }}</h5>
        <h6>{{ step.description }}</h6>
      </div>
    </header>

    <main class="ac-wizard__body">
      <!-- <slot
        v-if="current_slot"
        :name="current_slot"
      /> -->

      <slot v-if="current_slot == 'step_1'" name="step_1"></slot>
      <slot v-if="current_slot == 'step_2'" name="step_2"></slot>
      <slot v-if="current_slot == 'step_3'" name="step_3"></slot>

      <div class="ac-wizard__footer d-flex justify-content-between mt-2">
        <b-button
          :disabled="current_step === 0"
          :class="previous_btn_class"
          @click="on_back_click"
        >
          {{ previous_btn_label }}
        </b-button>

        <b-button
          v-show="current_step !== ac_steps.length - 1"
          :class="next_btn_class"
          @click="on_next_click"
        >
          {{ next_btn_label }}
        </b-button>

        <b-button
          v-show="current_step === ac_steps.length - 1"
          :class="finish_btn_class"
          @click="on_finish_click"
        >
          {{ finish_btn_label }}
        </b-button>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'ac-wizard',

  props: {
    steps: {
      type: Array,
      required: true,
    },

    previous_btn_label: {
      type: String,
      required: false,
      default: 'Previous',
    },

    previous_btn_class: {
      type: String,
      required: false,
      default: '',
    },

    next_btn_label: {
      type: String,
      required: false,
      default: 'Next',
    },

    next_btn_class: {
      type: String,
      required: false,
      default: '',
    },

    finish_btn_label: {
      type: String,
      required: false,
      default: 'Finish',
    },

    finish_btn_class: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      current_step: 0,
      ac_steps: [],
      current_slot: null,
    }
  },

  created() {
    this.ac_steps = this.steps.map((el, index) => {
      const is_current = index === this.current_step
      if (!el.id) {
        el.id = new Date().getTime() * Math.random()
      }

      el.is_current = is_current

      if (is_current) {
        this.current_slot = el.block
      }

      return el
    })
  },

  methods: {
    on_next_click() {
      // if (!this.validate_step(this.current_step)) {
      //   return
      // }

      this.$emit('completed-step', this.ac_steps[this.current_step])
      this.current_step += 1
      this.change_current_step()
    },

    on_back_click() {
      this.current_step -= 1
      this.change_current_step()
      this.$emit('clicked-back')
    },

    on_finish_click() {
      this.$emit('stepper-finished')
    },

    validate_step(step_index) {
      const { validator } = this.ac_steps[step_index]

      if (!validator) return true

      return validator()
    },

    change_current_step() {
      this.ac_steps = this.ac_steps.map((el, index) => {
        const is_current = index === this.current_step
        el.is_current = is_current
        if (is_current) {
          this.current_slot = el.block
        }
        return el
      })
    },

    go_next() {
      this.on_next_click()
    },

    go_previous() {
      this.on_back_click()
    },

    get_active_step() {
      return this.ac_steps[this.current_step]
    },
  },
}
</script>

<style scoped>
.ac-wizard.is-vertical {
  display: flex;
}

.ac-wizard__body {
  flex: auto;
}

.ac-wizard__header--default {
  display: flex;
  justify-content: space-around;
}

.item {
  opacity: .6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item.active {
  opacity: 1;
}
</style>
