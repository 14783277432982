<template>
  <div>
    <li
      class="list-group-item d-flex justify-content-between align-items-center px-2 text-nowrap"
      v-for="(data,index) in form_json"
      :key="index"
    >
      <span class="text-muted">{{data.label}}</span>
      <div>
        <div class="input-group mr-3 inline-edit-forms">
          <!--If input type is text field-->
          <template v-if="data.type == 'text'">
            <p
              class="mb-0"
              @click="on_txt_click(data)"
              v-if="data.display"
            >{{data.value ? data.value : 'Enter'}}</p>
            <form
              v-if="!data.display"
              :data-vv-scope="'inlineform'+index"
              @submit.prevent="on_key_enter(data,`inlineform${index}`,$event,index)"
            >
              <input
                type="text"
                :placeholder="data.placeholder"
                :name="data.label"
                :data-vv-as="data.label"
                class="form-control border-0 text-right"
                v-validate="data.validateObj"
                v-model="data.value"
                style="font-size:12px;"
                v-autofocus
                @blur="blur_control(index)"
              />
              <p
                v-show="errors.has(`inlineform${index}.${data.label}`)"
                class="error validations-text text-danger"
              >{{errors.first(`inlineform${index}.${data.label}`) }}</p>
            </form>
          </template>
          <!--If input type is dropdown field-->
          <template v-if="data.type == 'select'">
            <p
              class="mb-0"
              @click="on_txt_click(data)"
              v-if="data.display"
            >{{data.textDisplay}}</p>
            <select
              class="form-control"
              v-if="!data.display"
              v-model="data.value"
              @change="on_select_change(data,index)"
              @blur="blur_control(index)"
              v-autofocus
            >
              <option
                v-for="(option,index) in data.options"
                :value="option[data.keyvalue]"
                :key="index"
              >{{option[data.keylabel]}}</option>
            </select>
            <!-- <b-form-select v-if="!data.display" v-model="data.value" :options="data.options" @change="on_select_change(data,index)" @blur="blur_control(index)" v-autofocus></b-form-select> -->
            <!-- <b-form-select v-if="!data.display" v-model="data.value" class="mb-3" @change="on_select_change(data,index)" @blur="blur_control(index)" v-autofocus>
                            <option v-for="(option,index) in data.options" :value="option.value" :key="index">{{option.text}}</option>                          
            </b-form-select>-->
          </template>
          <!--If input type is date field-->
          <template v-if="data.type == 'date'">
            <p
              class="mb-0"
              @click="on_txt_click(data)"
              v-if="data.display"
            >{{data.value ? data.value : 'Enter'}}</p>
            <input
              v-if="!data.display"
              type="Date"
              :name="data.label"
              class="form-control border-0 text-right"
              v-validate="'required'"
              v-model="data.value"
              @blur="on_date_change(data,index)"
              style="font-size:12px;"
              v-autofocus
            />
          </template>
          <!--If input type is gravatar field-->
          <template v-if="data.type == 'gravatar'">
            <ac-gravatar :avatar_props="data.config" :email="data.email" :name="data.name" />
          </template>
          <!--If input type is gravatar field-->
          <template v-if="data.type == 'upload'">
            <appup-upload
              :name="data.name"
              :upload_type="data.upload_type"
              v-model="data.value"
              @after_upload="on_image_change(data,index)"
              :uploadConfig="data.uploadConfig"
            />
          </template>
        </div>
      </div>
    </li>
  </div>
</template>
<script>
import AppPreload from "./mixins/ac-preload";

/**
 * @requires ac-preload.js
 * @inherits ac-preload
 */

export default {
  name: "ac-inline-form-edit",
  mixins: [AppPreload],
  props: {
    /**
     * form_json prop will receive the array of objects which can iterate the field controls
     * @param {string} form_json
     * @label Form JSON
     * @category_name 1_General
     */
    form_json: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      emit_object: {}
    };
  },
  created() {
    this.set_urls_data();
  },
  methods: {
    /**
     * @function on_key_enter - Pressing enter key on each control with type of text, this function will be invoked
     */
    on_key_enter: function(data, scope, e, inx) {
      this.form_json[inx].display = true;
      this.$validator.validateAll(scope).then(res => {
        if (!res) return;
        let selectedData = data.value;
        this.emit_object = {};
        this.emit_object[data.name] = selectedData;
        this.$emit("updated_value", this.emit_object);
        // this.onEnter(this.form_json[inx]); // on pressing enter key in the control, we can emit the onEnter function
      });
    },
    /**
     * @function on_txt_click - To disable inline edit
     */
    on_txt_click: function(data) {
      if (data.isInlineEnable) data.display = false;
      else return;
    },
    /**
     * @function get_select_text - this will be invoked to display the text from the options of a dropdown as value to be sent to DB and text to be displayed
     */
    get_select_text: function(val, dropdownList) {
      if (dropdownList && dropdownList.length) {
        var selectedObj = dropdownList.find(item => item.value == val);
        if (selectedObj && selectedObj.value) return selectedObj.text;
        else return "Select";
      } else return "Select";
    },
    /**
     * @function on_select_change - For select data changes, this function will be invoked
     * @fires updated_value event
     */
    on_select_change: function(data, inx) {
      this.form_json[inx].display = true;
      var optionsList = data.options;
      for (var i = 0; i < optionsList.length; i++) {
        if (optionsList[i].value == this.form_json[inx].value) {
          this.form_json[inx].textDisplay = optionsList[i].text;
          break;
        }
      }
      let selectedData = data.value;
      this.emit_object = {};
      this.emit_object[data.name] = selectedData;
      this.$emit("updated_value", this.emit_object);
    },
    /**
     * @function on_date_change - For date changes, this function will be invoked
     * @fires updated_value event
     */
    on_date_change: function(data, inx) {
      this.form_json[inx].display = true;
      let selectedData = data.value;
      this.emit_object = {};
      this.emit_object[data.name] = selectedData;
      this.$emit("updated_value", this.emit_object);
    },
    /**
     * @function on_image_change - For image,file changes, this function will be invoked
     * @fires updated_value event
     */
    on_image_change: function(data,inx) {
      let selectedData = data.value;
      this.emit_object = {};
      this.emit_object[data.name] = selectedData;
      this.$emit("updated_value", this.emit_object);
    },
    /**
     * @function blur_control - To get focus
     * @fires updated_value event
     */
    blur_control: function(inx) {
      this.form_json[inx].display = true;
    },
    /**
     * @function set_urls_data - To get data from url and form dropdown options
     */
    async set_urls_data() {
      if (this.form_json && this.form_json.length) {
        for (let index = 0; index < this.form_json.length; index++) {
          if (
            this.form_json[index].type == "select" &&
            this.form_json[index].url
          ) {
            try {
              let dropdownList = await fetch(this.form_json[index].url, {
                withCredentials: true,
                credentials: "include"
              }).then(res => res.json());
              if (dropdownList && dropdownList.length) {
                this.form_json[index].options = dropdownList;
              }
            } catch (e) {
              this.form_json[index].options = [];
            }
          }
        }
      }
    }
  },

  /* It helps to set focus for the input is displayed */
  directives: {
    autofocus: {
      inserted(el) {
        el.focus();
      }
    }
  }
};
</script>
<style scoped>
:focus,
focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.inline-edit-forms input {
  line-height: 1 !important;
  padding: 0 !important;
  height: auto !important;
}
.inline-edit-forms select {
  line-height: 1 !important;
  padding: 0 !important;
  height: auto !important;
}
</style>