<template>
  <div>
    <div>
      <table class="table table-responsive d-md-table border">
        <thead class="bg-light">
          <tr>
            <th scope="col" class="border-bottom-0 text-nowrap" v-if="checkbox">
              <b-form-checkbox v-model="allSelected" @change="toggleAll" />
            </th>
            <th scope="col" class="border-bottom-0 text-nowrap text-capitalize" v-if="number" :serialno="serialno"  >{{serialno}}</th>
            <th v-for="(col,index) in columns" :key="index" class="border-bottom-0 text-nowrap text-capitalize">{{col}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row,index) in rows" :key="index">
            <td scope="col" class="align-middle" v-if="checkbox">
              <b-form-checkbox v-model="selected"/>
            </td>
            <td v-if="number">{{index+1}}
              </td>
            <td v-for="(col,index) in columns" :key="index">{{row[col]}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ac-collection-tableone",
  props: {
    /**
     * @param {Array} rows - rows data
     * @label Rows
     */
    rows: {
      type: Array,
      required: true
    },
    /**
     * @param {Boolean} chekbox - checkox required
     * @label Checkbox
     */
    checkbox: {
      type: Boolean,
      required: true
    },
    /**
     * @param {Boolean} number - number required
     * @label Number
     */
    number: {
      type:Boolean,
      required:true
    },
  },
  data() {
    return {
      selected: [],
      allSelected: false,
      serialno:"sl no"
    };
  },
  methods: {
    toggleAll(checked) {
      this.selected = checked ? this.rows.slice() : [];
    }
  },
  computed: {
    columns: function columns() {
      if (this.rows.length == 0) {
        return [];
      }
      return Object.keys(this.rows[0]);
    }
  }
};
</script>

<style>
</style>
