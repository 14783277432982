<template>
  <div  v-if="isrow" :class="rowclass + ' ' + 'm-0'">
      <div  :class="colclass" v-for="(values,index) in datacard" :key="index">
        <div class="card h-100">
          <div class="card-body">
            <div class="appup-card-collection">
              <div class="mb-4 pb-4">
                <div>
                  <h5 class="card-title">
                    {{values.name}}
                    <span
                      class="ml-2 cursor-pointer"
                    >
                      <i :class="values.icon + ' ' + 'text-muted'"></i>
                    </span>
                  </h5>
                  <h6 class="card-subtitle small mb-2 text-muted">({{values.time}})</h6>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center pb-2">
                <span>
                  <h4 class="mb-0">{{values.time1}}</h4>
                </span>
                <div>
                  <div class="mb-0 mt-2">
                    <h4 class="d-inline">{{values.pec}}</h4>
                    <span>
                      <i class="fal fa-angle-up mr-2 text-success"></i>
                    </span>
                    <span class="small text-muted">
                     {{values.time2}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="progress" style="height: 10px;">
                <div
                  role="progressbar"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :class="values.color + ' '+ 'progress-bar' "
                  :style="{width: values.width}"
                ></div>
              </div>
              <!---->
              <!---->
              <!---->
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name:"ac-my-simple-card",
    props: {
      /**
       * @param {string} rowclass - provide the row class for card
       */
      rowclass:{
        type:String,
        required:true
      },
      /**
       * @param {string} colclass - provide the card column class
       */
      colclass:{
        type:String,
        required:true
      },
        /**
       * @param {Boolean} isrow - provide the card column class
       */
      isrow:{
        type:Boolean
      },
         /**
     * @param {Array} datacard - Array of objects. Each object will be rendered as title
     *@properties {"name": {"type": "String"}, "icon": {"type": "String"}, "time": {"type": "String"}, "time1": {"type": "String"}, "pec": {"type": "String"}, "time2": {"type": "String"}, "color": {"type": "String"}, "width": {"type": "String"}  }
     */
        datacard:{
            type:Array
    }

    }
};
</script>

<style>
</style>
