var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.is_popup)?_c('b-modal',{ref:"filesModal",attrs:{"title":"Files","size":"lg","hide-footer":""},on:{"hidden":_vm.popup_hidden}},[_c('ac-tab',{attrs:{"data":_vm.tab_data,"align":_vm.align,"tabs_attrs":{ card: true, pills: true }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var data = ref.data;
return [_vm._v(_vm._s(data.label))]}},{key:"gallery_tab",fn:function(ref){
var data = ref.data;
return [(data.tab_content.type)?_c(data.tab_content.type,_vm._b({tag:"component",staticClass:"w-100",on:{"input":function($event){return _vm.emit_input_event($event)},"choose":function($event){return _vm.emit_choose_event($event)}}},'component',data.tab_content.props,false)):_c('b-row',_vm._l((data.tab_content),function(item,index){return _c('b-col',{key:index,staticClass:"mb-2",attrs:{"md":"2"}},[_c('b-card',{staticClass:"text-center cursor-pointer p-1",attrs:{"no-body":""},on:{"click":function($event){return _vm.grid_click(item)}}},[_c('div',{staticClass:"img-container"},[_c('b-card-img',{attrs:{"src":item.thumbnail,"alt":item.label}})],1),_c('b-card-text',[_vm._v(_vm._s(item.label))])],1)],1)}),1),(!data.tab_content.hide_button)?[_c('hr'),_c('b-row',{staticClass:"justify-content-end mx-0"},[_c('b-button',{on:{"click":_vm.add_click}},[_vm._v("Add")])],1)]:_vm._e()]}}],null,false,2239355405)})],1):_c('div',[_c('h4',[_vm._v("Files")]),_c('ac-tab',{attrs:{"data":_vm.tab_data,"align":_vm.align,"tabs_attrs":{ card: true, pills: true }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var data = ref.data;
return [_vm._v(_vm._s(data.label))]}},{key:"gallery_tab",fn:function(ref){
var data = ref.data;
return [(data.tab_content.type)?_c(data.tab_content.type,_vm._b({tag:"component",staticClass:"w-100",on:{"input":function($event){return _vm.emit_input_event($event)},"choose":function($event){return _vm.emit_choose_event($event)}}},'component',data.tab_content.props,false)):_c('b-row',_vm._l((data.tab_content),function(item,index){return _c('b-col',{key:index,staticClass:"mb-2",attrs:{"md":"2"}},[_c('b-card',{staticClass:"text-center cursor-pointer p-1",attrs:{"no-body":""},on:{"click":function($event){return _vm.grid_click(item)}}},[_c('div',{staticClass:"img-container"},[_c('b-card-img',{attrs:{"src":item.thumbnail,"alt":item.label}})],1),_c('b-card-text',[_vm._v(_vm._s(item.label))])],1)],1)}),1),(!data.tab_content.hide_button)?[_c('hr'),_c('b-row',{staticClass:"justify-content-end mx-0"},[_c('b-button',{on:{"click":_vm.add_click}},[_vm._v("Add")])],1)]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }