<template>
  <b-form-group>
    <b-form-checkbox v-bind="$attrs" v-on="$listeners">
        <slot />
      </b-form-checkbox>
    </b-form-group>
</template>

<script>

/**
   * ac Checkbox component
   * @requires VueBootstrap
   */

export default {
  name: 'ac-checkbox',
  model: {
    prop: 'checked',
    event: 'change',
  },
}
</script>
