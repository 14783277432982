import Vue from 'vue'
import vuescroll from 'vue-scroll'
import { BootstrapVue, BCarousel } from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import { Picker } from 'emoji-mart-vue'
import VueMoment from 'vue-moment'
import VueSlideoutPanel from 'vue2-slideout-panel'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import router from './router'
import FullCalendar from "vue-full-calendar";
import "fullcalendar/dist/fullcalendar.min.css";
import 'flatpickr/dist/flatpickr.css';
let _forEach = require('lodash.foreach');

Vue.use(vuescroll);
Vue.use(BootstrapVue)
Vue.use(VeeValidate)
Vue.use(VueMoment)
Vue.use(VueSlideoutPanel);
Vue.use(FullCalendar);

// Bootstrap components
Vue.component('ac-image-slider', BCarousel)

// Third party components direct registration
Vue.component('ac-emoji', Picker)

// Import components from Appup Components and register components globally
import * as Appup from '../components';
for (let compKey in Appup) {
  Vue.component(Appup[compKey].name, Appup[compKey])
  
  if(compKey === "AppupHttp"){
    Vue.use(Appup[compKey]);
  }
}

Vue.config.productionTip = false

// Register Components
import AppupControls from 'components'
_forEach(AppupControls, function (value, key) {
  console.log("Key " + key)
  Vue.component(key, value);
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
