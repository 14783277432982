<template>
  <div class="row">
    <div class="col-md-6">
      <appup-form :preload="preload_handler" :submit="submit_handler" v-model="form_data" :form-data="form_data" :workflow_params="workflow_params" />
    </div>
    <div class="col-md-6 form-bg">
      <slot name="form_preview" :data="form_preview_data">
        <template v-if="Object.keys(form_data).length">
          <div v-if="html" v-html="html"></div>
          <template v-if="form_preview_data.form_json.fields && !html">
            <div v-for="(key,index) in form_preview_data.form_json.fields" :key="index">
              <p
                v-if="form_preview_data.form_data.hasOwnProperty(key.name)"
              >{{key.label}} : {{form_preview_data.form_data[key.name]}}</p>
            </div>
          </template>
        </template>
        <template v-else>
          <slot name="empty_state"></slot>
        </template>
      </slot>
    </div>
  </div>
</template>
<script>
import Handlebars from "handlebars/dist/handlebars.js";

export default {
  name: "ac-form-preview",
  props: {
    /**
     * Fields data for form
     * @param {Array} fields
     * @label Fields
     * @category_name 2_Data
     */
    fields: {
      type: Array,
      required: true
    },
    /**
     * Buttons data for form
     * @param {Array} fields
     * @label Buttons
     * @category_name 2_Data
     */
    buttons: {
      type: Array,
      required: true
    },
    /**
     * Html template with dynamic data from form
     * @param {String} -template
     * @label Template
     * @category_name 2_Data
     */
    template: {
      type: String,
      required: true
    },
    /**
     * form Preload event
     * @param {Array} -preload
     * @label Preload
     * @category_name 2_Data
     */
    preload: {
      type: Array,
      required: false
    },
    /**
     * form Submit event
     * @param {Array} -submit
     * @label submit
     * @category_name 2_Data
     */
    submit: {
      type: Array,
      required: false
    },
    /**
     * @private
     */
    workflow_params:{
      type: Object,
      required: false
    }
  },
  data() {
    return {
      form_data: {},
      html: "",
      form_json: {
        fields: this.fields,
        buttons: this.buttons
      },
      form_preview_data: {
        form_data: null,
        form_json: null
      },
      preload_handler: null,
      submit_handler: null
    };
  },
  watch: {
    form_data: {
      handler(val) {
        if (this.template) {
          let compiled_template = Handlebars.compile(this.template);
          console.log("template is ", this.template);
          this.html = compiled_template(this.form_data);
          console.log("template is ", this.html);
        }
        this.form_preview_data.form_data = val;
      },
      deep: true
    },
    form_json(val) {
      this.form_preview_data.form_json = val;
    },
    fields(val) {
      this.form_json.fields = val;
    },
    buttons(val) {
      this.form_json.buttons = val;
    },
    submit(val) {
      this.submit_handler = JSON.stringify(val);
    },
    preload(val){
      this.preload_handler = JSON.stringify(val);
    }
  },
  created() {
    this.html = this.template;

    //giving builder to fields so we kept two different props for fields and buttons
    this.form_json.fields = this.fields;
    this.form_json.buttons = this.buttons;

    //To give datato scoped slot
    this.form_preview_data.form_data = this.form_data;
    this.form_preview_data.form_json = this.form_json;

    this.preload_handler = JSON.stringify(this.preload);
    this.submit_handler = JSON.stringify(this.submit);
  }
};
</script>
