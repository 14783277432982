<template>
  <div>
    <template>
      <div>
        <b-card no-body>
          <b-tabs v-model="tab_Index" card @input="is_tab_changed">
            <b-tab v-if="avail_tabs.trigger" title="Trigger" :disabled="!avail_tabs.trigger">
              <b-form-select
                v-model="finalAutomateJson.triggerOutput"
                @change="on_trigger_change(finalAutomateJson.triggerOutput)"
              >
                <option :value="null">Please select an option</option>
                <option
                  v-for="(triggerOption,index) in trigger_options"
                  :key="index"
                  :value="triggerOption.value"
                >{{triggerOption.text}}</option>
              </b-form-select>
              <!-- <appup-select :url="trigger_JSON.url"
                                :key_value="trigger_JSON.keyvalue ? trigger_JSON.keyvalue : null"
                                :key_label="trigger_JSON.keytext ? trigger_JSON.keytext : null"
              :options="trigger_JSON.options" v-model="finalAutomateJson.triggerOutput" />-->
            </b-tab>
            <b-tab
              v-if="avail_tabs.filter"
              title="Filter"
              :disabled="!(finalAutomateJson.triggerOutput && avail_tabs.filter)"
            >
              <ac-builder :rule-json="rule_JSON" :prefill-data="prefill_data" ref="WizardQueryRef"></ac-builder>
            </b-tab>
            <b-tab
              v-if="avail_tabs.action"
              title="Action"
              :disabled="!(finalAutomateJson.triggerOutput && avail_tabs.action)"
            >
              <appup-workflow
                ref="WizardwfRef"
                :src="workflow_JSON.src"
                :width="workflow_JSON.width"
                :height="workflow_JSON.height"
                :toolbar1="workflow_JSON.toolbar1"
                :toolbar2="workflow_JSON.toolbar2"
                :marketplace="workflow_JSON.marketplace"
                :class="workflow_JSON.class"
                v-model="finalAutomateJson.wfOutput"
              />
              <button @click="submit_automation" class="btn btn-primary">Submit</button>
            </b-tab>
          </b-tabs>
        </b-card>
        <div v-if="show_buttons">
          <!-- <b-button @click="tab_Index--">Previous</b-button> -->
          <b-button
            v-if="spButton && tab_Index != 0"
            variant="outline-secondary"
            class="mr-1"
            @click="previous_button('previous')"
          >Previous</b-button>
          <b-button
            v-if="snButton && tab_Index != tabsarr.length-1"
            variant="outline-secondary"
            @click="previous_button('next')"
          >Next</b-button>
          <!-- <b-button @click="tab_Index++">Next</b-button> -->
        </div>
        <!-- <b-modal ref="jsonDisplay" title="Query">
                    <pre>{{output | json}}</pre>
        </b-modal>-->
      </div>
    </template>
  </div>
</template>
<script>
import acBuilder from "./ac-automate-builder/ac-builder.vue";
export default {
  components: {
    acBuilder
  },
  name: "ac-wizard-builder",
  props: {
    /**
     * Make filter tab data.
     * @param {Object} rule_JSON
     * @label Rule Json
     * @category_name 2_Data
     */
    rule_JSON: {
      type: Object
    },
    /**
     * Prefill filter tab data.
     * @param {Array} prefill_data
     * @label Prefill Data
     * @category_name 2_Data
     */
    prefill_data: {
      type: Array
    },
    /**
     * Json for workflow designer
     * @param {Object} workflow_JSON
     * @label Workflow Json
     * @category_name 2_Data
     */
    workflow_JSON: {
      type: Object
    },
    /**
     * JSON Data for Trigger tab
     * @param {Object} trigger_JSON
     * @label Trigger JSON
     * @category_name 2_Data
     */
    trigger_JSON: {
      type: Object
    },
    /**
     * It shows tab titles
     * @param {Object} avail_tabs
     * @label Avail Tabs Obj
     * @category_name 3_Advanced
     */
    avail_tabs: {
      type: Object
    },
    /**
     * If it is true, shows next and previous buttons - default is true
     * @param {Object} show_buttons
     * @label Show Buttons
     * @category_name 3_Advanced
     */
    show_buttons: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tab_Index: 0,
      isWfdone: true,
      trigger_options: [],
      finalAutomateJson: {
        rulesOutput: [],
        wfOutput: "",
        triggerOutput: null
      },
      output: {},
      disable: true,
      spButton: false,
      snButton: true,
      tabsarr: []
    };
  },
  mounted() {
    if (this.avail_tabs) {
      for (var key in this.avail_tabs) {
        if (this.avail_tabs[key]) {
          this.tabsarr.push(1);
        }
      }
    }
  },
  created() {
    if (this.trigger_JSON.url) {
      fetch(this.trigger_JSON.url, {
        withCredentials: true,
        credentials: "include"
      })
        .then(res => res.json())
        .then(res => {
          if (res.length) {
            this.trigger_options = res.map(obj => {
              let newObj = {};
              newObj.text = obj[this.trigger_JSON.keytext];
              newObj.value = obj[this.trigger_JSON.keyvalue];
              if (obj.url) newObj.url = obj.url;
              if (obj.options && obj.options.length)
                newObj.options = obj.options;
              return newObj;
            });
          }
        })
        .catch(err => {});
    } else {
      this.trigger_options = JSON.parse(
        JSON.stringify(this.trigger_JSON.options)
      );
    }
  },
  methods: {
    /**
     * Triggers on change of triiger value
     * @param {(string|number)} triggerVal -Value of trigger
     */
    on_trigger_change: function(triggerVal) {
      this.$refs.WizardQueryRef.groups = [];
      if (this.trigger_options.length) {
        var selectdTriggrObj = this.trigger_options.find(
          item => item.value == triggerVal
        );
        console.log(selectdTriggrObj);
        if (selectdTriggrObj.url) {
          this.$refs.WizardQueryRef.groups = [];
          this.$refs.WizardQueryRef.rule_JSON.url = selectdTriggrObj.url;
        } else if (
          selectdTriggrObj.options &&
          selectdTriggrObj.options.length
        ) {
          this.$refs.WizardQueryRef.rule_JSON.keys.options =
            selectdTriggrObj.options;
        }
      }
      // It fires when select the filter tab data
      this.$emit("on_trigger_change", { triggerVal });
    },

    /**
     * Triggers when tab changed
     * @param {Number} tab -Index of selected tab
     */
    is_tab_changed: function(tab) {
      console.log(tab);
      if (tab == 2) {
        this.finalAutomateJson["rulesOutput"] =
          this.$refs.WizardQueryRef.get_query() || [];
      }
    },

    /**
     * Method not used
     */
    get_query: function(data) {
      alert("in query method");
      this.finalAutomateJson["rulesOutput"] = data;
    },

    /**
     * Triggers on click of submit button emits output_JSON
     */
    submit_automation: async function() {
      var automatnObj = Object.assign({}, this.finalAutomateJson);
      try {
        let wizardResolved = await this.$refs.WizardwfRef.save().then(() => {});
        automatnObj["wfOutput"] = this.$refs.WizardwfRef.get_json();
        console.log(automatnObj);
        //this.$refs.jsonDisplay.show();
        this.output = JSON.stringify(automatnObj);
        // It fires when click on submit button in action tab, to get Final output
        this.$emit("output_JSON", automatnObj);
      } catch (e) {}
    },
    /**
     * Triggers on click of previous and next buttons
     * Enables or disables them
     */
    previous_button(bStatus) {
      if (bStatus === "previous") {
        this.tab_Index--;
        if (this.tab_Index === 0) {
          this.spButton = false;
        }
        this.snButton = true;
      } else {
        if (this.finalAutomateJson.triggerOutput) {
          this.tab_Index++;
          if (this.tab_Index === this.tabsarr.length - 1) {
            this.snButton = false;
          }
          this.spButton = true;
        }
      }
    }
  }
};
</script>
<style>
.header-circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: darkblue;
  color: white;
}
</style>