<template>
  <div>
    <b-list-group v-if="group_items">
      <template v-for="(item,index) in group_items">
        <b-list-group-item
          :href="item.href"
          :key="index"
          :class="active_class_fun(item,index)?'active':''"
          @click="make_active(item)"
        >
          {{item.label}}
          <slot :name="item.key" :data="item"></slot>
        </b-list-group-item>
      </template>
    </b-list-group>
  </div>
</template>
<script>
import axios from "axios";
import AppPreload from "./mixins/ac-preload";

/**
 * @requires ac-preload.js
 * @inherits ac-preload
 */

export default {
  name: "ac-list-group",
  mixins: [AppPreload],
  components: {},
  props: {
    /**
     * @param {array} group_items - list of group_items.
     * @properties {"label": {"type": "String"},"key": {"type": "String"},"href": {"type": "String"},"__slot__": {"type": "String"}}
     * @label Group Items
     * @category_name 2_Data
     */
    group_items: {
      type: Array,
      required: false
    },
    /**
     * Fetch data with url
     * @label URL
     * @category_name 1_General
     */
    url: {
      type: String,
      required: false
    }
    // /**
    //  * Give params to be send to handler
    //  * @param {string} handler_params
    //  * @label Handler Params
    //  * @category_name 1_General
    //  */
    // handler_params: {
    //   type: String,
    //   required: false
    // },
    // /**
    //  * Onload handler call
    //  * @param {string} preload
    //  * @label Preload
    //  */
    // preload: {
    //   type: String,
    //   required: false
    // }
  },
  data: function() {
    return {
      active_class: "",
      active_label: "",
      custom: ""
    };
  },

  watch: {
    /**
     * To keep track with url and group_items from user
     */
    url(newval, oldval) {
      this.url = newval;
      this.fetch_data();
    },
    group_items(newval, oldval) {
      this.group_items = newval;
      this.active_label = this.group_items[0].label;
    }
  },

  created() {
    /**
     * Fetch data from url
     */
    if (this.url) {
      this.fetch_data();
    }
    // /**
    //  * Execute preload handler with handler params
    //  */
    // if (this.preload) {
    //   if (this.handler_params) {
    //     try {
    //       this.custom = JSON.parse(this.handler_params);
    //     } catch (e) {
    //       // handle error
    //       this.custom = this.handler_params;
    //     }
    //   } else {
    //     this.custom = {};
    //   }

    //   //Calling handler
    //   this.start(this.preload, {
    //     helper: {
    //       component: this,
    //       custom: this.custom
    //     }
    //   });
    // }
    /**
     * Store initial label to make active on load
     */
    if (this.group_items && this.group_items.length) {
      this.active_label = this.group_items[0]["label"];
    }
  },

  methods: {
    /**
     * get data from url using axios
     */
    fetch_data() {
      if (this.url) {
        var _this = this;
        this.$appupajax.get2(_this.url, {}, {}, {withCredentials: true, credentials: "include" }).
      //  this.$appupajax.get2(_this.url, {}, { withCredentials: true, credentials: "include" }).
                    then(this.$appupajax.handleResponse).then(response => {
            _this.group_items = response.data;
          })
          .catch(function(error) {
            // handle error
            console.log(error);
          })
          .finally(function() {
            // always executed
          });
      } else {
        this.group_items = this.group_items;
      }
    },
    /**
     * Making label name active
     * @param {Object} item - selected list item data
     * @param {Integer} index - index of list item
     * @return {Boolean} - True or False
     */
    active_class_fun(item, index) {
      if (this.active_label == item.label) return true;
      else return false;
    },
    /**
     * Assigning label name to be active
     * @param {Object} item - selected list item data
     */
    make_active(item) {
      this.active_label = item.label;
      //Fires click event
      this.$emit("click", item);
    }
  }
};
</script>
<style>
</style>