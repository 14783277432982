<template>
	<b-form-group v-bind="$attrs">
		<multiselect v-bind="$attrs" v-on="$listeners" :multiple="true" :taggable="true" track-by="name" label="name" />
	</b-form-group>
</template>

<script>

	/**
	 * ac Multiselect component
	 * @requires VueMultiselect
 	 */
  import Multiselect  from 'vue-multiselect'

	export default {
    name: 'ac-multiselect',

    components: {
      Multiselect
    },
	}
</script>
