<template>
  <div>
    <b-tooltip v-if="data_tooltip_text" target="ac-date-range-picker-tooltip">
      {{data_tooltip_text}}
    </b-tooltip>

    <date-range-picker
      :opens="opens"
      @update="update_value"
      @toggle="toggle"
      :date-range="value"
      :min-date="min_date"
      :max-date="max_date"
      :single-date-picker="data_single_date_picker"
      :time-picker="data_time_picker"
      :time-picker-increment="time_picker_increment"
      :time-picker24-hour="data_time_picker_24_hour"
      :time-picker-seconds="data_time_picker_seconds"
      :show-week-numbers="data_show_week_numbers"
      :show-dropdowns="data_show_dropdowns"
      :auto-apply="data_auto_apply"
      :ranges="show_ranges ? (ranges ? ranges:undefined) : show_ranges"
    >
      <!-- Use this slot for input field format-->
      <div
        id="ac-date-range-picker-tooltip"
        slot="input"
        slot-scope="picker"
        style="min-width: 207px;"
      >{{ picker.startDate | date }} - {{ picker.endDate | date }}</div>
    </date-range-picker>
  </div>
</template>

<script>
/**
 * ac date range picker component
 * @requires vue2-daterange-picker - Forked from https://github.com/Innologica/vue2-daterange-picker
 */
import dateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

// Import moment for date conversion
import moment from "moment";

export default {
  name: "ac-date-range-picker",

  components: {
    dateRangePicker
  },

  data() {
    return {
      data_value: this.value,
      data_auto_apply: this.auto_apply,
      data_show_dropdowns: this.data_show_dropdowns,
      data_time_picker: this.data_time_picker,
      data_time_picker_seconds: this.time_picker_seconds,
      data_time_picker_24_hour: this.data_time_picker_24_hour,
      data_single_date_picker: this.single_date_picker,
      data_show_week_numbers: this.data_show_week_numbers,
      data_tooltip_text: this.tooltip_text
    };
  },

  filters: {
    date(value) {
      let options = { year: "numeric", month: "short", day: "numeric" };
      return Intl.DateTimeFormat("en-US", options).format(value);
    }
  },

  // As it is a wrapper keeping the props same as the wrapper
  props: {
    /**
     * Tooltip text for component
     * @param {String} tooltip_text
     * @label tooltip_text
     * @category_name 3_Advanced
     */
    tooltip_text: {
      type: String,
      default: null
    },

    /**
     * which way the picker opens - center, left or right default center
     * @param {String} opens
     * @label opens
     * @category_name 3_Advanced
     */
    opens: {
      type: String,
      default: "center"
    },

    /**
     * minimum date allowed to be selected
     * @param {String} min_date
     * @label min_date
     * @category_name 1_General
     */
    min_date: {
      type: String
    },

    /**
     * maximum date allowed to be selected
     * @param {String} max_date
     * @label max_date
     * @category_name 1_General
     */
    max_date: {
      type: String
    },

    /**
     * v-model prop
     * @param {Object} value
     * @label value
     * @category_name 1_General
     */
    value: {
      type: Object,
      required: true
    },

    /**
     * Allows you to select only one date (instead of range). This will hide the ranges with different start/end
     * @param {Boolean} single_date_picker
     * @label single_date_picker
     * @category_name 3_Advanced
     */
    single_date_picker: {
      type: [Boolean, String]
    },

    /**
     * Show the dropdowns for time (hour/minute) selection below the calendars
     * @param {Boolean} time_picker
     * @label time_picker
     * @category_name 3_Advanced
     */
    time_picker: {
      type: [Boolean, String],
      default: false
    },

    /**
     * Use 24h format for the time
     * @param {Boolean} time_picker_24_hour
     * @category_name 3_Advanced
     */
    time_picker_24_hour: {
      type: [Boolean, String],
      default: true
    },

    /**
     * Determines the increment of minutes in the minute dropdown. Default 5
     * @param {Number} time_picker_increment
     * @label time_picker_increment
     * @category_name 3_Advanced
     */
    time_picker_increment: {
      type: Number,
      default: 5
    },

    /**
     * Allows you to select seconds except hour/minute. Default false
     * @param {Boolean} time_picker_seconds
     * @label time_picker_seconds
     * @category_name 3_Advanced
     */
    time_picker_seconds: {
      type: [Boolean, String],
      default: false
    },

    /**
     * Show the ranges on the left side of the calendar
     * @param {Boolean} show_ranges
     * @label show_ranges
     * @category_name 1_General
     */
    show_ranges: {
      type: [Boolean, String],
      default: true
    },
    /**
     * Show the week numbers on the left side of the calendar
     * @param {Boolean} show_week_numbers
     * @label show_week_numbers
     * @category_name 3_Advanced
     */
    show_week_numbers: {
      type: [Boolean, String]
    },
    /**
     * Show the dropdowns for month and year selection above the calendars.
     * @param {Boolean} show_dropdowns
     * @label show_dropdowns
     * @category_name 3_Advanced
     */
    show_dropdowns: {
      type: [Boolean, String]
    },
    /**
     * Auto apply selected range. If false you need to click an apply button
     * @param {Boolean} auto_apply
     * @label auto_apply
     * @category_name 3_Advanced
     */
    auto_apply: {
      type: [Boolean, String]
    },
    /**
     * You can set this to false in order to hide the ranges selection. Otherwise it is an object with key/value.
     * @param {Boolean} ranges
     * @label ranges
     * @category_name 3_Advanced
     */
    ranges: {
      type: [Boolean, Object]
    },

    /**
     * Format of date
     * @param {String} date_format
     * @label date_format
     * @category_name 3_Advanced
     */
    date_format: {
      type: String
    }
  },

  beforeMount() {
    // In case of form prop is of string type, as it is causing component to not render. Parsing props
    this.convert_string_props_into_boolean();
  },

  methods: {
    /**
     * provides the updated date range value.
     * @function update_value - emits the updated values
     * @param values
     */
    update_value(values) {
      // Check for date_format prop, if exists do date transformation using moment
      if (this.date_format) {
        this.data_value = Object.assign({
          startDate: moment(values.startDate).format(this.date_format),
          endDate: moment(values.endDate).format(this.date_format)
        });
      } else {
        this.data_value = Object.assign({
          startDate: values.startDate,
          endDate: values.endDate
        });
      }
      // Update v-model by emiting
      this.$emit("input", this.data_value);
    },

    /**
     * fires when date range is opened or close.
     * @function toggle
     * @public
     */
    toggle() {
      // In case of form prop is of string type, as it is causing component to not render. Parsing props
      this.convert_string_props_into_boolean();
    },

    /**
     * Handles the props conversion from string to boolean
     * @function convert_string_props_into_boolean
     * @public
     */
    convert_string_props_into_boolean() {
      // Generate array for all props name to be converted
      let props = [
        "auto_apply",
        "show_dropdowns",
        "time_picker",
        "time_picker_seconds",
        "time_picker_24_hour",
        "single_date_picker",
        "show_week_numbers"
      ];

      // Loop through all props and call conversion method
      for (let i = 0; i < props.length; i++) {
        this[`data_${props[i]}`] = this.convert_string_to_boolean(
          this[props[i]]
        );
      }
    },

    /**
     * Converts given string param into boolean
     * @function convert_string_to_boolean
     * @param string_prop
     */
    convert_string_to_boolean(string_prop) {
      // If string is true return boolean true and boolean false for fail condition
      if (string_prop == "true") {
        return true;
      }
      return false;
    }
  }
};
</script>
<style>
.daterangepicker.opensleft {
  left: 0 !important;
  right: inherit !important;
}
.daterangepicker.opensright {
  left: inherit !important;
  right: 0 !important;
}
.daterangepicker.openscenter {
  left: inherit !important;
  right: inherit !important;
}
.daterangepicker.opensleft:before {
  right: inherit !important;
  left: 16px !important;
}
.daterangepicker.opensleft:after {
  right: inherit !important;
  left: 17px !important;
}
.daterangepicker.opensright:before {
  right: 16px !important;
  left: inherit !important;
}
.daterangepicker.opensright:after {
  left: inherit !important;
  right: 17px !important;
}
.daterangepicker.openscenter:before {
  left: 40%;
  transform: translateX(-40%);
}
.daterangepicker.openscenter:after {
  left: 40%;
  transform: translateX(-44%);
}
</style>