<template>
  <div class="ac-collection-grouped-card">
    <!-- loader image -->
    <div v-show="is_loading" class="loader">
      <!--onError is to handle the broken image of loader -->
      <img :src="loading_image" class="loader__image" :onError="onerror_handle_loader" alt="loader" />
    </div>

    <header class="ac__header">
      <!-- Use this slot for header -->
      <slot name="header"></slot>
    </header>
    <main class="ac__body container-fluid">
      <component
        :is="dropdown_element"
        :data="add_reference(data)"
        :tabs_attrs="tabs_attrs"
        :align="get_tabs_align()"
      >
        <template #title="{ data }">
          <div class="group-header" @click="$emit('group-header-click', data)">
            <!-- Use this slot group_header for each item in response -->
            <slot name="group_header" :groupItem="data.data[0]"></slot>
          </div>
        </template>
        <div
          v-for="(value, key, index_data) in data"
          :key="index_data"
          :slot="key"
          slot-scope="card_data"
          class="p-2"
        >
          <div class="ac__item__body m-0">
            <b-row>
              <b-col
                v-for="(card, index) in card_data.data.data"
                :key="index"
                v-bind="col_options"
                @dragover.stop="on_drag_over"
                @drop.stop="on_drop($event,index,key,card_data.data.data)"
                :draggable="enable_drag"
                @dragstart.stop="on_drag_start($event,index,card)"
              >
                <!-- Rendering data using simple-card component -->
                <ac-simple-card
                  ref="ac_simple_card"
                  :data="card"
                  :img="card[card_img] || ''"
                  :card_title="card_title"
                  :card_subtitle="card_subtitle"
                  :card_header="card_header"
                  :header_slot="show_header"
                  :card_text="card_body"
                  :bulk="bulk"
                  :card_options="card_options"
                  :footer_slot="show_footer"
                  :dropdown_options="dropdown_options"
                  :dropdown_button_options="dropdown_button_options"
                  :show_dropdown_icons="show_dropdown_icons"
                  @input="on_card_select"
                  @delete="$emit('delete', $event)"
                  @click="$emit('click', $event)"
                  @hover="$emit('hover', $event)"
                >
                  <template #hover>
                    <div class="hover-div" v-html="card_img_hover" />
                  </template>

                  <template #footer>{{ card[card_footer] }}</template>
                </ac-simple-card>
              </b-col>
            </b-row>
          </div>
          <div class="group-footer">
            <!-- Use this slot group_footer for each item in response -->
            <slot name="group_footer"></slot>
          </div>
        </div>
      </component>
    </main>
    <div v-if="checkfor_empty_state">
      <!-- Use this slot to display something if no data available -->
      <slot name="empty_state"></slot>
    </div>
    <div
      v-show="load_more_type === 'loadMoreButton'"
      ref="loader_div"
      class="load-more text-center mt-2"
    >
      <!-- Slot for load more buttom, shown only if load_more_type is 'loadMoreButton' -->
      <slot name="load_more_button"></slot>
    </div>

    <footer class="ac__footer">
      <!-- Use this slot for footer -->
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
import acCollectionCard from "../ac-collection-card.vue";
import acSimpleCard from "../ac-simple-card.vue";
import acAccordion from "../ac-accordion.vue";
import acTab from "../ac-tab.vue";

/**
 * ac collection grouped card component
 * @requires VueBootstrap
 * @requires ac-collection-card.vue
 * @requires ac-simple-card.vue
 * @requires ac-accordion.vue
 * @requires ac-tab.vue
 * @inherits ac-collection-card,ac-preload
 */

export default {
  name: "ac-collection-grouped-card",

  components: {
    acSimpleCard,
    acAccordion,
    acTab
  },

  extends: acCollectionCard,

  props: {
    /**
     * Give key name from the data for grouping the data
     * @param {string} group_key - Data from every request will be grouped with this key
     * Required
     * @label Group Key
     * @category_name 1_General
     */
    group_key: {
      type: String,
      required: true
    },
    
     /**
     * Whenever true enables the drag and drop functionality
     * @param {boolean} enable_drag
     * @label Enable Drag
     * @category_name 1_General
     */
     enable_drag: {
      type: Boolean,
      default: false
    },

    /**
     * If true displays card header
     * @param {Boolean} show_header
     * @label Show Header
     * Required
     * @category_name 1_General
     */
    show_header: {
      type: Boolean,
      required: false
    },
    /**
     * If true displays card footer
     * @param {Boolean} show_footer
     * @label Show Footer
     * Required
     * @category_name 1_General
     */
    show_footer: {
      type: Boolean,
      required: false
    },
    /**
     * Tab style to be display Eg: horizontal-tab,vertical-tab,accordion,simple-label
     * Required
     * @label Ac Type
     * @category_name 4_Style
     */
    ac_type: {
      type: String,
      required: true,
      validator: value =>
        ["horizontal-tab", "vertical-tab", "accordion","simple-label"].indexOf(value) > -1
    },

    /**
     * Write attributes for tabs as object
     * @param {object} ac_type - Attributes for tabs
     * @properties {"card": {"type": "Boolean"}, "pills": {"type": "Boolean"}}
     * @label Tabs Attribute
     * @category_name 4_Style
     */
    tabs_attrs: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      drag_from_index: "",
      dragged_ids:{}
    };
  },
  computed: {
    /**
     * return accordion or tab component based on ac_type
     * @return {String} - component type
     */
    dropdown_element() {

      return this.ac_type === "accordion" ? "ac-accordion" : (this.ac_type === "simple-label")? "ac-simple-label":"ac-tab";
      //return "ac-simple-label";
    },
    /**
     * To display empty state slot on condition
     * @return {Boolean} - True or False
     */

    checkfor_empty_state() {
      return this.data && !Object.keys(this.data).length;
    },
    /**
     * To handle the broken image of loader
     * @return {String} - Loading image
     */
    onerror_handle_loader() {
      if (this.loading_image) {
        return "this.onerror=null;this.src='" + this.loading_image + "';";
      } else
        return "this.onerror=null;this.src='https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif';";
    }
  },
  watch: {
    /**
     * Forcefully update the DOM
     */
    show() {
      this.show = true;
    }
  },

  methods: {
    /**
     * Executes on drag start and stores index in variable
     * @param {string|number} event - daragged item value
     * @param {number} index - dragged item index
     * @param {card} card - dragged item data
     */
    on_drag_start(event, index, card) {
      this.drag_from_index = index;
      this.dragged_ids.target_id = card.id;
      //this.$emit('on-drag',card);
    },
    /**
     * Executes over drag
     * @param {string|number} event - daragged item value
     */
    on_drag_over(event) {
      event.preventDefault();
    },
    /**
     * Executes on dragged item dropped
     * @param {string|number} event - daragged over item value
     * @param {number} index - dragged over item index
     * @param {number} index_data - data of grouped index
     */
    on_drop(event, index, index_data,card) {

       if(this.drag_from_index!==0 && index !== 0 && index+1 !== Object.keys(card).length){
             this.dragged_ids.to_id=card[index].id;
             this.dragged_ids.from_id=card[index-1].id;
       } else if(this.drag_from_index==0 && index !== 0 && index+1 !== Object.keys(card).length){
             this.dragged_ids.from_id=card[index].id;
             this.dragged_ids.to_id=card[index+1].id;
       } else if(index == 0){
             this.dragged_ids.to_id = 0;
             this.dragged_ids.from_id = card[index].id;
       } else if (index+1 === Object.keys(card).length){
             this.dragged_ids.to_id = card[index].id;
             this.dragged_ids.from_id = 0;
       }

      let tempObj = this.data[index_data][this.drag_from_index];

      this.data[index_data].splice(this.drag_from_index, 1);

      this.data[index_data].splice(index, 0, tempObj);

      this.show=false;

      this.$emit('on-drag',event,this.dragged_ids);
    },
    /**
     * Triggers on card checkbox change
     * @param {string|number} event - checkbox value
     */
    on_card_select(event) {
      const index = this.selected.indexOf(event);
      if (index === -1) {
        this.selected.push(event);
      } else {
        this.selected.splice(this.selected.indexOf(event), 1);
      }
    },

    /**
     * Add reference for tab and accordion
     * @param {object} obj - data
     */
    add_reference(obj) {
      return Object.keys(obj).map(key => ({
        ref: key,
        data: obj[key]
      }));
    },

    /**
     * Get tabs alignment
     * @param {String} - vertical or horizontal
     */
    get_tabs_align() {
      return this.ac_type === "vertical-tab" ? "vertical" : "horizontal";
    }
  }
};
</script>
