<template>
  <div>
    <header class="ac__header my-3" v-if="header_slot">
      <!-- Use this slot for header -->
      <slot name="header"></slot>
    </header>

    <div role="tablist" class="ac-accordion">
      <!-- Tab will be created for each object in data prop -->
      <b-card no-body class="mb-1" v-for="(tab, index) in data" :key="index">
        <b-card-header
          header-tag="header"
          class="p-1"
          role="tab"
          v-b-toggle="`accordion${tab.ref}`"
        >
          <!--  Use this slot for title -->
          <slot name="title" :data="tab"></slot>
        </b-card-header>

        <b-collapse
          :id="`accordion${tab.ref}`"
          accordion="my-accordion"
          role="tabpanel"
          class="p-2"
        >
          <!--  Slot takes name from 'ref' key inside data -->
          <slot :name="tab.ref" :data="tab"></slot>
        </b-collapse>
      </b-card>
    </div>

    <footer class="ac__footer my-3" v-if="footer_slot">
      <!--  Use this slot for footer -->
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
/**
 * ac Accordion component
 * @requires VueBootstrap
 */

export default {
  name: "ac-accordion",

  props: {
    /**
     * Array of objects. Each object will be rendered as tab
     * @param {object} data
     * @label data
     * @properties {"label": {"type": "String"}, "body": {"type": "String"}, "ref": {"type": "String"}}
     * @category_name 1_General
     */
    data: {
      type: Array,
      required: true
    },

    /**
     * Whether want to show header slot or not. Default false
     * @label Header Slot
     * @category_name 3_Advanced
     */
    header_slot: {
      type: Boolean,
      default: false
    },

    /**
     * Array of objects. Each object will be rendered as tab. Default false
     * @label Footer Slot
     * @category_name 3_Advanced
     */
    footer_slot: {
      type:Boolean,
      default: false
    }
  }
};
</script>
