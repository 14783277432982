<template>
  <div class="row m-0 vh-100">
    <!-- Start of vertical_menu_items conditional display on prop- show_vertical_menu -->
    <aside class="col-auto px-0 bg-dark" v-if="show_vertical_menu">
      <nav
        class="navbar navbar-expand navbar-dark bg-dark flex-md-column flex-row align-items-center p-0"
      >
        <div class="collapse navbar-collapse text-center">
          <ul class="flex-md-column flex-row navbar-nav w-100 justify-content-between">
            <!-- Start list for vertical_menu_items -->
            <li
              class="nav-item"
              v-for="(vertical_menu_item,vertical_menu_index) in vertical_menu_items"
              :key="vertical_menu_index"
            >
              <!-- Render vertical_menu_item  -->
              <a
                class="nav-link p-2 text-nowrap btn btn-dark"
                :class="{'active':vertical_menu_item.active}"
                :href="vertical_menu_item.href"
              >
                <!-- Bind class for vertical_menu_item -->
                <i :class="vertical_menu_item.icon" class="fa-2x"></i>
                <!-- End of class binding  -->
              </a>
              <!-- End of vertical_menu_item render -->
            </li>
            <!-- End list for vertical_menu_items -->

            <!-- Slot for vertical_menu_items -->
            <li class="nav-item">
              <!-- Use this slot for logo in vertical_menu_items -->
              <slot name="logo"></slot>
            </li>
          </ul>
        </div>
      </nav>
    </aside>
    <!-- End of vertical_menu_items -->

    <!-- Start of vertical-bar -->
    <aside class="w-250 p-0" :class="vertical_bar_class">
      <nav class="navbar navbar-expand-lg navbar-dark p-0 pb-2">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <ul class="navbar-nav mr-auto d-md-inline w-100">
            <li class="nav-item mb-0">
              <!-- Use this slot for header in vertical-bar -->
              <slot name="header"></slot>
            </li>
            <li>
              <!-- Use this slot for search bar in vertical-bar  -->
              <slot name="search"></slot>
            </li>
            <div class="custom-sidebar-scroll">
              <div class="scrollbar-content">
                <ul class="navbar-nav mr-auto d-md-inline">
                  <!-- Render each category in categories prop, NOTE: rendering order will be same as in categories prop -->
                  <template v-for="(category,category_index) in categories">
                    <!-- Check category name exists in sorted data_items -->
                    <template v-if="category.name in data_items">
                      <li class="pb-3" :key="category_index">
                        <template>
                          <!-- Start of category render -->
                          <div
                            class="cursor-pointer px-3 d-flex justify-content-between align-items-center"
                          >
                            <!-- Render category name -->
                            <div class="text-white-50 p-0 py-1" @click="category_click(category)">{{category.name}}</div>

                            <!-- Event when any category is clicked -->
                            <div @click="category_click(category)">
                              <i class="nav-link pr-0" :class="category.icon"></i>
                            </div>
                          </div>
                          <!-- End of category render -->

                          <!-- Render each item from particular category -->
                          <div>
                            <ul class="navbar-nav mr-auto d-md-inline">
                              <!-- Get each item from sorted data_item -->
                              <li
                                class="nav-item"
                                v-for="(item,item_index) in data_items[category.name]"
                                :key="item_index"
                                :id="item.id"
                              >
                                <!-- Start ac-vertical-bar-item to display each item in list -->
                                <div
                                  class="nav-link cursor-pointer px-3 py-0 d-flex align-items-center justify-content-between"
                                  :class="[item.active ? item_active_class:'',item.mute? item_mute_class:'']"
                                  @mouseover="hover_in(item.id)"
                                  @mouseleave="hover_out()"
                                >
                                  <div
                                    class="d-flex align-items-center w-100"
                                    @click="item_click(item)"
                                  >
                                    <!-- @slot Use this slot for status -->
                                    <slot name="status" :item="item"></slot>
                                    <!-- Render item name -->
                                    <div class="p-0 py-1 ml-2" :key="item.name">{{item.name}}</div>
                                  </div>

                                  <!-- Show unread of greater than 0 and override hover css -->
                                  <div v-if="item.unread > 0" :key="item.unread">
                                    <!-- @slot Use this slot for unread -->
                                    <slot name="unread" :count="item.unread"></slot>
                                  </div>
                                  <div v-else>
                                    <div v-if="item.id == item_hover_id">
                                      <template v-if="item.category == 'Favorites'">
                                        <!-- @slot Use this slot for favorite -->
                                        <slot name="favorite" :item="item"></slot>
                                      </template>
                                      <template v-else>
                                        <!-- @slot Use this slot for non_favorite -->
                                        <slot name="non_favorite" :item="item"></slot>
                                      </template>
                                    </div>
                                  </div>
                                </div>
                                <!-- End of ac-vertical-bar-item -->
                              </li>
                              <!-- End of displaying each item -->
                            </ul>
                          </div>
                          <!-- End of rendering items from each category -->
                        </template>
                      </li>
                    </template>
                    <!-- End of render category -->
                  </template>
                  <!-- End of category loop -->
                </ul>
              </div>
            </div>

            <!-- Footer slot-->
            <li class="nav-item mb-0">
              <!-- Use this slot for footer -->
              <slot name="footer"></slot>
            </li>
          </ul>
        </b-collapse>
      </nav>
    </aside>
    <!-- End of vertical-bar -->
  </div>
</template>

<script>
// To perform group actions in array
let group_by = require("lodash.groupby");

export default {
  name: "ac-vertical-bar",

  props: {
    /**
     * Preload handler execute
     */
    preload: {
      type: String,
      default: null
    },

    /**
     * Handler params for preload
     * @param {string} - handler_params
     * @category_name 1_General
     */
    handler_params: {
      type: String,
      required: false
    },

    /**
     * If true, vertical-bar will have vertical-menu which includes different workspace. By default it is false.
     * @param {boolean} show_vertical_menu
     * @label show_vertical_menu
     * @category_name 3_Advanced
     */
    show_vertical_menu: {
      type: Boolean,
      default: false
    },

    /**
     * Array of categories to be present in vertical-bar, the order here will be considered while rendering.
     * @param {object} categories
     * @properties {"name": {"type": "String"}, "icon": {"type": "String"}}
     * @label Categories
     * @category_name 1_General
     */
    categories: {
      type: Array
    },

    /**
     * Array of items to be present in vertical-bar.
     * @param {object} items
     * @properties {"id": {"type": "String"}, "name": {"type": "String"}, "private": {"type": "Boolean"},  "category": {"type": "String"}, "status": {"type": "String"}, "unread": {"type": "Number"},"active": {"type": "Boolean"}, "mute": {"type": "Boolean"}}
     * @label items
     * @category_name 1_General
     */
    items: {
      type: Array
    },

    /**
     * Array of items to be present in vertical-menu
     * @param {object} vertical_menu_items
     * @properties {"id": {"type": "String"}, "active": {"type": "Boolean"},  "icon": {"type": "String"}}
     * @label vertical_menu_items
     * @category_name 2_Data
     */
    vertical_menu_items: {
      type: Array
    },

    /**
     * Name of class for vertical_bar. Default bg-primary
     * @param {string} vertical_bar_class
     * @label vertical_bar_class
     * @category_name 4_Style
     */
    vertical_bar_class: {
      type: String,
      default: "bg-primary"
    },

    /**
     * Name of class to render on vertical-bar item when it is active. Default bg-dark
     * @param {string} item_active_class
     * @label item_active_class
     * @category_name 4_Style
     */
    item_active_class: {
      type: String,
      default: "bg-dark"
    },

    /**
     * Name of class to render on vertical-bar item when it is mute. Default text-muted
     * @param {string} item_mute_class
     * @label item_mute_class
     * @category_name 4_Style
     */
    item_mute_class: {
      type: String,
      default: "text-muted"
    }
  },

  data() {
    return {
      // Need another temp object for storing the grouped items for vertical-bar
      data_items: {},
      // Need id to bind each item to specific list item for hover.
      item_hover_id: null
    };
  },

  watch: {
    // Need to have deep watcher for items
    items: {
      handler: function() {
        // Group again if prop json changes
        this.data_items = group_by(this.items, "category");

        // Sort on mute key
        for (let i = 0; i < Object.keys(this.data_items).length; i++) {
          this.data_items[Object.keys(this.data_items)[i]].sort(function(x, y) {
            return x.mute - y.mute;
          });
        }
      },
      deep: true
    }
  },

  mounted() {
    // Execute appup handler workflow
    if (this.preload && this.start) {
      setTimeout(() => {
        this.start(this.preload, {
          helper: {
            component: this,
            custom: this.handler_params ? JSON.parse(this.handler_params) : {}
          }
        });
      }, 300);
    } 
      // Assign grouped array to data_items
      this.data_items = group_by(this.items, "category");

      // Sort on mute key
      for (let i = 0; i < Object.keys(this.data_items).length; i++) {
        this.data_items[Object.keys(this.data_items)[i]].sort(function(x, y) {
          return x.mute - y.mute;
        });
      }
  },

  methods: {
    // fires when user is hovered in list
    hover_in(id) {
      this.item_hover_id = id;
    },

    // fires when user is hovered out from list
    hover_out() {
      this.item_hover_id = null;
    },

    // Emits item object when clicked - coming from item component
    item_click(item) {
      // Emit item object
      this.$emit("item_click", item);
    },

    // Update active key
    apply_active_class(list, obj) {
      // Update active key with match obj
      list.forEach(item => {
        if (item.id == obj.id) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },

    // To refresh content i.e JSON array manually
    refresh_content() {
      // Assign grouped array to data prop
      this.data_items = group_by(this.items, "category");

      // Sort on mute key
      for (let i = 0; i < Object.keys(this.data_items).length; i++) {
        this.data_items[Object.keys(this.data_items)[i]].sort(function(x, y) {
          return x.mute - y.mute;
        });
      }
    },

    apply_active_class_by_id(id) {
      // Update active key with match obj
      this.items.forEach(item => {
        if (item.id == id) {
          item.active = true;
        } else {
          item.active = false;
        }
      });

      // Since Watchers not getting triggered, again grouped and sorted
      // Grouping
      this.data_items = group_by(this.items, "category");

      // Sort on mute key
      for (let i = 0; i < Object.keys(this.data_items).length; i++) {
        this.data_items[Object.keys(this.data_items)[i]].sort(function(x, y) {
          return x.mute - y.mute;
        });
      }
    },

    // Emits category when clicked
    category_click(c) {
      this.$emit("category_click", c);
    }
  }
};
</script>

<style>
.w-250 {
  width: 250px;
}
.cursor-pointer {
  cursor: pointer;
}
.custom-sidebar-scroll {
  overflow-y: auto;
  height: calc(100vh - 148px);
  visibility: hidden;
}
.scrollbar-content,
.custom-sidebar-scroll:hover,
.custom-sidebar-scroll:focus {
  visibility: visible;
}
</style>
