<template>
  <div>
    <span v-if="!editable" @dblclick="setFocus()">{{content}}</span>
    <input
      v-if="editable"
      v-model="content"
      @input="handleInput"
      @keyup.enter="editable = false"
      ref="inputField"
      :type="type"
      @blur="removeFocus()"
    >
  </div>
</template>

<script>
/** Inline edit component which will trigger edit functionality when double clicked on content, supports v-model
 */
export default {
  name: "ac-inline-edit",
  data() {
    return {
      content: "",
      editable: false
    };
  },
  props: {
      /**
     * @param {String} value - for v-model support default prop is value
     * @label Value
     */
    value: {
      type: String
    },
     /**
     * @param {String} type - type of the field
     * Default : text
     * @label Type
     */
    type: {
      type: String,
      default: "text"
    }
  },
  mounted(){
    this.content= this.value;
  },
  methods: {
    // Need to emit since we are providing v-model support
    handleInput(e) {
      this.$emit("input", this.content);
    },
    // Make content editable inside input tag
    setFocus() {
      this.editable = true;
      
      this.$nextTick(() => this.$refs.inputField.focus());
    },
    // 
    removeFocus() {
      this.editable = false;
    }
  }
};
</script>