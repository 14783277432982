<template v-if="rule">
  <div class="row">
    <b-button-group v-if="!onlyRoot">
      <b-button
        :variant="ruleObj.condition == 'And' ? 'primary':'light'"
        @click="ruleObj.condition = 'And'"
      >And</b-button>
      <b-button
        :variant="ruleObj.condition == 'Or' ? 'primary':'light'"
        @click="ruleObj.condition = 'Or'"
      >Or</b-button>
    </b-button-group>
    <div class="col-md-3">
      <b-form-select
        v-model="ruleObj.selectedKey"
        :options="rule.keys.options"
        @input="setSelectedName('selectedNameKey', rule.keys.options, $event)"
        @change="dynamicAddInput(ruleObj.selectedKey)"
      ></b-form-select>
      <!-- <select class="form-control input-sm" v-model="ruleObj.selectedKey" @change="OnFieldChange(ruleObj)">
                <option v-for="(option,opInx) in rule.keys.options" :value="option.value" :key="opInx">
                    {{option.text}}
                </option>
      </select>-->
      <!-- <appup-select
                :options="rule.keys.options"
                v-model="ruleObj.selectedKey"
                @input="dynamicAddInput(ruleObj.selectedKey)"
              />
      {{rule.keys.options}}-->
    </div>
    <div class="col-md-3" v-if="hasOperator">
      <!-- <select class="form-control input-sm" v-model="ruleObj.selectedOperator">
                <option v-for="(option,opInx) in rule.operators[conditionType]" :value="option.value" :key="opInx">
                    {{option.label}}
                </option>
      </select>-->
      <b-form-select
        v-model="ruleObj.selectedOperator"
        :options="rule.operators[conditionType]"
        @change="onOperatorChange"
      ></b-form-select>
      <!-- <appup-select v-model="ruleObj.selectedOperator" :options="rule.operators[conditionType]"></appup-select> -->
    </div>

    <div class="col-md-3">
      <div v-if="conditionType == 'text'">
        <b-form-input type="text" v-model="ruleObj.selectedVal" placeholder="Enter the text"></b-form-input>
        <!-- <input type="text" class="form-control input-sm" v-model="ruleObj.selectedVal" placeholder="Enter the text"> -->
      </div>
      <div v-if="conditionType == 'date'">
        <!-- <appup-datetime :id="'appupdate'+ruleInx" v-model="ruleObj.selectedVal" :type="conditionType" /> -->

        <appup-datetime
          type="date"
          :config="rule.controls.date.custom"
          placeholder="Select a date"
          v-model="ruleObj.selectedVal"
          :id="'appupdate'+ruleInx"
        ></appup-datetime>
        <!-- <input type="date" class="form-control  input-sm" v-model="ruleObj.selectedVal"> -->
      </div>
      <div v-if="conditionType == 'number'">
        <!-- <input type="number" class="form-control input-sm" v-model="ruleObj.selectedVal"> -->
        <b-form-input
          v-if="operatorConditnType == 'number'"
          type="number"
          v-model="ruleObj.selectedVal"
          placeholder="Enter the text"
        ></b-form-input>
        <div v-if="operatorConditnType != 'number'" class="d-flex">
          <!-- <input type="number" class="form-control input-sm" v-model="ruleObj.selectedVal"> -->
          <b-form-input
            type="number"
            :value="ruleObj.selectedVal.min"
            @input="ruleObj.selectedVal = { ...ruleObj.selectedVal, min: $event }"
            placeholder="Min value"
          ></b-form-input>
          <b-form-input
            type="number"
            :value="ruleObj.selectedVal.max"
            @input="ruleObj.selectedVal = { ...ruleObj.selectedVal, max: $event }"
            placeholder="Max value"
          ></b-form-input>
        </div>
      </div>

      <div v-if="conditionType == 'select'">
        <!-- <select class="form-control input-sm" v-model="ruleObj.selectedVal">
                <option v-for="(option,opInx) in rule.controls.select.options" :value="option.value" :key="opInx">
                    {{option.text}}
                            :url="rule.controls.select.url"
                :key_value="rule.controls.select.keyvalue ? rule.controls.select.keyvalue : null"
                :key_label="rule.controls.select.keytext ? rule.controls.select.keytext : null"
                </option>
        </select>-->
        <!-- <appup-select
        
                :options="rule.controls.select.options"
                v-model="ruleObj.selectedVal"
        />-->
        <b-form-select
          @input="setSelectedName('selectedNameVal', rule.controls.select.options, $event)"
          :options="rule.controls.select.options"
          v-model="ruleObj.selectedVal"
        ></b-form-select>
      </div>
      <div></div>
    </div>
    <button
      v-if="!onlyRoot"
      class="btn btn-xs btn-purple-outline btn-radius btn-purple-round"
      @click.prevent="deleteRule"
    >
      <i class="fal fa-trash-alt"></i>
    </button>
  </div>
</template>
<script>
export default {
  name: "ac-rule",
  props: {
    rule: {
      type: Object
    },
    ruleInx: {
      type: Number
    },
    groupInx: {
      type: Number
    },
    onlyRoot: {
      type: Boolean,
      default: false
    },
    hasOperator: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      conditionType: "text",
      operatorConditnType: "numsdfsdfber",
      ruleObj: {
        condition: "Or",
        selectedKey: "",
        selectedOperator: "",
        selectedVal: "",
        selectedNameKey: "",
        selectedNameVal: ""
      }
    };
  },
  methods: {
    setSelectedName(key, options, val) {
      this.ruleObj[key] = (options.find(opt => opt.value === val) || {}).text;
    },
    onOperatorChange() {
      try {
        if (this.ruleObj.selectedOperator == "INBTW") {
          if (this.conditionType == "date") {
          }
          this.operatorConditnType = "Inbtwn";
        }
      } catch (e) {}
    },
    dynamicAddInput(data) {
      try {
        var isControlApplied = false;
        this.rule.keys.options.map(item => {
          if (item.type && item.value == data) {
            if (item.type == "select") {
              this.rule.controls["select"].url = item.url;
            }
            this.conditionType = item.type;
            isControlApplied = true;
          }
        });
        if (!isControlApplied) this.conditionType = "text";
      } catch (e) {}
    },
    async getSelectList(val, url) {
      var optionsList = [];
      try {
        let dropdownList = await fetch(url, {
          withCredentials: true,
          credentials: "include"
        }).then(res => res.json());
        if (dropdownList && dropdownList.length) {
          optionsList = dropdownList.map(obj => {
            var newObj = {};
            newObj.text = obj[this.rule.keys.keytext];
            newObj.value = obj[this.rule.keys.keyvalue];
            newObj.type = obj.type;
            newObj.url = obj.url;
            return newObj;
          });
        }
      } catch (e) {
        optionsList = [];
      }
      if (val == "keys") this.rule.keys.options = [...optionsList];
      else this.rule.controls["select"].options = [...optionsList];
    },
    deleteRule() {
      this.$parent.$parent.delete_rule({
        ruleInx: this.ruleInx,
        groupInx: this.groupInx
      });
    }
  },
  watch: {
    conditionType: function(newVal, oldVal) {
      if (newVal == "select") {
        if (this.rule.controls["select"].url)
          this.getSelectList("controls", this.rule.controls["select"].url);
      }
    },
    ruleObj: {
      handler(val) {
        //this.formInput();
        console.log("ruleObj", this.ruleObj);
      },
      deep: true
    }
  },
  created() {
    if (this.rule.keys && this.rule.keys.url)
      this.getSelectList("keys", this.rule.keys.url);
  },
  mounted() {
    //this.conditionType = this.rule.keys.keytype;
    let { condition, selectedKey, selectedOperator, selectedVal } = this.rule;
    this.ruleObj = { condition, selectedKey, selectedOperator, selectedVal };
  }
};
</script>