<template>
  <b-form-input
    v-model="columnName"
    placeholder="Input column name"
    :state="state"
    @input="onInputColumnName"/>
</template>
<script>
import store from '../store'

export default {
  name: 'column-name-input',
  props: {
    state: {
      type: Boolean,
    },
    value: {
      type: String,
    },
    column: {
      type: Object,
    },
    is_new: {
      type: Boolean,
    },
  },
  data() {
    return {
      columnName: this.value,
    }
  },
  methods: {
    onInputColumnName(value) {
      if (!this.is_new) {
        store.actions.updateCurrentTableColumn(this.column.id, { column_name: value })
        return
      }
      const newColumn = { ...this.column, column_name: value }
      store.actions.addCurrentTableColumn(newColumn)
    },
  },
}
</script>
