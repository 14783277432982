<template>
  <div class>
    <carousel
      v-bind="{
        ...attrs
      }"
      v-model="slide"
      @navigation-click="handleSlideClick"
      @slide-click="$emit('slide_click',$event)"
      @page-change="$emit('page_change',$event)"
    >
      <template v-for="(a,b) in data">
        <!-- obj {{chart_data[a].data}} -->
        <slide :key="`${b}`" class="d-flex">
          <template v-for="(item,index) in a">
            <ac-chart
              :key="index"
              :class="dynamicClass"
              :data="JSON.stringify(item.data)"
              :type="item.type"
              :title="item.title"
              :config="item.config"
            ></ac-chart>
          </template>
        </slide>
      </template>
    </carousel>
  </div>
</template>
<script>
//ref--https://github.com/SSENSE/vue-carousel
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide
  },

  name: "ac-dynamic-chart",
  props: {
    /**
     * Data for thr component
     * @param {Array} -chart_data
     */
    chart_data: {
      type: Array,
      required: false,
      default: []
    },
    /**
     * Number of charts in a slide max 4
     * @param {String} -count
     */
    count: {
      type: String,
      default: 3,
      required: false
    },
    /**
     * Extra props for componets for reference--https://github.com/SSENSE/vue-carousel
     * @param {Object} -attributes
     */
    attributes: {
      type: Object,
      required: false
    },
    /**
     * preload handler
     * @param {String} -preload
     */
    preload: {
      type: String,
      required: false
    },
    /**
     * handler_params for preload
     * @param {String} -handler_params
     */
    handler_params: {
      type: String,
      required: false
    }
  },
  created() {
    this.set_data();
  },
  mounted() {
    if (this.preload) {
      this.start(this.preload, {
        helper: {
          component: this,
          custom: this.handler_params ? this.handler_params : {},
          helperParams: this.params ? this.params : {}
        }
      });
    }
  },
  data() {
    return {
      dynamicClass: "col-md-4",
      attrs: {
        navigationNextLabel:
          "<i class='fa fa-angle-right' aria-hidden='true'></i>",
        navigationPrevLabel:
          "<i class='fa fa-angle-left' aria-hidden='true'></i>",
        navigationEnabled: true,
        "per-page": 1
      },
      intervel: 0,
      data: [],
      sildesCount: "",
      slide: 0,
      pageNumber: ""
    };
  },
  watch: {
    chart_data: {
      handler(val) {
        this.set_data();
      },
      deep: true
    }
  },
  methods: {
    set_data: function() {
      if (this.attributes) {
        this.attrs = { ...this.attrs, ...this.attributes };
      }
      //Set dynamic class
      if (Number(this.count) == 2) {
        this.dynamicClass = "col-md-6";
      } else if (Number(this.count) == 1) {
        this.dynamicClass = "col-md-12";
      } else if (Number(this.count) == 4) {
        this.dynamicClass = "col-md-3";
      }
      // Calculate slides Count
      this.sildesCount = Math.ceil(this.chart_data.length / Number(this.count));
      var arr = [];
      var dummyarr = this.chart_data;
      var len = 0;
      do {
        var b = [];
        b = dummyarr.slice(len, len + Number(this.count));
        arr.push(b);
        len = len + Number(this.count);
      } while (len < dummyarr.length);
      this.data = arr;
    },
    handleSlideClick(event) {
      var obj = {};
      if (
        (event == "forward" && this.slide == this.sildesCount - 1) ||
        (event == "backward" && this.slide == 0)
      ) {
        obj.currentPage = this.slide;
      } else {
        if (event == "forward") obj.currentPage = this.slide + 1;
        else {
          obj.currentPage = this.slide - 1;
        }
      }
      obj.action = event;
      this.$emit("navigation_click", obj);
    }
  }
};
</script>
<style>
.VueCarousel-navigation-next,
.VueCarousel-navigation-prev {
  transform: none !important;
}
</style>