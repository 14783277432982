<template>
  <ac-sql-builder :sql_json="sqlBuilder"/>
</template>

<script>
import sqlBuilder from '../src/assets/sql-builder.json'

export default {
  data() {
    return {
      sqlBuilder,
    }
  },
  methods: {
  },
}
</script>
