<template>
  <div>
    <signature-pad
      :width="data_width"
      :height="data_height"
      :images="data_images"
      :saveType="data_save_type"
      :options="data_options"
      :customSyle="data_custom_style"
      ref="ac_signature_pad"
    ></signature-pad>
    <b-button variant="danger" @click="signature_undo()">Undo</b-button>
    <b-button variant="success" @click="signature_save()">Save</b-button>
  </div>
</template>

<script>
/**
 * ac signature pad component
 * @requires vue-signature-pad- Forked from https://github.com/neighborhood999/vue-signature-pad
 */
import SignaturePad from "vue-signature-pad";

export default {
  name: "ac-signature-pad",

  components: {
    SignaturePad
  },

  // As it is a wrapper keeping the props same as the wrapper
  props: {
    /**
     * Width of the pad
     * @param {String} width
     * @label width
     * @category_name 4_Style
     */
    width: {
      type: String
    },

    /**
     * Height of the pad
     * @param {String} height
     * @label height
     * @category_name 4_Style
     */
    height: {
      type: String
    },

    /**
     * Image type, support image/png, image/jpeg, image/svg+xml.
     * @param {String} save_type
     * @label saveType
     * @category_name 3_Advanced
     */
    save_type: {
      type: String,
      required: true
    },

    /**
     * Set the signature pad config options.
     * @param {Object} options
     * @label options
     * @category_name 1_General
     */
    options: {
      type: Object,
      default: () => {}
    },

    /**
     * Merge signature with the provide images.
     * @param {Array} images
     * @label images
     * @category_name 1_General
     */
    images: {
      type: Array,
      default: () => []
    },

    /**
     * Custom CSS
     * @param {Object} customStyle
     * @label customStyle
     * @category_name 1_General
     */
    custom_style: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      data_width: this.width,
      data_height: this.height,
      data_save_type: this.save_type,
      data_options: this.options,
      data_images: this.images.slice(),
      data_custom_style: this.custom_style
    };
  },

  methods: {
		/**
		 * Fires when undo action
		 */
    signature_undo() {
      this.$refs.ac_signature_pad.undoSignature();
    },

		/**
		 * Fires when save action is hit
		 * Emits save event with image object
		 */
    signature_save() {
      // console.log(this.$refs.ac_signature_pad.saveSignature());
      this.$emit("save", this.$refs.ac_signature_pad.saveSignature());
    }
  }
};
</script>
