<template>
  <b-form-group v-bind="$attrs">
    <b-form-file ref="file-input" v-bind="$attrs" v-on="$listeners" />
  </b-form-group>
</template>
<script>

/**
   * ac File component
   * @requires VueBootstrap
   */

export default {
  name: 'ac-file',
  methods: {
    reset() {
      this.$refs['file-input'].reset()
    }
  }
}
</script>
