<template>
      <b-row>
          <b-col class="border-right pl-4">
              <slot>Rating</slot>
          </b-col>
          <b-col>
              <template v-for="rating in ratings">
                  <b-progress :value="rating.value?rating.value:''" :variant="rating.variant?rating.variant:''" :key="rating" class="w-75 mb-2"></b-progress>
              </template>
          </b-col>
      </b-row>
</template>
<script>
export default {
    name: "ac-rating-bar",
    props:{
        ratings:{
            type: Array,
            default: [
                {variant:"primary", value:20},
                {variant:"primary", value:40},
                {variant:"primary", value:60},
                {variant:"primary", value:80}
            ]
        },
        max: {
            type: Number,
            default: 100
        },
        
    }
}
</script>