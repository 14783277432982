<template>
  <appup-workflow
    ref="ac_automation"
    :src="workflow_JSON.src"
    :width="workflow_JSON.width"
    :height="workflow_JSON.height"
    :toolbar1="workflow_JSON.toolbar1"
    :toolbar2="workflow_JSON.toolbar2"
    :marketplace="workflow_JSON.marketplace"
    :class="workflow_JSON.class"
    v-model="wfOutput"
  />
</template>
<script>
export default {
    name: 'ac-automation-workflow',

    props: {
        /**
         * Json for workflow designer
         * @param {Object} workflow_JSON
         */
        workflow_JSON: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            // Designer v-model output is string
            wfOutput: ""
        }
    },

    methods: {
        async get_query() {
            // Save workflow in designer
            try {
                let ac_automation_resolve = await this.$refs.ac_automation.save().then(() => {});
                // Get the JSON from designer using refs
                this.wfOutput = this.$refs.ac_automation.get_json();
            } catch (e) {}

            // Return workflow JSON
            return {
                workflow_JSON: this.wfOutput
            }
        }
    }
}
</script>
