<template>
  <div>
    <div class="row" v-for="(row,index) in options" :key="index">
      <template v-for="(col,ind) in row.columns">
        <!-- <slot :name="col.key"> -->
          <componet :class="col.class" :key="ind" :is="col.helper"></componet>
        <!-- </slot> -->
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "ac-simple-grid",
  props: {
    /**
     *
     * @param {Array} options
     * @label Rows
     * @properties {"inside_grid": {"name":"columns","class":{"type": "String"},"helper":{"type": "String"}},"name":{"type": "String"}}
     */
    options: {
      type: Array,
      required: true
    }
  }
};
</script>