<template>
  <full-calendar
    :events="events"
    :config="config"
    @event-selected="eventSelected"
    @event-drop="eventDrop"
    @event-resize="eventResize"
    @day-click="dayClick"
    @event-render="eventRender"
  ></full-calendar>
</template>

<script>
/**
 * Simple config based on current requirments
 */
import moment from "moment";
import AppData from "./mixins/ac-data";

/**
 * @requires ac-data.js
 * @inherits ac-data
 */

export default {
  name: "ac-calendar",
  mixins: [AppData],
  props: {
    /**
     * preload handler
     * @param {String} -preload
     * @category_name 2_Data
     */
    preload: {
      type: String,
      required: false
    },
    /**
     * events object for calender
     * @param {Array} -events
     * @category_name 1_General
     */
    events: {
      type: Array,
      required: false
    },
    /**
     * config object for calender
     * @param {Object} -config
     * @category_name 1_General
     */
    config: {
      type: Object,
      default() {
        return {
          defaultView: "month",
          eventLimit: true
        };
      }
    }
  },
  created() {
    /**
     * On created we can  call handler
     */
    if(this.preload && this.preload.length){
    var preload_array = [];
    this.preload.forEach(element => {
      let preload_object = {};
      preload_object["name"] = element.name;
      if (element.params) {
        preload_object["params"] = {
          helper: {
            custom: element.params ? element.params : {}
          }
        };
      }
      //forming array of objects
      preload_array.push(preload_object);
    });
    this.start(preload_array);
    }
  },
  mounted() {
    // Emit calendar ready event
    this.$emit("calendar-ready");
  },
  methods: {
    eventSelected(event) {
      this.$emit("event-selected", event);
    },
    eventDrop(event) {
      this.$emit("event-drop", event);
    },
    eventResize(event) {
      this.$emit("event-resize", event);
    },
    dayClick(event) {
      this.$emit("day-click", event);
    },
    eventRender(info, element) {
      
      element[0].querySelectorAll(".fc-content")[0].setAttribute("v-b-tooltip.hover", '');
      element[0].querySelectorAll(".fc-content")[0].setAttribute("title", info.title);
    },
    //If url available fetches data from url
    async fetch_url_data(){
       const msg = await this.set_ajax();
       this.events = this.data;
    }
  },
  watch: {
    events: {
      immediate: true,
      handler: function(newval, oldval) {
        this.events = newval;
      },
      deep: true
    },
    url:{
      immediate:true,
      handler: function(newval, oldval){
        this.url=newval;
        this.fetch_url_data();
      },
      deep: true
    },
    ac_cursor(newval, oldval) {
      this.ac_cursor=newval;
      this.fetch_url_data();
    },
  }
};
</script>
<style>
.fc-header-toolbar .fc-button {
  text-transform: capitalize;
}
</style>