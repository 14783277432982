<template>
  <li
    :class="item.action_needed"
    class="timeline-item"
  >
    <div
      :class="item.icon_status"
      class="timeline-badge"
    >
      <i :class="item.icon_class"></i>
    </div>

    <div
      :class="[item.element_status, item.element_day_marker]"
      class="timeline-panel"
    >
        <div class="timeline-heading">
            <h4
              :class="item.text_status"
              class="timeline-title"
            >
              {{ item.title }}
            </h4>

            <div class="timeline-panel-controls">
                <div class="controls">
                    <a
                      v-for="(control, index) in item.controls"
                      :key="index"
                      :control="control"
                      is="timeline-control"
                    />
                </div>
                <div class="timestamp">
                    <small class="">{{ item.created }}</small>
                </div>
            </div>
        </div>
        <div class="timeline-body">{{ item.body }}</div>
    </div>
  </li>
</template>

<script>
/**
 * ac-timeline-item component
 */

export default {
  name: 'ac-timeline-item',

  props: {
    /**
     * @param {Object} item - Timeline object.
     */
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>