<template>
  <div ref="collection_wrapper" :style="{maxHeight: max_height}" :class="add_class">
    <!-- loader image -->
    <div v-show="is_loading" class="loader row">
      <!--onError is to handle the broken image of loader -->
      <img
        :src="loading_image"
        class="loader__image m-auto"
        :onError="onerror_handle_loader"
        alt="loader"
      />
    </div>
    <!-- Serachbar option -->
    <template v-if="show_searchbar">
     <div class="float-right">
      <b-form-input
        size="sm"
        class="row col-md-4 m-0 my-3 float-right mr-3 form-control form-control-sm"
        @keyup="search_results()"
        v-model="search_value"
        :placeholder="searchbar_placeholder"
      ></b-form-input>
      </div>
    </template>
    <header class="ac-card-collection-header">
      <!-- Use this slot for header -->
      <slot name="header"></slot>
    </header>
    <main class="ac__body">
      <div v-if="data && data.length" :class="add_body_class">
        <!-- Use this slot for body, If this slot is used then card-header, card-body, card-footer slots will be ignored.  -->
        <slot name="body" :data="data">
          <b-row>
            <!-- <b-col v-for="(item,index) in data" :key="index" :class="response_class" :md="column_size"> -->
            <b-col v-for="(item,index) in data" :key="index" :class="response_class">
              <div class="card h-100 card-head-bg w-100" @click="card_click(item)">
                <ac-material-dropdown
                  v-if="show_dropdown"
                  :options="dropdown_options"
                  :button_options="dropdown_button_options"
                  :right="dropdown_button_options.right"
                  :show_icon="show_dropdown_icons"
                  @item_click="on_dropdown_item_click($event, item)"
                  class="top"
                />
                <!-- Use this slot for each card header-->
                <slot name="card_header" :item="item"></slot>
                <!-- Use this slot for each card body-->
                <slot name="card_body" :item="item"></slot>
                <!-- Use this slot for each card footer-->
                <slot name="card_footer" :item="item"></slot>
              </div>
            </b-col>
          </b-row>
        </slot>
      </div>
      <div v-if="data && !data.length" class="ac-card-collection-emptystate">
        <!-- Use this slot to show empty state. Whenever data is empty, this slot will be shown.-->
        <slot name="empty_state"></slot>
      </div>
      <!-- pagination option -->
    <template v-if="pagination_type=='pagination'">
      <div class="d-flex justify-content-end">
        <b-button class="mr-2" :disabled="page_number === 0" @click="prev_page"><i class="fas fa-chevron-left"></i></b-button>
         <b-button
            :variant="(pageNumber == page_number+1)?'outline-primary':'outline-secondary'"
            v-for="pageNumber in pages.slice(0, pages.length)"
            :key="pageNumber"
            :disabled="page_number+1 == pageNumber"
            @click="pagenumber_click(pageNumber)"
          >{{pageNumber}}</b-button>
        <b-button class="mr-3" :disabled="page_number >= page_count" @click="next_page"><i class="fas fa-chevron-right"></i></b-button>
      </div>
    </template>
    </main>
    <footer class="ac-card-collection-footer">
      <!-- Use this slot for footer -->
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
import AcCollection from "./ac-collection.vue";
/**
 * @inherits ac-collection,ac-data,ac-preload
 */
export default {
  name: "ac-card-collection",
  extends: AcCollection,

  props: {
    /**
     * If Show Dropdown is true then only dropdown will be shown
     * @label Show Dropdown
     * @category_name 1_General
     */
    show_dropdown: {
      type: Boolean,
      default: false
    },

    /**
     * Options for dropdown menu for each card
     * @param {object} dropdown_options
     * @properties {"label": {"type": "String","description":"Give label name of option"}, "type": {"type": "String","description":"Type of the option Eg:edit,submit..etc"}, "icon": {"type": "String", "description":"Give icon class for option"}, "handler": {"type": "String", "description":"Select handler to perform action"}, "handler_params": {"type": "String", "description":"Give params to be send to handler"}}
     * @label Dropdown Options
     * @category_name 1_General
     */
    dropdown_options: {
      type: Array,
      required: false
    },
    /**
     * If true - display dropdown options icon
     * @param {boolean} show_dropdown_icons
     * @label Show Dropdown Icons
     * @category_name 1_General
     */
    show_dropdown_icons: {
      type: Boolean,
      default: false
    },

    /**
     * Dropdown click button option like ellipsis
     * @param {object} dropdown_button_options
     * @properties
        {
          "icon": {"type": "String", "default": "fas fa-ellipsis-v", "description":"Give button icon class"},
          "variant": {"type": "String", "default": "outline-secondary", "description":"Give variant class for button", "values": ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark", "outline-primary", "outline-secondary", "outline-success", "outline-danger", "outline-warning", "outline-info", "outline-light", "outline-dark"]},
          "size": {"type": "String", "default": "sm", "description":"Give size of the button Eg:sm,lg,md", "values": ["sm", "md", "lg"]},
          "right": {"type": "Boolean", "default": "true", "description":"To display dropdown options right or left"}
        }
     * @label  Dropdown Button Options
     * @category_name 1_General
     */
    dropdown_button_options: {
      type: Object,
      required: false
    },
    /**
     * To show how many columns for each row, by default it is 3.
     * @label No of Columns
     * @category_name 4_Style
     */
    no_of_columns: {
      type: Number,
      default: 3
    },
    /**
     * To display searchbar, Whenever true show searchbar above the collection.
     * @param {boolean} search bar
     * @label Show Searchbar
     * @category_name 3_Advanced
     */
    show_searchbar: {
      type: Boolean,
      default: false
    },
    /**
     * To change default place holder
     * @param {string} searchbar placeholder
     * @label Searchbar Placeholder
     * @category_name 3_Advanced
     */
    searchbar_placeholder: {
      type: String,
      default: "Search"
    },
    /**
     * Search Key to perform search action, based on what key search should be performed.
     * @param {String} search key
     * @label Search Key
     * @category_name 3_Advanced
     */
    search_key: {
      type: String,
      required: false
    },
    /**
     * Whenever true, show pagination buttons below the collection.
     * @param {boolean} pagination buttons
     * @label Show Pagination
     * @category_name 3_Advanced
     * @private
     */
    show_pagination: {
      type: Boolean,
      default: false
    },
    
    /**
     * Key to apply offset which support url Eg. offset , _start etc
     * @param {Number} offset_key - When pagination is true offset should apply for which key in ac_cursor
     * @label Offset Key
     * @category_name 3_Advanced
     */
    offset_key: {
      type: String,
      required: false
    },
    /**
     * Bootstrap class to change UI look Eg. col-12 col-md-12
     * @param {String} responsive_class - bootstrap class to change UI look
     * @label Responsive Class
     * @category_name 4_Style
     */
    responsive_class: {
      type: String,
      required: false
    },
    /**
     * User defined class, It will add main div of this helper
     * @param {String} main_class
     * @label Main Class
     * @category_name 4_Style
     */
    main_class: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * User defined class, It will add to card body
     * @param {String} body_class
     * @label Body Class
     * @category_name 4_Style
     */
    body_class: {
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      current_item: null,
      stored_data: [],
      search_value: "",
      offset: 0,
      page_number: 0,
      total_count: 0,
      pages: []
    };
  },

  computed: {
    //Not usnig
    column_size() {
      return this.no_of_columns >= 1 && 12 % this.no_of_columns == 0 ? parseInt(12 / this.no_of_columns) : 2;
    },
    /**
     * disabling previous and next buttons based on data length
     * @return {Integer}
     */
    page_count() {
      let l = parseInt(this.total_count);
      let s = parseInt(this.limit);
      if (l % s != 0) return Math.floor(l / s);
      else return Math.floor(l / s) - 1;
    },
    /**
     * Returning css class as per users chaoice
     * @return {String}
     */
    response_class() {
      return this.responsive_class + " ac__item mb-3";
    },
    /**
     * To handle the broken image of loader
     * @return {String}
     */
    onerror_handle_loader() {
      if (this.loading_image) {
        return "this.onerror=null;this.src='" + this.loading_image + "';";
      } else
        return "this.onerror=null;this.src='https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif';";
    },
    /**
     * Custom class for the component
     * @return {String}
     */
    add_class() {
      return "ac-card-collection card d-flex flex-column " + this.main_class;
    },
    /**
     * Custom class for the component body slot
     * @return {String}
     */
    add_body_class() {
      return "ac-card-collection-body b-padding " + this.body_class;
    }
  },

  watch: {
    /**
     * To keep tracking changes from user
     */
    pagination_type(newval, oldval) {
      this.pagination_type = newval;
      this.handler_data();
    },
    show_searchbar(newval, oldval) {
      this.show_searchbar = newval;
      this.handler_data();
    },
    total_count(newval, oldval) {
      this.set_pages();
    }
  },

  created() {
    /**
     * Calling function to get data
     */
    this.handler_data();
  },

  methods: {
    /**
     * Executes onclick of dropdown options
     * @public
     * @param {string} type - type of click e.g. 'edit'
     * @param {object} item - row info related to clicked dropdown
     */
    on_dropdown_item_click(option, item) {
      // Execute appup handler workflow
      if (option.handler && this.start) {
        let custom = {};
        if (option.handler_params) {
          try {
            custom = JSON.parse(option.handler_params);
          } catch (error) {
            if (typeof option.handler_params === "object") {
              custom = option.handler_params;
            }
          }
        }
        this.start(option.handler, {
          helper: {
            component: this,
            item: item,
            custom: custom
          }
        });
      }
      //Event fires whenever the click on the dropdown
      this.$emit("dropdown_click", {
        type: option.type,
        item
      });
    },
    /**
     * Executes on click of card
     * @param {string} item - card data
     */
    card_click(item) {
      this.current_item = item;
      //This event fire whenever the click action on card
      this.$emit("click", item);
    },
    /**
     * get the data from url
     */
    async handler_data() {
      if (this.pagination_type == "pagination") {
        this.append_to_ac_cursor();
      }
      const MESSAGE = await this.set_ajax();
      this.stored_data = this.data;
    },
    /**
     * search based on the search_value entered from searchbar
     * @return {Array} - filteres data based on search
     */
    search_results() {
      this.data = this.stored_data.filter(item => {
        if (this.search_value) {
          return item[this.search_key]
            .toLowerCase()
            .includes(this.search_value.toLowerCase());
        } else return this.stored_data;
      });
    },
    /**
     * invoke by clicking on next button when pagination is true
     */
    next_page() {
      this.page_number++;
      this.offset += JSON.parse(this.limit);
      this.append_to_ac_cursor();
      this.handler_data();
    },
    /**
     * invoke by clicking on previous button when pagination is true
     */
    prev_page() {
      this.page_number--;
      this.offset -= JSON.parse(this.limit);
      this.append_to_ac_cursor();
      this.handler_data();
    },
     /**
     * Get total page numbers to be displayed in UI
     */
    set_pages() {
      let num_of_pages = Math.ceil(this.total_count / this.limit);
      for (var i = 1; i <= num_of_pages; i++) {
        this.pages.push(i);
      }
    },
    /**
     * Invoke onclick of page number
     * Set offset according to the page number to fetch data
     */
    pagenumber_click(page) {
      this.page_number = page - 1;
      this.offset = parseInt(this.limit) * (page - 1);
      this.append_to_ac_cursor();
      this.handler_data();
    },
    /**
     * appending limit and offset values when pagination is true
     */
    append_to_ac_cursor() {
      this.ac_cursor[this.limit_key] = this.limit;
      this.ac_cursor[this.offset_key] = this.offset;
    }
  }
};
</script>

<style>
.ac-card-collection .ac-material-dropdown {
  position: absolute;
  right: 8px;
}
.ac-card-collection .ac-material-dropdown.top {
  top: 8px;
}
.ac-card-collection .ac-material-dropdown.bottom {
  bottom: 8px;
}
.px-custom-card {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
</style>
