
<script>
/**
 * ac Colection component which can be used to display data from API
 * @requires vue-grid-layout
 */

import VueGridLayout from "vue-grid-layout";
import acComponent from "./ac-component";
export default {
  name: "ac-grid",

  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    acComponent: acComponent
  },

  props: {
    /**
     * Array of objects. Each object will be rendered as grid item
     * @param {array} grid_items
     * @label Grid Items
     * @category_name 1_General
     */
    grid_items: {
      type: Array,
      required: true
    },

    /**
     * Says if the grids items are draggable.
     * @param {boolean} enable_drag
     * @default true
     * @label Enable Drag
     * @category_name 1_General
     */
    enable_drag: {
      type: Boolean,
      default: true
    },

    /**
     * Says what are the margins of elements inside the grid.Each value is expressed in pixels. The first element is a margin horizontally, the second element is a vertical margin.
     * @param {array} margin
     * @default [10,10]
     * @label Margin
     * @category_name 4_Style
     */
    margin: {
      type: Array,
      required: false,
      default: () => [10, 10]
    },

    /**
     * Says if the container height should swells and contracts to fit contents.
     * @param {boolean} auto_size
     * @default true
     * @label Auto Size
     * @category_name 4_Style
     */
    auto_size: {
      type: Boolean,
      required: true
    },

    /**
     * Default grid item width
     * @param {number} grid_width
     * @label Grid Width
     * @category_name 4_Style
     */
    grid_width: {
      type: Number,
      required: true
    },

    /**
     * Default grid item height
     * @param {number} grid_height
     * @label Grid Height
     * @category_name 4_Style
     */
    grid_height: {
      type: Number,
      required: true
    },

    /**
     * Says how many columns the grid has.
     * @param {number} grid_column
     * @default 12
     * @label Grid Columns
     * @category_name 4_Style
     */
    grid_column: {
      type: Number,
      required: false,
      default: 12
    },

    /**
     * Says if the grids items are resizable.
     * @param {boolean} resizable
     * @default true
     * @label Resizable
     * @category_name 4_Style
     */
    resizable: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      h: null,
      grid_data: []
    };
  },

  created() {
    this.h = this.$createElement;
    this.prepare_items();
  },

  methods: {
    /**
     * @function prepare_items - Converts grid_items prop so
     * vue-grid-layout can use it
     */
    prepare_items() {
      this.grid_data = this.grid_items.map(item => ({
        w: parseInt(item.width, 10) || parseInt(this.grid_width, 10),
        h: parseInt(item.height, 10) || parseInt(this.grid_height, 10),
        x: parseInt(item.position.x, 10),
        y: parseInt(item.position.y, 10),
        label: item.label,
        description: item.description,
        helper: item.helper,
        i: item.id
      }));
    },

    /**
     * @function add_item - Adds item to grid
     * @prop {object} item - item to add, must be same as object inside grid_items prop
     */
    add_item(item) {
      if (!this.check_id(item.id)) {
        console.error("Id is already taken");
        return;
      }

      const obj = {
        w: item.width || this.grid_width,
        h: item.height || this.grid_height,
        x: item.position.x,
        y: item.position.y,
        label: item.label,
        description: item.description,
        helper: item.helper,
        i: item.id
      };

      this.grid_data.push(obj);
    },

    /**
     * @function check_id - Checks if given id is not taken
     * @prop {number|string} id - id to check
     */
    check_id(id) {
      return this.grid_data.filter(item => item.i === id).length === 0;
    },

    /**
     * @function render_grid - Renders grid
     */
    render_grid() {
      const grid_items = this.grid_data.map(item =>
        this.h(
          "grid-item",
          {
            props: {
              x: item.x,
              y: item.y,
              i: item.i,
              w: Number(item.w),
              h: item.h
            },
            class: {
              "ac-grid__item": true,
              "p-2": true, // this is for demo
              border: true // this is for demo
            },
            on: {
              /**
               * Fires on grid item resize.
               * @event resize_event
               * @type {object} - resizing grid item
               */
              resize: e => {
                this.$emit("resize_event", e);
              },

              /**
               * Fires after grid item resize complete.
               * @event resized_event
               * @type {object} - resized grid item
               */
              resized: e => {
                this.$emit("resized_event", e);
              },

              /**
               * Fires on grid item move.
               * @event move_event
               * @type {object} - moving grid item
               */
              move: e => {
                this.$emit("move_event", e);
              },
              /**
               * Fires on grid item moved.
               * @event moved_event
               * @type {object} - moved grid item
               */
              moved: e => {
                this.$emit("moved_event", e);
              }
            },

            nativeOn: {
              /**
               * Fires on grid item click.
               * As vue-grid-layout library has no built-in click event,
               * this will trigger on any click e.g on drag, resize
               * @event clicked_event
               * @type {object} - clicked grid item
               */
              mousedown: () => {
                this.$emit("clicked_event", item);
              }
            }
          },
          [
            this.h("h5", item.label),
            this.h("p", item.description),
            this.h("acComponent", {
              props: {
                component_name: item.helper
              }
            })
          ]
        )
      );

      return this.h(
        "grid-layout",
        {
          props: {
            "is-resizable": this.resizable,
            "is-draggable": this.enable_drag,
            "col-num": parseInt(this.grid_column, 10),
            layout: this.grid_data,
            margin: this.margin ? this.margin : [10, 10]
          },
          on: {
            "layout-updated": e => {
              this.$emit("layout_updated_event", e);
            }
          }
        },
        [...grid_items]
      );
    }
  },

  render() {
    const grid = this.render_grid();
    return this.h(
      "div",
      {
        class: {
          "ac-grid": true
        }
      },
      [grid]
    );
  }
};
</script>
