<template>
  <div class="ac-mentions">
    <vue-tribute
      ref="tribute"
      v-if="ac_options"
      :options="ac_options"
    >
      <b-input
        ref="tribute_input"
        v-model="input_value"
        @change="on_input_change"
        @tribute-replaced="on_mention_pick"
        @keypress.enter="on_enter"
      />
    </vue-tribute>
  </div>
</template>

<script>
import VueTribute from 'vue-tribute'
/**
  * ac mentions component
  * @requires VueBootstrap
  * @requires tributejs
  * @requires vue-tribute
  */

export default {
  name: 'ac-mentions',

  components: {
    VueTribute,
  },

  props: {
    /**
     * @param {array} options - Array of options for mentions
     * each object will be created as separate mention collection
     */
    options: {
      type: Array,
      required: true,
    },

    /**
     * @param {string} value - v-model value
     */
    value: {
      type: String,
      required: false,
      default: '',
    },
  },

  created() {
    this.ac_options = this.prepare_options(this.options)
    this.input_value = this.value
  },

  data() {
    return {
      ac_options: null,
      input_value: '',
    }
  },

  watch: {
    value(val) {
      this.input_value = val
    },
  },

  methods: {
    /**
     * @function prepare_options - prepare options for vue--mentions
     * @prop {array} options - array of objects to trigger
     * each option can have next props https://github.com/zurb/tribute#a-collection
     */
    prepare_options(options) {
      return {
        collection: options.map((el) => {
          const fin = {
            selectTemplate(item) {
              if (!el.html) {
                return `@${item.original.value}`
              }

              const str = el.html.replace('{{key}}', item.original.key)
              return str.replace('{{value}}', item.original.value)
            },
            ...el,
          }

          if (el.data) {
            fin.values = el.data
          }

          return fin
        }),
      }
    },

    /**
     * @function on_input_change - runs on input change
     */
    on_input_change() {
      this.$emit('input', this.input_value)
    },

    /**
     * @function on_mention_pick - runs on mention pick
     */
    on_mention_pick(e) {
      this.input_value = e.target.value
      this.on_input_change()
    },

    /**
     * @function run_trigger - programatically calls mentions
     * @property {string} trigger - what to trigger
     */
    run_trigger(trigger) {
      let index = 0
      const input = this.$refs.tribute_input.$el
      this.options.forEach((el, i) => {
        if (el.trigger === trigger) index = i
      })
      this.$refs.tribute.tribute.showMenuForCollection(input, index)
    },

    on_enter() {
      this.$emit('enter')
    },
  },
}
</script>

<style>
  .tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999; }
  .tribute-container ul {
    margin: 0;
    margin-top: 2px;
    padding: 0;
    list-style: none;
    background: #efefef; }
  .tribute-container li {
    padding: 5px 5px;
    cursor: pointer; }
    .tribute-container li.highlight {
      background: #ddd; }
    .tribute-container li span {
      font-weight: bold; }
    .tribute-container li.no-match {
      cursor: default; }
  .tribute-container .menu-highlighted {
    font-weight: bold; }
</style>
