<template>
  <div>
    <b-navbar
      :id="id"
      class="ac-header p-0"
      toggleable="lg"
      :variant="title_varient"
      :type="title_text_color"
    >
      <!-- header title displayed -->
      <template class="ac-header__component my-3">
        <!-- Use this slot for Left end component -->
        <slot name="leftend_component"></slot>
      </template>
      <template v-if="title">
        <b-navbar-brand :href="title.link_to" class="p-2 bg-theme-color">
          <b-nav-text v-if="title.name">
            <b>{{title.name}}</b>
          </b-nav-text>
          <img
            v-else-if="title.image"
            v-bind:src="title.image"
            alt="logo"
            width="40"
            height="40"
            class="dark-logo"
          />
        </b-navbar-brand>
      </template>
      <div class="ml-auto ml-lg-0">
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
        <b-button
          class="d-lg-none d-inline-block toggle_default"
          variant="bg-transparent p-2"
          @click="show_mobie_buttons()"
        >
          <i class="fal fa-ellipsis-v fa-2x text-black m-2"></i>
        </b-button>
      </div>
      <b-collapse is-nav id="nav_collapse">
        <!-- Left aligned Nav Items -->
        <b-navbar-nav class="align-items-center">
          <template v-for="(nav,indexing) in left_controls">
            <!-- Navigation Item -->
            <b-nav-item
              :key="'nav-'+indexing"
              v-if="nav.type == 'link'"
              :href="nav.link_to"
              :class="active_class(nav)"
            >
              <template v-if="nav.icon">
                <span :class="nav.icon"></span>
              </template>
              {{nav.label}}
            </b-nav-item>

            <!-- Dropdown -->
            <b-nav-item-dropdown
              v-if="nav.type == 'dropdown'"
              :text="nav.label"
              :key="'nav-'+indexing"
              :left="nav.opens == 'left'?true:false"
              :right="nav.opens == 'right'?true:false"
              :class="active_class(nav)"
            >
              <template v-for="(item,index) in nav.items">
                <b-dropdown-item
                  :href="item.link_to"
                  :key="'nav-item-'+index"
                  @click="item_clicked(item)"
                  :active="active_class_dropdown_item(item)"
                >{{item.label}}</b-dropdown-item>
              </template>
            </b-nav-item-dropdown>
          </template>

          <template class="ac-header__component my-3">
            <!-- Use this slot for Left component -->
            <slot name="left_component"></slot>
          </template>
        </b-navbar-nav>
      </b-collapse>
      <!-- Right aligned Nav Items -->
      <b-navbar-nav
        id="right_con"
        class="show-app-list ml-auto d-lg-flex align-items-center"
        style="display:none"
      >
        <template class="ac-header__component my-3">
          <!-- Use this slot for Right component, like onboardmeter -->
          <slot name="right_component"></slot>
        </template>

        <template v-for="(nav,index) in right_controls">
          <!-- Navigation Item -->
          <b-nav-item
            :key="'nav-'+index"
            class="d-inline-block rightside-icons p-0"
            v-if="nav.type == 'link'"
            :href="nav.link_to"
            :class="active_class(nav)"
          >
            <template v-if="nav.icon">
              <i :class="nav.icon" class="p-2 fa-lg"></i>
            </template>
            {{nav.label}}
          </b-nav-item>
          <!-- Dropdown -->
          <div class="gr-avthar" v-if="nav.type == 'dropdown'" :key="index">
            <b-dropdown
              :key="'nav-'+index"
              :left="nav.opens == 'left' ? true : false"
              :right="nav.opens == 'right' ? true : false"
              no-caret
              class="py-1"
              variant="transparent rightside-icons border-0"
            >
              <template slot="button-content">
                <h6
                  class="d-inline-block mb-0 mr-2 nav-link"
                  :class="active_class(nav)"
                  v-if="nav.label"
                >{{nav.label}}</h6>
                <img
                  v-if="nav.image"
                  v-bind:src="nav.image"
                  width="30px"
                  height="30px"
                  class="rounded"
                  alt="userimage"
                />
                <i v-if="nav.icon" :class="nav.icon"></i>
              </template>
              <template v-for="(item,index) in nav.items">
                <b-dropdown-item
                  :href="item.link_to"
                  :key="'nav-item-'+index"
                  @click="item_clicked(item)"
                  :active="active_class_dropdown_item(item)"
                >{{item.label}}</b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
        </template>
        <template class="ac-header__component my-3">
          <!-- Use this slot for Right end component, like ninedots -->
          <slot name="rightend_component"></slot>
        </template>
      </b-navbar-nav>
      <!-- </b-collapse> -->
    </b-navbar>
  </div>
</template>
<script>
const PREFIX_CLS = "ac-header";
const ACCEPTED_STYLES = [
  "primary",
  "secondary",
  "success",
  "warning",
  "danger"
];
const ACCEPTED_TEXT_STYLES = ["light", "dark"];
let index_of = require("lodash.indexof");
/**
 * Appup Header with capabilty to render controls either left or right or both
 *
 * TODOs:
 *
 * Actions for header controls
 *
 * Icons are not aligned properly
 */
export default {
  name: "ac-header",

  props: {
    /**
     * Id of input elements.
     * @label ID
     * @category_name 3_Advance
     */
    id: {
      type: String,
      required: false
    },

    /**
     * Title is object with keys are name, link_to, image. It shows APP LOGO/TITLE 
     * @param {object} title
     * @properties
        {
          "name": {"type": "String"},
          "link_to": {"type": "String"},
          "image": {"type": "String"}
        }
     * @label Title
     * @category_name 2_Data
     */
    title: {
      type: Object,
      required: false
    },

    /**
     * It shows left side NAVITEMS
     * @param {object} left_controls
     * @properties {"activeWhen": {"type": "Array"}, "icon": {"type": "String"}, "label": {"type": "String"}, "opens":{"type": "String"}, "type": {"type": "String"}, "icon": {"type": "String"}, "link_to": {"type": "String"}, "items": {"type": "String"},  "handler": {"type": "String"}, "handler_params": {"type": "String"}}
     * @label Left Controls
     * @category_name 2_Data
     */
    left_controls: {
      type: Array,
      required: false
    },

    /**
     * It shows right side NAVITEMS
     * @param {object} right_controls
     * @properties {"activeWhen": {"type": "Array"}, "icon": {"type": "String"},"label": {"type": "String"}, "opens":{"type": "String"}, "type": {"type": "String"}, "image": {"type": "String"}, "link_to": {"type": "String"}, "items": {"type": "String"}}
     * @label Right Controls
     * @category_name 2_Data
     */
    right_controls: {
      type: Array,
      required: false
    },

    /**
     * Style varient of the navbar Background color
     * @label Background Color
     * `primary, seconday, success, warning, danger`
     * @category_name 4_Style
     */
    navbgcolor: {
      type: String,
      default: "light"
    },

    /**
     * Type of the navbar color
     * @label Text Color
     * `light, dark`
     * @category_name 4_Style
     */
    text_color: {
      type: String,
      default: "light"
    }
  },

  data: function() {
    return {
      // Need to remove
      nav_item_active: false,
      title_varient:
        index_of(ACCEPTED_STYLES, this.navbgcolor) != -1
          ? this.navbgcolor
          : "light",
      title_text_color:
        index_of(ACCEPTED_TEXT_STYLES, this.text_color) != -1
          ? this.text_color
          : "light"
    };
  },

  methods: {
    /**
     * Dropdown on click call handler
     * @function item_clicked
     * @public
     * @param {object} item - item clicked in dropdown
     */
    item_clicked(item) {
      // Execute appup handler workflow
      if (item.handler && this.start) {
        this.start(item.handler, {
          helper: {
            component: this,
            item: {},
            custom: item.handler_params ? JSON.parse(item.handler_params) : {}
          }
        });
      }
    },

    /**
     * Dropdown on click call handler
     * @function item_clicked
     * @public
     * @param {object} item - item clicked in dropdown
     */
    show_mobie_buttons() {
      var right_obj = document.querySelectorAll("#right_con");
      var ob = right_obj[0].style.display;
      right_obj[0].style.display = ob == "block" ? "none" : "block";
    },

    /**
     * To determine active class for nav items
     * @function active_class
     * @public
     * @param {object} nav - item in navbar
     */
    active_class(nav) {
      if (nav.activeWhen) {
        for (var i = 0; i < nav.activeWhen.length; i++) {
          // For testing use $route.path
          if (this.$route.meta.url === nav.activeWhen[i]) {
            return "active";
          }
        }
      }
    },

    /**
     * To determine active attribute from dropdown item
     * @function active_class_dropdown_item
     * @public
     * @param {object} item - item in dropdown
     */
    active_class_dropdown_item(item) {
      if (item.activeWhen) {
        for (let i = 0; i < item.activeWhen.length; i++) {
          // For testing use $route.path
          if (this.$route.meta.url === item.activeWhen[i] ? true : false) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  }
};
</script>
<style>
@media screen and (max-width: 820px) {
  .show-app-list .t-dark {
    display: none;
  }
  .show-app-list > li,
  .show-app-list > div {
    display: inline-block;
    margin-top: 2px;
  }
  .show-app-list {
    position: absolute;
    top: 56px;
    height: 56px;
    width: 100%;
    background: rebeccapurple;
    text-align: right;
  }
}
.rightside-icons .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>