<template>
  <div class="ac-collection-table">
    <template v-if="is_custom_header">
      <ac-collection-table-custom-headers></ac-collection-table-custom-headers>
    </template>
    <header class="ac-collection-table-header">
      <!-- Use this slot for table header -->
      <slot name="header"></slot>
    </header>

    <!-- Show searchbar -->
    <template v-if="show_searchbar">
      <b-input-group class="w-25 float-right mb-3">
        <b-input
          v-model="search_value"
          :placeholder="searchbar_placeholder"
          @keyup="search_results()"
        />
        <b-input-group-append
          is-text
          class="search-symbol cursor-pointer"
          v-b-tooltip.hover
          title="Search"
        >
          <i class="fal fa-search" @click="on_input"></i>
        </b-input-group-append>
      </b-input-group>
    </template>
    <div v-if="actual_data && actual_data.length">
      <!-- ref: https://bootstrap-vue.js.org/docs/components/table/ -->

      <!-- use this slot to display data on top of table, like table caption-->
      <div v-if="pagination_type !== 'deselect'">
        <slot name="caption" :loaded_data_count="loaded_data_count" :total_count="total_count"></slot>
      </div>
      <b-table
        outlined
        responsive
        :items="actual_data"
        :fields="table_columns"
        @row-clicked="row_clicked($event)"
        @row-dblclicked="$emit('row_dblclicked', $event)"
        :class="height"
        @scroll.native="infiniteScroll"
        :style="scroll_style"
      >
        <!-- Checkbox template. Only shown when bulk property is true -->

        <template v-if="bulk" slot="HEAD_#">
          <b-form-checkbox class="cursor-pointer" v-model="select_all" @change="bulk_actions()" />
        </template>

        <template slot="HEAD_options">
          <!-- Use this slot for options head -->
          <slot name="actions_slot"></slot>
        </template>

        <template slot="#" slot-scope="actual_data">
          <div>
            <b-form-checkbox
              class="cursor-pointer"
              v-model="selected"
              :value="actual_data.item.id"
              @change.native="checkbox_click(actual_data.item.id)"
            />
          </div>
        </template>

        <!-- ref: https://stackoverflow.com/questions/51418781/pass-row-templates-to-vue-boostrap-table-from-parent-component-using-slots -->
        <template :slot="slot.key" slot-scope="row" v-for="slot in table_columns">
          <slot :name="slot.key" :item="row.item">{{row.item[slot.key]}}</slot>
        </template>

        <!--
        Dropdown template. Only shown when dropdown property is true
        and prop dropdown_options.options is present
        -->

        <template slot="options" slot-scope="actual_data">
          <!-- Use this slot for Options-->
          <slot name="option" :data="actual_data">
            <div>
              <ac-material-dropdown
                :options="dropdown_options_handler"
                :button_options="dropdown_button_options"
                :right="dropdown_button_options.right"
                @item_click="on_dropdown_item_click($event, actual_data.item)"
                :show_icon="show_dropdown_icons"
                :from_data="actual_data.item"
              >
                <!-- renders slot for material dropdown -->
                <template
                  :slot="dropdown.key"
                  slot-scope="data"
                  v-for="dropdown in dropdown_options_handler"
                >
                  <slot :name="dropdown.key" :item="data.data"></slot>
                </template>
              </ac-material-dropdown>
            </div>
          </slot>
        </template>
        <!-- Optional one to show when action object is present -->
        <template slot="actions" slot-scope="actual_data">
          <div>
            <ac-material-dropdown
              :options="dropdown_options_handler"
              :button_options="dropdown_button_options"
              :right="dropdown_button_options.right"
              @item_click="on_dropdown_item_click($event, actual_data.item)"
              :show_icon="show_dropdown_icons"
              :from_data="actual_data.item"
            >
              <!-- renders slot for material dropdown -->
              <template
                :slot="dropdown.key"
                slot-scope="data"
                v-for="dropdown in dropdown_options_handler"
              >
                <slot :name="dropdown.key" :item="data.data"></slot>
              </template>
            </ac-material-dropdown>
          </div>
        </template>
      </b-table>
      <!-- pagination option -->
      <template v-if="pagination_type === 'pagination'">
        <div class="my-3 float-right">
          <b-button
            class="mr-2"
            variant="outline-secondary"
            :disabled="page_number === 0"
            @click="prev_page"
           >Previous</b-button>
          <b-button
            class
            variant="outline-secondary"
            :disabled="page_number >= page_count"
            @click="next_page"
          >Next</b-button>
        </div>
      </template>
    </div>

    <div v-if="actual_data && !actual_data.length">
      <!-- Use this slot for Empty State -->
      <slot name="empty_state"></slot>
    </div>
    <!-- loader image -->
    <div v-show="is_loading" class="loader row">
      <!--onError is to handle the broken image of loader -->
      <img
        :src="loading_image"
        class="loader__image m-auto"
        :onError="onerror_handle_loader"
        alt="loader"
      />
    </div>

    <div
      v-show="load_more_type === 'loadMoreButton'"
      v-if="pagination_type === 'loadMore'"
      ref="loader_div"
      class="load-more text-center"
    >
      <!--
        Use this slot for load more button, shown only if load_more_type is 'loadMoreButton'
        and there is more data to fetch
      -->
      <slot name="load_more_button"></slot>
    </div>

    <footer class="ac__footer">
      <!-- Use this slot for footer -->
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
import acCollection from "./ac-collection.vue";

/**
 * ac Table component which can be used to display data from API in table view
 * @requires VueBootstrap
 * @requires ac-collection.vue
 * @requires ac-material-dropdown.vue
 *
 * Fires event on row click and row double click
 * @event row_clicked
 * @event row_dblclicked
 * @type {object} row data
 *
 * @type {object} row data
 *
 * @inherits ac-collection,ac-data,ac-preload
 *
 */

export default {
  name: "ac-collection-table",

  extends: acCollection,

  props: {
    /**
     * List of table columns.
     * @param {array} columns
     * @properties {"label": {"type": "String","description":"Give label name of table header"}, "key": {"type": "String","description":"Give key name from the data"}, "sortable": {"type": "Boolean","description":"Give true or false for sort option"}, "__slot__": {"type": "String","description":"write HTML code and data-scope:item"}}
     * @label Columns
     * @category_name 1_General
     */
    columns: {
      type: Array,
      required: true
    },

    /**
     * Whenever true show checkbox in the first column
     * @param {boolean} bulk
     * @label Bulk
     * @category_name 1_General
     */
    bulk: {
      type: Boolean,
      default: false
    },

    /**
     * Whenever true show dropdown in the last column
     * @param {boolean} dropdown
     * @label Dropdown
     * @category_name 1_General
     */
    dropdown: {
      type: Boolean,
      default: false
    },

    /**
     * If true - display dropdown options icon
     * @param {boolean} show_dropdown_icons
     * @label Show Dropdown Icons
     * @category_name 1_General
     */
    show_dropdown_icons: {
      type: Boolean,
      default: false
    },

    /**
     * Options for dropdown menu for each row
     * @param {Array} dropdown_options
     * @properties {"label": {"type": "String","description":"Give label name of option"}, "type": {"type": "String","description":"Type of the option Eg:edit,submit..etc"}, "icon": {"type": "String","description":"Give icon class for option"},"key":{"type": "String"}, "__slot__": {"type": "String"}, "handler": {"type": "String","description":"Select handler to perform action"}, "handler_params": {"type": "String","description":"Give params to be send to handler"}}
     * @label Dropdown Options
     * @category_name 1_General
     */
    dropdown_options: {
      type: Array,
      required: false
    },

    /**
     * Dropdown Button Options is a object with keys icon, variant, size, right. It shows three dots based upon icon in each row
     * @param {object} dropdown_button_options
     * @properties
        {
          "icon": {"type": "String", "default": "fas fa-ellipsis-v","description":"Give button icon class"},
          "variant": {"type": "String", "default": "outline-secondary", "description":"Give variant class for button", "values": ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark", "outline-primary", "outline-secondary", "outline-success", "outline-danger", "outline-warning", "outline-info", "outline-light", "outline-dark"]},
          "size": {"type": "String", "default": "sm", "description":"Give size of the button Eg:sm,lg,md", "values": ["sm", "md", "lg"]},
          "right": {"type": "Boolean", "default": "true", "description":"To display dropdown options right or left"}
        }
      * @label Dropdown Button Options 
      * @category_name 1_General 
     */
    dropdown_button_options: {
      type: Object,
      required: false
    },

    /**
     * Whenever true show searchbar above the collection table
     * @param {boolean} search bar
     * @label Show Searchbar
     * @category_name 3_Advanced
     */
    show_searchbar: {
      type: Boolean,
      default: false
    },

    /**
     * To change default place holder
     * @param {string} searchbar placeholder
     * @label Searchbar Placeholder
     * @category_name 3_Advanced
     */
    searchbar_placeholder: {
      type: String,
      default: "Search"
    },

    /**
     * Indicates based on what key search should be performed
     * @param {String} search key
     * @label Search Key
     * @category_name 3_Advanced
     */
    search_key: {
      type: String,
      required: false
    },

    /**
     * Whenever true show pagination buttons below the collection table
     * @param {boolean} pagination buttons
     * @label Show Pagination
     * @category_name 3_Advanced
     * @private
     */
    show_pagination: {
      type: Boolean,
      default: false
    },

    /**
     * When pagination is true displays the rows based on limit
     * @param {Number} limit
     * @label Limit
     * @category_name 3_Advanced
     * @private
     */
    limit: {
      type: Number,
      required: false
    },

    /**
     * When pagination is true to divide the rows in pages based on total count
     * @param {Number} totalcount
     * @label Total Count
     * @category_name 3_Advanced
     * @private
     */
    // TODO: defined in data
    // total_count: {
    //   type: Number,
    //   required: false
    // },

    /**
     * When pagination is true limit should apply for which key in ac_cursor
     * @param {Number} limit_key
     * @label Limit Key
     * @category_name 3_Advanced
     * @private
     */
    limit_key: {
      type: String,
      required: false
    },

    /**
     * When pagination is true offset should apply for which key in ac_cursor
     * @param {Number} offset_key
     * @label Offset Key
     * @category_name 3_Advanced
     * @private
     */
    offset_key: {
      type: String,
      required: false
    },
    /**
     * Only show when dropdown is true - default is options
     * @param {String} option_label key
     * @label Option Label
     * @category_name 1_General
     */
    option_label: {
      type: String,
      required: false,
      default: "options"
    },
    /**
     * Only show when custom header is needed - default is false
     * @param {String} is_custom_header key
     * @label Custom Header
     * @category_name 3_Advanced
     */
    is_custom_header: {
      type: Boolean,
      default: false
    },
    /**
     * Height class for scrolling
     * @param {String} height_class
     * @label Height Class
     * @category_name 4_Style
     */
    height_class: {
      type: String,
      required: false
    },
    /**
     * Style for sroll bar eg:{"over-flow" : "auto","max-height" : "350px"}
     * @param {Object} scroll_style
     * @label Scroll Style
     * @category_name 4_Style
     */
    scroll_style: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      selected: [],
      select_all: false,
      current_item: null,
      search_value: "",
      stored_data: [],
      offset: 0,
      page_number: 0,
      custom_header: [],
      sticky_header: false,
      total_count: 0,
      bulk_selected: []
    };
  },

  computed: {
    /**
     * Adds column depending on bulk and dropdown props
     * @private
     */
    table_columns() {
      const cols = [...this.columns];

      if (this.bulk) {
        cols.unshift("#");
      }

      if (this.dropdown) {
        cols.push(this.option_label);
        // for(var i=0; i<cols.length; i++){
        //   if(cols[i].label === "options"){
        //     cols.splice(i,1);
        //     cols.push('options')
        //   }else if(cols[i].label === "actions"){
        //     cols.push('actions')
        //     cols.splice(i,1);
        //   }else{
        // console.log("Nothing");
        //   }
        // }
      }

      return cols;
    },

    /**
     * Depends on dropdown_options prop
     * If dropdown prop is true - checks for dropdown_options presense
     * @private
     */
    dropdown_options_handler() {
      if (!this.dropdown) return [];

      if (this.dropdown && !this.dropdown_options) {
        console.error(
          "dropdown_options is required when dropdown prop is true"
        );
        return [];
      }

      return [...this.dropdown_options];
    },
    /**
     * getting count of loaded data
     */
    loaded_data_count() {
      // return this.actual_data.length;
      return parseInt(this.limit) + parseInt(this.offset);
    },
    /**
     * diabling previous and next buttons based on data length
     * @return {Integer} - Page Number
     */
    page_count() {
      let l = parseInt(this.total_count);
      let s = parseInt(this.limit);
      if (l % s != 0) return Math.floor(l / s);
      else return Math.floor(l / s) - 1;
    },
    /**
     * Custom height class for the table
     * @return {String} - Custom class
     */
    height() {
      if (this.height_class) {
        return "table mb-0 custom-padding " + this.height_class;
      } else {
        return "table mb-0 custom-padding";
      }
    },
    /**
     * To handle the broken image of loader
     * @return {String} - Loading Image
     */
    onerror_handle_loader() {
      if (this.loading_image) {
        return "this.onerror=null;this.src='" + this.loading_image + "';";
      } else
        return "this.onerror=null;this.src='https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif';";
    }
  },

  watch: {
    ac_cursor(newval, oldval) {
      this.append_to_ac_cursor();
      this.set_ajax();
    },
    pagination_type(newval, oldval) {
      this.pagination_type = newval;
      this.handler_data();
    },
    show_searchbar(newval, oldval) {
      this.show_searchbar = newval;
      this.handler_data();
    },
    selected(newval, oldval) {
      this.select_checkbox(newval);
    },
    scroll_height(newval, oldval) {
      this.scroll_height = newval;
    },
    // To keep track with data on change
    data: {
      handler(val) {
        this.actual_data = this.data;
      },
      deep: true
    }
  },

  created() {
    if (this.pagination_type !== "deselect") {
      this.handler_data();
    }
    if (
      this.pagination_type == "loadMore" &&
      this.load_more_type == "infiniScroll"
    ) {
      this.scroll_style =
        typeof this.scroll_style == "string"
          ? JSON.parse(this.scroll_style)
          : this.scroll_style;
    }
  },

  methods: {
    /**
     * Executes on selecting checkbox from table header
     * @public
     */
    bulk_actions() {
      this.selected = [];
      if (!this.select_all) {
        this.actual_data.forEach(object => {
          this.selected.push(object.id);
        });
      }
      // It fires when click on header checkbox
      this.$emit("bulk_action", this.selected);
    },
    /**
     * append checkbox selected data to an array
     * @public
     * @returns {array} id's array of checked rows
     */
    get_selected() {
      return [...this.selected];
    },
    /**
     * It fires when click on each row checkbox
     * @param  {number} id - id of selected row's data
     */
    checkbox_click(id) {
      if (this.selected.length === this.actual_data.length)
        this.select_all = true;
      else this.select_all = false;
      //It fires when click on row checkbox
      this.$emit("checkbox_clicked", { id: id, checked: event.target.checked });
    },
    /**
     * Sets checked rows
     * @public
     * @param {array} data - array of row ids
     */
    set_selected(actual_data) {
      this.selected = [...actual_data];
    },

    /**
     * Executes on click of dropdown option
     * @public
     * @param {object} option - selected option events
     * @param {object} item - row info related to clicked dropdown
     */
    on_dropdown_item_click(option, item) {
      // Execute appup handler workflow
     if(option.handler && this.start){
       let custom = {};
       if (option.handler_params){
         try {
            custom = JSON.parse(option.handler_params);
         }catch{
             if (typeof option.handler_params === "object") {
               custom = option.handler_params;
             }
         }
       }
       this.start(option.handler, {
         helper: {
           component: this,
           item: item,
           custom: custom
         }
       });
    }  
     
      // It fires when Dropdown Click
      this.$emit("dropdown_click", {
        type: option.type,
        item
      });
    },
    /**
     * Execute when clicked on row
     */
    row_clicked(event) {
      this.current_item = event;
      // It fires when Row Click
      this.$emit("row_clicked", event);
    },
    /**
     * search based on the search_value entered from searchbar
     * @return {Array} - Filtered data based on search value
     */
    search_results() {
      this.actual_data = this.stored_data.filter(item => {
        if (this.search_value) {
          return item[this.search_key]
            .toLowerCase()
            .includes(this.search_value.toLowerCase());
        } else return this.stored_data;
      });
    },

    /**
     * Get data when scroll reaches end
     */
    async infiniteScroll(event) {
      if (this.load_more_type == "infiniScroll") {
        if (
          event.target.scrollTop + event.target.offsetHeight >=
          event.target.scrollHeight
        ) {
          if (this.loaded_data_count < this.total_count) {
            const result = await this.loadmore_click_fun();
            this.stored_data = this.actual_data.concat(this.data);
          }
        }
      }
    },
    /**
     * get data from url
     */
    async handler_data() {
      if (this.pagination_type == "pagination") {
        this.append_to_ac_cursor();
      }
      const msg = await this.set_ajax();
      this.stored_data = this.data;
      this.actual_data = this.data;
      console.log("handler_data.... ", this.stored_data);
    },
    /**
     * invoke by clicking on next button when pagination is true
     * increments page number and offset value
     */
    next_page() {
      this.select_all =
        (this.selected.length >= this.actual_data.length)? true : false;
      this.page_number++;
      this.offset += parseInt(this.limit);
      this.append_to_ac_cursor();
      this.handler_data();
    },
    /**
     * invoke by clicking on previous button when pagination is true
     * decrement page number and offset value
     */
    prev_page() {
      this.page_number--;
      this.offset -= parseInt(this.limit);
      this.append_to_ac_cursor();
      this.handler_data();
    },
    /**
     * appending limit and offset values when pagination is true
     */
    append_to_ac_cursor() {
      if (typeof this.ac_cursor == "string") {
        this.ac_cursor = {};
      }
      this.ac_cursor[this.limit_key] = parseInt(this.limit, 10);
      this.ac_cursor[this.offset_key] = parseInt(this.offset, 10);
    },
    /**
     * Get custom headers
     * @public
     */
    get_headers() {
      var jsonKeys = Object.keys(this.data[0]);
      var columns = JSON.parse(JSON.stringify(this.columns));
      this.tableJsondata = [];
      //modified JSON for checkboxes
      for (var i = 0; i < jsonKeys.length; i++) {
        for (var j = 0; j < columns.length; j++) {
          if (columns[j].key === jsonKeys[i]) {
            columns[j]["checked"] = true;
            this.tableJsondata.push(columns[j]);
            columns.splice(j, 1);
            delete jsonKeys[i];
          }
        }
        if (jsonKeys[i]) {
          var object = {
            label: jsonKeys[i],
            sortable: true,
            key: jsonKeys[i]
          };
          object["checked"] = false;
          this.tableJsondata.push(object);
        }
      }
      // this.showCustomheader = true;
      return this.tableJsondata;
    },
    /**
     * show custom headers
     */
    add_headers(newLabel, newKey) {
      this.columns.push({
        label: newLabel,
        key: newKey,
        checked: false
      });
      this.tableJsondata.push({
        label: newLabel,
        key: newKey,
        checked: false
      });
      this.custom_header.push({
        label: newLabel,
        key: newKey,
        checked: false
      });
      this.data[0][newKey] = "";
    },
    /**
     * Set custom headers
     */
    set_headers(clickedData) {
      var columnsdata = JSON.parse(JSON.stringify(this.columns));
      // findout index of selected data
      var index = columnsdata.findIndex(p => p.key == clickedData.key);
      if (index != -1) {
        columnsdata.splice(index, 1);
        this.columns = columnsdata;
      } else {
        columnsdata.push(clickedData);
        this.columns = columnsdata;
      }
    },
    /**
     * Execute selected checkbox
     */
    select_checkbox(newval) {
      // It fires when Row Click
      this.$emit("selected_checkbox", newval);
    },
    /**
     * Executes on click of searchbar icon and emits event
     */
    on_input() {
      this.$emit("search_button_click", this.search_value);
    },
    /**
     * To get all the bulk ids from data
     */
    get_bulk_selected(){
      
      this.bulk_selected=[];
     
      //To get all the data selected
       if (this.url) {
         //fetch the data
        fetch(this.url, { })
          .then(res => res.json())
          .then(res => {
             if (res.length) {
                res.forEach(object => {
                     this.bulk_selected.push(object.id);
                 });
                 this.select_all = true;
             }
          })
          .catch(err => {});
           //to check all the checkboxes
           this.selected = this.bulk_selected;
           this.$emit("bulk_select", this.bulk_selected);
      } 
      
    }
  }
};
</script>

<style >
/* .loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, .65);
} */
.table-thead-border th {
  border-bottom-width: 0 !important;
  white-space: nowrap !important;
}
.table-thead-border td {
  vertical-align: middle !important;
}
.table thead th {
  border-bottom: 0px !important;
}
table {
  margin-bottom: 0px !important;
}
.ac-collection-table th,
.ac-collection-table td {
  white-space: nowrap !important;
}
.cursor-pointer .custom-control-label {
  cursor: pointer;
}
</style>
