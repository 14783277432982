
<script>
/**
 * ac timeago component
 * @requires VueBootstrap
 * @requires VueMoment
 */

export default {
  name: 'ac-timeago',

  props: {
    /**
     * @param {string} date - date to work with.
     */
    date: {
      type: String,
      required: true,
    },

    /**
     * @param {string} in_date_format - format of date prop.
     */
    in_date_format: {
      type: String,
      required: true,
    },

    /**
     * @param {string} out_date_format - in some methods data will be returned in this format.
     */
    out_date_format: {
      type: String,
      required: true,
    },
  },

  methods: {
    /**
     * @function convert_date - convert date to other format
     * @param {string} date - date to be formatted
     * @param {string} informat - format of date
     * @param {string} outformat - date will be formatted to this format
     * @returns {string} formatted date
     */
    convert_date(date, informat, outformat) {
      const d = this.$moment(date, informat)
      return d.format(outformat)
    },

    /**
     * @function from_now - get difference between date and now
     * @param {string} date - date to be formatted. Default date prop
     * @param {string} informat - format of date. Default in_date_format prop
     * @returns {string} difference
     */
    from_now(date = this.date, informat = this.in_date_format) {
      const d = this.$moment(date, informat)
      return d.fromNow()
    },

    /**
     * @function from_now - get difference between prop date and given date
     * @param {string} date - date
     * @param {string} informat - format of date
     * @returns {string} difference
     */
    from(date, informat) {
      const start = this.$moment(this.date, this.in_date_format)
      const end = this.$moment(date, informat)
      return end.from(start)
    },

    /**
     * @function custom_from_date - manipulation with date
     * @param {string} date - date
     * @param {string} status - what to do with date
     * Must start with '+' or '-' and operations divided with commas
     * E.g. '+ 7 days, 1 year, 2 hours'
     * @returns {string} calculated date
     */
    custom_from_date(date, status) {
      const d = this.$moment(new Date(date))
      const type = status.slice(0, 1) === '+' ? 'add' : 'subtract'
      const val = status.substr(1, status.length)
      val.split(',').forEach((el) => {
        const str = el.trim()
        d[type](str[0], str.substr(1, el.length).trim())
      })
      return d.format(this.out_date_format)
    },
  },

  render(h) {
    return h()
  },
}
</script>
