<script>
import draggable from 'vuedraggable'
import acSection from './ac-section.vue'

/**
 * ac Form component
 * @requires VueBootstrap
 */

export default {
  name: 'ac-form',

  components: {
    acSection,
    draggable,
  },

  props: {
    /**
     * @param {array} data - Array of objects. Each object will be rendered as form item
     * @label Data
     */
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    /**
     * @param {string} fetch_url - URL for fetch data for form
     * @label Fetch URL
     */
    fetch_url: {
      type: String,
      required: true,
    },
    /**
     * @param {object} props - Form properties
     * @label Properties
     */
    props: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    /**
     * @param {string} post_url - URL for submit form
     * (if id is there, it will use PUT, or POST for new)
     * @label Post URL
     */
    post_url: {
      type: String,
      required: true,
    },
    /**
     * @param {string} save_submit_route - after submit redirect to this route
     * @label Route- After Submit
     */
    save_submit_route: {
      type: String,
      required: true,
    },
    /**
     * @param {string} loading_image - image to show while fetching or submitting
     * @label Loading Image
     */
    loading_image: {
      type: String,
      required: true,
    },
  },

  render(h) {
    const children = this.form_data.map(item => h('ac-section', {
      props: {
        multi: item.multi,
        max: 10,
        item,
      },
      on: {
        input: (e) => { console.log('form input', e) },
      },
    }))

    const draggable_wrapper = h('draggable', {
      props: {
        list: this.form_data,
        options: {
          handle: '.col-form-label',
        },
      },
    },
    [
      children,
    ])

    return h('b-form',
      {
        attrs: { ...this.props, novalidate: !this.validate },
        on: { submit: this.on_form_submit },
      },
      [draggable_wrapper, this.$slots.default])
  },

  data() {
    return {
      show_loading_image: false,
      form_data: [],
    }
  },

  watch: {
    data(val) {
      if (val) {
        this.form_data = Object.assign([], val)
      }
    },
  },

  /**
   * @param {function} created - Initial params of data
   */
  created() {
    this.form_data = Object.assign([], this.data)
    if (this.fetch_url) {
      this.get_fetch_data()
    }
  },

  methods: {
    /**
     * @function on_form_submit - call on submit form.
     */
    on_form_submit(e) {
      e.preventDefault()
      const data = this.data.reduce((obj, item) => {
        if (item.multi) {
          let childs
          if (item.ac_type === 'Object') {
            childs = Object.keys(item.children).reduce((childObj, key) => {
              childObj[key] = item.children[key].value
              return childObj
            }, {})
          }
          if (item.ac_type === 'Array') {
            childs = item.children.map((item) => {
              return item.value
            })
          }
          obj[item.name] = childs
        } else {
          obj[item.name] = item.value
        }
        return obj
      }, {})

      this.$emit('submit', data)

      if (this.post_url) {
        this.show_loading_image = true
        fetch(`${this.post_url}`, {
          method: 'post',
          withCredentials: true, 
          credentials: 'include',
          body: JSON.stringify(data),
        }).then(() => {
          this.show_loading_image = false
          if (this.save_submit_route && this.$router) {
            this.$router.push(this.save_submit_route)
          }
        })
      }
    },
    /**
     * @function get_fetch_data - fetch data from URL.
     */
    get_fetch_data() {
      fetch(`${this.fetch_url}`,{withCredentials: true, credentials: 'include' }).then((response) => {
        if (response.ok) {
          const contentType = response.headers.get('content-type')
          if (contentType.indexOf('application/json') !== -1) {
            response.json().then((json) => {
              this.form_data = json
            })
          }
        }
      })
    },

    get_json() {
      return this.form_data
    },

    deserialize() {
      this.form_data = JSON.parse(this.form_data)
    },

    set_json(data) {
      this.form_data = data
    },
  },
}
</script>
