<template>
  <b-form-group v-bind="$attrs">
    <b-form-input v-bind="$attrs" v-on="$listeners" />
  </b-form-group>
</template>
<script>

/**
 * ac Input component
 * @requires VueBootstrap
 */

export default {
  name: 'ac-input',
}
</script>
