<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */

import AcCollection from "./ac-collection.vue";
import acSimpleCard from "./ac-simple-card.vue";

/**
 * ac collection card component
 * @requires VueBootstrap
 * @requires ac-collection.vue
 * @requires ac-simple-card.vue
 * @inherits ac-collection,ac-data,ac-preload
 *
 */

export default {
  name: "ac-collection-card",

  components: {
    acSimpleCard
  },

  extends: AcCollection,

  props: {
    /**
     * Whenever true - displays checkbox
     * @param {boolean} bulk - Whenever true - show checkbox
     * @label Bulk
     * @category_name 1_General
     */
    bulk: {
      type: Boolean,
      default: false
    },

    /**
     * Give image key from data to display in card
     * @param {string} card_img - key for image in each item inside data
     * @label Card Image
     * @category_name 1_General
     */
    card_img: {
      type: String,
      required: true
    },

    /**
     * Write HTML to display something on hover of the card image
     * @param {string} card_img_hover - html for hovering image
     * @label Card Image Hover
     * @category_name 1_General
     */
    card_img_hover: {
      type: String,
      required: true
    },

    /**
     * Give key name from data to display value in card header
     * @param {string} card_header - content of header of card
     * Not shown if empty
     * @label Card Header
     * @category_name 1_General
     */
    card_header: {
      type: String,
      required: true
    },

    /**
     * Give key name from data to display value in card title
     * @param {string} card_title - title of every card
     * @label Card Title
     * @category_name 1_General
     */
    card_title: {
      type: String,
      required: true
    },

    /**
     * Give key name from data to display value in card sub title
     * @param {string} card_subtitle - subtitle of every card
     * @label Card Subtitle
     * @category_name 1_General
     */
    card_subtitle: {
      type: String,
      required: true
    },

    /**
     * Give key name from data to display value in card body
     * @param {string} card_body - Body text of every card
     * @label Card Body
     * @category_name 1_General
     */
    card_body: {
      type: String,
      required: true
    },

    // /**
    //  * @param {string} card_body - Body text html tag
    //  */
    // card_body_html: {
    //   type: String,
    //   required: true,
    // },

    /**
     * Give key name from data to display value in card footer
     * @param {string} card_footer - Content of every card footer
     * Not shown if empty
     * @label Card Footer
     * @category_name 1_General
     */
    card_footer: {
      type: String,
      required: true
    },

    /**
     * Properties that support b-card Eg.{"img-start":true,"footer-text-variant":""} etc.
     * @param {object} card_options - Objects with properties for <b-card>
     * https://bootstrap-vue.js.org/docs/components/card
     * @label Card Options
     * @category_name 1_General
     */
    card_options: {
      type: Object,
      required: true
    },

    /**
     * Alignment of every card Eg.vertical,horizontal
     * @param {string} align - Alignment of every card
     * 'vertical,horizontal'
     * @label Align
     * @category_name 4_Style
     */
    align: {
      type: String,
      required: true,
      validator: value => ["vertical", "horizontal"].indexOf(value) > -1
    },

    // /**
    //  * @param {array} card_links - List of button links
    //  * Each item must contain label and href
    //  * @properties {"label": {"type": "String"}, "href": {"type": "String"}, "variant": {"type": "String"}}
    //  */
    // card_links: {
    //   type: Array,
    //   required: true,
    // },

    /**
     * Column size for card Eg.{md: 6, lg: 4}
     * @param {object} col_options - options for each column
     * @label Column Options
     * @category_name 4_Style
     */
    col_options: {
      type: Object,
      required: false,
      default: () => ({ md: 6, lg: 4 })
    },
    /**
     * Options for dropdown menu for each card
     * @param {object} dropdown_options - Options for dropdown menu for each row
     * @properties {"label": {"type": "String","description":"Give label name of option"}, "type": {"type": "String","description":"Type of the option Eg:edit,submit..etc"}, "icon": {"type": "String", "description":"Give icon class for option"}, "handler": {"type": "String","description":"Select handler to perform action"}, "handler_params": {"type": "String", "description":"Give params to be send to handler"}}
     * @label Dropdown Options
     * @category_name 1_General
     */
    dropdown_options: {
      type: Array,
      required: false
    },

    /**
     * If true - display dropdown options icon
     * @param {boolean} show_dropdown_icons
     * @label Show Dropdown Icons
     * @category_name 1_General
     */
    show_dropdown_icons: {
      type: Boolean,
      default: false
    },
    /**
       * Dropdown click button options - icon,variant,size,right side alignment
       * @param {object} dropdown_button_options
       * @properties
          {
            "icon": {"type": "String", "default": "fas fa-ellipsis-v","description":"Give button icon class"},
            "variant": {"type": "String", "default": "outline-secondary", "description":"Give variant class for button", "values": ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark", "outline-primary", "outline-secondary", "outline-success", "outline-danger", "outline-warning", "outline-info", "outline-light", "outline-dark"]},
            "size": {"type": "String", "default": "sm", "description":"Give size of the button Eg:sm,lg,md", "values": ["sm", "md", "lg"]},
            "right": {"type": "Boolean", "default": "true", "description":"To display dropdown options right or left"}
          }
       * @label Dropdown Button Options
       * @category_name 1_General
      */
    dropdown_button_options: {
      type: Object,
      required: false,
      default: () => ({
        icon: "far fa-ellipsis-h",
        variant: "bg-transparent p-0",
        size: "sm",
        right: true
      })
    },

    /**
     * To display searchbar
     * @param {boolean} search bar - Whenever true show searchbar above the collection table
     * @label Show Searchbar
     * @category_name 3_Advanced
     */
    show_searchbar: {
      type: Boolean,
      default: false
    },
    /**
     * To change default place holder
     * @param {string} searchbar placeholder
     * @label Searchbar Placeholder
     * @category_name 3_Advanced
     */
    searchbar_placeholder: {
      type: String,
      default: "Search"
    },
    /**
     * Field key to perform search action
     * @param {String} search key - indicates based on what key search should be performed
     * @label Search Key
     * @category_name 3_Advanced
     */
    search_key: {
      type: String,
      required: false
    },
    /**
     * To provide pagination
     * @param {boolean} pagination buttons - Whenever true show pagination buttons below the collection table
     * @label Show Pagination
     * @category_name 3_Advanced
     * @private
     */
    show_pagination: {
      type: Boolean,
      default: false
    },

    /**
     * When true display default card
     * @param {Boolean} show_default_card - When show_default_card is true user can add default card
     * @category_name 3_Advanced
     */
    show_default_card: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      button_options: {
        icon: "fas fa-ellipsis-v",
        variant: "light"
      },
      selected: [],
      header_slot: false,
      footer_slot: false,
      h: this.$createElement,
      search_value: "",
      test: "ds",
      stored_data: [],
      show: true,
      offset: 0,
      page_number: 0,
      current_item: null,
      total_count: 0
    };
  },

  computed: {
    //Not used
    is_horizontal() {
      return this.align === "horizontal";
    },
    /**
     * diabling previous and next buttons based on data length
     * @return {Integer}
     */
    page_count() {
      let l = parseInt(this.total_count);
      let s = parseInt(this.limit);
      if (l % s != 0) return Math.floor(l / s);
      else return Math.floor(l / s) - 1;
    }
  },

  watch: {
    /**
     * To keep tracking changes from user
     */
    show_searchbar(newval, oldval) {
      this.show_searchbar = newval;
      this.handler_data();
    },
    pagination_type(newval, oldval) {
      this.pagination_type = newval;
      this.handler_data();
    }
  },

  created() {
    /**
     * Making hader slot true to display data in header
     */
    if (this.card_header) this.header_slot = true;
    /**
     * Making footer slot true to display data in footer
     */
    if (this.card_footer) this.footer_slot = true;

    this.handler_data();
  },

  methods: {
    /**
     * Not used
     * To get the checked cards
     * @public
     * @returns {array} id's array of checked cards
     */
    get_selected() {
      return [...this.selected];
    },

    /**
     * Not used
     * Sets checked cards
     * @public
     * @param {array} data - array of cards ids
     */
    set_selected(data) {
      this.selected = [...data];
      if (this.$refs.ac_simple_card) {
        this.$refs.ac_simple_card.forEach(ref => {
          ref.checkbox =
            this.selected.indexOf(ref.data.id) > -1 ? ref.data.id : false;
        });
      }
    },

    /**
     * Renders column for each card
     * @private
     * @param {object} card - item from the data
     */
    render_column(card) {
      const ac_card = this.render_card(card);
      return this.h(
        "b-col",
        {
          props: this.col_options,
          class: "mb-3"
        },
        [ac_card]
      );
    },

    /**
     * Renders Default column
     * @private
     */
    render_default_column() {
      return this.h(
        "b-col",
        {
          attrs: {
            name: "defaultCard"
          },
          props: this.col_options,
          class: "mb-3"
        },
        this.$slots.defaultCard
      );
    },

    /**
     * Renders card using simple card
     * @private
     * @param {object} card - item from the data
     */
    render_card(card) {
      const hover_div = this.h("div", {
        class: {
          "h-100": true
        },
        domProps: {
          innerHTML: this.card_img_hover || ""
        }
      });

      //using simple card
      return this.h(
        "ac-simple-card",
        {
          props: {
            data: card,
            img: this.card_img,
            card_title: this.card_title,
            card_text: this.card_body,
            bulk: this.bulk,
            card_options: this.card_options,
            card_header: this.card_header,
            card_footer: this.card_footer,
            card_subtitle: this.card_subtitle,
            dropdown_options: this.dropdown_options,
            header_slot: this.header_slot,
            footer_slot: this.footer_slot,
            dropdown_button_options: this.dropdown_button_options,
            show_dropdown_icons: this.show_dropdown_icons
          },
          on: {
            input: event => {
              const index = this.selected.indexOf(event);
              if (index === -1) {
                this.selected.push(event);
              } else {
                this.selected.splice(this.selected.indexOf(event), 1);
              }
            },
            delete: event => {
              this.$emit("delete", event);
            },
            click: event => {
              this.current_item = event;
              this.$emit("click", event);
            }
          },
          ref: "ac_simple_card",
          refInFor: true
        },
        [
          this.h(
            "template",
            {
              slot: "hover"
            },
            [hover_div]
          )
        ]
      );
    },
    /**
     * get data from url if pagination true then appends limit and offset to url
     */
    async handler_data() {
      if (this.pagination_type === "pagination") {
        this.append_to_ac_cursor();
        const msg = await this.set_ajax();
        this.stored_data = this.data;
        this.actual_data = this.data;
      }
      this.show = false;
    },

    /**
     * Get data when we click loadmore button
     */
    async load_more_click_fun() {
      const result = await this.loadmore_click_fun();
      // this.stored_data = [];
      this.stored_data = [...this.actual_data];
      this.show = false;
    },

    /**
     * @private
     * Renders Load more button
     * If load_more_button slot is present then render it
     */
    render_load_more_button(h) {
      return h(
        "div",
        {
          class: {
            "d-none":
              this.pagination_type !== "loadMore" &&
              this.load_more_type !== "loadMoreButton",
            "load-more": true,
            "text-center": true,
            "mt-2": true
          }
        },
        [
          h(
            "button",
            {
              class: {
                "d-none": this.page_number >= this.load_option ? true : false,
                "btn btn-link": true
              },
              on: {
                click: this.load_more_click_fun
              }
            },
            "Load more"
          )
        ]
      );
    },

    /**
     * render search bar with searched data in UI
     * @return {Array} - filtered data based on search
     */
    render_search(h) {
      return h("b-form-input", {
        style: {
          marginBottom: "10px",
          width: "300px"
        },
        props: {
          value: this.search_value,
          placeholder: this.searchbar_placeholder
        },
        on: {
          keyup: event => {
            this.actual_data = this.stored_data.filter(item => {
              if (this.search_value) {
                return item[this.search_key]
                  .toLowerCase()
                  .includes(this.search_value.toLowerCase());
              } else return this.stored_data;
            });
            this.show = false;
          },
          input: value => {
            this.search_value = value;
          }
        }
      });
    },
    /**
     * render pagination buttons with functionality in UI
     */
    render_pagination(h) {
      return [
        h("b-button", {
          domProps: {
            innerHTML: "Previous"
          },
          on: {
            click: event => {
              this.page_number--;
              this.offset -= parseInt(this.limit);
              this.append_to_ac_cursor();
              this.handler_data();
            }
          },
          attrs: {
            disabled: this.disable_pre_btn_fun()
          }
        }),
        h("b-button", {
          domProps: {
            innerHTML: "Next"
          },
          style: {
            marginLeft: "10px"
          },
          on: {
            click: event => {
              this.page_number++;
              this.offset += parseInt(this.limit);
              this.append_to_ac_cursor();
              this.handler_data();
            }
          },
          attrs: {
            disabled: this.disable_next_btn_fun()
          }
        })
      ];
    },
    /**
     * appending limit and offset values when pagination is true
     */
    append_to_ac_cursor() {
      if (typeof this.ac_cursor == "string") {
        this.ac_cursor = {};
      }
      this.ac_cursor[this.limit_key] = parseInt(this.limit);
      this.ac_cursor[this.offset_key] = parseInt(this.offset);
      //console.log("after next page...",this.ac_cursor);
    },
    /**
     * To disable Previous button when pagination is true
     */
    disable_pre_btn_fun() {
      if (this.page_number === 0) return true;
    },
    /**
     * To disable Next button when pagination is true
     */
    disable_next_btn_fun() {
      if (this.page_number >= this.page_count) return true;
    }
  },

  render(h) {
    /**
     * intially this.is_loading is true - displays loader
     * after ajax success or failure becomes false
     */
    if (this.is_loading) {
      return h(
        "div",
        {
          class: "loader row"
        },
        [
          h("img", {
            class: "loader__image",
            attrs: {
              alt: "loader m-auto",
              src: this.loading_img,
              onError: () => {
                if (this.loading_image) {
                  return (
                    "this.onerror=null;this.src='" + this.loading_image + "';"
                  );
                } else
                  return "this.onerror=null;this.src='https://cmswebsites.s3.us-west-1.amazonaws.com/Dual%20Ring-0.9s-200px.gif';";
              }
            }
          })
        ]
      );
    }

    //rendering pagination buttons by calling render_pagination function
    let paginationElement = this.render_pagination(h);
    //rendering searchbar by calling render_search function
    let searchElement = this.render_search(h);
    // search data is not reflecting in UI, so forcefully deleting DOM
    if (!this.show) {
      this.show = true;
      return h(
        "div",
        {
          class: "ac-collection-card"
        },
        [searchElement]
      );
    }

    /**
     * rendering default card by calling render_default_column function
     */
    if (this.show_default_card) {
      var defaultCard = this.render_default_column();
    }

    /**
     * when data available renders data as cards
     */
    if (this.actual_data && this.actual_data.length) {
      // this.stored_data = this.actual_data;
      const columns = this.actual_data.map(card => {
        return this.render_column(card);
      });
      /**
       * rendering load more button by calling render_load_more_button function
       */
      const load_button = this.render_load_more_button(h);

      let display_ui_elements = [
        h("b-row", {}, [defaultCard, ...columns]),
        load_button
      ];
      //adding searchElement to be display in ui when show_searchbar is true
      if (this.show_searchbar) display_ui_elements.unshift(searchElement);
      //adding paginationElement to be display in ui when pagination_type is pagination
      if (this.pagination_type === "pagination")
        display_ui_elements.push(paginationElement);
      const wrapper = h(
        "div",
        {
          class: "ac-collection-card"
        },
        display_ui_elements
      );

      return wrapper;
    } else if (this.actual_data && !this.actual_data.length) {
      /**
       * when data is not available renders empty slot
       */
      let display_ui_elements = [this.$slots.emptyState];

      /**
       * When show search_bar or pagination_type is pagination then displays search bar and pagination element
       */
      if (this.show_searchbar) display_ui_elements.unshift(searchElement);
      if (this.pagination_type === "pagination")
        display_ui_elements.push(paginationElement);
      const wrapper = h(
        "div",
        {
          attrs: {
            name: "emptyState"
          }
        },
        display_ui_elements
      );
      return wrapper;
    }
  }
};
</script>

<style scoped>
.ac-collection-card__wrapper {
  position: relative;
}

.ac-collection-card__hover {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.2s ease;
}

.ac-collection-card__hover:hover {
  opacity: 1;
}

.loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.65);
}
.loader__image {
  width: 100px;
}
</style>
