<template>
  <b-modal ref="myModalref" hide-footer title="Using Component Methods" v-bind="$attrs">
    <template slot="modal-header">
      <slot name="modal-header"></slot>
    </template>
    <component :is="block" />
    <div class="modal-footer">
      <!-- @slot Use this slot for Modal footer -->
      <slot name="modal-footer"></slot>
    </div>
  </b-modal>
</template>

<script>

/**
 * ac modal component
 * @requires vue-bootstrap
 */

export default {
  name: "ac-modal",

  props: {
    /**
     * @param {String} block - name of modal body component.
     */
    block: {
      type: String,
      required: true
    },
    ref: {
      type: String
    }
  },
  mounted() {
    this.$refs.myModalref.show();
  }
};
</script>
