<template>
  <div>
    <div no-body class="mb-1" v-for="(tab, index) in data" :key="index">
      <div header-tag="header" class="p-1">
        <!--  Use this slot for title -->
        <slot name="title" :data="tab"></slot>
      </div>

       <slot :name="tab.ref?tab.ref:index" :data="tab"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "ac-simple-label",
  props: {
    /**
     * Array of objects. Each object will be rendered as tab
     * @param {object} data
     * @label data
     * @category_name 1_General
     */
    data: {
      type: Array,
      required: true
    }
  }
};
</script>