<template>
  <div class="ac-dynamic-view">
    <b-button-group v-if="!custom_buttons">
      <template v-for="(item,index) in config">
        <b-button
          variant="outline-secondary"
          :id="item.button_id"
          @click="switch_view(item,item.button_id)"
          :key="index"
          v-b-tooltip.hover :title="item.tooltip"
        >{{item.label}}</b-button>
        
      </template>
    </b-button-group>
    <component v-bind:is="current_component"></component>
  </div>
</template>
<script>
export default {
  name: "ac-dynamic-view",
  props: {
    /**
     * List of helpers
     * @properties {"label": {"type": "String"}, "button_id": {"type": "String"},"tooltip": {"type": "String"}, "helper": {"type": "String"},"helper_ref":{"type": "String"},"handler": {"type": "String","description":"Select handler to perform action"}, "handler_params": {"type": "String","description":"Give params to be send to handler"}}
     * @label Views
     * @category_name 2_Data
     */
    config: {
      type: Array
    },
    /**
     * Enable/Disable component buttons
     * @label Custom Buttons
     * @category_name 2_Data
     */
    custom_buttons: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      current_component: null,
      old_value: null,
      count: 0
    };
  },
  mounted() {
    try {
      var reference = localStorage.getItem(this.$vnode.data.ref + this.count);
      var active_component = localStorage.getItem(
        this.$vnode.data.ref + "_active"
      );
      var cur_helper_obj = null;
      //Save view to local storage
      if (reference) {
        this.current_component = reference;

        // Check for active component if exists
        if (active_component) {
          this.config.forEach(item => {
            if (item.helper == active_component) {
              cur_helper_obj = item;
            }
          });
          if (cur_helper_obj) {
            this.current_component = cur_helper_obj.helper;
            this.active_button(cur_helper_obj.helper, cur_helper_obj.button_id);
          } else {
            this.show_default_component();
          }
        } else {
          this.show_default_component();
        }

        /* for (var i = 0; i < this.config.length; i++) {
          if (reference == this.config[i].helper) {
            document
              .getElementById(this.config[i].button_id)
              .classList.remove("btn-outline-secondary");
            document
              .getElementById(this.config[i].button_id)
              .classList.add("btn-secondary");
          } else {
            document
              .getElementById(this.config[i].button_id)
              .classList.add("btn-outline-secondary");
            document
              .getElementById(this.config[i].button_id)
              .classList.remove("btn-secondary");
          }
        }*/
      } else {
        this.show_default_component();
      }
    } catch (error) {
      this.current_component = null;
      console.log("error is", error);
    }

    // Add event listner to dynamic id
    if (this.custom_buttons) {
      for (var i = 0; i < this.config.length; i++) {
        if (this.config[i].button_id) {
          document
            .getElementById(this.config[i].button_id)
            .addEventListener(
              "click",
              this.show_view(this.config[i], this.config[i].button_id)
            );
        }
      }
    }
  },
  methods: {
    /**
     * Change view
     * @param {String} helper -Name of helper
     * @param {Number} buttonid -key value of item
     */

    switch_view(item, buttonid) {
      if (item.handler) {
        this.callHandler(item);
      }
      this.count = this.count + 1;
      this.current_component = item.helper;
      localStorage.setItem(this.$vnode.data.ref + this.count, item.helper);
      localStorage.setItem(this.$vnode.data.ref + "_active", item.helper);
      this.active_button(item.helper, buttonid);
    },

    /**
     * Trigger Handler on button click
     * @param {Object} item -current item
     *
     */

    callHandler: function(item) {
      this.start(item.handler, {
        helper: {
          component: this,
          custom: item.handler_params ? item.handler_params : {}
        }
      });
    },

    /**
     * @returns {Object} obj -with all refs
     */

    get_all_refs() {
      var obj = {};
      var arr = [];
      var current_ref = "";
      this.config.forEach(item => {
        if (item.helper == this.current_component) {
          current_ref = item.helper_ref;
        }
        arr.push(item.helper_ref);
      });
      obj.refs = arr;
      obj.current_ref = current_ref;
      return obj;
    },

    /**
     * To Highlight the active button based on helper
     * @param {String} helper -Name of helper
     * @param {Number} buttonid -key value of item
     */
    active_button(helper, buttonid) {
      for (var i = 0; i < this.config.length; i++) {
        if (this.config[i].helper == helper) {
          document
            .getElementById(this.config[i].button_id)
            .classList.remove("btn-outline-secondary");
          document
            .getElementById(this.config[i].button_id)
            .classList.add("btn-secondary");
        } else {
          document
            .getElementById(this.config[i].button_id)
            .classList.add("btn-outline-secondary");
          document
            .getElementById(this.config[i].button_id)
            .classList.remove("btn-secondary");
        }
      }
    },
    //return function to addeventlistner
    show_view(helper, buttonid) {
      var _this = this;
      return function() {
        _this.switch_view(helper, buttonid);
      };
    },

    // To display first component as default component
    show_default_component() {
      this.current_component = this.config[0].helper;
      localStorage.setItem(
        this.$vnode.data.ref + this.count,
        this.current_component
      );
      document
        .getElementById(this.config[0].button_id)
        .classList.remove("btn-outline-secondary");
      document
        .getElementById(this.config[0].button_id)
        .classList.add("btn-secondary");
    }
  }
};
</script>

<style>
</style>