<template>
  <div>
    <b-form-file
      class="ac-local-upload_input mb-2"
      ref="file-input"
      v-model="file"
      @input="choose_file"
      @click.native="prevent_click"
    ></b-form-file>
    <b-button
      class="m-2 d-flex justify-content-center align-items-center"
      size="lg"
      variant="outline-dark"
      @click="upload"
    >
      <i class="fas fa-upload fa-2x"></i>
    </b-button>
  </div>
</template>
<script>
export default {
  props: {
    has_disable_click: {
      type: Boolean,
      default: true
    }
  },

  name: "ac-local-upload",

  data() {
    return {
      file: null,
      disable_click: true
    };
  },

  watch: {
    has_disable_click(val) {
      this.disable_click = val;
    }
  },

  methods: {
    prevent_click(ev) {
      if (this.disable_click) {
        ev.preventDefault();
      }
    },

    upload() {
      const old_val = this.disable_click;
      this.disable_click = false;
      this.$refs["file-input"].$el.querySelector('input[type="file"]').click();
      this.disable_click = old_val;
    },

    choose_file(file) {
      if (!file) return;
      this.$emit("choose", [
        {
          file,
          size: file.size,
          title: file.name
        }
      ]);

      this.$refs["file-input"].reset();
    }
  }
};
</script>