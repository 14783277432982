<template v-if="rule">
  <div>
    <div v-if="label" class="mb-2">{{label}}</div>
    <div class="row justify-content-center">
      <div class="col">
        <b-form-select
          :value="rule.selectedKey"
          :options="rule.keys.options"
          @input="setSelectedName('selectedNameKey', rule.keys.options, $event);"
          @change="update_rule({ selectedKey: $event })"
        ></b-form-select>
      </div>

      <div class="col" v-if="hasOperator">
        <b-form-select
          :value="rule.selectedOperator"
          @change="update_rule({ selectedOperator: $event })"
          :options="rule.operators[conditionType]"
        ></b-form-select>
      </div>

      <div class="col">

        <div v-if="conditionType == 'text'">
          <b-form-input
            type="text"
            :value="rule.selectedVal"
            @input="update_rule({ selectedVal: $event })"
            placeholder="Enter the text"
          ></b-form-input>
        </div>

        <div v-if="conditionType == 'date'">
          <appup-datetime
            type="date"
            :config="rule.controls.date.custom"
            placeholder="Select a date"
            :value="rule.selectedVal"
            @change="update_rule({ selectedVal: $event })"
            :id="'appupdate'+ruleInx"
          ></appup-datetime>
        </div>

        <div v-if="conditionType == 'number'">
          <div v-if="rule.selectedOperator === 'INBTW'" class="d-flex">
            <b-form-input
              type="number"
              :value="rule.selectedVal.min"
              @input="update_rule({ selectedVal: { ...rule.selectedVal, min: $event } })"
              placeholder="Min value"
            ></b-form-input>
            <b-form-input
              type="number"
              :value="rule.selectedVal.max"
              @input="update_rule({ selectedVal: { ...rule.selectedVal, max: $event } })"
              placeholder="Max value"
            ></b-form-input>
          </div>
          <b-form-input
            v-else
            type="number"
            :value="typeof rule.selectedVal === 'object' ? '' : rule.selectedVal"
            @input="update_rule({ selectedVal: $event })"
            placeholder="Enter the number"
          ></b-form-input>
        </div>

        <div v-if="conditionType == 'select'">
          <b-form-select
            :options="rule.controls.select.options"
            :value="rule.selectedVal"
            @change="update_rule({ selectedVal: $event })"
            @input="setSelectedName('selectedNameVal', rule.controls.select.options, $event);"
          ></b-form-select>
        </div>
        <div></div>
      </div>
      <button
        v-if="!onlyRoot"
        class="btn btn-xs btn-purple-outline btn-radius btn-purple-round mr-1"
        @click.prevent="deleteRule"
      >
        <i class="fal fa-trash-alt"></i>
      </button>

    </div>

    <div class="d-flex mt-3 justify-content-center ac-automation-rule_condition" v-if="hasCondition && !onlyRoot">
      <b-button-group>
          <b-button
            :variant="rule.condition == 'And' ? 'primary':'light'"
            @click="update_rule({ condition: 'And' })"
          >And</b-button>
          <b-button
            :variant="rule.condition == 'Or' ? 'primary':'light'"
            @click="update_rule({ condition: 'Or' })"
          >Or</b-button>
      </b-button-group>
    </div>
  </div>
</template>
<script>
export default {
  name: "ac-rule",

  props: {
    rule: {
      type: Object,
    },
    ruleInx: {
      type: Number
    },
    groupInx: {
      type: Number
    },
    onlyRoot: {
      type: Boolean,
      default: false
    },
    hasOperator: {
      type: Boolean,
      default: true
    },
    hasCondition: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      conditionType: "text",
    };
  },

  methods: {
    update_rule(changes) {
      const rule = { ...this.rule, ...changes }
      this.$emit('update:rule', {
        groupInx: this.groupInx,
        ruleInx: this.ruleInx,
        rule
      })
      this.onChangeKey(rule.selectedKey)
    },

    setSelectedName(key, options, val) {
      this.update_rule({
        [key]: (options.find(opt => opt.value === val) || {}).text
      })
    },

    onChangeKey(data) {
      try {
        let isControlApplied = false;
        this.rule.keys.options.map(item => {
          if (item.type && item.value == data) {
            if (item.type == "select") {
              this.rule.controls["select"].url = item.url;
            }
            this.conditionType = item.type;
            isControlApplied = true;
          }
        });
        if (!isControlApplied) this.conditionType = "text";
      } catch (e) {}
    },

    async getSelectList(val, url) {
      let optionsList = [];
      try {
        let dropdownList = await fetch(url, {
          withCredentials: true,
          credentials: "include"
        }).then(res => res.json());
        if (dropdownList && dropdownList.length) {
          optionsList = dropdownList.map(obj => {
            var newObj = {};
            newObj.text = obj[this.rule.keys.keytext];
            newObj.value = obj[this.rule.keys.keyvalue];
            newObj.type = obj.type;
            newObj.url = obj.url;
            return newObj;
          });
        }
      } catch (e) {
        optionsList = [];
      }
      if (val == "keys") this.rule.keys.options = [...optionsList];
      else this.rule.controls["select"].options = [...optionsList];
    },

    deleteRule() {
      this.$emit('delete', {
        ruleInx: this.ruleInx,
        groupInx: this.groupInx
      })
    },
  },

  watch: {
    conditionType(newVal, oldVal) {
      if (newVal == "select") {
        if (this.rule.controls["select"].url)
          this.getSelectList("controls", this.rule.controls["select"].url);
      }
    },
  },

  created() {
    if (this.rule.keys && this.rule.keys.url) {
      this.getSelectList("keys", this.rule.keys.url);
    }
  },
};
</script>
<style>
.ac-automation-rule_condition > .btn-group,
.ac-automation-rule_condition {
  position: relative;
  z-index: 1;
}

.ac-automation-rule_condition:after {
  content: "";
  position: absolute;
  top: 50%;
  width: calc(100% - 3rem);
  height: 2px;
  background-color: rgba(230, 230, 230, 0.5);
  z-index: 0;
}

.ac-automation-rule_condition > .btn-group:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  background: white;
  height: 100%;
  width: calc(100% + 3rem);
  transform: translateX(-1.5rem);
  z-index: -1;
}
</style>
