<template>
  <div class="ac-properties-editor">
    <b-modal
      title="Update table"
      v-model="isShowEditorModal"
      body-class="ac-properties-editor"
      size="lg"
      @ok="onModalOk"
      @hidden="onModalHidden">
      <b-form-input
        v-model="tableName"
        placeholder="Input table name"
        :state="getTableNameInputState()"/>
      <div class="mt-2 column-params">
        <div v-for="el in constants.EDITABLE_PROPERTIES" :key="el">{{el}}</div>
      </div>
      <div class="mt-2 column-params" v-for="(column, index) in columns" :key="column.id">
        <i
          :class="{
            'fas fa-key primary-key-select': true,
            'primary-key': getPrimaryKeyByColumn(column),
            'no-pointer-events': index === columns.length - 1
          }"
          @click="onClickPrimaryKey(column)"></i>
        <column-name-input
          :column="column"
          :value="column.column_name"
          :is_new="getIsNewColumnInput(index)"
          :state="getColumnNameInputState(column, getIsNewColumnInput(index))"/>
        <data-type-input
          :id="column.id"
          :value="column.data_type"
          :class="{ 'no-pointer-events': getIsNewColumnInput(index) }"
          :state="getColumnTypeInputState(column, getIsNewColumnInput(index))"/>
        <div
          :class="{ 'no-pointer-events': getIsNewColumnInput(index), 'null-select': true }"
          :style="`color: ${!column.not_null ? 'black' : '#b4b4b4'}`"
          @click="onClickNullSelect(column)">
          NULL
        </div>
        <default-value-input
          :id="column.id"
          :value="column.auto_increment || column.default"
          :class="{ 'no-pointer-events': getIsNewColumnInput(index) }"/>
        <i
          v-if="!getIsNewColumnInput(index)"
          :class="{ 'fas fa-times': true, 'remove-button': true }"
          @click="onClickRemoveButton(column)"></i>
      </div>
      <div class="error-message">{{error}}</div>
    </b-modal>
  </div>
</template>
<script>
import uuid from 'uuid'
import defaultValueInput from './default-value-input.vue'
import dataTypeInput from './data-type-input.vue'
import columnNameInput from './column-name-input.vue'
import store from '../store'
import constants from '../constants'

export default {
  name: 'ac-sql-builder-properties-editor',
  components: {
    defaultValueInput,
    dataTypeInput,
    columnNameInput,
  },
  computed: {
    isShowEditorModal: {
      get() {
        return store.getters.isShowEditorModal()
      },
      set(value) {
        if (!value) {
          store.actions.hideEditorModal()
        }
      },
    },
    sqlJson() {
      return store.getters.sqlJson()
    },
    currentTable() {
      const tables = store.getters.currentElements()
      return (tables.length === 1 && tables[0]) || {}
    },
    tableName: {
      get() {
        return this.currentTable.table_name
      },
      set(value) {
        store.actions.updateCurrentTableName(value)
      },
    },
    columns() {
      const columns = []
      if (this.currentTable.columns) columns.push(...this.currentTable.columns)
      columns.push(this.getEmptyColumn())
      return columns
    },
  },
  data() {
    return {
      h: null,
      error: '',
      invalidNames: [],
      invalidTypes: [],
      invalidTableName: false,
      constants,
    }
  },
  created() {
    this.h = this.$createElement
  },
  methods: {
    async onModalOk(e) {
      this.checkTextInputs()
      if (this.error) {
        e.preventDefault()
        return
      }
      await store.actions.hideEditorModal()
      await store.actions.saveCurrentTableToSqlJson()
    },
    onModalHidden() {
      store.actions.hideEditorModal()
      store.actions.setCurrentElements([])
      this.error = ''
      this.invalidTypes = []
      this.invalidNames = []
      this.invalidTableName = false
    },
    getPrimaryKeyByColumn(column) {
      if (!this.currentTable.keys) return null
      const primaryKeys = this.currentTable.keys
        .filter(key => key.type === 'primary' && key.columns.includes(column.column_name))
      return primaryKeys.length === 0 ? null : primaryKeys[0]
    },
    onClickPrimaryKey(column) {
      const key = this.getPrimaryKeyByColumn(column)
      if (!key) {
        store.actions.addCurrentTableKey('primary', [column.column_name])
        return
      }
      store.actions.removeCurrentTableKey(key)
    },
    onClickNullSelect(column) {
      store.actions.updateCurrentTableColumn(column.id, { not_null: !column.not_null })
    },
    onClickRemoveButton(column) {
      store.actions.removeCurrentTableColumn(column.id)
    },
    getEmptyColumn() {
      const column = {
        id: uuid(),
        column_name: '',
        data_type: '',
        not_null: '',
        auto_increment: '',
        default: '',
      }
      return column
    },
    getTableNameInputState() {
      return this.invalidTableName ? false : null
    },
    getColumnNameInputState(column, isNew) {
      return this.invalidNames.length === 0
        || this.invalidNames.indexOf(column.column_name) === -1
        || isNew ? null : false
    },
    getColumnTypeInputState(column, isNew) {
      return this.invalidTypes.length === 0
        || this.invalidTypes.indexOf(column.data_type) === -1
        || isNew ? null : false
    },
    getIsNewColumnInput(index) {
      return index === this.columns.length - 1
    },
    checkTextInputs() {
      this.error = ''
      this.invalidNames = []
      this.invalidTypes = []
      this.invalidTableName = false
      if (this.currentTable.table_name === '') {
        this.invalidTableName = true
        this.error = 'Table name must contain at least one character'
      }
      if (this.currentTable.columns.length === 0) {
        this.error = 'Create at least one column'
      }
      const columnNames = []
      const columnIds = []
      this.currentTable.columns.forEach((column) => {
        if (column.column_name === '') {
          this.error = 'Column names must contain at least one character'
          this.invalidNames.push(column.column_name)
        }
        if (column.data_type === '') {
          this.error = 'Data types must contain at least one character'
          this.invalidTypes.push(column.data_type)
        } else {
          const dataTypes = /^(?:(?:INTEGER|INT|SMALLINT|TINYINT|DECIMAL|DEC|FIXED|NUMERIC|FLOAT|REAL|DOUBLE|BIT|VARCHAR|CHAR|VARBINARY|BINARY|TINYBLOB|MEDIUMBLOB|LONGBLOB|BLOB|TINYTEXT|MEDIUMTEXT|LONGTEXT|TEXT|ENUM|SET)(?:\(\s*[\d]+(?:,\s*[\d]+)?\s*\))?)$|^(?:DATETIME|TIMESTAMP|DATE|TIME|YEAR|MULTIPOINT|MULTILINESTRING|MULTIPOLYGON|GEOMETRYCOLLECTION|POINT|LINESTRING|POLYGON|GEOMETRY)$/gi
          if (!dataTypes.test(column.data_type)) {
            this.error = `${column.data_type} is incorrect data type`
            this.invalidTypes.push(column.data_type)
          }
        }
        columnNames.push(column.column_name.toLowerCase())
        columnIds.push(column.id)
      })
      const sameColumns = columnNames
        .map((column, index) => (columnNames.indexOf(column.toLowerCase()) !== index ? index : -1))
      const invalidNames = sameColumns.filter(col => col !== -1).map(col => columnIds[col])
      if (invalidNames.length) this.error = 'Column names must be different'
      this.invalidNames = [...this.invalidNames, ...invalidNames]
    },
  },
}
</script>
<style scoped>
.selectedOption {
  background: #007bff;
  color: white;
}
.primary-key-select {
  text-align: center;
  color: #d6d6d6;
  line-height: 1.5;
  padding: 0.375rem 0rem;
  cursor: pointer;
}
.remove-button {
  text-align: center;
  cursor: pointer;
}
.remove-button:hover {
  color: red;
}
.primary-key {
  color:rgb(212, 174, 48);
}
.column-params {
  display: grid;
  grid-template-columns: 25px 200px 200px 70px 200px 25px;
  grid-gap: 10px;
  align-items: center;
  font-size: 14px;
}
.no-pointer-events {
  pointer-events: none;
}
.error-message {
  margin-top: 0.5em;
  color: #dc3545;
}
.null-select {
  text-align: center;
  font-weight: 700;
  cursor: pointer;
}
</style>
