<template>
  <div role="tablist">
    <div class="row mb-4">
      <div class="col-md-12">
        <!-- <button class="btn btn-primary float-right" @click="get_query">Get Query</button> -->
        <button class="btn btn-primary float-right" @click="adding_group">Add group</button>
      </div>
    </div>
    <template v-if="groups.length > 0">
      <b-card no-body class="mb-1" v-for="(grp,grp_index) in groups" :key="grp_index">
        <b-card-header
          header-tag="header"
          class="p-1"
          role="tab"
          v-b-toggle="'accordion'+grp_index"
        >
          <b-button-group>
            <b-button
              :variant="grp.condition == 'And' ? 'primary':'light'"
              @click.stop="grp.condition = 'And'"
            >And</b-button>
            <b-button
              :variant="grp.condition == 'Or' ? 'primary':'light'"
              @click.stop="grp.condition = 'Or'"
            >Or</b-button>
          </b-button-group>
        </b-card-header>
        <b-collapse :id="'accordion'+grp_index" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="row">
              <div class="col-md-12 mb-4">
                <button class="btn btn-primary float-right" @click="adding_rule(grp_index)">Add Rule</button>
                <br />
              </div>
              <div
                class="form-group col-md-12"
                v-for="(rule,rule_index) in grp.rules"
                :key="rule_index"
              >
                <ac-rule :rule="rule" ref="rules" :group-inx="grp_index" :rule-inx="rule_index"></ac-rule>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </template>
  </div>
</template>

<script>
import acRule from "./ac-rule.vue";
export default {
  name: "ac-builder",
  components: {
    acRule
  },
  props: {
    /**
     * Json Data for sections
     * @param {object} rule_json
     * @label Rule Json
     * @category_name 1_Data
     */
    rule_json: {
      type: Object
    },
    /**
     * Data to prefilled
     * @param {array} prefill_data
     * @label Prefill Data
     * @category_name 1_Data
     */
    prefill_data: {
      type: Array
    }
  },
  data() {
    return {
      groups: [],
      query_builder_output: [], // Not used
      query_build_json: {
        condition: "Or",
        rules: []
      }
    };
  },
  methods: {
    /**
     * Invoke on click of add group button
     * @function adding_group - To add a new group
     */
    adding_group() {
      var query_build_json = Object.assign({}, this.query_build_json);
      var rule_json = Object.assign({}, this.rule_json);
      query_build_json["rules"] = [{ ...rule_json }];
      this.groups.push(query_build_json);
    },
    /**
     * Invoke on click of add rule button
     * @function adding_rule - To add a new section for group
     * @param
     */
    adding_rule(grp_index) {
      var rule_json = Object.assign({}, this.rule_json);
      var rules = JSON.parse(JSON.stringify(this.groups[grp_index]["rules"]));
      this.groups[grp_index]["rules"] = [...rules, rule_json];
    },
    /**
     * Not using
     * @function get_query
     */
    get_query() {
      let rules = this.$refs.rules || [];
      var groups_list = [...this.groups];
      var final_query_list = [];
      groups_list.map((item, index) => {
        var final_query_json = { rules: [] };
        final_query_json["condition"] = item.condition;
        rules.map((ruleItem, ruleinx) => {
          if (rules[ruleinx].groupInx == index)
            final_query_json["rules"].push(ruleItem.ruleObj);
        });
        final_query_list.push(final_query_json);
      });
      console.log(this);
      return final_query_list;
      // this.$refs.jsonDisplay.show();
      // console.log(this.ruleBuilderJson);
      // console.log(JSON.stringify(final_query_list));
    },
    /**
     * Invoke on click of close button
     * @function delete_rule - To delete the added section
     */
    delete_rule({ ruleInx, groupInx }) {
      this.groups[groupInx].rules.splice(ruleInx, 1);
      if (!this.groups[groupInx].rules.length) this.groups.splice(groupInx, 1);
    }
  },
  created() {
    var groups = [];
    if (this.prefill_data.length) {
      var avail_data = [...this.prefill_data];
      avail_data.map(item => {
        var rulesList = [];
        let { condition, rules } = item;
        let { keys, operators, controls } = this.rule_json;
        rulesList = rules.map(rl => {
          return { ...rl, keys, operators, controls };
        });
        groups.push({ condition, rules: rulesList });
      });
    } else {
      this.adding_group();
    }
    this.groups = [...groups];
  },
  mounted() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>