<template>
  <b-modal
    ref="modal"
    hide-footer
    centered
    scrollable
    :title="title"
    class="ac-modal"
    body-class="p-0"
    @hide="on_modal_hide"
  >
    <b-tabs pills>
      <!-- Tab for 'Upload new file' -->
      <b-tab
        title-item-class="ac-tab__item"
        title-link-class="rounded-0 text-center"
      >
        <template slot="title">
          Upload new file
        </template>

        <div class="ac-tab__content border-top p-3 d-flex align-items-center flex-column">
          <div
            v-for="(file, index) in upload_previews"
            :key="index"
            class="d-flex list-item w-100 mb-2"
          >
            <img
              v-if="file.src"
              :src="file.src"
              :alt="file.name"
              class="preview"
            >

            <div class="file-details ml-3 d-flex flex-column justify-content-between ">
              <div class="file-name mb-2">{{ file.name }}</div>
              <div class="text-secondary">{{ file.size }} KB</div>
            </div>
          </div>
        </div>

        <div class="p-3 border-top">
          <b-form-file
            ref="fileinput"
            class="w-100"
            @change="on_upload_change"
          />
        </div>
      </b-tab>

      <!-- Tab for 'Upload from link' -->
      <b-tab
        title-item-class="ac-tab__item"
        title-link-class="rounded-0 text-center"
      >
        <template slot="title">
          Upload from link
        </template>

        <div class="ac-tab__content border-top p-3 d-flex justify-content-center flex-column">
            <label for="url-upload">FILE LINK</label>
          <b-form-input
            v-model="upload_link"
            id="url-upload"
          />
        </div>

        <div class="p-3 border-top d-flex justify-content-between">
          <b-button
            variant="outline-secondary"
            class="upload-btn"
            @click="on_cancel_link_click"
          >
            CANCEL
          </b-button>

          <b-button
            variant="primary"
            class="upload-btn"
            @click="on_upload_link_click"
          >
            UPLOAD FILE
          </b-button>
        </div>
      </b-tab>

      <!-- Tab for 'File images' -->
      <b-tab
        title-item-class="ac-tab__item"
        title-link-class="rounded-0 text-center"
        @click="get_gallery_images"
      >
        <template slot="title">
          File images
        </template>

        <div class="ac-tab__content border-top p-3 d-flex justify-content-between flex-wrap">
          <label
            v-for="(file, index) in gallery_images"
            :key="index"
            :class="{selected: gallery_selected.indexOf(file.id) > -1}"
            class="list-item mb-2"
          >
              <img
                v-if="file.url"
                :src="file.url"
                :alt="file.title"
                class="preview"
              >

              <input
                v-model="gallery_selected"
                :value="file.id"
                class="gallery-checkbox"
                type="checkbox"
                @change="$emit('select', gallery_selected)"
              >
          </label>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
/* eslint-disable no-alert */
/**
 * ac Image Upload component
 * @requires VueBootstrap
 */

export default {
  name: 'ac-image-upload',

  props: {
    /**
     * @param {string} title - Title of modal.
     * @label Title
     */
    title: {
      type: String,
      required: true,
    },

    /**
     * @param {string} gallery_url - Url of gallery images.
     * @label Gallery URL
     */
    gallery_url: {
      type: String,
      required: true,
    },

    /**
     * @param {string} upload_url - Url to upload images or links.
     * @label Upload URL
     */
    upload_url: {
      type: String,
      required: true,
    },

    /**
     * @param {string} extensions - List of allowed extensions.
     * @label Extensions
     */
    extensions: {
      type: String,
      required: true,
    },

    /**
     * @param {number} max_img_width - Maximal allowed image width.
     * @label Maximum Image Width
     */
    max_img_width: {
      type: Number,
      required: true,
    },

    /**
     * @param {number} max_img_height - Maximal allowed image height.
     * @label Maximum Image Height
     */
    max_img_height: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      gallery_images: [],
      upload_previews: [],
      gallery_selected: [],
      upload_link: '',
    }
  },

  methods: {
    /**
     * @function show - shows modal
     * @public
     */
    show() {
      this.$refs.modal.show()
    },

    /**
     * @function hide - closes modal
     * @public
     */
    hide() {
      this.$refs.modal.hide()
    },

    /**
     * @function on_modal_hide - triggers after modal is closed
     */
    on_modal_hide() {
      this.gallery_images = []
      this.upload_previews = []
      this.$refs.fileinput.reset()
    },

    /**
     * @function validate_extension - validates file extension
     * compares with extensions prop
     * @param {object} file
     */
    validate_extension(file) {
      const extensions_arr = this.extensions.split(', ')
      const { name } = file
      const file_extension = name.substring(name.lastIndexOf('.'), name.length)

      if (extensions_arr.indexOf(file_extension) > -1) {
        return true
      }

      console.error('Not allowed extension')
      return false
    },

    /**
     * @function on_upload_link_click - upoad file link to api
     * @async
     */
    async on_upload_link_click() {
      if (this.validate_URL(this.upload_link)) {
        const response = await this.send_to_api(this.upload_link)
        if (response.ok) {
          alert('File successfully uploaded')
          this.upload_link = ''
        }
        return
      }

      alert('Given file link is not valid url')
    },

    on_cancel_link_click() {
      this.upload_link = ''
    },

    /**
     * @function validate_URL - validates string to be url
     * @param {string} str
     */
    validate_URL(str) {
      const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
        + '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
      return !!pattern.test(str)
    },

    /**
     * @function get_gallery_images - get preview images from api
     * @async
     */
    async get_gallery_images() {
      const response = await fetch(this.gallery_url, {
        method: 'GET',
        withCredentials: true, 
        credentials: 'include' 
      })

      if (response.ok) {
        this.gallery_images = await response.json()
      }
    },

    /**
     * @function upload_file - upload files to api
     * @async
     */
    upload_file(image) {
      const formData = new FormData()
      formData.append('image', image)

      return this.send_to_api(formData)
    },

    /**
     * @function send_to_api - send data to api
     * @prop {any} data - data to send
     */
    send_to_api(data) {
      return fetch(this.upload_url, {
        body: data,
        withCredentials: true,
         credentials: 'include',
        method: 'POST',
      })
    },

    /**
     * @function on_upload_change - triggers when user uploads file
     * @async
     */
    async on_upload_change(event) {
      const { files } = event.target

      if (!files.length) {
        return
      }

      Array.from(files).forEach((file) => {
        if (this.validate_extension(file)) {
          const reader = new FileReader()

          const file_data = {
            size: file.size,
            name: file.name,
          }

          reader.onload = async (e) => {
            file_data.src = e.target.result

            const img = new Image()
            img.onload = async () => {
              let isValid = true
              let err_msg = ''

              // Uploaded image width validation
              if (img.width > this.max_img_width) {
                err_msg = `Validation failed, max image width is ${this.max_img_width} \n`
                isValid = false
              }

              // Uploaded image height validation
              if (img.width > this.max_img_height) {
                err_msg += `Validation failed, max image height is ${this.max_img_height} \n`
                isValid = false
              }

              if (!isValid) {
                alert(err_msg)
                this.$refs.fileinput.reset()
                return
              }

              const server_response = await this.upload_file(file)
              // After successfull validation push to preview
              if (server_response.ok) {
                this.upload_previews.push(file_data)
              }
            }

            img.src = e.target.result
          }

          reader.readAsDataURL(file)
        }
      })
    },
  },
}
</script>

<style>
  .ac-modal .ac-tab__content {
    height: 200px;
    overflow: auto;
  }

  .ac-modal .ac-tab__item {
    width: 33.33333%;
  }

  .ac-modal .preview {
    max-width: 120px;
    height: auto;
  }

  .ac-modal .list-item {
    height: 80px;
    width: 80px;
    min-height: 80px;
    cursor: pointer;
  }

  .ac-modal .list-item img {
    max-height: 100%;
    max-width: 100%;
  }

  .ac-modal .list-item.selected {
    border: 3px solid red;
  }

  .ac-modal .file-name {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ac-modal .upload-btn {
    width: 48%;
  }

  .ac-modal .gallery-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
</style>
