import state from './state'
import utils from '../utils'

const getCytoscapeElements = (sqlJson) => {
  if (!sqlJson || !sqlJson.tables) {
    return []
  }
  const nodes = utils.getTableNodes(sqlJson, 1)
  const edges = utils.getEdges(sqlJson)
  return { nodes, edges }
}

const getSQL = (sqlJson) => {
  if (Object.keys(sqlJson).length === 0) return ''
  let query = `CREATE DATABASE IF NOT EXISTS \`${sqlJson.database_name}\`;`
  query = `${query}\nUSE \`${sqlJson.database_name}\`;`
  sqlJson.tables.forEach((table) => {
    query = `${query}\nCREATE TABLE IF NOT EXISTS \`${table.table_name}\`(\n`
    table.columns.forEach((column, ind) => {
      query = `${query}${ind ? ',\n' : ''}\`${column.column_name}\` ${column.data_type}`
      if (column.not_null) query = `${query} ${column.not_null}`
      if (column.auto_increment) query = `${query} ${column.auto_increment}`
      if (column.default) query = `${query} DEFAULT ${column.default}`
    })
    table.keys.forEach((key) => {
      let constraint = ''
      if (key.type === 'key' || key.type === 'fulltext') {
        constraint = `${key.type === 'key' ? '' : 'FULLTEXT '}KEY \`${key.name}\` (`
      } else {
        if (key.name) constraint = `CONSTRAINT \`${key.name}\` `
        constraint = `${constraint}${key.type.toUpperCase()} KEY (`
      }
      key.columns.forEach((column, ind) => {
        constraint = `${constraint}${ind ? ',' : ''}\`${column}\``
      })
      constraint = `${constraint})`
      if (key.type === 'foreign') {
        constraint = `${constraint} REFERENCES \`${key.tableRef}\` (\`${key.columnRef}\`)`
      }
      query = `${query},\n${constraint}`
    })
    query = `${query}\n);`
  })
  return query
}

const getters = {
  isShowImportModal: () => state.isShowImportModal,
  isShowEditorModal: () => state.isShowEditorModal,
  isShowCommentModal: () => state.isShowCommentModal,
  isShowExportModal: () => state.isShowExportModal,
  isAddingTable: () => state.isAddingTable,
  isAddingEdge: () => state.isAddingEdge,
  isAddingComment: () => state.isAddingComment,
  sqlJson: () => state.sqlJson,
  cytoscapeElements: () => getCytoscapeElements(state.sqlJson),
  sql: () => getSQL(state.sqlJson),
  currentElements: () => state.currentElements,
  changedElements: () => state.changedElements,
  isRedrawNeeded: () => state.isRedrawNeeded,
  stack: () => state.stack,
  stackIndex: () => state.stackIndex,
  zoomMode: () => state.zoomMode,
  isMultiselectEnabled: () => state.isMultiselectEnabled,
}

export default getters
