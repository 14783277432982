<template>
  <b-form-group>
    <b-form-radio v-bind="$attrs" v-on="$listeners">
      <slot />
    </b-form-radio>
  </b-form-group>
</template>
<script>

/**
 * ac Radio component
 * @requires VueBootstrap
  */

export default {
  name: 'ac-radio',
  model: {
    prop: 'checked',
    event: 'change',
  },
}
</script>
