<template>
  <div>
    <div class="row m-0">
      <div class="col-lg-12 message-hover" :class="{'star':data_starred}">
        <div class="media p-0 show-on-hover rounded pt-1 time-text">
          <!-- Use this slot for avatar -->
          <slot name="message_avatar"></slot>
          <!-- message avatar slot ends -->

          <div class="media-body rounded align-items-center">
            <div class="d-inline-block">
              <!-- Use this slot for user_name -->
              <slot name="message_user_name"></slot>
              <!-- message user_name slot ends -->

              <!-- Use this slot for time -->
              <slot name="message_time"></slot>
              <!-- message time slot ends -->
            </div>

            <!-- Use this slot for toolbar -->
            <div class="visible-hidden float-right">
              <slot name="message_toolbar"></slot>
            </div>
            <!-- message toolbar slot ends -->

            <!-- Use this slot for message -->
            <slot name="message"></slot>
            <!-- message slot ends -->

            <!-- Use this slot for reactions -->
            <slot name="message_reactions"></slot>
            <!-- message reactions ends -->

            <!-- Use this slot for threads -->
            <slot name="message_threads"></slot>
            <!-- message threads ends -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ac-message",
  props: {
    /**
     * If true, star class will append.
     * @param {boolean} starred
     * @label starred
     * @category_name 4_Style
     */
    starred: {
      type: Boolean
    }
  },
  data() {
    return {
      data_starred: null
    };
  },
  mounted() {
    this.data_starred = this.starred;
  }
};
</script>

<style>
.toolbar-pos {
  float: right;
}
.star {
  background-color: #fceb8d;
}
.message-hover:hover {
  background-color: rgb(240, 240, 240);
}
.show-on-hover:hover .visible-hidden {
  opacity: 1;
}
.visible-hidden {
  opacity: 0;
  position: relative;
}
.thread-wrap {
  padding: 6px 15px 5px 15px;
  border: 1px solid transparent;
}
.custom-thread-image .collage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
}
.custom-thread-image .mr-3 {
  margin: 0 !important;
}
.custom-thread-image .collage .pr-4 {
  padding-right: 0.5rem !important;
}

.custom-thread-image .collage .replies-img {
  display: inline-block !important;
  margin: 0 !important;
}
.collage .replies-img {
  display: inline-block;
  margin: -4px !important;
  padding: 0 !important;
}
.thread-wrap:hover {
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 4px;
}
.visible-hidden .btn {
  border-radius: 6px;
  padding: 0.1rem 0.5rem !important;
}
</style>