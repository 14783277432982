<template>
  <div>
       <button type="button" class="btn btn-outline-secondary p-0">
                                <div>
                                    <div>
                                        <div>
                                            <b-dropdown variant="outline-secondary border-0" no-caret
                                                text="Customize table" title="Customize Table">
                                                <template slot="button-content"><i
                                                        class="fal fa-th-large fa-sm"></i></template>

                                                <b-dropdown-form class="p-3">
                                                    <b-form-checkbox class="mb-2 d-block"
                                                        v-for="tableJSONdata in tableJSON" :key="tableJSONdata.id"
                                                        @change="setHeaders(tableJSONdata)"
                                                       :id="tableJSONdata.key"
                                                        v-model="tableJSONdata.checked">{{ tableJSONdata.key }}
                                                    </b-form-checkbox>
                                                    <a id="show-btn" href="#" @click.prevent="showModal">Add Custom Header</a>
                                                </b-dropdown-form>
                                            </b-dropdown>
                                        </div>

                                        <!--  <b-dropdown  text="Customise table">
                                <b-dropdown-form>
                                       <b-form-checkbox class="mb-3" v-for="(cstm,index) in fieldsToCustomize"  v-model="cstm.isDisplay"  @change="customiseTableFun(cstm,$event)" v-if="cstm.title != 'Actions'">{{cstm.cklabel}}</b-form-checkbox>
                                </b-dropdown-form>
                                </b-dropdown>-->
                                    </div>
                                </div>
                            </button>
   <!-- <b-dropdown>
      <template slot="button-content">
        <i class="fa fa-plus" aria-hidden="true"></i>
      </template>
      <div v-for="tableJSONdata in tableJSON" :key="tableJSONdata.id">
        <b-dropdown-item>
          <input
            class="form-check-input"
            type="checkbox"
            @click="setHeaders(tableJSONdata)"
            :id="tableJSONdata.key"
            v-model="tableJSONdata.checked"
          >
          <label class="form-check-label" :for="tableJSONdata.key">{{ tableJSONdata.key }}</label>
        </b-dropdown-item>
      </div>
      <a id="show-btn" href="#" @click.prevent="showModal">Add Custom Header</a>
    </b-dropdown> -->
        <!-- Modal for adding custom elements -->
    <b-modal ref="my-modal" title="Add custom headers" hide-footer>
      <div class="d-block">
        <b-form-group id="fieldset-1" label="Label" label-for="input-1">
          <b-form-input id="input-1" v-model="label"></b-form-input>
        </b-form-group>
        <b-form-group id="fieldset-1" label="Key" label-for="input-1">
          <b-form-input id="input-1" v-model="key"></b-form-input>
        </b-form-group>
      </div>
      <b-button variant="primary" @click="addHeaders">Submit</b-button>
      <!-- <b-button variant="primary" @click="hideModal">Close Me</b-button> -->
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ac-collection-table-custom-headers",
  props: {
     /**
     * @param {object} componentref - Reference of the collection component
     * Required
     */
    componentref: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      tableJSON:'',
      label: "",
      key: ""
    };
  },
  methods: {
    getHeaderJSON(){
     // console.log(this.componentref.getHeaders());
      this.tableJSON = this.$parent.get_headers()
    },
    //retrive check and uncheckbox data
    setHeaders(tableJSONdata) {
      this.$parent.set_headers(tableJSONdata);
    },
    // show modal
    showModal() {
      this.$refs["my-modal"].show();
    },
    addHeaders() {
      if (this.label && this.key) {
        
        this.$parent.add_headers(this.label, this.key);
        this.label='';
        this.key='';
        this.$refs["my-modal"].hide();
      }
      
    }
  },
  created: {},
  mounted() {
    this.$root.$on('bv::dropdown::show', this.getHeaderJSON);
  }
};
</script>

<style>
</style>
