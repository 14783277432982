<template>
  <div :class="main_class">
    <!-- Get all the columns -->
    <div :class="item.class" v-for="(item,index) in columns" :key="index">
      <!-- Render each slot -->
      <slot :name="item.key"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ac-action-navbar",

  props: {
    /**
     * Preload handler execute
     */
    preload: {
      type: String,
      default: null
    },

    /**
     * Handler params for preload
     * @param {string} - handler_params
     * @category_name 1_General
     */
    handler_params: {
      type: String,
      required: false
    },

    /**
     * List of columns, each contaning slot.
     * @param {array} columns
     * @label columns
     * @properties {"key": {"type": "String"}, "class": {"type": "String"},"__slot__": {"type": "String"}}
     * @category_name 1_General
     */
    columns: {
      type: Array
    },
    /**
     * Main class.
     * @param {array} main_class
     * @label Main Class
     * @category_name 1_General
     */
    main_class: {
      type: String,
      default: "row"
    }
  },

  mounted() {
    // Execute appup handler workflow
    if (this.preload && this.start) {
      setTimeout(() => {
        this.start(this.preload, {
          helper: {
            component: this,
            custom: this.handler_params ? JSON.parse(this.handler_params) : {}
          }
        });
      }, 300);
    }
  }
};
</script>
