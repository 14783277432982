class Uploader {
    constructor({ name, component, is_cloud }) {
        this.name = name;
        this.component = component;
        this.is_cloud = Boolean(is_cloud)
    }
}

const GOOGLE_DRIVE = new Uploader({ 
    name: 'google-drive', 
    component: 'ac-google-picker',
    is_cloud: true
});

const ONEDRIVE = new Uploader({
  name: 'onedrive',
  component: 'ac-onedrive-picker',
  is_cloud: true
});

const LOCAL = new Uploader({
  name: 'local',
  component: 'ac-local-upload',
});

const DROPBOX = new Uploader({
  name: 'dropbox',
  component: 'ac-dropbox-picker',
  is_cloud: true
});

export {
  GOOGLE_DRIVE,
  ONEDRIVE,
  LOCAL,
  DROPBOX
}
