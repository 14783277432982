<template>
  <b-modal
    title="Import data"
    hide-footer
    v-model="isShowImportModal">
    <b-form @submit.prevent="onSubmitImportData">
      <b-form-select
        v-model="dataType"
        :options="dataTypeOptions"
        class="mb-3" />
      <b-form-textarea
        id="textarea"
        :placeholder="`Enter ${dataType}...`"
        :state="isValid"
        v-model="text"
        rows="15"
        class="mb-3" />
      <b-form-invalid-feedback :state="isValid" class="mb-2">
        {{errorMessage}}
      </b-form-invalid-feedback>
      <div class="d-flex justify-content-end">
        <b-button type="submit" variant="outline-primary" class="mr-2">Import</b-button>
        <b-button @click="onClickImportCancel" variant="primary">Cancel</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import store from './store'
import constants from './constants'

export default {
  name: 'ac-sql-builder-import-modal',
  data() {
    return {
      dataType: constants.JSON_TYPE,
      dataTypeOptions: [
        { value: constants.JSON_TYPE, text: constants.JSON_TYPE },
        { value: constants.SQL_TYPE, text: constants.SQL_TYPE },
      ],
      text: '',
      isValid: null,
      errorMessage: '',
    }
  },
  computed: {
    sqlJson() {
      return store.getters.sqlJson()
    },
    isShowImportModal: {
      get() {
        return store.getters.isShowImportModal()
      },
      set(value) {
        if (!value) {
          store.actions.hideImportModal()
        }
      },
    },
  },
  watch: {
    isShowImportModal(value) {
      if (value) {
        this.dataType = constants.JSON_TYPE
        this.text = ''
        this.isValid = null
      }
    },
  },
  methods: {
    async onSubmitImportData() {
      if (!this.text) {
        store.actions.hideImportModal()
        return
      }

      this.isValid = null
      let action = null
      switch (this.dataType) {
        case constants.JSON_TYPE:
          action = store.actions.setSqlJsonFromJsonString
          break
        case constants.SQL_TYPE:
          action = store.actions.setSqlJsonFromSqlString
          break
        default: break
      }

      if (!action) {
        return
      }

      try {
        await action(this.text)
        await store.actions.hideImportModal()
        await store.actions.setIsRedrawNeededState(true)
      } catch (error) {
        this.isValid = false
        this.errorMessage = error.message
      }
    },
    onClickImportCancel() {
      store.actions.hideImportModal()
    },
  },
}
</script>
