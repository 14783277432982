<template>
  <div>
    <header class="ac__header">
      <!--  Use this slot for header -->
      <slot name="header"></slot>
    </header>
    <div class="ac-tab" :class="align === 'vertical' ? 'vertical-tab' : 'horizontal-tab'">
      <b-card :class="card_class" no-body>
        <b-tabs
          v-bind="{
          vertical: align === 'vertical',
          ...tabs_attrs,
        }"
        >
          <template slot="tabs">
            <!--  Use this slot for new tab button -->
            <slot name="tabs"></slot>
          </template>

          <template slot="empty">
            <!-- Use this slot if no tabs -->
            <slot name="empty"></slot>
          </template>

          <!-- Tab will be created for each object in data prop -->
          <b-tab
            v-for="(tab,index) in tabData"
            :key="index"
            :title="tab.label"
            :active="tab.active"
          >
            <!--  Use this slot for tab title -->
            <template slot="title">
              <slot name="title" :data="tab"></slot>
            </template>

            <!--  Slot takes name from ref key inside data -->
            <slot :name="tab.ref" :data="tab"></slot>

            <!--  Slot for tab body when url exist -->
            <slot name="tab_body" :data="tab"></slot>

            <!-- Render html -->
            <span v-if="tab.html != undefined" v-html="tab.html"></span>
            <!-- Render helper if exist -->
            <template v-if="tab.key != undefined && load_onclick?index==active_tab:true">
              <slot :name="tab.key"></slot>
              <!-- <component :is="tab.helper"></component> -->
            </template>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <footer class="ac__footer">
      <!--  Use this slot for footer -->
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
/**
 * ac Tab component
 * @requires VueBootstrap
 * @requires axios
 */
import axios from "axios";

export default {
  name: "ac-tab",

  props: {
    /**
     * Options for data menu for each row
     * @param {object} data - Options for data menu for each row
     * @properties {"label": {"type": "String"},"key": {"type": "String"},"__slot__": {"type": "String"}}
     * @label Data
     * @category_name 2_Data
     */
    data: {
      type: Array,
      required: false
    },
    /**
     * Fetch data with url
     * @label URL
     * @category_name 1_General
     */
    url: {
      type: String,
      required: false
    },
    /**
     * Aligment of tabs horizontal,vertical
     * @param {string} align - Aligment of tabs.
     * horizontal,vertical. Can be changed in real time
     * @label Align
     * @category_name 3_Advanced
     */
    align: {
      type: String,
      default: "horizontal",
      validator: value => ["horizontal", "vertical"].indexOf(value) !== -1
    },

    /**
     *  Attributes for b-tab
     * @param {object} tabs_attrs .
     * @label Tab Attributes
     * @category_name 3_Advanced
     */
    tabs_attrs: {
      type: Object,
      required: false,
      default: () => ({})
    },
    /**
     * to load tabs on click
     * @param {Boolean} -load_onclick
     * @category_name 3_Advanced
     */
    load_onclick: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Class for card
     * @param {String} card_class
     * @label card_class
     * @category_name 4_Style
     */
    card_class: {
      type: String,
      required: false
    }
  },
  data: function() {
    return {
      active_tab: 0,
      tabData: this.data
    };
  },
  watch: {
    url(newval, oldval) {
      this.url = newval;
      this.fetch_data();
    },
    data(newData) {
      this.tabData = newData;
    }
  },
  created() {
    if (this.url) {
      this.fetch_data();
    }
  },
  methods: {
    /**
     * Get data from url
     */
    fetch_data() {
      if (this.url) {
        this.$appupajax.get2(this.url, {}, {}, {withCredentials: true, credentials: "include" }).
        // this.$appupajax.get2(this.url, {}, { withCredentials: true, credentials: "include" }).
                    then(this.$appupajax.handleResponse).then(response => {
            this.tabData = response.data;
          })
          .catch(error => {
            // handle error
            console.log(error);
          })
          .finally(function() {
            // always executed
          });
      } else {
        this.tabData = this.data;
      }
    },
    /**
     * @return {Number} -Index of current tab
     */
    get_active_tab() {
      return this.data[this.active_tab];
    }
  }
};
</script>
<style>
.vertical-tab .card {
  /* border: 0 !important; */
}
.vertical-tab .col-auto {
  width: 25% !important;
}
.vertical-tab .tabs .nav-pills {
  padding: 1rem !important;
  background-color: transparent !important;
  border: 1px solid #dee2e6 !important;
}
.vertical-tab .tab-content {
  border: 1px solid #dee2e6 !important;
}
.vertical-tab .tab-content .tab-pane {
  padding: 1rem !important;
}
.horizontal-tab .tabs .card-header {
  background-color: transparent !important;
}

.vertical-tab .tabs.row {
  flex-wrap: nowrap;
}
</style>
