import Vue from 'vue'

const state = Vue.observable({
  sqlJson: {},
  isShowImportModal: false,
  isShowEditorModal: false,
  isShowCommentModal: false,
  isShowExportModal: false,
  isAddingTable: false,
  isAddingEdge: false,
  isAddingComment: false,
  isMultiselectEnabled: false,
  currentElements: [],
  changedElements: [],
  isRedrawNeeded: false,
  stack: [],
  stackIndex: 0,
  zoomMode: '',
})

export default state
