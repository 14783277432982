<template>
  <sidebar-menu
    :menu="menu"
    :collapsed="collapsed"
    :width="width"
    :widthCollapsed="width_collapsed"
    :showChild="show_child"
    :theme="theme"
    :showOneChild="show_one_child"
    :rtl="rtl"
    @collapse="on_collapse"
    @itemClick="on_item_click"
  />
</template>

<script>
/**
 * ac vertical navigation component
 * @requires vue-sidebar-menu - Forked from https://github.com/yaminncco/vue-sidebar-menu
 */
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

export default {
  name: "ac-vertical-navigation",

  components: {
    SidebarMenu
  },

  // As it is a wrapper so keeping the props name similar to it
  props: {
    /**
     * Preload handler execute
     */
    preload: {
      type: String,
      default: null
    },

    /**
     * Handler params for preload
     * @param {string} - handler_params
     * @category_name 1_General
     */
    handler_params: {
      type: String,
      required: false
    },

    /**
     * Sidebar menu (required), Array of Objects for each row.
     * @param {object} menu
     * @label menu
     * @properties {"title": {"type": "String"}, "href": {"type": "String"},  "icon": {"type": "String"}, "child": {"type": "String"}}
     * @category_name 1_General
     */
    menu: {
      type: Array,
      required: true
    },

    /**
     * Sidebar Collapse state
     * @param {boolean} collapsed
     * @label collapsed
     * @category_name 3_Advanced
     */
    collapsed: {
      type: Boolean,
      default: false
    },

    /**
     * Sidebar width (expanded), default width is 350 px
     * @param {string} width
     * @label width
     * @category_name 4_Style
     */
    width: {
      type: String,
      default: "350px"
    },

    /**
     * Sidebar width (collapsed), default width_collapsed is 50 px
     * @param {string} width_collapsed
     * @label width_collapsed
     * @category_name 4_Style
     */
    width_collapsed: {
      type: String,
      default: "50px"
    },

    /**
     * To keep all child open
     * @param {boolean} show_child
     * @label show_child
     * @category_name 3_Advanced
     */
    show_child: {
      type: Boolean,
      default: false
    },

    /**
     * Sidebar theme (available themes: 'white-theme')
     * @param {string} theme
     * @label theme
     * @category_name 4_Style
     */
    theme: {
      type: String,
      default: ""
    },

    /**
     * To keep only one child opened at a time (first level only)
     * @param {boolean} show_one_child
     * @label show_one_child
     * @category_name 3_Advanced
     */
    show_one_child: {
      type: Boolean,
      default: false
    },

    /**
     * To display sidebar right to left, on the left side
     * @param {boolean} rtl
     * @label rtl
     * @category_name 3_Advanced
     */
    rtl: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    // Execute appup handler workflow
    if (this.preload && this.start) {
      setTimeout(() => {
        this.start(this.preload, {
          helper: {
            component: this,
            custom: this.handler_params ? JSON.parse(this.handler_params) : {}
          }
        });
      }, 300);
    }
  },

  methods: {
    /**
     * On menu collapsed
     * @function on_collapse
     * @public
     * @param {boolean} collapsed - is menu collapsed or not
     */
    on_collapse(collapsed) {
      this.$emit("collapse", collapsed);
    },

    /**
     * On menu-item clicked
     * @function on_item_click
     * @public
     * @param {object} event - event object
     * @param {object} item - item object which was clicked
     */
    on_item_click(event, item) {
      this.$emit("item_click", event, item);
    }
  }
};
</script>
<style>
/* .v-sidebar-menu {
  top: 56px;
  height: calc(100vh - 56px);
} */
</style>