import axios from 'axios'

const get_presigned_url = async (url, file) => {
    const config = {
        withCredentials: true,
        credentials: "include"
    };

    const response = await axios.post(url + file.name, config)

    return response && response.data
}

export default {
    props: {
        /**
         * S3 config object
         * @param {object} s3_config
         * @label s3_config
         * @category_name 3_Advanced
        */
        s3_config: Object
    },

    data() {
        return {
            cancel_tokens: {},
            current_upload_status: {},
            presigned_urls: {}
        }
    },

    methods: {
        async upload_to_s3(file) {
            if (!file) return

            const { s3_presign_url, s3_url } = await get_presigned_url(this.s3_config.url, file)

            this.presigned_urls[s3_presign_url] = file

            let config = {
                headers: {
                    'Content-Type': file.type
                },
                cancelToken: new axios.CancelToken(c => {
                    this.cancel_tokens[s3_presign_url] = c
                }),
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    this.current_upload_status = {
                        loaded: percentCompleted,
                        loaded_bytes: progressEvent.loaded
                    }
                }
            };

            const response = await axios.put(s3_presign_url, file, config)
            this.presigned_urls[s3_presign_url] = null
            return s3_url
        },
    }
}