<template>
  <div class="ac-bulk-actions">
    <b-button
      v-for="(button, index) in buttons"
      :key="index"
      :class="{'ml-2': index !== 0}"
      @click="on_button_click(button)"
    >
      {{ button.label }}
    </b-button>
  </div>
</template>

<script>
/* eslint-disable no-restricted-globals */
/**
 * ac bulk actions component
 * @requires VueBootstrap
 */

export default {
  name: 'ac-bulk-actions',

  props: {
    /**
     * @param {array} buttons - Buttons array to be rendered
     */
    buttons: {
      type: Array,
      required: true,
    },

    /**
     * @param {object} collection_ref - Component reference for handler
     */
    collection_ref: {
      type: Object,
      required: true,
    },
  },

  methods: {
    /**
     * @function on_button_click - Click handler for each button
     */
    on_button_click(button) {
      if (button.url) {
        this.send_request(button.url, button.method)
        return
      }

      if (button.type === 'delete') {
        if (confirm(button.message)) {
          this.collection_ref[button.handler]()
        }
        return
      }

      this.collection_ref[button.handler]()
    },

    /**
     * @function send_request - Sends request to given url with given method
     * @prop {string} url
     * @prop {string} method
     */
    send_request(url, method) {
      fetch(url, { method })
    },
  },
}
</script>

<style scoped>
</style>
