<template>
  <div>
    <!-- Give border to editor -->
    <div class="ac-compose-message border border-secondary d-flex rounded">
      <!-- Use this slot for attachment -->
      <slot name="attachment"></slot>

      <!-- Start of quill editor -->
      <quill-editor
        class="ac-quill-editor"
        v-model="content"
        ref="ac_quill_editor"
        :options="editor_options"
        @blur="editor_blur($event)"
        @focus="editor_focus($event)"
        @ready="editor_ready($event)"
        @input="editor_change($event)"
        @keypress.native="editor_key_press($event)"
        @keydown.native="editor_key_down($event)"
        @keyup.native="editor_key_up($event)"
      ></quill-editor>
      <!-- End of quill editor -->

      <!-- Mentions in editor i.e show @ and # in right side of editor -->
      <div class="d-flex align-item-center ac-quill-editor-icons mt-auto mb-2">
        <i class="fal fa-at px-1" @click="append_text_to_editor('@')"></i>
        <i class="fal fa-hashtag px-1" @click="append_text_to_editor('#')"></i>
      </div>
      <!-- End of Mentions -->
    </div>
    <!-- End of border to editor -->
  </div>
</template>

<script>
// Import all quill dependencies and plugins

// Import quillEditor as component and Quill for registering modules
import { quillEditor, Quill } from "vue-quill-editor";

// Import quill css
import "quill-emoji/dist/quill-emoji.css";

// Import all supported themes
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

// Import quill-editor modules
import MarkdownShortcuts from "quill-markdown-shortcuts";
import QuillMention from "quill-mention";
import QuillEmoji from "quill-emoji";
import AutoFormat from "quill-autoformat";

// Import delta for quill operations like add or delete char from editor
import Delta from "quill-delta";

// Register the plugins using Quill
Quill.register("modules/markdown_shortcuts", MarkdownShortcuts);
Quill.register("modules/quill_mention", QuillMention);
Quill.register("modules/quill_emoji", QuillEmoji);
Quill.register("modules/auto_format", AutoFormat);

// Global variables
// Import custom emoji list from JSON array
import CUSTOM_EMOJI_LIST from "./ac-compose-custom-emoji-list.js";

// md5 for generating hash code for gravatar in mentions
let MD5 = require("md5");

// for grouping mention on trigger key @ or #
let GROUPBY = require("lodash.groupby");

// Global variable to store mentions after grouping - Cannot use vue variables NEED TO FIX
let MENTIONS = {
  atValues: [],
  hashValues: []
};

// To maintain total mentions count
let MENTIONS_COUNT = 0;

// To disable enter event on certain conditions. Example - when emoji or mentions are selected
let DISABLE_ENTER = false;

// To determine when to emit mentions. Default do not emit.
let EMIT_MENTIONS = false;

export default {
  name: "ac-compose-message",

  components: {
    quillEditor
  },

  props: {
    /**
     * Preload handler execute
     */
    preload: {
      type: String,
      default: null
    },

    /**
     * Handler params for preload
     * @param {string} - handler_params
     * @category_name 1_General
     */
    handler_params: {
      type: String,
      required: false
    },

    /**
     * v-model html content for prepopulate the editor.
     * @param {string} value
     * @label value
     * @category_name 1_General
     */
    value: {
      type: String,
      required: true
    },

    /**
     * Placeholder for the editor.
     * @param {string} editor_placeholder
     * @label Editor placeholder
     * @category_name 1_General
     */
    editor_placeholder: {
      type: String,
      required: true
    },

    /**
     * Array of mentions.
     * @param {object} mentions
     * @properties {"trigger": {"type": "String"}, "list": {"type": "Array"}}
     * @label Mentions
     * @category_name 1_General
     */
    mentions: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      // content used for v-model for quill-editor
      content: "",

      // grouped mentions
      grouped_mentions: () => {},

      // includes all the selected mentions
      selected_mentions: [],

      // editor configuration
      editor_options: {
        // define modules
        modules: {
          // toolbar for quill-editor
          toolbar: [["bold", "italic", "underline"]],

          // emoji module in quill-editor
          "emoji-shortname": {
            // Add custom emojis into quill editor
            emojiList: CUSTOM_EMOJI_LIST,

            // When emoji dropdown menu is opened, this function is called. Use it to disable enter emit functionality.
            onOpen: function() {
              DISABLE_ENTER = true;
            },

            // When emoji dropdown menu is closed, this function is called. Use it to enable enter emit functionality after 1 second.
            onClose: function(emoji_list_item) {
              // Wait until enter event is emitted after emit again re-assign DISABLE_ENTER.
              // See keyUp and keyDown events for ref.
              setTimeout(function() {
                DISABLE_ENTER = false;
              }, 1000);
            },

            // shortname is the key for emojis in custom emojilist
            keys: ["shortname"]
          },

          // Whether to display emoji in textarea of editor
          "emoji-textarea": true,

          // If want to add any custom markdown rules, in our case we want default rules so keep empty object
          markdown_shortcuts: {},

          // Auto format module used to perform autolink in the editor
          auto_format: {
            // Dont need any tranform for mentions
            hashtag: {
              trigger: /$a/,
              find: /$a/i,
              extract: /$a/i,
              transform: "",
              insert: ""
            },

            mention: {
              trigger: /$a/,
              find: /$a/i,
              extract: /$a/i,
              transform: "",
              insert: ""
            },

            // Add custom regEx for url identification and conversion
            link: {
              // Determine when to trigger this module, in our case trigger all time.
              trigger: /[\s]/g,

              // RegEx which we need to match. Any string which starts from www, http, https or any string that ends with (fullstop) followed by minimum two character
              find: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,

              // Transform is called when string matching any regEx is matched. This method is used to convert particular string into link.
              // value is the string which is matched with regEx
              transform: function(value, noProtocol) {
                // Apply protocols if url does not include them
                if (value.startsWith("http") || value.startsWith("https")) {
                  // return the string if it starts with http or https. Considering it to be a link copied.
                  return value;
                }

                // If string starts with www, auto link it by prepending protocol
                else if (value.startsWith("www")) {
                  // prepend https://
                  value = "https://" + value;
                }

                // Last case where the string does not have protocols and www
                else {
                  // prepend http://www. to the string
                  value = "https://www." + value;
                }

                // Finally after all the operations return the created link
                return value;
              },

              // Determines in which format we want output of transform, in our case we want it to be link
              format: "link"
            }
          },

          // Quill module for mentions integration in editor
          quill_mention: {
            // Default plugin configuration

            // Trigger for all characters
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,

            // When to denote mentions, accepts an array of characters
            mentionDenotationChars: ["@", "#"],

            // Default menu orientation for mentions, since editor will be at bottom considering top menu oreintation
            defaultMenuOrientation: "top",

            // This method is callback, fires when any mention is selected
            onSelect(item, insert_item) {
              // Make emit mentions true so we can emit them
              EMIT_MENTIONS = true;

              // Default method used by plugin to render mention
              insert_item(item);
            },

            // This method is callback, fires when Mentions menu is opened.
            onOpen() {
              // Need to over-ride enter emit
              DISABLE_ENTER = true;
            },

            // This method is callback, fires when Mentions menu is closed.
            onClose() {
              // Wait until enter event is over-ride.
              // See keyUp and keyDown events for ref.
              setTimeout(function() {
                DISABLE_ENTER = false;
              }, 1000);
            },

            // Render each item with custom html into mentions list
            renderItem: (item, mentions_search_string) => {
              // If item has email considering it to be user and not channel
              if (item.email) {
                // create temp variables to store data, else it renders undefined
                // Empty status
                let status = "";

                // Empty username
                let username = "";

                // Generate the email hash code for gravatar API
                let email_hash_gravatar = MD5(item.email);

                // Append the hash code to finalize url for image generation
                // params d=retro represents the type of display we want in gravatar, we using retro ~ similar to github and npm
                let image_url =
                  "http://www.gravatar.com/avatar/" +
                  email_hash_gravatar +
                  "?d=retro";

                // If status exists in item, assign to temp status variable
                if (item.status) {
                  status = item.status;
                }

                // If username exists in item, assign to temp username variable
                if (item.username) {
                  username = item.username;
                }

                // If image_url exists in item, assign to temp image_url variable
                if (item.image_url) {
                  image_url = item.image_url;
                }

                // At last render a user in mention by custom HTML
                return `
                        <div class="cql-list-item-inner">                    
                          <img src="${image_url}" class="cql-list-item-inner-image">
                            <strong>${item.value}</strong>
                            ${status}
                            <span><small style="color:grey;">${username}</small>
                          </div>
                        </div>
                      `;
              }

              // If item does not have email, considering as channel
              else {
                // else case - Assuming channels dont have emails
                // Render the below custom HTML for channels
                return `
                        <div class="cql-list-item-inner">
                        <strong>
                        <i class="fal fa-hashtag fa-sm small"></i>
                          ${item.value}
                        </strong>
                        </div>
                      `;
              }
            },

            // Triggers when mentioned characters are found. EG: @ or # in our case
            source: function(
              mentions_search_string,
              render_mentions_list,
              mentions_trigger_character
            ) {
              // Consider a temp variable for storing mentions list depending on mentioned char
              let mentions_list;

              // If char is @ assign atValues to temp variable
              if (mentions_trigger_character === "@") {
                mentions_list = MENTIONS.atValues;
              }

              // If char is # assign hashValues to temp variable
              if (mentions_trigger_character === "#") {
                mentions_list = MENTIONS.hashValues;
              }

              // If searchterm i.e the value after mentioned char (@,#) is nothing, then show full mentions_list
              if (mentions_search_string.length === 0) {
                render_mentions_list(mentions_list, mentions_search_string);
              }

              // Else if search term is there then show only mentions which match with searchterm
              else {
                // Consider an array to store similar mentions to searched term
                let matches = [];

                // Loop through the mentions_list
                for (let i = 0; i < mentions_list.length; i++)
                  // If similar to search term
                  if (
                    ~mentions_list[i].value
                      .toLowerCase()
                      .indexOf(mentions_search_string.toLowerCase())
                  ) {
                    // Push into temp array
                    matches.push(mentions_list[i]);
                  }

                // Render the selected mentions
                render_mentions_list(matches, mentions_search_string);
              }
            }
          }
        },

        // Theme to be applied to quill editor, since we want overlay toolbar using bubble theme.
        theme: "bubble",

        // Assign placeholder to the quill editor from prop.
        placeholder: this.editor_placeholder
      }
    };
  },

  computed: {
    ac_quill_editor() {
      return this.$refs.ac_quill_editor.quill;
    }
  },

  watch: {
    // Deep watcher on v-model content
    value: {
      handler: function() {
        // on every change update content data prop
        this.content = this.value;
      },
      deep: true
    },

    // Watcher on placeholder, if placeholder changes update in quill
    editor_placeholder(new_placeholder) {
      // Update with new value
      this.ac_quill_editor.root.dataset.placeholder = new_placeholder;
    },

    // Watcher on content, if any changes
    content() {
      // Update mentions with selected mentions
      this.update_mentions();

      // Emit mentions if content of editor changes and any mentions is selected from library
      if (EMIT_MENTIONS) {
        // As vuedoc doesn't work for conditional emits calling a method
        this.emit_selected_mentions(this.selected_mentions);

        // Again making it false so event can emit on when it becomes true
        EMIT_MENTIONS = false;
      }
    }
  },

  mounted() {
    // Execute appup handler workflow
    if (this.preload && this.start) {
      setTimeout(() => {
        this.start(this.preload, {
          helper: {
            component: this,
            custom: this.handler_params ? JSON.parse(this.handler_params) : {}
          }
        });
      }, 300);
    }

    // Assign content from v-model
    this.content = this.value;

    // Get grouped mentions on bases on trigger key
    this.grouped_mentions = GROUPBY(this.mentions, "trigger");

    // Assign global mentions variable from mentions prop
    MENTIONS.atValues = this.grouped_mentions["@"][0]["list"];
    MENTIONS.hashValues = this.grouped_mentions["#"][0]["list"];
  },

  methods: {
    // Emit on editor blur
    editor_blur(quill) {
      this.$emit("blur", quill);
    },

    // Emit on editor focus
    editor_focus(quill) {
      this.$emit("focus", quill);
    },

    // Emit on editor is ready
    editor_ready(quill) {
      this.$emit("ready", quill);
    },

    // Emit on editor changed
    editor_change(html) {
      this.$emit("input", html);
    },

    // Emit on editor key press event
    editor_key_press($event) {
      this.$emit("key_press", $event);
    },

    // Emit on key down event
    editor_key_down($event) {
      // If event is enter key and also mentions and emojis are opened, then do not emit that event.
      // DISABLE_ENTER denotes if any mentions or emojis are open.
      if ($event.keyCode == 13 && DISABLE_ENTER) {
        // break
        return;
      }

      // TO add new line on alt+enter and alt+control
      else if ($event.keyCode == 13 && ($event.altKey || $event.ctrlKey)) {
        // Add new line
        this.append_text_to_editor("\n");
      }

      // Else emit event - last case
      else {
        this.emit_key_down($event);
      }
    },

    // As vuedoc parser doesn't recognise events from if-else or any nested block so making seperate function.
    emit_selected_mentions(mentions_list) {
      // Emit key_down event
      this.$emit("mentions", mentions_list);
    },

    // As vuedoc parser doesn't recognise events from if-else or any nested block so making seperate function.
    emit_key_down(event) {
      // Emit key_down event
      this.$emit("key_down", event);
    },

    // Emit on key up event
    editor_key_up($event) {
      // If event is enter key and also mentions and eomjis are opened, then dont't emit that event.
      // DISABLE_ENTER denotes if any mentions or emojis are open.
      if ($event.keyCode == 13 && DISABLE_ENTER) {
        // break
        return;
      }

      // Else emit event - last case
      else {
        this.emit_key_up($event);
      }
    },

    // As vuedoc parser doesn't recognise events from if-else or any nested block so making seperate function.
    emit_key_up(event) {
      // Emit key_up event
      this.$emit("key_up", event);
    },

    // append string to the editor
    append_text_to_editor(append_string) {
      // Get quill reference using refs and call updateContents method.
      // As quill follows Delta operations
      this.ac_quill_editor.updateContents(
        // Create new delta object
        new Delta()

          // retain previous text
          .retain(this.get_editor_text().length - 1 + MENTIONS_COUNT)

          // insert the string from params
          .insert(append_string)
      );

      // Set the cursor at the end in case of any mis-alignments
      this.set_editor_cursor_at_end();
    },

    // Set the cursor of editor at the end position
    set_editor_cursor_at_end() {
      // Using refs call setSelection method in quill instance
      this.ac_quill_editor.setSelection(
        // Assign number of characters to get at the end
        // Also append mentions count as, module does not support
        this.get_editor_text().length - 1 + MENTIONS_COUNT
      );
    },

    // Get the text and content from editor
    get_editor_text() {
      // Using refs call getText method in quill instance
      return this.ac_quill_editor.getText();
    },

    // Updates selected mentions array
    update_mentions() {
      // Consider temp variable to get all delta operations
      let delta = this.ac_quill_editor.getContents()["ops"];

      // Consider temp variable to store all mentioned mentions
      let mentioned_mentions_list = [];

      // Loop throught delta
      for (let i = 0; i < delta.length; i++) {
        // find insert operation which is of type object
        if (typeof delta[i]["insert"] == "object") {
          // find all the mention objects in insert operation and push into mentioned_mentions_list
          if (delta[i]["insert"]["mention"])
            mentioned_mentions_list.push(delta[i]["insert"]["mention"]);
        }
      }

      // Update mentions count
      MENTIONS_COUNT = mentioned_mentions_list.length;

      // Create map to remove duplicate entries
      this.selected_mentions = [
        ...new Map(
          mentioned_mentions_list.map(mention => [mention.id, mention]).values()
        )
      ];
    }
  }
};
</script>

<style>
.cql-list-item-inner-image {
  width: 30px;
  height: 30px;
  border-radius: 2px;
}
.ac-quill-editor {
  width: 100%;
}
.ql-mention-list-container {
  max-height: 280px;
  overflow-y: auto;
}
.emoji_completions {
  min-width: 250px;
  max-height: 300px;
  overflow-y: auto;
}
.emoji_completions li {
  display: block;
}
.emoji_completions li button {
  background: none;
}
.emoji_completions li:hover {
  background: blue;
}
.emoji_completions li button.active {
  background: blue;
  color: #fff;
}
#textarea-emoji {
  min-width: 350px !important;
  right: 15px;
}
.textarea-emoji-control {
  bottom: 3px;
  top: inherit;
  right: 16px;
  position: absolute;
}
.bem {
  margin: 5px;
}
.ac-compose-message {
  max-height: 300px;
  position: relative;
}
.ac-quill-editor-icons {
  margin-top: 13px;
  font-weight: bolder;
  color: grey;
}
.ac-quill-editor-icons {
  position: absolute;
  right: 42px;
  bottom: 0;
}
</style>