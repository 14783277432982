<template>
  <b-form-checkbox
    @change="$emit('change', $event);"
    v-model="selected"
    :disabled="disabled"
    @input="$emit('input', $event);"
    switch
  ></b-form-checkbox>
</template>
<script>
export default {
  name: "ac-switch-button",
  components: {},

  props: {
    /**
     * To enable or disable switcher
     * @param { Boolean } disabled
     * @label Disabled
     * @category_name 2_Data
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Value for v-model binding. Not to be binded directly
     * @label Value
     * @category_name 2_Data
     */
    value: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      selected: this.value
    };
  },
  watch: {
    value() {
      this.selected = this.value;
    }
  }
};
</script>
