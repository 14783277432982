import * as Appup from '../components';
let examplesRoutes = [];

for (let compKey in Appup) {
    examplesRoutes.push({
        path: Appup[compKey].name,
        component: (resolve) => require(['../examples/' + Appup[compKey].name + '.vue'], resolve)
    });
}

export default examplesRoutes;
