<template>
  <b-container fluid>
    <template>
      <div class="row">
        <div class="col-12 ml-auto p-0">
          <div class="_modal">
            <div
              class="modal-dialog modal-xl my-0 mr-0 mw-100 modal-dialog-scrollable"
              role="document"
            >
              <div class="modal-content rounded-0 border-top-0 border-right-0">
                <div class="modal-body p-0">
                  <b-row class="mx-0 vh-100">
                    <b-col
                      class="border-right py-3 col-md-12 col-sm-12 vh-100"
                      style="overflow:auto;"
                    >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="closeSlideout"
                      >
                        <span aria-hidden="true">
                          <i class="fal fa-times"></i>
                        </span>
                      </button>
                      <component :is="cname" @valueUpdated="updateValue"></component>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-container>
</template>
<script>
export default {
  name: "ac-slideout",
  props: {
    /** formJson prop will receive the array of objects which can iterate the field controls
     * @label Cname
     */
    cname: {
      type: String
    }
  },
  methods: {
    closeSlideout: function() {
      this.$emit("closePanel", {});
      this.$router.back();
      //this.onClose(); // on closing the slideout, we can emit the onClose function
    },
    updateValue: function(data) {
      //alert(data.value);
      this.$emit("controlUpdateVal", data);
    }
  }
};
</script>