<template>
  <b-modal
    title="Export"
    hide-footer
    v-model="isShowExportModal">
    <b-form-select
      v-model="dataType"
      :options="dataTypeOptions"
      class="mb-3" />
    <b-form-textarea
      id="export-textarea"
      :placeholder="'Data for export'"
      v-model="text"
      rows="15"
      class="mb-3" />
    <div class="d-flex justify-content-end">
      <b-button @click="onClickExportCancel" variant="primary">Close</b-button>
    </div>
  </b-modal>
</template>
<script>
import store from './store'
import constants from './constants'

export default {
  name: 'ac-sql-builder-export-modal',
  data() {
    return {
      dataType: constants.JSON_TYPE,
      dataTypeOptions: [
        { value: constants.JSON_TYPE, text: constants.JSON_TYPE },
        { value: constants.SQL_TYPE, text: constants.SQL_TYPE },
      ],
      text: '',
    }
  },
  computed: {
    sqlJson() {
      return store.getters.sqlJson()
    },
    sql() {
      return store.getters.sql()
    },
    isShowExportModal: {
      get() {
        return store.getters.isShowExportModal()
      },
      set(value) {
        if (!value) {
          store.actions.hideExportModal()
        }
      },
    },
  },
  watch: {
    isShowExportModal(value) {
      if (value) {
        this.dataType = constants.JSON_TYPE
        this.text = JSON.stringify(this.sqlJson)
      }
    },
    dataType(value) {
      if (value === constants.SQL_TYPE) this.text = this.sql
      else if (value === constants.JSON_TYPE) this.text = JSON.stringify(this.sqlJson)
    },
  },
  methods: {
    onClickExportCancel() {
      store.actions.hideExportModal()
    },
  },
}
</script>
