<template>
  <div>
    <table class="table table-responsive d-md-table border">
        <thead>
            <tr>
                <th v-if="checkbox">
                    <b-form-checkbox value="me"></b-form-checkbox>
                </th>
                <th v-if="numbers" >Sr no</th>
                <th v-for="(thead,index) in theader" :key="index">{{thead.tableheader}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(value, key) in items" :key="key">
                
                <td v-if="checkbox" >
                   <b-form-checkbox value="me"></b-form-checkbox>
                </td>
               <td v-if="numbers" >{{key+1}}</td>
                <td >{{value.age}}</td>
                <td >
                  <span :class="value.badges">{{value.badge_name}}</span> {{value.first_name}}</td>
                <td >{{value.date}}<span><i :class="value.icon"></i></span></td>
                

             </tr>
        </tbody>

    </table>
  </div>
</template>

<script>
import { type } from 'os';
export default {
  name: "ac-traclytable",
  props: {
    /**
     * @param {Object} customCheck2 - checkbox
     * @label Custom Checkbox
     */
    customCheck2:{
      type:Object
    },
    /**
     * @param {Array} items - items
     * @label Items
     *   */    
      items:{
          type:Array
      },
      /**
       * @param {Array} theader -theader
       * @label Header
       */
    theader:{
        type:Array
    },
    /**
     * @param {Array} tdata - tdata
     */
    tdata:{
        type:Array
    },
    /**
     * @param {Boolean} checkbox - checkbox
     */
    checkbox:{
        default:true,
        type:Boolean
    },
    /**
     * @param {Boolean} numbers -numbers
     */
    numbers:{
        default:false,
        type:Boolean
    }
  },
  data(){
      return{
        
      }
  },
};
</script>

<style scoped>
.table thead th{
  border-bottom: 0px !important;
}
</style>