<template>
  <div class="ac-image-picker">
    <b-tabs v-model="selected_type">
      <b-tab
        v-for="(type, index) in ac_data"
        :key="`type_${index}`"
        :title="type.label"
        class="border-right border-left border-bottom rounded-bottom"
      >
        <div class="top-section p-2 mb-4">
          <div class="d-flex">
            <div class="categories d-flex">
              <b-button
                v-for="(cat, index) in type.categories"
                :key="`cat_${index}`"
                :class="{'category-active': selected_cat === index}"
                variant="link"
                @click="selected_cat = index"
              >
                {{ cat.label }}
              </b-button>
            </div>

            <div class="flex-auto">
              <b-input
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        <div
          v-for="(cat, index) in type.categories"
          :key="`cat_body_${index}`"
          class="files-container px-2"
        >
          <b-container>
            <b-row
              v-if="index === selected_cat"
            >
              <b-col
                class="mb-4"
                cols="3"
              >
                <label>
                  <input
                    type="file"
                    class="file-input"
                    @change="on_upload_change"
                  >
                  <div class="file-input-view rounded">Choose file</div>
                </label>
              </b-col>

              <b-col
                v-for="file in cat.files"
                :key="file.id"
                class="file-holder mb-4"
                cols="3"
              >
                <img
                  :src="file.src"
                  class="rounded"
                  alt="image"
                >

                <b-checkbox
                  v-model="cat.selected"
                  :value="file.id"
                  :class="{'checked': cat.selected.indexOf(file.id) > -1}"
                  class="bulk-checkbox"
                  @change="on_checkbox_change(file)"
                />
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
/**
 * ac image picker component
 * @requires vue-bootstrap
 */

export default {
  name: 'ac-image-picker',

  props: {
    /**
     * @param {Array} data - Array of objects with types and categories.
     */
    data: {
      type: Array,
      required: true,
    },

    /**
     * @param {string} upload_url - Url to upload images or links.
     */
    upload_url: {
      type: String,
      required: true,
    },

    /**
     * @param {string} search_key - Key inside file object for searching.
     */
    search_key: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      ac_data: [],
      selected_cat: 0,
      selected_type: 0,
    }
  },

  watch: {
    data() {
      this.update_data()
    },
  },

  created() {
    this.update_data()
  },

  methods: {
    /**
     * @function update_data - updates ac_data with data prop
     */
    update_data() {
      this.ac_data = this.data.map((el) => {
        el.categories = el.categories.map(cat => ({ ...cat, selected: [] }))
        return el
      })
    },

    /**
     * @function on_upload_change - triggers when user uploads file
     * @async
     */
    async on_upload_change(event) {
      const { files } = event.target

      if (!files.length) {
        return
      }

      Array.from(files).forEach((file) => {
        const reader = new FileReader()

        const file_data = {
          size: file.size,
          name: file.name,
        }

        reader.onload = async (e) => {
          file_data.src = e.target.result
          file_data.id = new Date().getTime() + (Math.random() * 100)

          const img = new Image()
          img.onload = async () => {
            this.upload_file(file)
            this.ac_data[this.selected_type].categories[this.selected_cat].files.push(file_data)
          }

          img.src = e.target.result
        }

        reader.readAsDataURL(file)
      })
    },

    /**
     * @function upload_file - upload files to api
     * @async
     */
    upload_file(image) {
      const formData = new FormData()
      formData.append('image', image)

      return this.send_to_api(formData)
    },

    /**
     * @function send_to_api - send data to api
     * @prop {any} data - data to send
     */
    send_to_api(data) {
      return fetch(this.upload_url, {
        body: data,
        method: 'POST',
        withCredentials: true, 
        credentials: 'include'
      })
    },

    /**
     * @function on_checkbox_change - triggens on checkbox change
     * @emits 'file-check' - file data
     */
    on_checkbox_change(e) {
      this.$emit('file-check', e)
    },
  },
}
</script>

<style scoped>
  .flex-auto {
    flex: auto;
  }

  .category-active {
    color: black;
  }

  label {
    width: 100%;
    height: 100%;
  }

  .file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-input + .file-input-view {
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ddd;
    border-style: dashed;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  .file-input + .file-input-view, .file-holder {
    min-height: calc(10vh + 10vw);
  }

  .file-holder {
    position: relative;
  }

  .file-holder .bulk-checkbox {
    position: absolute;
    opacity: 0;
    top: 10%;
    right: 10%;
    transition: opacity .3s ease;
  }

  .file-holder:hover .bulk-checkbox, .bulk-checkbox.checked {
    opacity: 1;
  }

  .file-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>
