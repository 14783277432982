<template>
<div>
    <!-- <button @click="showSlideout" class="btn btn-primary">Slideout</button> -->
   <slideout-panel></slideout-panel>    
</div>
</template>
<script>
    export default {
        name: "ac-slideout-panel",
        props: {
           /** @label Cname */
           cname:{
               type:String,
               required:true
           },
            /** @label Panel Width */
           panelWidth:{
               type:String
           }
        },
        methods: {
           showSlideout(){
               this.$showPanel({
                    component: "ac-slideout",
                    width:this.panelWidth,
                    disableBgClick:true,
                    cssClass: "",
                    props: {
                        "cname": this.cname
                    }
            });            
           },
           closingSlideOut(){
             this.$children[0].closeCurrentPanel();
             this.$router.back();
           }
        },
        mounted(){
            
        }
    }
</script>
<style>
</style>