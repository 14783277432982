<template>
  <b-button
    class="m-2 d-flex justify-content-center align-items-center"
    size="lg"
    variant="outline-dark"
    @click="open"
  >
    <i class="fas fa-cloud fa-2x"></i>
  </b-button>
</template>
<script>
import uploaderMixin from "../mixins/ac-uploader";
export default {
  mixins: [uploaderMixin],

  props: {
    one_drive_application_id: {
      type: String,
      required: true
    },
    actual_api: {
      type: String,
      required: true
    } 
  },

  name: "ac-onedrive-picker",

  created() {
    this.script_el.setAttribute("type", "text/javascript");
    this.script_el.setAttribute("src", this.actual_api);
    document.head.appendChild(this.script_el);
  },

  methods: {
    launch_one_drive_picker() {
      return new Promise((resolve, reject) => {
        var od_options = {
          clientId: this.one_drive_application_id,
          action: "download",
          multiSelect: true,
          openInNewWindow: true,
          advanced: {
            //filter: "folder,.png" // Show only folders and png files
            //filter: "folder,photo" // Show only folders and photos
          },
          success: function(files) {
            resolve(files);
          },
          cancel: function() {
            resolve(null);
          },
          error: function(e) {
            reject(e);
          }
        };

        OneDrive.open(od_options);
      });
    },
    async open() {
      try {
        const result = await this.launch_one_drive_picker();
        if (result) {
          for (const file of result.value) {
            this.$emit("choose", [
              {
                ...file,
                title: file.name,
                link: file["@microsoft.graph.downloadUrl"],
                iconURL: file.thumbnails[0].small.url
              }
            ]);
          }
        }
      } catch (reason) {
        console.error(reason);
      }
    }
  }
};
</script>