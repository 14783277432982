import uuidv4 from 'uuid/v4'

/**
 * @function getInlineConstraints - returns array of inline key constraints
 * @param {string} constraintsString - string of possible constraints
 * @param {string} columnName - name of column
 */
const getInlineConstraints = (constraintsString, columnName) => {
  if (!constraintsString) return []
  const keys = []
  const primaryKey = /PRIMARY\s+KEY/i.test(constraintsString)
  if (primaryKey) keys.push({ type: 'primary', name: '', columns: [columnName] })
  const uniqueKey = /UNIQUE/i.test(constraintsString)
  if (uniqueKey) keys.push({ type: 'unique', name: '', columns: [columnName] })
  const fulltextKey = /FULLTEXT\s+KEY/i.test(constraintsString)
  if (fulltextKey) keys.push({ type: 'fulltext', name: '', columns: [columnName] })
  // foreign key is special case because it has extra info (e.g. reference to another table and column)
  const foreignKey = /FOREIGN\s+KEY\s+REFERENCES\s+(.+)\(['"`]?(.+?)['"`]?\)/i.exec(constraintsString)
  if (foreignKey) {
    keys.push({
      type: 'foreign',
      name: '',
      columns: [columnName],
      tableRef: foreignKey[1],
      columnRef: foreignKey[2],
    })
  }
  return keys
}

/**
 * @function getColumnJSON - returns JSON for a column
 * @param {array} columnData - array of column name, data type and inline SQL constraints
 */
const getColumnJSON = (columnData) => {
  // in different versions keys may be defined either after columns' definition or inside them
  const keys = getInlineConstraints(columnData[3], columnData[1])
  const column = {
    id: uuidv4(),
    column_name: columnData[1],
    data_type: columnData[2],
    not_null: columnData[3] && /NOT\s+NULL/i.test(columnData[3]) ? 'NOT NULL' : '',
    auto_increment: columnData[3] && /AUTO_INCREMENT/i.test(columnData[3]) ? 'AUTO_INCREMENT' : '',
    default: '',
  }
  // default value is special case because it requires its value to be parsed
  // CURRENT_TIMESTAMP is also special as it doesn't use neither ['"`] nor () in definition
  const defaultValue = /DEFAULT\s+((?:(?:['"`].*['"`])|(?:\d+(?:.\d+)?)|(?:\(.+\))|CURRENT_TIMESTAMP))/i.exec(columnData[3])
  if (defaultValue) [, column.default] = defaultValue
  return { column, keys }
}

/**
 * @function findColumns - looks for columns inside parsed table
 * @param {string} columnsString - string with column definitions
 */
const findColumns = (columnsString) => {
  const columns = []
  // same regex as in findTables() function for searching for columns, but in this case it extracts column data
  const findColumnRegex = /\s*['"`]?(\w+)['"`]?\s+(\w+(?:\([\s\d,]+\))?)((?:(?:\s+[\s\w]*(?:DEFAULT|FOREIGN\s+KEY\s+REFERENCES)\s(?:['"`]?.*?['"`]?(?:\(.*?\))?))|(?:\s+[\s\w]+))?(?:,|\s*\)))/gi
  let columnData = findColumnRegex.exec(columnsString)
  let keys = []
  while (columnData) {
    const column = getColumnJSON(columnData)
    columns.push(column.column)
    keys = [...keys, ...column.keys]
    columnData = findColumnRegex.exec(columnsString)
  }
  return { columns, keys }
}

/**
 * @function getKeyConstraints - returns array of PRIMARY KEY, UNIQUE, FULLTEXT and KEY constraints
 * @param {string} constraintsString - string of possible constraints
 */
const getKeyConstraints = (constraintsString) => {
  const keys = []
  const findKey = /(?:CONSTRAINT\s+['"`]?(.*?)['"`]?\s+)?(?:([A-Z]+)\s+)?(?:KEY|(UNIQUE))\s+(?:['"`]?(\w+)['"`]?\s+)?\((['"`]?.+?['"`]?)\)/gi
  let key = findKey.exec(constraintsString)
  while (key) {
    if (!key[2] || key[2].toLowerCase() !== 'foreign') {
      const keyJSON = {
        type: (key[2] && key[2].toLowerCase()) || (key[3] && key[3].toLowerCase()) || 'key',
        name: key[1] || key[4] || '',
        columns: [],
      }
      const findComplexKeys = /['"`](\w+?)['"`],?|(\w+),?/gi
      let complexKey = findComplexKeys.exec(key[5])
      while (complexKey) {
        keyJSON.columns.push(complexKey[1] || complexKey[2])
        complexKey = findComplexKeys.exec(key[5])
      }
      keys.push(keyJSON)
    }
    key = findKey.exec(constraintsString)
  }
  return keys
}

/**
 * @function getForeignKeyConstraints - returns array of FOREIGN KEY constraints
 * @param {string} constraintsString - string of possible constraints
 */
const getForeignKeyConstraints = (constraintsString) => {
  const keys = []
  const findForeignKey = /(?:CONSTRAINT\s+['"`]?([#\w]+?)['"`]?\s+)?FOREIGN\s+KEY\s+\(['"`]?(.+?)['"`]?\)\s+REFERENCES\s+['"`]?([#\w]+?)['"`]?\s*\(['"`]?(.+?)['"`]?\)/gi
  let foreignKey = findForeignKey.exec(constraintsString)
  while (foreignKey) {
    const keyJSON = {
      type: 'foreign',
      name: foreignKey[1] || '',
      columns: [foreignKey[2]],
      tableRef: foreignKey[3],
      columnRef: foreignKey[4],
    }
    keys.push(keyJSON)
    foreignKey = findForeignKey.exec(constraintsString)
  }
  return keys
}

/**
 * @function findTableConstraints - looks for SQL constraints placed after columns' definition
 * @param {string} constraintsString - string of possible constraints
 */
const findTableConstraints = (constraintsString) => {
  const keys = getKeyConstraints(constraintsString)
  const foreignKeys = getForeignKeyConstraints(constraintsString)
  const constraints = [...keys, ...foreignKeys]
  return constraints
}

/**
 * @function findTables - looks for tables inside SQL query
 * @param {string} sqlString - input SQL string
 */
const findTables = (sqlString) => {
  const tables = []
  /*
  Divides table string into array containing its NAME, COLUMNS, CONSTRAINTS
    CREATE\s+TABLE\s+(?:IF\s+NOT\s+EXISTS\s+)?['"`]?([#\w]+)['"`]?\s*
        - looks for table definition and gets its name
    \s*['"`]?\w+['"`]?\s+\w+(?:\(\d+\))?
        - looks for column name and type (needed to divide columns and constraints)
    (?:\s+[\s\w]*(?:DEFAULT|FOREIGN\s+KEY\s+REFERENCES)\s(?:['"]?.*?['"]?(?:\(.*?\))?))
        - looks for inline SQL constraints for tables with DEFAULT and FOREIGN KEY (inline) constraints
        - DEFAULT and FOREIGN KEY REFERENCES (inline) are special cases because of () chars inside their definition
    |(?:\s+[\s\w]+))?(?:,|\s*\)))+)
        - parses common cases e.g. not DEFAULT and FOREIGN KEY constraints or no constraints at all
    ([^;]*) - gets constrains
  */
  const findTableRegex = /CREATE\s+TABLE\s+(?:IF\s+NOT\s+EXISTS\s+)?['"`]?([#\w]+)['"`]?\s*\(((?:\s*['"`]?\w+['"`]?\s+\w+(?:\([\s\d,]+\))?(?:(?:\s+[\s\w]*(?:DEFAULT|FOREIGN\s+KEY\s+REFERENCES)\s(?:['"]?.*?['"]?(?:\(.*?\))?))|(?:\s+[\s\w]+))?(?:,|\s*\)))+)([^;]*)/gi
  let tableData = findTableRegex.exec(sqlString)
  while (tableData) {
    const table = {
      id: uuidv4(),
      table_name: tableData[1],
      columns: [],
      keys: findTableConstraints(tableData[3]),
    }
    const columns = findColumns(tableData[2], table.keys)
    table.columns = columns.columns
    table.keys = [...table.keys, ...columns.keys]
    tables.push(table)
    tableData = findTableRegex.exec(sqlString)
  }
  return tables
}

const checkReferences = (tables) => {
  const foreignKeys = []
  const uniqueKeys = {}
  tables.forEach((table) => {
    table.keys.forEach((key) => {
      if (key.type === 'foreign') {
        foreignKeys.push({ key, tableName: table.table_name, found: false })
      }
      if (!uniqueKeys[table.table_name]) {
        uniqueKeys[table.table_name] = [...key.columns]
      } else uniqueKeys[table.table_name] = [...uniqueKeys[table.table_name], ...key.columns]
    })
  })
  tables.forEach((table) => {
    const referencedTable = foreignKeys.filter(key => key.key.tableRef === table.table_name)
    let foundReferencedColumn = false
    table.columns.forEach((column) => {
      if (referencedTable.length && referencedTable[0].key.columnRef === column.column_name) {
        foundReferencedColumn = true
      }
      if (uniqueKeys[table.table_name]) {
        let foundColumnIndex = uniqueKeys[table.table_name].indexOf(column.column_name)
        while (foundColumnIndex > -1) {
          uniqueKeys[table.table_name].splice(foundColumnIndex, 1)
          foundColumnIndex = uniqueKeys[table.table_name].indexOf(column.column_name)
        }
      }
    })
    if (foundReferencedColumn) {
      let foundRef = foreignKeys.splice(foreignKeys.indexOf(referencedTable[0]), 1)
      while (foundRef.length) {
        foundRef = foreignKeys.splice(foreignKeys.indexOf(referencedTable[0]), 1)
      }
    }
  })
  Object.entries(uniqueKeys).forEach(([key, value]) => {
    if (value.length > 0) throw new Error(`Unable to find ${value} column referenced in ${key} table key`)
  })
  if (foreignKeys.length) throw new Error(`Unable to find references for foreign key ${foreignKeys[0].key.tableRef} (${foreignKeys[0].key.columnRef}) in ${foreignKeys[0].tableName} table`)
}

/**
 * @function parseSQL - parses SQL to JSON
 * @param {string} sqlString - input SQL string
 */
const parseSQL = (sqlString) => {
  const dbName = sqlString.match(/CREATE\s+DATABASE\s+(?:IF\s+NOT\s+EXISTS\s+)?['"`]?(.+?)['"`]?;/i)
  if (!dbName) throw new Error('CREATE DATABASE statement is absent')
  const json = {
    database_name: dbName[1],
    tables: [],
  }
  json.tables = findTables(sqlString)
  checkReferences(json.tables)
  console.log(json)
  return json
}

export default {
  parseSQL,
}
