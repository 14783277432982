<template>
    <div role="tablist">
    <template v-if="groups.length > 0">
      <b-card no-body class="mb-4 mb-8" v-for="(grp,grp_index) in groups" :key="grp_index">
        <b-card-header
          header-tag="header"
          class="p-2"
          role="tab"
          v-b-toggle="'accordion_actions'+grp_index"
        >
          <b-container fluid>
            <b-row align-v="center">
                <b-col cols="auto" class="mr-auto"><h6 class="mb-0">Action {{grp_index + 1}}</h6> </b-col>
                <b-col cols="auto" class="pr-0">
                    <button class="btn btn-xs btn-purple-outline btn-radius btn-purple-round" @click.stop="delete_group(grp_index)">
                        <i class="fal fa-trash-alt"></i>
                    </button>
                </b-col>
            </b-row>
          </b-container>

        </b-card-header>
        <b-collapse :id="'accordion_actions'+grp_index" visible role="tabpanel">
          <b-card-body>
            <div class="row">
              <div
                class="form-group col-md-12 mb-0"
                v-for="(rule,rule_index) in grp.rules"
                :key="rule_index"
              >

                <ac-rule class="justify-content-center" :rule="rule" ref="rules" :groupInx="grp_index" :ruleInx="rule_index"
                         @update:rule="on_update_rule"
                         :hasOperator="false"
                         onlyRoot></ac-rule>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </template>

    <b-row class="justify-content-md-center mt-4">
        <b-col cols="12" md="auto">
            <button class="btn btn-primary justify-content-center" @click="adding_group">Add Another Action</button>
        </b-col>
    </b-row>
  </div>
</template>
<script>
import acRule from "./ac-automation-rule";
export default {
    components: {
        acRule
    },
    name: 'ac-automate-actions-list',
    props: {
        rule_json: {
            type: Object,
            required: false,
            default: () => ({})
        },
        prefill_data: {
            type: Array,
            required: false,
            default: () => ([])
        }
    },
    data() {
        return {
            groups: [],
            query_build_json: {
                condition: "Or",
                rules: []
            }
        }
    },
   methods: {
      on_update_rule({ groupInx, ruleInx, rule }) {
        const updated_rule = { ...this.groups[groupInx].rules[ruleInx], ...rule }
        this.groups[groupInx].rules.splice(ruleInx, 1, updated_rule)
      },
      /**
       * Invoke on click of add group button
       * @function adding_group - To add a new group
       */
      adding_group() {
        const query_build_json = Object.assign({}, this.query_build_json);
        const rule_json = Object.assign({}, this.rule_json);
        query_build_json["rules"] = [{ ...rule_json }];
        this.groups.push(query_build_json);
      },
      /**
       * Invoke on click of delete group button
       * @function delete_group - To delete the added group
       */
      delete_group(groupInx) {
        this.groups.splice(groupInx, 1);
      },
      /**
       * Not using
       * @function get_query
       */
      async get_query() {
        let rules = this.$refs.rules || [];
        const groups_list = [...this.groups];
        const final_query_list = [];
        groups_list.map((item, index) => {
          let final_query_json = { };
          rules.map((ruleItem, ruleinx) => {
            if (rules[ruleinx].groupInx == index)
              final_query_json = ruleItem.rule;
              delete final_query_json.condition
          });
          final_query_list.push(final_query_json);
        });
        return {
            actions: final_query_list
        };
      },
  },
  created() {
    var groups = [];
    if (this.prefill_data.length) {
      var avail_data = [...this.prefill_data];
      avail_data.map(item => {
        var rulesList = [];
        let { condition, rules } = item;
        let { keys, operators, controls } = this.rule_json;
        rulesList = rules.map(rl => {
          return { ...rl, keys, operators, controls };
        });
        groups.push({ condition, rules: rulesList });
      });
    } else {
      this.adding_group();
    }
    this.groups = [...groups];
  },
  mounted() {}
}
</script>
