<template>
  <b-dropdown
    :text="buttonText"
    class="ac-sort"
  >
    <b-dropdown-item
      v-for="(item, index) in options.columns"
      :key="`col-${index}`"
      :active="selectedCol === index"
      @click="on_change(index, 'Col')"
    >
      {{ item.label }}
    </b-dropdown-item>

    <b-dropdown-divider></b-dropdown-divider>

    <b-dropdown-item
      v-for="(item, index) in options.types"
      :key="`type-${index}`"
      :active="selectedType === index"
      @click="on_change(index, 'Type')"
    >
      {{ item.label }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
/**
  * ac sort component
  * @requires VueBootstrap
  */

export default {
  name: 'ac-sort',

  props: {
    /**
     * @param {object} options - Options for dropdown
     * must contain key `columns`.
     * `types` key is not required `asc` is default type
     */
    options: {
      type: Object,
      required: true,
    },

    /**
     * @param {object} collection_ref - collection reference to be sorted
     */
    collection_ref: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedCol: null,
      selectedType: null,
    }
  },

  computed: {
    buttonText() {
      return this.selectedCol !== null
        ? this.options.columns[this.selectedCol].label
        : 'Select'
    },
  },

  created() {
    this.options.columns.forEach((col, index) => {
      if (col.checked) {
        this.selectedCol = index
      }
    })

    if (this.options.types && this.options.types.length) {
      this.options.types.forEach((type, index) => {
        if (type.checked) {
          this.selectedType = index
        }
      })
    }
  },

  methods: {
    /**
     * @function on_change - fires on dropdown click
     * @prop {number} index - index of item inside columns or types
     * @prop {string} - type of change `Col` or `Type`
     */
    on_change(index, type) {
      const obj = {
        column: null,
        type: null,
      }

      this[`selected${type}`] = index

      if (this.selectedCol === null) return

      obj.column = this.options.columns[this.selectedCol].value

      if (this.selectedType !== null && this.options.types) {
        obj.type = this.options.types[this.selectedType].value
      }

      this.sort_collection(obj.column, obj.type)

      this.$emit('input', obj)
      this.$emit('change', obj)
    },

    /**
     * @function sort_collection - sorts collection from props
     * @prop {string} col - key inside item of collection data
     * @prop {string} type - type of sorting. Can be `asc` or `desc`
     * @public
     * this can be called from other components to programatically sort collection
     * e.g. when collection receives new data
     */
    sort_collection(col, type = 'asc') {
      const data = this.collection_ref.get_data()

      if (type === 'asc') {
        data.sort((a, b) => {
          if (a[col] > b[col]) {
            return 1
          }
          if (a[col] < b[col]) {
            return -1
          }
          return 0
        })
      } else {
        data.sort((a, b) => {
          if (a[col] > b[col]) {
            return -1
          }
          if (a[col] < b[col]) {
            return 1
          }
          return 0
        })
      }

      this.collection_ref.set_data(data)
    },
  },
}
</script>
