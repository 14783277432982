<template>
  <div>
    <div v-if="show && !showDefaultSlot">
      <highcharts :options="chartOptions" />
    </div>
    <div v-if="showDefaultSlot">
      <!-- Use this slot to show default template. if data not available this slot shown -->
      <slot name="default"></slot> 
    </div> 
  </div>
</template>
<script>
/**
 * @author Dharma
 * Chart components is using Highcharts api and vue components
 * Following charts are supported
 * 1)Line
 * 2)Bar
 * 3)Bubble
 * 4)Pie
 * 5)Funnel
 * ref: https://github.com/highcharts/highcharts-vue
 */
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
// Load bubble chart module
import highchartsMore from "highcharts/highcharts-more";
// Load funnel chart module
import highchartsFunnel from "highcharts/modules/funnel";

highchartsMore(Highcharts);
highchartsFunnel(Highcharts);

import axios from "axios";
let _merge = require("lodash.merge");

export default {
  name: "ac-chart",
  components: {
    highcharts: Chart
  },
  props: {
    /**
     * Fetch json data from url
     * expecting json data as following
     * [{"name": "Year 1800","data": [107, 31, 635, 203, 2]},{"name": "Year 1900","data": [133, 156, 947, 408, 6]}]
     * @param {string} url
     * @label Url
     * @category_name 1_General
     */
    url: {
      type: String
    },
    /**
     * Title of the chart
     * @param {string} title
     * @label Title
     * @category_name 1_General
     */
    title: {
      type: String,
      required: true
    },

    /**
     * type of chart to display
     * accepts
     * line,bar,bubble,pie,funnel
     * @param {string} type
     * @label type
     * @category_name 1_General
     */
    type: {
      type: String,
      required: true
    },
    /**
     * If in case url is not available
     * then data will be considered,
     * sample data:
     * [{"name": "Year 1800","data": [107, 31, 635, 203, 2]},{"name": "Year 1900","data": [133, 156, 947, 408, 6]}]
     * @param {string} data
     * @label Data
     * @category_name 2_Data
     */
    data: {
      type: String
    },
    /**
     * Fetch extra addons for highcharts by using config
     * extra addons like legend,tootltip,xaxis,yaxis
     * x-axis and y-axis categories.
     * @param {object} config
     * @label Config
     * @category_name 3_Advanced
     */
    config: {
      type: Object,
      default() {
        return {};
      }
    },
    /**
     * Preload handler execute
     */
    preload: {
      type: String,
      default: null
    },
    /**
     * Handler params for preload
     * @param {string} - handler_params
     * @category_name 1_General
     */
    handler_params: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      // Chart options
      chartOptions: {},
      show: true,
      showDefaultSlot: false
    };
  },
  watch: {
    url: function(newval, oldval) {
      // change url we will call method
      this.getDataFromUrl(newval);
    },
    config(newval, oldval) {
      this.show = false;
      this.config = newval;
      this.buildChartoptions();
      this.mergeConfig();
      // add delay to make sure component gets deleted from DOM
      let _this = this;
      setTimeout(function() {
        _this.show = true;
      }, 50);
    }
  },
  created() {
    this.buildChartoptions();
    this.mergeConfig();
  },

  methods: {
    // Pass data to handler
    executeHandler(data) {
      // Execute appup handler workflow
      if (this.preload && this.start) {
        this.start(this.preload, {
          helper: {
            component: this,
            item: data,
            custom: this.handler_params ? JSON.parse(this.handler_params) : {}
          }
        });
      }
    },
    getDataFromUrl: function(url) {
      this.$appupajax.get2(url, {}, {}, {withCredentials: true, credentials: "include" }).
      // this.$appupajax.get2(url, {}, { withCredentials: true, credentials: "include" }).
                    then(this.$appupajax.handleResponse).then(response => {
          // Append JSON data from url to method
          this.getHighCartData(response.data, this.type);
        })
        .catch(error => {
          this.showDefaultSlot = true;
          console.log(error);
        });
    },
    getHighCartData: function(data, type) {
      try {
        // Parse data if it is a string
        data = JSON.parse(data);
      } catch {
        data = data;
      }
      this.showDefaultSlot = false;
      if (data && data.length <= 0) {
        this.showDefaultSlot = true;
      }
      var that = this;
      var newData = [];

      // call handler
     // this.executeHandler(data);

      // Parse string array to normal array from data
      for (var i = 0; i < data.length; i++) {
        // if not array then parse into array
        if (!Array.isArray(data[i].values)) {
          data[i].values = JSON.parse(data[i].values);
        }
      }

      /**
       * Here data is coming from either JSON data from url or data field
       * for example data will be [{"name": "Year 1800","data": [107, 31, 635, 203, 2]},{"name": "Year 1900","data": [133, 156, 947, 408, 6]}]
       * By using this data, iterate one and modified data to highcharts data
       * for example funnel data [['Website visits', 15654],['Downloads', 4064]]
       * TODO: optimise loop and conditions
       */
      for (var i = 0; i < data.length; i++) {
        switch (type) {
          case "funnel":
            newData.push([data[i].name, data[i].values[0]]);
            break;
          case "bubble":
            newData.push({
              x: data[i].values[0],
              y: data[i].values[1],
              z: data[i].values[2],
              name: data[i].name
            });
            break;
          case "pie":
            newData.push({
              y: data[i].values[0],
              name: data[i].name
            });
            break;
          case "line":
          case "bar":
          case "column":
            newData.push({ name: data[i].name, data: data[i].values });
            break;
          default:
            console.log("please check your chart type");
        }
        if (type == "line" || type == "bar" || type == "column") {
          that.chartOptions.series = newData;
        } else {
          that.chartOptions.series[0].data = newData;
        }
      }
    },
    mergeConfig: function() {
      this.chartOptions = _merge(this.chartOptions, this.config);
      // console.log(this.chartOptions);
      var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      // var that = this;
      // Check url is exist or not, if exist check url is valid or not
      if (regexp.test(this.url) && this.url != null) {
        this.getDataFromUrl(this.url);
      } else {
        // Append JSON data from data to method
        try {
          this.getHighCartData(this.data, this.type);
        } catch (error) {
          this.showDefaultSlot = true;
        }
      }
    },
    buildChartoptions: function() {
      this.chartOptions = {
        series: [
          {
            data: []
          }
        ],
        chart: {
          type: this.type
        },
        colorByPoint: true,
        title: {
          text: this.title
        },
        tooltip: {
          enabled: this.config.tooltip
        },
        subtitle: {
          text: this.config.subtitle
        },
        xAxis: {
          title: {
            text: this.config.xtitle
          },
          categories: this.config.xcategory
        },
        yAxis: {
          title: {
            text: this.config.ytitle
          }
        },
        legend: {
          enabled: this.config.legend
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        credits: {
          enabled: false
        }
      };
    }
  },
  mounted(){
    this.executeHandler();
  }
};
</script>
<style>
</style>