<template>
  <div id="ac-email-builder" ref="email_builder_container">
  </div>
</template>
<script>
import 'grapesjs/dist/css/grapes.min.css'
import grapesjs from 'grapesjs'
// import 'ckeditor4'
// import 'grapesjs-plugin-ckeditor'
import 'grapesjs-preset-newsletter'

export default {
  name: 'ac-email-builder',
  props: {
    /**
     * Html string
     * @param {string} data
     * @label Data
     * @category_name 2_Data
     */
    data: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      html: '',
      editor: null,
    }
  },
  created() {
    this.html = this.data
  },
  mounted() {
    this.init()
  },
  methods: {
    /**
     * @function onEditorChange - editor change event handler
     */
    onEditorChange() {
      const html = this.editor.getHtml()
      const css = this.editor.getCss()
      this.html = `${html}<style>${css}</style>`
      this.$emit('export', this.html)
    },
    /**
     * @function init - editor object initialization
     */
    init() {
      if (this.editor) {
        return
      }
      this.editor = grapesjs.init({
        container: this.$refs.email_builder_container,
        fromElement: false,
        components: this.html,
        height: '100%',
        width: '100%',
        storageManager: false,
        assetManager: {
          assets: [],
          upload: 0,
          uploadText: 'Uploading is not available',
        },
        panels: {
          defaults: [],
        },
        colorPicker: {
          appendTo: 'parent',
          offset: { top: 26, left: -166 },
        },
        plugins: [
          // 'gjs-plugin-ckeditor',
          'gjs-preset-newsletter',
        ],
        pluginsOpts: {
          'gjs-preset-newsletter': {
            modalLabelImport: 'Paste all your code here below and click import',
            modalLabelExport: 'Copy the code and use it wherever you want',
            codeViewerTheme: 'material',
            importPlaceholder: '<table class="table"><tr><td class="cell">Hello world!</td></tr></table>',
            cellStyle: {
              'font-size': '12px',
              'font-weight': 300,
              'vertical-align': 'top',
              color: 'rgb(111, 119, 125)',
              margin: 0,
              padding: 0,
            },
          },
          // 'gjs-plugin-ckeditor': {
          //   position: 'center',
          //   options: {
          //     startupFocus: true,
          //     extraAllowedContent: '*(*);*{*}', // Allows any class and any inline style
          //     allowedContent: true, // Disable auto-formatting, class removing, etc.
          //     enterMode: CKEDITOR.ENTER_BR,
          //     extraPlugins: 'sharedspace,justify,colorbutton,panelbutton,font',
          //     toolbar: [
          //       { name: 'styles', items: ['Font', 'FontSize'] },
          //       ['Bold', 'Italic', 'Underline', 'Strike'],
          //       { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
          //       { name: 'links', items: ['Link', 'Unlink'] },
          //       { name: 'colors', items: ['TextColor', 'BGColor'] },
          //     ],
          //   },
          // },
        },
      })

      const panels = this.editor.Panels

      panels.addPanel({
        id: 'devices',
        buttons: [
          {
            active: true,
            id: 'deviceDesktop',
            className: 'fa fa-desktop',
            command: 'set-device-desktop',
            attributes: { title: 'Desktop' },
          },
          {
            id: 'deviceTablet',
            className: 'fa fa-tablet-alt',
            command: 'set-device-tablet',
            attributes: { title: 'Tablet' },
          },
          {
            id: 'deviceMobile',
            className: 'fa fa-mobile-alt',
            command: 'set-device-mobile',
            attributes: { title: 'Mobile' },
          },
        ],
      })

      panels.addPanel({
        id: 'options',
        buttons: [
          {
            active: true,
            id: 'sw-visibility',
            className: 'far fa-square',
            command: 'sw-visibility',
            context: 'sw-visibility',
            attributes: { title: 'View components' },
          },
          {
            id: 'fullscreen',
            className: 'fa fa-arrows',
            attributes: { title: 'Fullscreen' },
            command: 'fullscreen',
          },
          {
            id: 'gjs-open-import-template',
            className: 'fa fa-download',
            command: 'gjs-open-import-template',
            attributes: { title: 'Import template' },
          },
          {
            id: 'export-template',
            className: 'fa fa-code',
            command: 'export-template',
            attributes: { title: 'View code' },
          },
          {
            id: 'undo',
            className: 'fa fa-undo',
            attributes: { title: 'Undo' },
            command: 'core:undo',
          },
          {
            id: 'redo',
            className: 'fa fa-redo',
            attributes: { title: 'Redo' },
            command: 'core:redo',
          },
          {
            id: 'clear-all',
            className: 'fa fa-trash-alt',
            attributes: { title: 'Clear canvas' },
            command: {
              run(editor, sender) {
                if (sender) sender.set('active', false)
                if (confirm('Are you sure to clean the canvas?')) {
                  editor.DomComponents.clear()
                  setTimeout(() => {
                    localStorage.clear()
                  }, 0)
                }
              },
            },
          },
        ],
      })

      panels.addPanel({
        id: 'views',
        buttons: [
          {
            id: 'open-sm',
            className: 'fa fa-paint-brush',
            command: 'open-sm',
            active: true,
            togglable: 0,
            attributes: { title: 'Open Style Manager' },
          },
          {
            id: 'open-tm',
            className: 'fa fa-cog',
            command: 'open-tm',
            togglable: 0,
            attributes: { title: 'Settings' },
          },
          {
            id: 'open-layers',
            className: 'fa fa-bars',
            command: 'open-layers',
            togglable: 0,
            attributes: { title: 'Open Layer Manager' },
          },
          {
            active: true,
            id: 'open-blocks',
            className: 'fa fa-th-large',
            command: 'open-blocks',
            togglable: 0,
            attributes: { title: 'Open Blocks' },
          },
        ],
      })

      this.editor.on('change:changesCount', this.onEditorChange)
    },
  },
}
</script>
<style>
#ac-email-builder .gjs-cv-canvas {
  width: calc(100% - 220px);
}
#ac-email-builder .gjs-pn-views, #ac-email-builder .gjs-pn-views-container {
  width: 220px;
}
#ac-email-builder .gjs-pn-options {
  right: 220px;
}
#ac-email-builder .gjs-field-arrows {
  z-index: 1;
}
#ac-email-builder .fa-trash-o:before {
  content: "\f2ed";
}
#ac-email-builder .fa.fa-clone:before {
  font-weight: 400;
}
#ac-email-builder .fa.fa-check-square-o:before {
  content: "\f14a";
}
</style>
