export default {
  JSON_TYPE: 'JSON',
  SQL_TYPE: 'SQL',
  EDITABLE_PROPERTIES: ['PK', 'Name', 'Type', 'Null', 'Default'],
  DATA_TYPES: [
    'INTEGER', 'INT', 'SMALLINT', 'TINYINT', 'DECIMAL', 'DEC', 'FIXED', 'NUMERIC',
    'FLOAT', 'REAL', 'DOUBLE', 'BIT',
    'VARCHAR', 'CHAR', 'VARBINARY', 'BINARY',
    'TINYBLOB', 'MEDIUMBLOB', 'LONGBLOB', 'BLOB',
    'TINYTEXT', 'MEDIUMTEXT', 'LONGTEXT', 'TEXT',
    'ENUM', 'SET',
    'DATETIME', 'TIMESTAMP', 'DATE', 'TIME', 'YEAR',
    'MULTIPOINT', 'MULTILINESTRING', 'MULTIPOLYGON', 'GEOMETRYCOLLECTION', 'POINT', 'LINESTRING', 'POLYGON', 'GEOMETRY',
  ],
  DEFAULT_VALUES: ['NULL', 'CURRENT_TIMESTAMP', 'AUTO_INCREMENT'],
}
