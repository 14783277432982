<template>
  <div>
    <component :is="component_name"></component>
  </div>
</template>
<script>
export default {
  name: "ac-component",
  props: {
    component_name: { type: String, required: true }
  }
};
</script>