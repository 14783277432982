import { render, staticRenderFns } from "./ac-simple-wizard.vue?vue&type=template&id=005b8464&scoped=true&"
import script from "./ac-simple-wizard.vue?vue&type=script&lang=js&"
export * from "./ac-simple-wizard.vue?vue&type=script&lang=js&"
import style0 from "./ac-simple-wizard.vue?vue&type=style&index=0&id=005b8464&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "005b8464",
  null
  
)

export default component.exports