let emoji_list = [
  {
    "name": "blush",
    "unicode": "1f60a",
    "shortname": ":)",
    "code_decimal": "&#128522;",
    "category": "p",
    "emoji_order": "10"
  },
  {
    "name": "smiley",
    "unicode": "1f603",
    "shortname": ":D",
    "code_decimal": "&#128515;",
    "category": "p",
    "emoji_order": "5"
  },
  {
    "name": "laughing",
    "unicode": "1f606",
    "shortname": "xD",
    "code_decimal": "&#128518;",
    "category": "p",
    "emoji_order": "8"
  },
  {
    "name": "stuck_out_tongue",
    "unicode": "1f61b",
    "shortname": ":P:",
    "code_decimal": "&#128539;",
    "category": "p",
    "emoji_order": "37"
  },
  {
    "name": "kissing_heart",
    "unicode": "1f618",
    "shortname": ":*:",
    "code_decimal": "&#128536;",
    "category": "p",
    "emoji_order": "14"
  },
  {
    "name": "confused",
    "unicode": "1f615",
    "shortname": ":/:",
    "code_decimal": "&#128533;",
    "category": "p",
    "emoji_order": "44"
  },
  {
    "name": "hushed",
    "unicode": "1f62f",
    "shortname": ":o:",
    "code_decimal": "&#128559;",
    "category": "p",
    "emoji_order": "31"
  },{
    "name": "pensive",
    "unicode": "1f614",
    "shortname": ":(:",
    "code_decimal": "&#128532;",
    "category": "p",
    "emoji_order": "43"
  },
  {
    "name": "cry",
    "unicode": "1f622",
    "shortname": ":'(:",
    "code_decimal": "&#128546;",
    "category": "p",
    "emoji_order": "54"
  },{
    "name": "neutral_face",
    "unicode": "1f610",
    "shortname": ":|:",
    "code_decimal": "&#128528;",
    "category": "p",
    "emoji_order": "22"
  },
  {
    "name": "unamused",
    "unicode": "1f612",
    "shortname": ":J:",
    "code_decimal": "&#128530;",
    "category": "p",
    "emoji_order": "41"
  },
  {
    "name": "kissing_closed_eyes",
    "unicode": "1f61a",
    "shortname": ":3:",
    "code_decimal": "&#128538;",
    "category": "p",
    "emoji_order": "17"
  },
  {
    "name": "joy",
    "unicode": "1f602",
    "shortname": ":'):",
    "code_decimal": "&#128514;",
    "category": "p",
    "emoji_order": "3"
  },
  {
    "name": "confounded",
    "unicode": "1f616",
    "shortname": ":$:",
    "code_decimal": "&#128534;",
    "category": "p",
    "emoji_order": "50"
  },
  {
    "name": "worried",
    "unicode": "1f61f",
    "shortname": ":{:",
    "code_decimal": "&#128543;",
    "category": "p",
    "emoji_order": "52"
  },
  {
    "name": "grin",
    "unicode": "1f601",
    "shortname": ":}:",
    "code_decimal": "&#128513;",
    "category": "p",
    "emoji_order": "2"
  }
];
export default emoji_list;