<template>
    <div>
        <codemirror v-bind="$attrs" v-model="code"></codemirror>
    </div>
</template>
<script>
import { codemirror } from "vue-codemirror";
// language js
import "codemirror/mode/javascript/javascript.js";
// theme css
import "codemirror/theme/base16-light.css";

// require styles
import 'codemirror/lib/codemirror.css'

export default {
 name: 'ac-codemirror',
 components: {
    codemirror
  },
  props: ["value"],
  data(){
      return {
      code: this.value
      }
  }
}
</script>