<template>
<div>
  <!-- for below tag my-3 and ml-3 added -->
  <header class="ac__header my-3 ml-3">
      <!-- @slot Use this slot for header -->
      <slot name="header"></slot>
    </header>

  <div class="ac-kanban">
  <!-- here px-3 removed and added col-md-6 -->
    <div
      class="ac-kanban__column col-md-6"
      v-for="(item,index) in positioned_items"
      :key="`ac-kanban__column_${index}`"
    >
      <draggable
        v-model="collections_data[item.collection_helper]"
        :disabled="!draggable"
        :sortable="headers_sortable"
        group="kanban"
        @change="on_change"
        draggable=".kanban-item"
      >
        <h3
          class="border p-2"
          slot="header"
        >
          {{ item.label }}
        </h3>

        <div
          v-for="element in collections_data[item.collection_helper]"
          :key="element[item_key]"
          class="kanban-item"
        >
          <!-- Slot for each draggable item -->
          <slot name="kanban-item" :item="element" :data="collections_data[item.collection_helper]"></slot>
        </div>
      </draggable>

    </div>
    
    <component ref="collectionHelper" v-for="(item,index) in positioned_items" :is="item.collection_helper" :key="`collectionHelper_${index}`"/>

  </div>
  <footer class="ac__footer my-3 ml-3">
      <!-- @slot Use this slot for footer -->
      <slot name="footer"></slot>
    </footer>
  </div>
</template>


<script>
import draggable from 'vuedraggable'

/**
 * ac Kanban component
 * @requires VueBootstrap
 * @requires vuedraggable
 */

export default {
  name: 'ac-kanban',

  components: {
    draggable,
  },

  props: {
    /**
     * @param {object} items - Object that holds items for drag-n-drop
     * @properties {"label": {"type": "String"}, "collection_helper": {"type": "String"}}
     * @label Items
     */
    items: {
      type: Object,
      required: true,
    },

    /**
     * @param {boolean} draggable - Indicates if drag-n-drop enabled
     * @label Draggable
     */
    draggable: {
      type: Boolean,
      required: true,
    },

    /**
     * @param {string|number} item_key - Key inside each draggable item for unique identification
     * @label Item Key
     */
    item_key: {
      type: [String, Number],
      required: true,
    },

    /**
     * @param {bolean} headers_sortable -  Indicates if sorting enabled
     * @label Headers Sortable
     */
    headers_sortable: {
      type: Boolean,
      required: true,
    },

    /**
     * @param {array} positions - Array used to render cols in specific positions
     * @label Positions
     */
    positions: {
      type: Array,
    },
  },

  computed: {
    /**
     * Create arranged items 
     * @returns {array}
     */
    positioned_items() {
      const items = this.positions.map(pos => this.items[pos]).filter(item => item)
      return items
    }
  },

  data() {
    return {
      collections: {},
      collections_data: {},
    }
  },

  created() {
    /**
     * On collection_added event register component locally
     */
    /* this.$on('collection_added', (e) => {
      this.collections[e.component.$vnode.data.ref] = e.component
    }) */
  },

  mounted() {
    let collectionRefs = this.$refs.collectionHelper;
    for(let index in collectionRefs) {
      let component = collectionRefs[index].$children[0].$children[0].$children[0];
      this.collections[component.$vnode.data.ref] = component;
    }
    // this.$refs.collectionHelper[0].$children[0].$children[0].$children[0]
    this.get_collections_data()
  },

  methods: {
    /**
     * @function get_collections_data - get data from each ac-collection
     */
    get_collections_data() {
      Object.keys(this.collections).forEach((key) => {
        this.collections[key].$on('success', (e) => {
          this.collections_data = {
            ...this.collections_data,
            [key]: e,
          }
        })

        this.collections[key].set_ajax()
      })
    },

    on_change(e) {
      /**
       * @event on_change
       * event is triggered when corresponding array is altered due to drag-and-drop operation.
       * @property {object} event - Type of change and corresponding data.
       */
      console.log(e)
      this.$emit('on_change', e)
    },
  },
}
</script>

<style scoped>
  .ac-kanban {
    display: flex;
  }

  .ac-kanban__column {
    flex: auto;
  }
</style>