<template>
    <div class="row my-3 mx-0">
    <div class="col-xl-11 col-lg-11 col-md-12 col-sm-11 p-0">
        <div class="border">
            <div class="card border-0">
                <div class="card-header p-4 border-top-0 border-left-0 border-right-0 bg-transparent">

                    <!-- @slot Use this slot for layout header -->
​                       <slot name="header"></slot>
                </div>
            </div>
            <div class="row m-0 slide-body">
                <div :class="rightclass">
                    <!-- @slot Use this slot for layout rightside -->
                   <slot name="rightSide"></slot>
                </div>
                <div :class="leftClass">
                    <!-- @slot Use this slot for layout leftside -->
​                    <slot name="leftSide"></slot>
                </div>
            </div>
            <div class="row m-0 p-3">
              <div class="ml-auto">
                  <!-- @slot Use this slot for layout footer -->
                  <slot name="footer"></slot>
              </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "ac-slideout-layout",
   
   props:{
    /**
     * @param {String} rightclass
     */
        rightclass:{
            type: String,
            default:"col-sm-12 col-md-8 p-4 border-right",
            required: false

        },
     /**
     * @param {String} leftClass
     */
        leftClass: {
            type: String,
            default:"col-sm-12 col-md-4 p-4 bg-light",
            required: false
        }
    }
}
</script>

<style>
.slide-body {
  height: calc(100vh - 227px);
  overflow: auto;
}
</style>
