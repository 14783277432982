<template>
  <div class="h-100 w-100">
    <ac-sql-builder-properties-editor />
    <ac-sql-builder-navbar />
    <ac-sql-builder-ui />
    <ac-sql-builder-import-modal />
    <ac-sql-builder-export-modal />
    <ac-sql-builder-comment-modal />
  </div>
</template>
<script>
import acSqlBuilderNavbar from './ac-sql-builder-navbar.vue'
import acSqlBuilderUi from './ac-sql-builder-ui.vue'
import acSqlBuilderPropertiesEditor from './ac-sql-builder-properties-editor/properties-editor.vue'
import acSqlBuilderImportModal from './ac-sql-builder-import-modal.vue'
import acSqlBuilderExportModal from './ac-sql-builder-export-modal.vue'
import acSqlBuilderCommentModal from './ac-sql-builder-comment-modal.vue'
import store from './store'

/**
 * acSqlBuilder - helps in list Databases, create tables and establish relationships
 * @requires acSqlBuilderNavbar
 * @requires acSqlBuilderUi
 * @requires VueBootstrap
 */
export default {
  name: 'ac-sql-builder',
  components: {
    acSqlBuilderNavbar,
    acSqlBuilderUi,
    acSqlBuilderPropertiesEditor,
    acSqlBuilderImportModal,
    acSqlBuilderExportModal,
    acSqlBuilderCommentModal,
  },
  props: {
    sql_json: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    if (this.sql_json) {
      await store.actions.setSqlJsonFromObject(this.sql_json)
      await store.actions.setIsRedrawNeededState(true)
    }
  },
}
</script>
