<template>
    <div role="tablist">
       <div class="row">
            <div class="col-md-12">
                <button class="btn btn-primary float-right" @click="getQuery">Get Query</button>
                <button class="btn btn-primary float-right" @click="addingGroup">Add group</button>
            </div>
        </div>
    <b-card no-body class="mb-1" v-for="(grp,grpInx) in groups" :key="grpInx">
      <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'accordion'+grpInx">
           <b-button-group>
                <b-button :variant="grp.condition == 'And' ? 'success':'light'" @click.stop="grp.condition = 'And'">And</b-button>
                <b-button :variant="grp.condition == 'Or' ? 'success':'light'" @click.stop="grp.condition = 'Or'">Or</b-button>
            </b-button-group>
      </b-card-header>
      <b-collapse :id="'accordion'+grpInx" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-success float-right" @click="addingRule(grpInx)">Add Rule</button><br />
                    </div>
                    <div class="form-group col-md-12 d-flex" v-for="(rule,rinx) in grp.rules" :key="rinx">
                        <b-button-group>
                            <b-button :variant="rule.condition == 'And' ? 'success':'light'"
                                @click="rule.condition = 'And'">And</b-button>
                            <b-button :variant="rule.condition == 'Or' ? 'success':'light'"
                                @click="rule.condition = 'Or'">Or</b-button>
                        </b-button-group>
                        <div class="col-md-3">
                            <select class="form-control input-sm" v-model="rule.selectedKey" @change="selectRuleType(rule,rinx)">
                                <option v-for="(option,opInx) in rule.keys" :value="option.id" :key="opInx">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-3">
                            <select class="form-control input-sm" v-model="rule.selectedOperator">
                                <option v-for="(option,opInx) in rule.operators" :value="option.id" :key="opInx">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-3">
                            <input type="text" class="form-control input-sm" v-model="rule.selectedVal" placeholder="Enter a Value">
                        </div>
                        <button class="btn btn-xs btn-purple-outline btn-radius btn-purple-round"
                            @click.prevent="deleteRule(rinx,grpInx)">close</button>
                    </div>
                </div>
        </b-card-body>
      </b-collapse>
    </b-card>
     <b-modal ref="jsonDisplay" title="Query">
              <pre>{{queryBuilderOutput}}</pre>
            </b-modal>
  </div>
</template>

<script>
    export default {
        name: 'ac-filter-query-builder',
        props:{
            /**
             *  @label Rule JSON 
            */
            ruleJson:{
                type:Object
            }
        },
        data() {
            return {
                groups: [],
                queryBuilderOutput:[],
                queryBuildJson: {
                    condition: "Or",
                    rules: []
                }
            }
        },
        methods: {
            addingGroup() {
                var queryBuildJson = Object.assign({}, this.queryBuildJson);
                var ruleJson = Object.assign({}, this.ruleJson);
                queryBuildJson["rules"] = [{ ...ruleJson }];
                this.groups.push(queryBuildJson)
            },
            addingRule(gInx) {
                var ruleJson = Object.assign({}, this.ruleJson);
                this.groups[gInx]["rules"] = [...this.groups[gInx]["rules"], ruleJson];
            },
            getQuery() {
                var groupsList = [...this.groups]
                var finalQueryList = [];
                groupsList.map((item) => {
                    var finalQueryJson = { "rules": [] };
                    finalQueryJson["condition"] = item.condition;
                    if (item.rules && item.rules.length) {
                        finalQueryJson["rules"] = item.rules.map(obj => {
                            let { condition, selectedKey, selectedOperator, selectedVal } = obj;
                            return { condition, selectedKey, selectedOperator, selectedVal };
                        })
                    }
                    finalQueryList.push(finalQueryJson);
                });
                this.queryBuilderOutput = finalQueryList;
                this.$refs.jsonDisplay.show();
                console.log(JSON.stringify(finalQueryList));
            },
            deleteRule(rInx, gInx) {
                this.groups[gInx].rules.splice(rInx, 1);
                !this.groups[gInx].rules.length ? this.groups.splice(gInx, 1) : "";
            },
            selectRuleType(data,ruleIndex){
                //  if(data.type == "select" && data.fieldjson.url){
                //       try{
                //         let dropdownList = await fetch(data.fieldjson.url).then(res => res.json());
                //         if(dropdownList && dropdownList.length){
                //             data.options = dropdownList.map(obj => {
                //             var newObj = {};
                //             newObj.text = obj[this.formJson[index].keytext];
                //             newObj.value = obj[this.formJson[index].keyvalue];
                //             return newObj;
                //         })
                //        }
                       
                //       }catch(e){

                //       }
                //  }
            }

        },
        mounted() {
            this.addingGroup();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 
</style>