<template>
    <b-form-group class="ac-automate-switch mb-0">
      <b-form-radio-group
        v-model="selected_option"
        :options="radio_group_options"
        :buttons="radio_buttons"
        :stacked="!radio_buttons"
        class="w-100"
        button-variant="outline-primary"
        name="radio-btn-outline"
      >
        <template v-if="!radio_buttons">
          <div
            v-for="(option, index) in options"
            :key="option.value.toString()"
            :class="['d-flex align-items-center',
            { 'mb-3': index !== options.length - 1 }]"
          >
            <b-form-radio :value="option.value">{{option.text}}</b-form-radio>
            <b-form-select
              v-if="option.value.type === 'select'"
              @change="selected_option = option.value"
              class="ml-3 w-25"
              v-model="selected_value"
              :options="option.value.options"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Select Filter</b-form-select-option>
              </template>
            </b-form-select>
          </div>

        </template>

      </b-form-radio-group>

      <div class="d-flex justify-content-center mt-3" v-if="selected_option.type === 'calendar'">
        <ac-date-range-picker
            class="w-25 text-center"
            :opens="opens"
            :min_date="minDate"
            single_date_picker="false"
            show_week_numbers="true"
            :show_dropdowns="show_dropdowns"
            auto_apply="false"
            v-model="dateRange"
            :ranges="ranges"
            show_ranges="true"
            time_picker="true"
            time_picker_24_hour="true"
            :time_picker_increment="3"
            time_picker_seconds="true"
            date_format="YYYY-MM-DD"
        />
      </div>
    </b-form-group>
</template>
<script>
export default {
    name: 'ac-automate-switch',

    props: {
        selected: {
            type: String,
            required: false
        },

        // Select options
        options: {
            type: Array,
            required: true
        },

        // Key for output object
        query_key: {
            type: String,
            required: true
        },

        // Using buttons in radio group
        radio_buttons: {
          type: Boolean,
          default: true
        }
    },

    computed: {
      radio_group_options() {
        return this.radio_buttons ? this.options : []
      }
    },

    data() {
        return {
            selected_option: this.selected ||  this.options[0].value,
            selected_value: null,

            // ac-date-range-picker props
            single_date_picker: false,
            opens: "center",
            ranges: {
              Today: ["2019-09-18T16:42:53+05:30","2019-09-18T16:42:53+05:30"]
            },
            minDate: "2016-09-02",
            maxDate: "2019-10-02",
            dateRange: {
              startDate: "2019-05-01",
              endDate: "2019-05-10"
            },
            show_ranges: true,
            show_dropdowns: true,
        }
    },

    methods: {
        async get_query() {
            let value = this.selected_option

            if (value.type) {
              value = value.type === 'calendar' ? this.dateRange : this.selected_value
            }

            return {
                [this.query_key]: value
            }
        }
    }
}
</script>
<style>
.ac-automate-switch .vue-daterange-picker {
  width: 100%;
}
</style>
