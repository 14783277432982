<template>
  <div>
    <b-navbar toggleable="lg" :type="textcolor" class="p-0" :class="bgcolor">
      <!-- header title displayed -->
      <a
        class="navbar-brand d-flex align-items-center p-2 mr-3 font-weight-bold bg-theme-color"
        href="#"
      >
        <img :src="imageicon" alt="logo" width="40" height="40" v-if="imageicon" />
      </a>
<div class="ml-auto ml-lg-0">
      <b-navbar-toggle target="nav-collapse" class="toggle_default p-0"></b-navbar-toggle>
      <b-button
        class="d-lg-none d-inline-block toggle_default"
        variant="bg-transparent p-2"
        @click="showMobieButtons()"
      >
        <i class="fal fa-ellipsis-v fa-2x text-white m-2"></i>
      </b-button>
</div>
      <!-- Left aligned Nav Items -->
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav
          v-if="leftControls"
          :class="{'ml-auto':(alignment == 'right' && !searchOption)}"
        >
          <template v-for="(value,index) in leftControls">
            <b-nav-item
              :href="value.link_to"
              :key="'value-'+index"
              class="text-capitalize"
              :class="{'active':activeNavIndex == index}"
              v-on:click="makeActive(index)"
            >
              <template v-if="value.icon">
                <span :class="value.icon"></span>
              </template>
              {{value.label}}
            </b-nav-item>
          </template>
        </b-navbar-nav>
      </b-collapse>

        <!-- Right aligned nav items -->
        <b-navbar-nav id="temp" style="display:none" class="show-app-list ml-auto d-md-flex align-items-lg-center align-items-start">
          <li class="nav-item d-none d-lg-inline-block" v-if="searchOption">
            <form>
              <div class="input-group pr-2">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-white text-muted border-0">
                    <i class="fal fa-search"></i>
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control border-0"
                  id="inlineFormInputGroup"
                  placeholder="Search something..."
                />
              </div>
            </form>
          </li>
          <div>
            <p class="mb-0 mx-2 t-dark" v-if="text1">{{text.x}}/{{text.y}}</p>
          </div>
          <div class="d-inline-block ninedots p-0">
            <a class="btn btn-transparent btn-lg py-2 p-0" title="OnBoarding-Meter">
              <apps-onboard-meter :items="items">
                <template slot="header">
                  <b>On Board</b>
                </template>
                <template slot="footer">
                  <a @click="showInviteUsers" class="text-decoration-none">Spread Your Love</a>
                </template>
              </apps-onboard-meter>
            </a>
          </div>
          <li
            class="nav-item mr-2 position-relative iconbtn"
            v-for="(value,index) in rightControls"
            :key="'value-'+index"
          >
            <template>
              <b-nav-item-dropdown
                class="btn btn-transparent p-0 ancorpad"
                id="drop-pad"
                v-if="value.type == 'dropdown'"
                right
                no-caret
                :key="'value-'+index"
              >
                <template slot="button-content" class="p-0">
                  <i :class="value.icon" v-if="value.icon"></i>
                </template>

                <b-dropdown-item
                  :href="value.link_to"
                  v-for="(item,index) in value.items"
                  :key="'value-item'+index"
                >{{item.label}}</b-dropdown-item>
              </b-nav-item-dropdown>
            </template>
          </li>

          <li class="nav-item border-white">
            <div class="btn btn-transparent navbar-rtbtns text-left rounded-0 px-2">
              <div
                class="media"
                :class="{'d-flex flex-row-reverse':(profileiconposition == 'right')}"
              >
                <template v-for="(value,index) in profiledata">
                  <b-nav-item-dropdown
                    class="p-0 ancorpad"
                    id="drop-pad"
                    v-if="value.type == 'dropdown'"
                    right
                    no-caret
                    :key="'value-'+index"
                  >
                    <template slot="button-content" class="p-0">
                      <img
                        class="align-self-center rounded"
                        width="36"
                        height="36"
                        alt="image"
                        :src="value.img"
                        v-if="value.img"
                      />
                    </template>

                    <b-dropdown-item
                      :href="value.link_to"
                      v-for="(item,index) in value.items"
                      :key="'value-'+index"
                    >{{item.label}}</b-dropdown-item>
                  </b-nav-item-dropdown>
                  <div
                    class="media-body text-nowrap my-auto"
                    :class="[{'ml-0 mr-2 text-right' :(profileiconposition == 'right')}, {'ml-2' :(profileiconposition == 'left')}]"
                    :key="'value-'+index"
                    id="profilepos"
                  >
                    <p class="pt-0 mb-0 text-capitalize h6 t-dark" v-if="value.name">{{value.name}}</p>
                    <p
                      class="mb-0 small text-capitalize t-dark"
                      v-if="Designation"
                    >{{Designationdata}}</p>
                  </div>
                </template>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <div class="d-inline-block py-2 p-0 mr-2">
              <apps-launcher :url="appsUrl"></apps-launcher>
            </div>
          </li>
        </b-navbar-nav>
 
    </b-navbar>
  </div>
</template>
<script>
export default {
  name: "ac-navbar",

  props: {
    /**
     * @param {Bollean} text - providing navitems text color
     */
    text1: {
      type: Boolean,
      required: true
    },
    /**
     * @param {Object} text - providing navitems text color
     */
    text: {
      type: Object,
      required: true
    },
    /**
     * @param {String} textcolor - providing navitems text color
     */
    textcolor: {
      type: String,
      required: true
    },
    /**
     * @param {String} bgcolor - providing navitems text color
     */
    bgcolor: {
      type: String,
      required: true
    },
    /**
     *  @param {string} profileiconposition - provide here  profile icon position left or right
     */
    profileiconposition: {
      type: String,
      required: true
    },
    /**
     *  @param {string} imageicon - provide here image url
     */
    imageicon: {
      type: String,
      required: true
    },

    /**
     * @param {array} leftControls - left controls to be displayed on left-hand side of header
     */
    leftControls: {
      type: Array,
      required: true
    },

    /**
     * @param {string} Allignmentleft- left controls should be placed in middle in case searchoptions false even though u want it left then select left
     */
    alignment: {
      type: String,
      required: false
    },
    /**
     * @param {array} rightControls - rightcontrols to be displayed on right-hand side of header it should icons
     */
    rightControls: {
      type: Array,
      required: true
    },
    /**
     * @param {array} profiledata - Controls to be displayed on right-hand side of header it should profile details
     */
    profiledata: {
      type: Array,
      required: true
    },
    /**
     * @param {Boolean} Designation - if u dont want designation choose false
     */
    Designation: {
      type: Boolean,
      required: true
    },
    /**
     * @param {Boolean} search_bar - searchbar option available based on the user choice if u choice false then can chose left or right
     */
    searchOption: {
      type: Boolean,
      default: true
    },
    /**
     * @param {String} designation_data - designation data of the profile
     */
    Designationdata: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // nameClass: this.Designation ? "pt-0 mb-0" : "pt-0 mb-0 my-auto",
      activeNavIndex: 0,
      ishowNav: false
    };
  },
  methods: {
    makeActive: function(index) {
      this.activeNavIndex = index;
    },
    showMobieButtons: function() {
      var divObj = document.querySelectorAll("#temp");
      var ob = divObj[0].style.display;
      if (ob == "block") {
        divObj[0].style.display = "none";
      } else {
        divObj[0].style.display = "block";
      }
    },
    /**
     * Dropdown on click call handler
     * @function itemClicked
     * @public
     * @param {object} item - item clicked in dropdown
     */
    itemClicked(item) {
      // Execute appup handler workflow
      if (item.handler && this.start) {
        this.start(item.handler, {
          helper: {
            component: this,
            item: {},
            custom: item.handler_params ? JSON.parse(item.handler_params) : {}
          }
        })
      }
    }
  }
};
</script>
 
<style>
.ancorpad .nav-link {
  padding: 0 !important;
}
.navbar-dark .t-dark {
  color: #fff;
}
.navbar-nav .dropdown-menu{
    margin-top: 11px;
}
@media screen and (max-width: 820px) {
  .show-app-list .t-dark {
    display: none;
}
.show-app-list > li, .show-app-list > div {
    display: inline-block;
    margin-top: 2px;
}
.show-app-list {
    position: absolute;
    top: 56px;
    height: 56px;
    width: 100%;
    background: rebeccapurple;
    text-align: right;
}
.navbar-rtbtns {
  padding:  0.5rem  0px !important;
}
button.toggle_default.p-0.navbar-toggler {
    border-width: 0;
}
span.navbar-toggler-icon:before {
    content: "\f0c9";
    font-size: 26px;
}
span.navbar-toggler-icon{
    background-image: none !important;
    font-family: 'Font Awesome 5 Pro';
    margin-top: 5px;
    color: #fff;
}
.collapse.show a.nav-link {
    padding: 10px 20px !important;
    border-top: 1px solid rgba(255,255,255, 0.6);
}
.navbar-nav .dropdown-menu{
    position: absolute;
    margin-top: 10px;
}
}
</style>