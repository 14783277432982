<template>
  <div id="app">
    <!--<router-view/>-->
    <ac-sql-builder />
  </div>
</template>

<script>
import acSqlBuilder from '../examples/ac-sql-builder.vue'

export default {
  components: {
    acSqlBuilder,
  },
}
</script>

<style>
  html, body, #app {
    height: 100%;
    /* padding-top: 10px; */
  }
  .pointer {
    cursor: pointer;
  }
  .hover-div {
    width: 100%;
    height: 100%;
    color: white;
    background-color: rgba(0,0,0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
